import { useTrimesh } from "@react-three/cannon";
import { useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react"
import { LoopRepeat, Quaternion, Vector3 } from "three";

export const EnldesRunObject = ({ isObstacle, data, parent, delay, objectId, onHit }) => {

    const groupRef = useRef();
    const [object, setObject] = useState();
    const [colliderPosRot, setColliderPosRot] = useState();
    const objectClone = useRef(data.object.clone());


    useEffect(() => {
        groupRef.current.position.set(data.position.x, data.position.y, data.position.z);
        groupRef.current.quaternion.set(data.rotation.x, data.rotation.y, data.rotation.z, data.rotation.w);
        groupRef.current.scale.set(data.scale.x, data.scale.y, data.scale.z);

        setTimeout(() => {

            let worldPosition = new Vector3();
            groupRef.current.localToWorld(worldPosition);

            let worldQuaternion = new Quaternion();
            groupRef.current.getWorldQuaternion(worldQuaternion);

            setColliderPosRot({
                position : [worldPosition.x, worldPosition.y, worldPosition.z],
                rotation : [worldQuaternion.x, worldQuaternion.y, worldQuaternion.z, worldQuaternion.w]
            });
        }, 500);


    }, []);

    return (
        <group>
            <group ref={groupRef}>{
                data.clip ?
                    <EnldesRunObjectAnimation delay={delay} object={objectClone.current} clip={data.clip} /> :
                    <primitive object={objectClone.current} position={[0, 0, 0]} quaternion={[0, 0, 0, 0]} scale={[1, 1, 1]} />
            }</group>
            {colliderPosRot && <EnldesRunObjectPhysics
                position={colliderPosRot.position}
                rotation={colliderPosRot.rotation}
                vertics={data.collider.attributes.position.array}
                indices={data.collider.index.array}
                object={groupRef}
                objectId={objectId}
                onHit={onHit}
            />}
        </group>

    );
}

const EnldesRunObjectPhysics = ({ position, rotation, vertics, indices, object, objectId, onHit }) => {

    const [colliderRef, colliderAPI] = useTrimesh(() => ({
        mass: 1,
        type: "Static",
        args: [vertics,indices],
        position:position,
        quaternion:rotation,
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {

            object.current.visible = false;
            onHit(objectId);
        },
        onCollideEnd: (collider) => {
            // console.log("KENA");
        },
        onCollide: () => {
            // console.log("KENA");
        },
    }));

    useEffect(()=>{
        
    }, []);

    return <group ref={colliderRef}></group>
}

const EnldesRunObjectAnimation = ({ object, clip, delay }) => {

    let { actions } = useAnimations([clip], object);

    useEffect(() => {
        let keys = Object.keys(actions);

        keys.forEach(key => {
            actions[key].loop = LoopRepeat;
            actions[key].startAt(delay / 10);
            actions[key].play();
        });
    }, []);

    return (<primitive object={object} position={[0, 0, 0]} quaternion={[0, 0, 0, 0]} scale={[1, 1, 1]} />);
}