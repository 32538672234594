import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { FunctionVariableContext, GamePlayerContext, VisitorTrackerContext } from "../../context/game-context";
import "../../../css/popup.css";
import { Button, ButtonGroup, ButtonToolbar, Carousel, Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import { Audio } from './animaverse-audio';
import { useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import TradingViewWidget from '../../../plugin/trading-view-widget';

export const Popup = () => {

    const [openPopup, setOpenPopup] = useState(false);
    const [title, setTitle] = useState([]);
    const [text, setText] = useState([]);
    const [audio, setAudio] = useState("");
    const [image, setImage] = useState([]);
    const [fontSize, setFontSize] = useState(18);
    const [languages, setLanguages] = useState([]);
    const [languageId, setLanguagesId] = useState(GamePlayerContext(state => state.popupLanguageId));
    const setPopupLanguageId = GamePlayerContext(state => state.setPopupLanguageId);
    const [openLightbox, setOpenLightbox] = useState(false);
    const [changeDescByImage, setChangeDescByImage] = useState(false);
    const [slideID, setSlideID] = useState(0);
    const [category, setCategory] = useState("");
    const [imageLightbox, setImageLightbox] = useState({ src: "", isVideo: false });
    const [idPopup, setIdPopup] = useState(0);
    const [iframe, setIframe] = useState();
    const [embed, setEmbed] = useState();
    const iframeRef = useRef();
    const embedRef = useRef();

    const clickPopup = FunctionVariableContext(state => state.setClickPopup);

    const setControlToggle = GamePlayerContext(state => state.setControlToggle);

    const imgRef = useRef();
    const sceneNameRef = useRef();
    const languageIdRef = useRef(GamePlayerContext(state => state.popupLanguageId));

    const setImagelist = () => {

        if (!imgRef.current) return;
        if (imgRef.current.length > 0) {
            let images = []
            imgRef.current.forEach(img => {

                if (img.isVideo) {
                    let vid = {
                        image: `${process.env.RESOURCE_URL}/${sceneNameRef.current}/images/${img.image}`,
                        title: img.title,
                        description: img.description,
                        isVideo: img.isVideo
                    };

                    if (img.followLanguageId == undefined || img.followLanguageId == -1) {
                        images.push(vid);
                    }
                    else if (img.followLanguageId != undefined && img.followLanguageId == parseInt(languageIdRef.current)) {
                        images.push(vid);
                    }
                }
                else {
                    images.push({
                        image: `${process.env.RESOURCE_URL}/${sceneNameRef.current}/images/${img.image}`,
                        title: img.title,
                        description: img.description,
                        isVideo: img.isVideo
                    });
                }
            });
            setImage(images);
        }
    }

    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);

    useEffect(() => {
        clickPopup(function (id, sceneName) {
            setOpenPopup(true);
            setControlToggle(false);
            setSlideID(0);
            setIdPopup(id);
            setIframe();
            setEmbed();

            axios.get(`${process.env.RESOURCE_URL}/${sceneName}/popups/${id}.json`, {
                crossDomain: true
            })
                .then((res) => res.data)
                .then(async (res) => {
                    setVisitorTrackerData("open_popup", { id: idPopup, title: res.title, lang: res.language, category: res.category })
                    setLanguages(res.language);
                    setTitle(res.title);
                    setText(res.text);
                    setCategory(res.category);
                    setChangeDescByImage(res.changeDescByImage == true ? true : false);

                    if (res.iframe) {
                        setIframe(res.iframe);
                        iframeRef.current = res.iframe;
                        embedRef.current = null;
                    }
                    else if (res.embed) {
                        setEmbed(res.embed);
                        iframeRef.current = null;
                        embedRef.current = res.embed;
                    } else {
                        iframeRef.current = null;
                        embedRef.current = null;
                    }

                    console.log(res, "RES")

                    if (languageId >= res.language.length) {
                        setLanguagesId(0);
                        languageIdRef.current = 0;
                    }

                    imgRef.current = res.images;
                    sceneNameRef.current = sceneName;
                    setImagelist();

                    if (res.audio.length > 0) {
                        let audioSrc = [];
                        for (let i = 0; i < res.audio.length; i++) {
                            const element = `${process.env.RESOURCE_URL}/${sceneName}/audios/${res.audio[i]}`;
                            audioSrc.push(element);
                        }
                        setAudio(audioSrc);
                    }
                })
        });
    }, []);

    useEffect(() => {
        setImagelist();
    }, [languageId]);

    const close = () => {

        setControlToggle(true);
        setOpenPopup(false);
        setTitle([]);
        setText([]);
        setAudio("");
        setImage([]);
    }

    console.log(iframeRef.current, embedRef.current, iframeRef.current && iframeRef.current != 'https://www.tradingview.com/symbols/IDX-UNTR/');
    return <>
        <Modal
            show={openPopup}
            size="xl"
            centered
        >
            <div className="popup">

                <div className="popup-panel">
                    <Row>
                        <div className="popup-title text-left" style={{ color: title[languageId] ? "black" : "rgba(0,0,0,0)" }}>
                            <div className="text-center category">{category}</div>
                            <div className='text-center title-text'>{title[languageId] ? title[languageId].text : "None"}</div>
                            <div className='popup-header-text'>
                                {
                                    languages && languages.length > 1 && <select className="select-language" onChange={(e) => {
                                        setLanguagesId(e.target.value);
                                        setPopupLanguageId(e.target.value);
                                        languageIdRef.current = e.target.value;
                                    }}>
                                        {
                                            languages.map((lang, idx) => <option key={idx} value={idx} selected={idx == languageId}>{lang}</option>)
                                        }
                                    </select>
                                }
                            </div>
                        </div>
                    </Row>

                    <Row style={{
                        display: "flex",
                        height: "100%",
                        overflow: "auto"
                    }}>
                        {
                            iframeRef.current && iframeRef.current != 'https://www.tradingview.com/symbols/IDX-UNTR/' ? <iframe className='popup-panel-iframe' src={iframeRef.current} /> : (iframeRef.current == 'https://www.tradingview.com/symbols/IDX-UNTR/' && <TradingViewWidget />)
                        }
                        {
                            embedRef.current && <div dangerouslySetInnerHTML={{ __html: embedRef.current }}></div>
                        }
                        {
                            ((!iframeRef.current || (iframeRef.current && iframeRef.current != 'https://www.tradingview.com/symbols/IDX-UNTR/')) && !embedRef.current) && <div className="popup-panel-scroll no-padding">
                                <div className="popup-content no-padding">
                                    <Row>
                                        {
                                            (image.length > 0 || (audio && audio.length > 0)) && <div className={(text && text.length > 0) ? "col-lg-7" : "col-lg-12"}>
                                                <div className="col-sm-12">
                                                    {
                                                        image && image.length > 0 &&
                                                        <Carousel
                                                            variant="dark"
                                                            className="explore-carousel-featured"
                                                            interval={null}
                                                            onSlide={(e) => {
                                                                setSlideID(e);
                                                            }}
                                                        >
                                                            {
                                                                image.map((img, idx) => {
                                                                    return (
                                                                        <Carousel.Item key={idx}>
                                                                            <Row>
                                                                                <Col sm={12}>
                                                                                    {
                                                                                        !img.isVideo && <Zoom>
                                                                                            <img
                                                                                                className="d-block popup-image"
                                                                                                src={img.image}
                                                                                                onClick={() => {
                                                                                                    //setOpenLightbox(true);
                                                                                                    //setImageLightbox({ src: img.image, isVideo: false })
                                                                                                }}
                                                                                                zoom={200}
                                                                                            /></Zoom>
                                                                                    }
                                                                                    {
                                                                                        img.isVideo && <video
                                                                                            controls
                                                                                            className="d-block popup-image"
                                                                                            controlsList="nodownload"
                                                                                            src={img.image}
                                                                                            autoPlay
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                                <Carousel.Caption className='carausel-caption-text'>
                                                                                    <div className='text-center text-description'>
                                                                                        {img.title && img.title.length > 0 && img.title.length > languageId && <div className='image-label' dangerouslySetInnerHTML={{ __html: img.title[languageId].text }}></div>}
                                                                                        {img.description && img.description.length > 0 && img.description.length > languageId && <div className='image-description'>{img.description[languageId].text}</div>}
                                                                                    </div>
                                                                                </Carousel.Caption>
                                                                            </Row>
                                                                        </Carousel.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                    }
                                                    {
                                                        audio && audio.length > 0 && <div className="audio-player"><Audio controls className="popup-audio " src={languageId < audio.length ? audio[languageId] : audio[0]} /></div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            text && text.length > 0 &&
                                            <div className="col-lg-5 text-left popup-description">
                                                <div className="text-base text-center">

                                                    <ButtonToolbar aria-label="Toolbar with button groups" style={{ float: 'right'}}>
                                                        <ButtonGroup className="me-2" aria-label="First group">
                                                            <Button className={'btn-sm'} onClick={() => setFontSize(18)}>1x <FontAwesomeIcon icon={["fas", "font"]} /></Button> 
                                                            <Button className={'btn-sm'}  onClick={() => setFontSize(20)}>2x <FontAwesomeIcon icon={["fas", "font"]} /></Button> 
                                                            <Button className={'btn-sm'}  onClick={() => setFontSize(22)}>3x <FontAwesomeIcon icon={["fas", "font"]} /></Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                    <div className="popup-text" style={{ fontSize: `${fontSize}px`}} dangerouslySetInnerHTML={{ __html: changeDescByImage ? text[slideID].text[languageId] : text[0].text[languageId] }}>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Row>
                                </div>
                            </div>
                        }
                    </Row>
                    <button className="popup-close" onClick={() => close()}>
                        <FontAwesomeIcon icon={`fas fa-xmark`} />
                    </button>

                </div >
            </div >
        </Modal>
        <Modal show={openLightbox}
            centered
            onHide={() => setOpenLightbox(false)}
            size="xl">
            <Modal.Header closeButton={true}></Modal.Header>
            <Modal.Body>
                {
                    !imageLightbox.isVideo && <img
                        className="d-block h-100 w-100"
                        src={imageLightbox.src}
                    />
                }
            </Modal.Body>
        </Modal>
    </>
}