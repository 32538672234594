import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import { AccountContext, GameConfigContext, GamePlayerContext } from '../../context/game-context';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useContext } from 'react';
import AccountComponent from './account-component';
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';
import { SocketContext } from '../player-component/context/socket-context';

export default function LandingPage() {

    const { sceneConfig } = useContext(SocketContext);
    const setLoading = GameConfigContext((state) => state.setLoading);
    useEffect(() => {


        const bearer = localStorage.getItem(`BEARER`);
        if (sceneConfig.autoIngame && !bearer) {
            playAsGuest();
        } else {
            setLoading(false, null)
        }

        const modalSub = AccountContext.subscribe(state => state.showLogin, (data) => {
            setShow(data);
        })

        return () => {
            modalSub();
        }
    }, [])

    const [show, setShow] = useState(false);
    const setShowLogin = AccountContext((state) => state.setShowLogin);
    const gameContext = GamePlayerContext((state) => state.setGamePlayerContext);

    const playAsGuest = () => {
        gameContext({ isPlayAsGuest: true })
    }

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowLogin(false);
    }

    const {
        isAuthenticated,
    } = useWeb3Service();

    return (
        !sceneConfig.autoIngame ? <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isAuthenticated ? "Account" : "Login to Bind Account"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AccountComponent />
                </Modal.Body>
            </Modal>
            <div className="landing container-fluid">
                <div className="container">
                    <div className="col-md-6 mx-auto">
                        <Image loading="lazy" src="/animaverse-logo.png" className="landing-logo" />
                        <Image loading="lazy" src="/islanddocking.png" className="landing-image" />
                        <div className='account-login'>
                            <h4 className='pd-10'>Sign in or create account</h4>
                            <div className='account-button-list row'>
                                <div className="col">
                                    <Button variant="primary" className="btn-block full-width btn-default" onClick={() => setShowLogin(true)}>Login or Register</Button>
                                    {/* <Button variant="primary" className="btn-block full-width btn-default" onClick={() => playAsGuest()}>Using Your Wallet</Button> */}
                                </div>
                                <div className="col">
                                    <Button variant="primary" className="btn-block full-width btn-default" onClick={() => playAsGuest()}>Play as Guest</Button>
                                </div>
                            </div>
                            {/* <div className='community-guide-lines'>
                                Keep things within the bounds of our <a href="#">Community Guidelines</a>
                            </div> */}
                        </div>
                        {/* <div className="input-player-name">
                        <InputPlayerName onPlayerNameInput={() => console.log()} />
                    </div> */}
                    </div>
                </div>
            </div>
        </> : <></>
    )
}