import axios from "axios";
import { useMemo } from "react";
import * as THREE from 'three';
import { MeshBasicMaterial, MeshPhysicalMaterial, MeshStandardMaterial } from "three";
import LoadTexture from './load-texture';
const LoadMaterial = async (materialName, sceneName, dynamic, assetRef, materialDatas) => {

    const isMobile = /Android|webOS|iPhone|iPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let material = null;
    // const url = `${process.env.RESOURCE_URL}/${sceneName}/materials/${materialName}.json`;
    const url = `material_id_${materialName}`;

    if (assetRef && assetRef.current && assetRef.current[url]) {
        // console.log("MASK MATERIAL CACHE")
        material = assetRef.current[url];
    } else {
        let md = materialDatas.find((x) => x.id == materialName);
        // md = await axios.get(url, {
        //     crossDomain: true
        // })
        //     .then(async (res) => {
        //         if (assetRef) {
        //             if (!assetRef.current) assetRef.current = [];
        //             assetRef.current[url] = res.data;
        //         }
        //         return res.data;
        //     })
        //     .catch(async (_catch) => {
        //     });

        let isTransparent = (md.transparent > 0 || md.opacity !== 1) && md.transparent != 3 ? true : false;
        if( md.opacity !== 1) isTransparent = true;

        let materialData = {
            color: md.color,
            transparent: isTransparent,
            alphaTest: isTransparent ? 0 : 0.5,
            opacity: md.opacity,
            depthWrite: !isTransparent,
            side: md.transparent == 3 ? THREE.DoubleSide : THREE.FrontSide,
            name: materialName
        }

        if (md.mainTexture.length > 0) {
            const loadTexture = await LoadTexture(md.mainTexture, sceneName, md.tiles, md.offset, dynamic == true ? md.local : dynamic, assetRef);
            loadTexture.name = md.mainTexture;
            materialData.map = loadTexture;
        }

        if (md.normalTexture.length > 0 && !isMobile) {
            const normalTexture = await LoadTexture(md.normalTexture, sceneName, md.tiles, md.offset, dynamic == true ? md.local : dynamic, assetRef)
            normalTexture.name = md.normalTexture;
            materialData.normalMap = normalTexture;
        }

        if (md.ambientTexture && md.ambientTexture.length > 0) {
            const ambientTexture = await LoadTexture(md.ambientTexture, sceneName, md.tiles, md.offset, dynamic == true ? md.local : dynamic, assetRef)
            ambientTexture.name = md.ambientTexture;
            materialData.aoMap = ambientTexture;
            materialData.aoMapIntensity = 0.5;
        }

        if (md.isEmission && !isMobile) {
            materialData.emissive = md.emmisionColor;
            if (md.emmisionTexture && md.emmisionTexture.length > 0) {
                const emmisionTexture = await LoadTexture(md.emmisionTexture, sceneName, md.tiles, md.offset, dynamic == true ? md.local : dynamic, assetRef)
                emmisionTexture.name = md.emmisionTexture;
                materialData.emissiveMap = emmisionTexture;
                materialData.emissiveIntensity = 2;
            }
        }


        if (isTransparent && materialData.map) {
            // mainTextures.alphaMap =  mainTextures.map;
        }

        material = isMobile ? new MeshBasicMaterial(materialData) : new MeshPhysicalMaterial(materialData);
        if (assetRef && assetRef.current)
            assetRef.current[url] = material;
    }


    return { material };
}

export default LoadMaterial