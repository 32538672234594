import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Button, Offcanvas, Form, Modal, Container, InputGroup } from "react-bootstrap";
import { useState, useRef, useEffect } from 'react';
import { AccountContext, GameConfigContext, GamePlayerContext, LocalPlayerContext } from '../../context/game-context';
import { DayNigntSlider } from './day-night-slider';
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';
import AccountComponent from './account-component';
import { updateUserProfile } from "../../../store/user.store";
import { HardwareDevicesSetting } from './hardware-devices-setting';

export default function Settings({ name, rtcRef, ...props }) {

    const playerSettings = LocalPlayerContext((state) => state);
    const shadowRef = useRef();
    const antiAliasingRef = useRef();
    const postProRef = useRef();
    const inputNameRef = useRef();

    const playerNameRef = useRef(playerSettings.displayName)
    // console.log(shadowRef, antiAliasingRef, "REF SETTINGS")

    const [shadow, setShadow] = useState(false);
    const [antiAliasing, setAntiAliasing] = useState(false);
    const [postProcessing, setPostProcessing] = useState(GameConfigContext((state) => state.postProcessing));
    const [show, setShow] = useState(false);
    const [playerName, setPlayerName] = useState(playerNameRef.current);

    const {
        isAuthenticated,
        user,
    } = useWeb3Service();

    const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);
    useEffect(() => {
        // console.log(user);
        if (isAuthenticated) {
            updateLocalPlayerSetting({ displayName: user.displayName, name: user.displayName })
            playerNameRef.current = user.displayName;
            setPlayerName(user.displayName);
            // inputNameRef.current.value = user.displayName;
        }
    }, [isAuthenticated])

    const handleClose = () => {
        setShow(false);
        setShowLogin(false);
    }
    useEffect(() => {

        const modalSub = AccountContext.subscribe(state => state.showLogin, (data) => {
            setShow(data);
        })

        return () => {
            modalSub();
        }
    }, [])

    const getLocalPlayerSetting = GamePlayerContext((state) => state.getLocalPlayerSetting);
    const savePlayerChange = (name) => {
        const localPlayerSettingUpdated = getLocalPlayerSetting();
        setPlayerName(name);
        playerNameRef.current = name;
        updateLocalPlayerSetting({ displayName: name, name: name })

        const bearer = localStorage.getItem(`BEARER`);
        if (bearer) {
            updateUserProfile({
                ...localPlayerSettingUpdated,
                name,
            })
        }
    }
    const setShowLogin = AccountContext((state) => state.setShowLogin);
    const setSetting = GameConfigContext((state) => state.setSetting);
    const handleChange = (evt) => {
        // console.log(evt.target, evt.target.id, evt.target.checked, "CHANGED SETTINGS")
        switch (evt.target.id) {
            case "anti-aliasing-setting":
                setSetting({ antiAliasing: evt.target.checked })
                setAntiAliasing(evt.target.checked)
                break;
            case "shadow-setting":
                setSetting({ shadow: evt.target.checked })
                setShadow(evt.target.checked);
                break;
            case "post-processing-setting":
                setSetting({ postProcessing: evt.target.checked })
                setPostProcessing(evt.target.checked);
            default:
                break;
        }
    }
    return (

        <Container className="flex-grow-1" style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: "30px",
            fontSize: "18px",
        }}>
            <Row>
                <Col sm={12}>
                    <div className='text-left text-default-grey' style={{ marginTop: "10px", fontSize: "18px" }}>
                        <h5 className='text-default'>Profile</h5>
                        <Row>
                            <Col>
                                {<Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    {/* <Form.Label column sm={4}>
                                        Player Name
                                    </Form.Label>
                                    <Col sm={5}>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="text"
                                                ref={inputNameRef}
                                                placeholder="Player Name"
                                                defaultValue={playerName}
                                                onKeyUp={(e) => {
                                                    // setPlayerNameTemp(e.target.value);
                                                    playerNameRef.current = e.target.value;
                                                }}
                                                aria-describedby="player-name"
                                            />
                                            <Button onClick={() => {
                                                savePlayerChange(playerNameRef.current)
                                            }} className="btn-default" variant="outline-secondary" id="player-name">
                                                Save
                                            </Button>
                                        </InputGroup>
                                    </Col> */}
                                </Form.Group>
                                }

                                {!isAuthenticated && <Button variant="primary" className="btn-block full-width btn-default" onClick={() => setShowLogin(true)}>Login</Button>}

                                {!isAuthenticated ? <Modal
                                    show={show}
                                    backdropClassName={"modal-account-settings"}
                                    dialogClassName={"modal-account-settings"}
                                    onHide={handleClose}
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>{isAuthenticated ? "Account" : "Login to Bind Account"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AccountComponent />
                                    </Modal.Body>
                                </Modal>
                                    : <AccountComponent />
                                }
                            </Col>
                        </Row>
                        <div className='my-5'></div>
                        <h5 className='text-default'>General</h5>
                        <Row>
                            <Col xs={12} sm={4}>
                                <DayNigntSlider />
                            </Col>
                        </Row>
                        <div className='my-5'></div>
                        <h5 className='text-default'>Graphic</h5>
                        <Form>
                            <Form.Group className="mb-3 form-setting" controlId="form-setting">
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <Form.Check
                                            type="switch"
                                            label="Anti-aliasing"
                                            id="anti-aliasing-setting"
                                            inline={false}
                                            ref={antiAliasingRef}
                                            onChange={handleChange}
                                            checked={antiAliasing}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Check
                                            type="switch"
                                            label="Shadow"
                                            id="shadow-setting"
                                            ref={shadowRef}
                                            onChange={handleChange}
                                            checked={shadow}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Check
                                            type="switch"
                                            label="Frame Limiter"
                                            id="frame-limiter-setting"
                                            ref={shadowRef}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Check
                                            type="switch"
                                            label="Post Processing"
                                            id="post-processing-setting"
                                            ref={postProRef}
                                            onChange={handleChange}
                                            checked={postProcessing}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>

                        <div className='my-5'></div>
                        {/* <h5 className='text-default'>Audio</h5> */}
                        {/* <HardwareDevicesSetting rtcRef={rtcRef} /> */}
                    </div>
                </Col>
            </Row >
        </Container>
        // <Tab.Container id="settings-left-tab" defaultActiveKey="graphic">
        //     <Row>
        //         <Col sm={3}>
        //             <Nav variant="pills" className="flex-column">
        //                 <Nav.Item>
        //                     <Nav.Link eventKey="general" href="#">
        //                         General
        //                     </Nav.Link>
        //                     <Nav.Link eventKey="graphic" href="#">
        //                         Graphic
        //                     </Nav.Link>
        //                 </Nav.Item>
        //             </Nav>
        //         </Col>
        //         <Col sm={9}>
        //             <Tab.Content>
        //                 <div style={{ marginTop: "10px", fontSize: "18px" }}>
        //                     <Tab.Pane eventKey="general">
        //                         <Row>
        //                             <Col>
        //                                 <DayNigntSlider />
        //                             </Col>
        //                         </Row>
        //                     </Tab.Pane>
        //                     <Tab.Pane eventKey="graphic">
        //                         <Form>
        //                             <Form.Group className="mb-3 form-setting" controlId="form-setting">
        //                                 <Row>
        //                                     <Col>
        //                                         <Form.Check
        //                                             type="switch"
        //                                             label="Anti-aliasing"
        //                                             id="anti-aliasing-setting"
        //                                             ref={antiAliasingRef}
        //                                             onChange={handleChange}
        //                                             checked={antiAliasing}
        //                                         />
        //                                     </Col>
        //                                     <Col>
        //                                         <Form.Check
        //                                             type="switch"
        //                                             label="Shadow"
        //                                             id="shadow-setting"
        //                                             ref={shadowRef}
        //                                             onChange={handleChange}
        //                                             checked={shadow}
        //                                         />
        //                                     </Col>
        //                                     <Col>
        //                                         <Form.Check
        //                                             type="switch"
        //                                             label="Frame Limiter"
        //                                             id="frame-limiter-setting"
        //                                             ref={shadowRef}
        //                                             onChange={handleChange}
        //                                         />
        //                                     </Col>
        //                                     <Col>
        //                                         <Form.Check
        //                                             type="switch"
        //                                             label="Post Processing"
        //                                             id="post-processing-setting"
        //                                             ref={postProRef}
        //                                             onChange={handleChange}
        //                                             checked={postProcessing}
        //                                         />
        //                                     </Col>
        //                                 </Row>
        //                             </Form.Group>
        //                         </Form>
        //                     </Tab.Pane>
        //                 </div>
        //             </Tab.Content>
        //         </Col>
        //     </Row>
        // </Tab.Container>
    );
}