import { useContext, useState } from "react";
import "../../../css/change-scene-button.css";
import { GamePlayerContext, SceneContext } from "../../context/game-context";
import { PlayerContext } from "../player-component/context/player-context";
import { SocketContext } from "../player-component/context/socket-context";

export const ChangeSceneCircular = ({ onChangeScene, sceneID }) => {

    const { subscene } = useContext(SocketContext);
    let sceneName = ["karafuruland", "house_a", "house_b", "house_c", "sandbox", "ut"];
    const firstScene = subscene ? subscene.find((r) => r.index == sceneID.current).index : sceneName[0];
    let [currentScene, setCurrentScene] = useState(sceneID && sceneID.current ? firstScene : 0);

    // let checkScene = sceneID;

    const setScene = SceneContext((state) => state.setScene);
    const changeScene = (idx) => {

        if (currentScene !== idx) {
            // if (onChangeScene.current !== undefined) onChangeScene.current(sceneName[sceneID]);

            /* UNTUK UPLOAD */
            window.history.replaceState(null, null, "/" + idx)
            // window.history.replaceState(null, null, "/animaverse/"+ sceneName[sceneID])
            setCurrentScene(idx);
            setScene(idx)
            // console.log(sceneName[sceneID])
        }
    }

    return (
        <div className="change-scene">
            <div className="change-scene-base circular">
                {subscene && subscene.map((r, idx) => <button
                    key={idx}
                    disabled={currentScene === r.index}
                    onClick={e => changeScene(r.index)}
                    className={"change-scene-button"}>
                    {r.name}
                </button>)}
                {/* {<button disabled={currentScene === 0} onClick={e => changeScene(0)} className={"change-scene-button"}>Karafuru</button>}
                {<button disabled={currentScene === 1} onClick={e => changeScene(1)} className={"change-scene-button"}>House A</button>}
                {<button disabled={currentScene === 2} onClick={e => changeScene(2)} className={"change-scene-button"}>House B</button>}
                {<button disabled={currentScene === 3} onClick={e => changeScene(3)} className={"change-scene-button"}>House C</button>}
                {<button disabled={currentScene === 4} onClick={e => changeScene(4)} className={"change-scene-button"}>Sandbox</button>}
                {<button disabled={currentScene === 5} onClick={e => changeScene(5)} className={"change-scene-button"}>UT</button>} */}
            </div>
        </div>

    );
}