import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';

export default function WelcomePageDG({ handleSkip, handleContinue }) {
    // console.log(`/img/logo/${minimap.sceneName}/logo.png`);
    const [currentStep, setCurrentStep] = useState(1);
    return (<>
        <Container>
            <Modal show={true} className="welcome-page-dg" centered>
                <Modal.Body className="no-padding">

                    <div className={"image-background"} style={{ backgroundImage: "url('/img/loading/dg/bg-welcome-page.png')" }}>

                        <div className='logo-section'>
                            <Image src="/img/logo/dg/dg-con-small.png" className={"logo"} />
                        </div>
                    </div>
                    <div className='text-section'>
                        {currentStep == 1 && <>
                            <h5>Selamat datang di DG Con 2022!</h5>
                            <br />
                            <p>
                                Berikut adalah hal yang dapat kamu lakukan:

                            </p>
                            <p>
                                1. Voting tim dan personil
                                favoritmu di DG Award

                            </p>
                            <p>
                                2. Mengumpulkan EXP
                                dengan melakukan login

                            </p>
                            <p>
                                3. Redeem EXP dengan hadiah
                                langsung tanpa diundi

                            </p>
                            <p>
                                4. Menyaksikan pertunjukan secara live
                                seperti apa yang ada di offline event
                            </p>
                            <br />
                        </>
                        }
                        {/* {currentStep == 2 && <>

                            <h5>Tentang DG Con 2022</h5>
                            <br />
                            <p>
                                Dunia sudah hancur dan DG Con adalah
                                tempat penampungan bagi para penyintas
                                paska kiamat. Hanya segelintir manusia
                                saja yang masih selamat.
                            </p>

                            <p>
                                Hari ini adalah 'The Great Reset'
                                untuk masa depan yang lebih baik<br />
                                Mari kita bangun dunia baru bersama-sama!
                            </p>
                            <br />
                        </>
                        } */}
                        <Row>
                            <Col className='text-center'>
                                <Button variant="default-dark" className="w-100" style={{ maxWidth: '180px' }}
                                    onClick={e => handleContinue()}
                                >
                                    Lanjut
                                </Button>
                            </Col>
                        </Row>
                        {currentStep == 1 &&
                            <Row>
                                <Col className='text-center' style={{ marginTop: '10px' }}>
                                    <Button variant="default-transparent-black" className="w-100" style={{ maxWidth: '180px' }}
                                        onClick={e => handleSkip()}
                                    >
                                        Skip
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </div>

                </Modal.Body>
            </Modal>
        </Container>
    </>
    )
}
