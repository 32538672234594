import { PositionalAudio } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as THREE from "three";
import { RtcLocalContext } from "../../context/game-context";
import { getStreamByIdPlayer } from "../../rtc-manager";
import { PositionalAudioHelper } from "../../utils/libs/PositionalAudioHelper";

export const PlayerAudioPosition = ({ name, playerModel, localPlayerPosition }) => {
    const [streamReady, isStreamReady] = useState(false);
    const getStreamPlayer = RtcLocalContext(state => state.getStreamPlayer);
    const positionalAudioElement = useRef(getStreamPlayer(name));

    const { camera } = useThree();
    useEffect(() => {
        const audioStreamListen = RtcLocalContext.subscribe(state => state.listStream[name], (data) => {

            // isStreamReady(true);
            // setIsAudioReady(true);

            // const listener = new THREE.AudioListener();
            // camera.add(listener);

            const streamPlayer = getStreamByIdPlayer(name);

            // const audioElement = document.getElementById(streamPlayer.mediaElement.id);
            // positionalAudioElement.current = new THREE.PositionalAudio(listener);
            // positionalAudioElement.current.setMediaStreamSource(streamPlayer.stream);
            // positionalAudioElement.current.setRefDistance(10);
            // positionalAudioElement.current.setMaxDistance(10);
            // positionalAudioElement.current.setDirectionalCone(180, 230, 0.1);
            // const helper = new PositionalAudioHelper(positionalAudioElement.current, 10);
            // positionalAudioElement.current.add(helper);

            positionalAudioElement.current = streamPlayer.stream;

        })

        return () => {
            audioStreamListen();
        }
    }, [])
    // console.log("PLAYER AUDIO POSITION INITIALIZED", name)

    // console.log("PLAYER AUDIO POSITION LISTEN STREAM", positionalAudioElement.current, streamReady)
    return (
        <>
            {positionalAudioElement.current && <PositionalAudio setRefDistance={10} setMaxDistance={10} setDirectionalCone={[180, 230, .1]} setMediaStreamSource={positionalAudioElement.current} />}
        </>
    )
}
