import { useState, useEffect } from "react";
import { Container, Fade, Image } from "react-bootstrap";
import { GameConfigContext, SceneContext } from "../../context/game-context";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useProgress } from "@react-three/drei";

export default function Loading({ progress }) {
    const loadingProgress = useProgress();
    const defaultLoadingFlag = GameConfigContext(state => state.loading);
    const [loadProgress, setLoadProgress] = useState(SceneContext(state => state.loadProgress));
    const [loading, setLoading] = useState({
        loading: defaultLoadingFlag,
        loadingMessage: 'Loading ...',
        loadingBackground: null,
        loadingLogo: null,
        loadingText: '',
        loadingColorClass: '',
    });

    useEffect(() => {

        const loadingSub = GameConfigContext.subscribe(state => ({ loading: state.loading, loadingMessage: state.loadingMessage, loadingLogo: state.loadingLogo, loadingBackground: state.loadingBackground, text: state.loadingTextServer, color: state.loadingColorClass }), (data) => {
            setLoading({
                ...loading,
                loading: data.loading,
                loadingText: data.text && data.text,
                loadingMessage: data.loadingMessage ? data.loadingMessage : "Loading",
                loadingLogo: data.loadingLogo ? data.loadingLogo : "",
                loadingBackground: data.loadingBackground ? data.loadingBackground : "",
                loadingColorClass: data.color
            })
        })

        const loadProgress = SceneContext.subscribe(state => state.loadProgress, (data) => {
            setLoadProgress(data);
        });

        return () => {
            loadingSub();
            loadProgress();
        }
    }, [])

    // console.log(progress, "PROGRESS");
    return (
        <>
            <Fade in={loading.loading} className="fade-transition">
                <div className="loading-cover">
                    <div className="loading" style={{ backgroundImage: loading.loadingBackground ? `url(${loading.loadingBackground})` : '' }}>
                        <Row className="logo-bar-section mx-auto my-10 flex-column">
                            <Col style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}><Image src={loading.loadingLogo && loading.loadingLogo.length > 0 ? loading.loadingLogo : './img/loading.gif'} className={"w-100"} rounded={true} style={{ maxWidth: '300px' }} /></Col>

                            <Col className="loading-text-section" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <h5>{loading.loadingText}</h5>
                            </Col>
                            <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="w-100">
                                    <Col className="loading-bar-message">{loading.loadingMessage}</Col>
                                    <div className="mx-auto" style={{ position: 'relative', maxWidth: '400px' }}>
                                        <div className="loading-bar-bg mx-auto"></div>
                                        {loading.loading && progress && <ProgressBar className={`progress-bar-loading ${loading.loadingColorClass}`} isChild={true} now={loadProgress} animated={false}/>}
                                    </div>
                                    <Col className="loading-bar-trademark">
                                        powered by <Image src={'./img/animaverse-logo2.png'} style={{ height: '24px' }} />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fade>
        </>
        // <div className={`loading ${loading.loading ? "" : "hide"}`}>
        //     {loading.loadingMessage}
        // </div>

    )
}