import { useReducer, useRef, useState } from "react"
import "../../../css/hosted-room.css"

export const HostedRoom = ({joinHostedRoom, createHostedRoom}) => {

    let [isMenu, setIsMenu] = useState(false);
    let hostInput = useRef();

    const openMenu = () => {
        setIsMenu(!isMenu);
    }

    const onJoinRoom = () => {
        if(joinHostedRoom && joinHostedRoom.current && hostInput.current.value.length > 0) 
        {
            joinHostedRoom.current(hostInput.current.value);
            setIsMenu(false);
        }
    }

    const onCreateRoom = () => {
        if(createHostedRoom && createHostedRoom.current  && hostInput.current.value.length > 0) 
        {
            createHostedRoom.current(hostInput.current.value);
            setIsMenu(false);
        }
    }

    return (
        <div className="hosted-room">
            <button className="hosted-room-button" onClick={openMenu}>HOSTED ROOM</button>
            {
                isMenu &&
                <div className="hosted-room-join">
                    <input ref={hostInput} type="text" className="hosted-room-menu-input" placeholder="Room Name"/>
                    <button className="hosted-room-menu-join-button" onClick={onJoinRoom}>JOIN</button>
                    <button className="hosted-room-menu-join-button" onClick={onCreateRoom}>CREATE</button>
                </div>
            }
        </div>);
}