import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";
import '../../../css/endlesrun-button.css'
import { useEffect } from "react";
import { useState } from "react";

export const EndlesRunButton = () => {

    const buttonLeft = FunctionVariableContext(state => state.endlessRunButtonLeft);
    const buttonRight = FunctionVariableContext(state => state.endlessRunButtonRight);
    let [endlessRunState, setEndlessRunState] = useState(SceneContext((state) => state.endlessRunState));
    let [endlessRunScore, setEndlessRunScore] = useState(SceneContext((state) => state.endlessRunScore));
    let [endlessRunSeat, setEndlessRunSeat] = useState(SceneContext((state) => state.endlessRunSeat));
    let setScene = SceneContext((state) => state.setScene);

    useEffect(() => {
        let endlessRunStateSubs = SceneContext.subscribe((state) => state.endlessRunState, (data) => {
            setEndlessRunState(data);
        });

        let endlessRunScoreSubs = SceneContext.subscribe((state) => state.endlessRunScore, (data) => {
            setEndlessRunScore(data);
        });
        
        let endlessRunSeatSubs = SceneContext.subscribe((state) => state.endlessRunSeat, (data) => {
            setEndlessRunSeat(data);
        });

        return () => {
            endlessRunStateSubs();
            endlessRunScoreSubs();
            endlessRunSeatSubs();
        }
    }, []);

    return <div>
        {endlessRunState == 'play' && <div className="endless-run-play-score-base"><div className="endless-run-play-score-title">SCORE</div><div className="endless-run-play-score">{endlessRunScore}</div></div>}
        {endlessRunState == 'waiting' && <div className="endless-run-state-base"><div className="endless-run-state">Waiting for another player...</div></div>}
        {endlessRunState == 'play' && ((endlessRunSeat + 1) % 2) == 0 && <button className="endles-run-button-left" onClick={() => { if (buttonLeft) buttonLeft(); }}><FontAwesomeIcon icon="arrow-left" /></button>}
        {endlessRunState == 'play' && ((endlessRunSeat + 1) % 2) != 0 && <button className="endles-run-button-right" onClick={() => { if (buttonRight) buttonRight(); }}><FontAwesomeIcon icon="arrow-right" /></button>}
        {endlessRunState == 'play' && <div className="endless-run-play-score">{endlessRunScore}</div>}
        {endlessRunState == 'result' &&
            <div className="endless-run-state-base">
                <div className="endless-run-play-result-base">
                    <div className="endless-run-play-score">FINISH</div>
                    <br /><br />
                    <div className="endless-run-play-score-title">SCORE</div>
                    <div className="endless-run-play-score">{endlessRunScore}</div>
                    <button className="endless-run-retry-button" onClick={() => {
                        window.location.reload();
                    }}>RETRY</button>
                    <button className="endless-run-retry-button" onClick={() => {
                        window.history.replaceState(null, null, "/dgplaza");
                        setScene('dgplaza');
                    }}>GO TO DG PLAZA</button>
                </div>
            </div>}
    </div>
}