import { useTrimesh } from "@react-three/cannon";
import { useEffect, useRef } from "react";
import { FunctionVariableContext } from "../../context/game-context";

export const TeleportDetection = (props) => {
    const vertics = props.data.geometry.geometry.attributes.position.array;
    const indices = props.data.geometry.geometry.index.array;
    const onCollide = useRef(FunctionVariableContext(state => state.onTeleportDetection)) ;
    const isTrigger = useRef(false);

    const playerPhysicsMaterial = {
        friction: 0,
        restitution: 0,
        contactEquationStiffness: 0,
        contactEquationRelaxation: 3,
        frictionEquationStiffness: 0,
        frictionEquationRelaxation: 3
    }

    useEffect(() => {
        const onCollideSubs = FunctionVariableContext.subscribe((state)=>state.onTeleportDetection, (data)=>{
            onCollide.current = data;
        });

        return ()=>{
            onCollideSubs();
        }
    });

    const [ref] = useTrimesh(() => ({
        mass: 0,
        position: props.data.geometry.geometry.position,
        type: "Static",
        args: [vertics, indices],
        material: playerPhysicsMaterial,
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            if (onCollide.current) onCollide.current(props.data.data.target, collider.body);
        },
    }));

    return (
        <group ref={ref} dispose={null}></group>
    )
}