import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { DoubleSide } from "three";
import { RtcLocalContext } from "../../context/game-context";
import { LoadClonedGeometry } from "./utils";

export const VideoBanner = ({ keyObject, data, loadedGeometry, sceneName, geometry }) => {

    const [isLoaded, SetLoaded] = useState(false);
    const mesh = useRef();


    const originalVideo =
        Object.assign(
            document.createElement('video'),
            {
                src: data.type == 0 ? `${process.env.RESOURCE_URL}/videos/` + data.link : data.link,
                crossOrigin: 'Anonymous',
                loop: true,
                autoPlay: true
            }
        );
    const [video, setVideo] = useState(() => originalVideo)

    useEffect(() => {
        video.play();
        video.muted = true;

        const shareScreenListen = RtcLocalContext.subscribe(state => state.screen,
            (data) => {
                if (data != null) {
                    setVideo(document.getElementById('video-share-screen-elem'));
                } else {
                    setVideo(originalVideo);
                }
            });

        return () => {
            video.pause();
            video.remove();
            shareScreenListen();
        }
    }, []);

    useEffect(() => {

        const LoadData = async () => {

            SetLoaded(true);
        }

        LoadData();
    }, []);

    return (
        isLoaded &&
        <mesh
            ref={mesh}
            key={`banner-${keyObject}`}
            geometry={geometry}
            castShadow
            receiveShadow
            onClick={
                (e) => {
                    video.play();
                    video.muted = !video.muted;
                }}
            dispose={null}
        >
            <meshStandardMaterial emissive={"white"} side={DoubleSide}>
                <videoTexture attach="map" args={[video]} />
                <videoTexture attach="emissiveMap" args={[video]} />
            </meshStandardMaterial>
        </mesh>
    );
}