import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { NotificationContext } from '../../context/game-context';
import ToastComponent from './toast-component';

export default function ToastNotification() {
    const [position, setPosition] = useState('top-start');
    const [listToast, setListToast] = useState([]);


    useState(() => {

        const notificationToastSub = NotificationContext.subscribe(state => state.toast, (data) => {

            if (data) {
                setListToast(oldArray => [...oldArray, data]);
            }

        });
        return () => {
            notificationToastSub();
        }
    }, [])

    const deleteNotif = (data) => {

        setListToast(oldArray => {
            const arr = oldArray.filter((r) => r.ts != data.ts); 
            return arr.length == 0 ? [] : arr;
        });
    }

    // console.log(listToast);
    return (

        <div className='toast-content'>
            <div
                aria-live="polite"
                aria-atomic="true"
                className="position-relative"
                style={{ minHeight: '240px' }}
            >
                {listToast.map((dt) => <ToastComponent data={dt} callbackClose={() => deleteNotif(dt)} />)}
            </div>
        </div>
    );

}
