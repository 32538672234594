import * as THREE from 'three';

export default async function LoadTexture(textureName, sceneName, tiles, offset, isLocal, assetRef) {

    let url = isLocal ? `${process.env.RESOURCE_URL}/${sceneName}/textures/${textureName}` : `${process.env.RESOURCE_URL}/${sceneName}/textures/${textureName}`;

    if (assetRef && assetRef.current && assetRef.current[url]) {
        return assetRef.current[url];
    } else {

        const loadTexture = await new THREE.TextureLoader().loadAsync(url);
        loadTexture.repeat.set(tiles.x, tiles.y);
        loadTexture.offset.set(offset.x, offset.y);
        loadTexture.wrapS = THREE.RepeatWrapping;
        loadTexture.wrapT = THREE.RepeatWrapping;
        if (assetRef && assetRef.current) {
            assetRef.current[url] = loadTexture;
            return assetRef.current[url];
        } else {
            return loadTexture;
        }
    }
}
