
import * as io from 'socket.io-client';
import hark from 'hark';
import RTCMultiConnection from "rtcmulticonnection";
import AudioControls from './component/player-component/audio-controls';
import { useThree } from '@react-three/fiber';
import VideoControls from './component/player-component/video-controls';
import { AudioContext } from './context/game-context';
window.io = io


const listStream = [];
let currentRoom = '';
let localStream = null;
let eventSetShareScreen = null;

const RtcConnection = ({ room, playerId, playerName, setShareScreen, micId, videoId, setLocalSpeaking }) => {


    eventSetShareScreen = setShareScreen;
    const rtc = new RTCMultiConnection();
    rtc.socketMessageEvent = `room-${room}-event`;
    rtc.autoCloseEntireSession = false;
    rtc.socketURL = 'https://avmmo3.virtu.ninja/';
    rtc.session = {
        data: true,
        audio: true,
        video: false,
    };
    rtc.bandwidth = {
        // audio: 64,
        video: 128,
        screen: 300
    };
    rtc.userid = playerId;

    // checking if any specific user mic
    if (!micId && !videoId) {
        rtc.mediaConstraints = {
            audio: true,
            video: false,
        };
    } else {

        const audioDeviceDefault = {
            audio: true
        };
        const videoDeviceDefault = {
            video: false
        }

        if (rtc.DetectRTC.browser.name === 'Firefox') {
            if (micId != '') {
                audioDeviceDefault.audio = {
                    deviceId: micId
                }
            }

            if (videoId != '') {
                videoDeviceFF.video = {
                    deviceId: videoId
                }
            }

        } else {
            if (micId != '') {
                audioDeviceDefault.audio = {
                    mandatory: {},
                    optional: [{
                        sourceId: micId
                    }]
                }
            }

            if (videoId != '') {
                videoDeviceFF.video = {
                    mandatory: {},
                    optional: [{
                        sourceId: videoId
                    }]
                }
            }
        }
        rtc.mediaConstraints = {
            ...audioDeviceDefault,
            ...videoDeviceDefault
        };
    }

    rtc.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: false
    };
    rtc.extra = {
        fullName: playerName,
        joinedAt: (new Date).toISOString()
    };


    rtc.onstream = function (event) {
        if (event.type == 'local') {
            localStream = event;
            const harkEvent = hark(event.stream);
            rtc.setCustomSocketEvent('onspeaking');
            rtc.setCustomSocketEvent('stopped_speaking');
            harkEvent.on('speaking', function () {
                console.log('Speaking!', rtc.socket);
                setLocalSpeaking(true);
                if (rtc.socket)
                    rtc.socket.emit("onspeaking", event);
            });
            harkEvent.on('stopped_speaking', function () {
                console.log('Stopped Speaking!', rtc.socket);
                setLocalSpeaking(false);
                if (rtc.socket)
                    rtc.socket.emit("stopped_speaking", event);
            });
            //   , {
            //     onspeaking: function() {
            //         console.log("ON SPEAKING HARK")
            //         rtc.onHarkSpeaking(event);
            //     },
            //     onsilence: function() {
            //         console.log("ON SILENCE HARK")
            //         rtc.onHarkSilence(event);
            //     },
            //     onvolumechange: function(volume, threshold) {
            //         console.log("ON VOLUME CHANGE HARK")
            //         if (!rtc.onHarkVolumeChange) {
            //             return;
            //         }
            //         rtc.onHarkVolumeChange(merge({
            //             volume: volume,
            //             threshold: threshold
            //         }, event));
            //     }
            // });
            // console.log(event, harkEvent, "LOCAL STREAM");
        } else {
            if (!event.stream.isScreen) {
                event.stream.mute()
            }
        }

        if (listStream.find(r => r.streamid == event.streamid) == null) {
            listStream.push(event);
            if (!event.stream.isScreen) {
                event.mediaElement.addEventListener('volumechange', (event) => {
                    if (event.target.targetVolume)
                        event.target.volume = parseFloat(event.target.targetVolume).toFixed(2);
                })
                document.getElementsByClassName('audio-list')[0].appendChild(event.mediaElement);
                // handlingStreamPlayer(event.userid)
                // rtc.handlingStreamPlayer(event.userid)
            } else {

                // console.log(document.getElementById('video-share-screen-elem'));
                document.getElementById('video-share-screen-elem').srcObject = event.stream;
                document.getElementById('video-share-screen-elem').onended = document.getElementById('video-share-screen-elem').onmute = document.getElementById('video-share-screen-elem').oninactive = function () {
                    // console.log("SHARE SCREEN REMOTE ENDED", event.stream)
                    document.getElementById('video-share-screen-elem').srcObject = null;
                    setShareScreen(null);
                }
                setShareScreen(document.getElementById('video-share-screen-elem'));
            }
        }
    };

    rtc.onstreamended = function (event) {
        // console.log(event, "EVENT ON ENDED")
        var mediaElement = document.getElementById(event.streamid);
        if (mediaElement) {
            mediaElement.parentNode.removeChild(mediaElement);
        }

        if (event.stream.isScreen) {
            setShareScreen(null);
            document.getElementById('video-share-screen-elem').srcObject = null;
        }
    }

    rtc.onMediaError = function (e) {
        // rtc.dontAttachStream = true;
        rtc.dontCaptureUserMedia = true;
        // rtc.session.oneway = true;
        rtc.openOrJoin(room);
        currentRoom = room;
    };

    rtc.onclose = rtc.onerror = rtc.onleave = function (event) {
        rtc.onUserStatusChanged(event);
    };

    rtc.openOrJoin(room);
    currentRoom = room;
    return rtc
}

function AudioChangeRoom(roomId, rtc) {
    if (rtc) {
        rtc.leave();
        // rtc.closeSocket();
        // console.log("CHANGE ROOM TO ", roomId)
        rtc.openOrJoin(roomId);
    }
}


async function ResetAudioConnection(stream, rtc) {
    // const room = rtc.sessionid;
    // const playerId = rtc.userid;
    // const playerName = rtc.extra.displayName;
    // rtc.leave();
    // RtcConnection({
    //     room,
    //     playerId,
    //     playerName,
    //     eventSetShareScreen,
    // })


    // stream.isAudio = true;
    // stream.streamid = stream.id;
    // stream.type = 'local';

    // var audioElement = document.createElement('audio');
    // audioElement.srcObject = stream;

    // rtc.StreamsHandler.setHandlers(stream, true, rtc);
    // var streamEvent = {
    //     isAudioMuted: true,
    //     type: 'local',
    //     stream: stream,
    //     streamid: stream.id,
    //     mediaElement: audioElement
    // };

    // rtc.streamEvents[streamEvent.streamid] = {
    //     stream: streamEvent.stream,
    //     type: 'local',
    //     mediaElement: streamEvent.mediaElement,
    //     userid: rtc.userid,
    //     extra: rtc.extra,
    //     streamid: streamEvent.streamid,
    //     isAudioMuted: true
    // };
    // // remove old stream audio
    // const listStream = rtc.streamEvents.selectAll();
    // rtc.removeStream(listStream.find((r) => r.type == 'local' && r.stream.isAudio).streamid);

    // rtc.addStream(stream);
    // rtc.onstream(streamEvent);
    // // rtc.attachStreams.push(stream)
    // // rtc.renegotiate();

    const type = 'audio';
    const track = stream.getAudioTracks()[0];
    rtc.replaceTrack(stream);
    // rtc.onstream(streamEvent);
    // Check track and type are valid
    // if (!track || !type) reject(new Error('You don\'t set track or type track.'));

    // console.log("TRACK AND TYPE AVAILABLE")
    // // Check that track is not ended
    // if (track.readyState === 'ended') reject(new Error('You don\'t can\'t replace with an "ended" track.'));
    // console.log("TRACK READY STATE NOT ENDED")
    // Wait for replace to all peers and ready
    // await  Promise.all(rtc.getAllParticipants().map((pid) => {
    //     // Get peer connection of every participant
    //     const { peer } = rtc.peers[pid];
    //     console.log(peer, "LIST PEER")
    //     // Check that peer have senders or next peer
    //     if (!peer.getSenders) return;
    //     console.log(peer, "GET SENDER PEER AVAILABLE")
    //     // Get all 
    //     peer.getSenders().forEach((sender) => {
    //         // Check the sender of peer and track, or next sender
    //         if (!sender || !sender.track) return;
    //         console.log(sender, "SENDER PEER TRACK AVAILABLE")
    //         //  Get the track type and replace track with the new stream if match
    //         if (sender.track.kind === type && track) rtc.replaceTrack(track);
    //         console.log(sender, track, sender.replaceTrack, "PEER TRACK REPLACED")
    //     });

    // }));

    // rtc.renegotiate()
}

function getLocalPlayerStream() {
    // console.log(listStream, listStream.find((r) => r.type == 'local'), "GET LOCAL STREAM")
    // return listStream.find((r) => r.type == 'local');

    return localStream;
}


function getListStream() {
    return listStream;
}


function getStreamByIdPlayer(playerId) {
    // console.log(listStream, "LIST STREAM GET STREAM")
    // console.log(listStream, listStream.find((r) => r.type == 'local'), "GET LOCAL STREAM")
    return listStream.find((r) => r.userid == playerId);
}


function LocalPlayerAudio({ state }) {
    // console.log("LOCAL  PLAYER AUDIO", state);
    if (state == 'push_to_talk') {
        LocalPushToTalk()
    } else if (state == 'mute') {
        MuteLocalPlayer();
    } else if (state == 'open_mic') {
        OpenMicLocalPlayer();
    }

    return <></>
}

// function GetObjectByName(name) {
//     const { scene } = useThree();
//     const object = scene.getObjectByName(name);
//     return object;
// }

// function ShowSpeaker(name) {
//     const obj = GetObjectByName(name);
//     obj.visible = true;
// }

// function HideSpeaker(name) {
//     const obj = GetObjectByName(name);
//     obj.visible = false;
// }

// function resetAssignPlayerSpeakerRef(ref) {
//     console.log(ref, "RESET SPEAKERREF")
//     listPlayerSpeakerRef = ref.filter(r => r != null);
// }

function MuteLocalPlayer() {
    const audioLocal = getLocalPlayerStream();
    if (audioLocal) {
        audioLocal.muteType = 'audio';
        audioLocal.stream.mute('audio');
    }
}

function OpenMicLocalPlayer() {
    const audioLocal = getLocalPlayerStream();
    if (audioLocal) {
        audioLocal.unmuteType = 'audio';
        audioLocal.stream.unmute('audio');
    }
}

function ToggleVideo() {
    const { openCamera } = VideoControls();
    const streamLocal = getLocalPlayerStream();
    // console.log("TOGGLEVIDEO", streamLocal, openCamera)
    if (streamLocal) {
        if (openCamera) {
            streamLocal.stream.unmute('video');
        } else if (!openCamera) {
            streamLocal.stream.mute('video');
        }

    }
}

function LocalPushToTalk() {
    const { openMic } = AudioControls();
    const audioLocal = getLocalPlayerStream();
    if (audioLocal) {
        if (openMic) {
            audioLocal.stream.unmute('audio');
        } else {
            audioLocal.stream.mute('audio');
        }
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export {
    RtcConnection,
    LocalPushToTalk,
    getLocalPlayerStream,
    LocalPlayerAudio,
    AudioChangeRoom,
    ToggleVideo,
    ResetAudioConnection,
    getStreamByIdPlayer,
    getListStream
    // resetAssignPlayerSpeakerRef
};