import axios from "axios";
import * as THREE from 'three';

export default async function LoadObject(objectName, sceneName, dynamicPath, assetRef, meshDatas) {

    // const url = !dynamicPath ? `${process.env.RESOURCE_URL}/${sceneName}/meshes/${objectName}.json` : `${process.env.RESOURCE_URL}/${sceneName}/dynamic/meshes/${objectName}.json`;

    const url = `mesh_id_${objectName}`;

    let dataSO = null;
    if (assetRef && assetRef.current && assetRef.current[url]) {
        dataSO = assetRef.current[url];
    } else {
        // get json material
        let data = meshDatas.find(x => x.id == objectName);
        // dataSO = await axios.get(url, {
        //     crossDomain: true
        // })
        //     // eslint-disable-next-line no-loop-func
        //     .then(async (resSo) => {
        //         if (assetRef) {
        //             if (!assetRef.current) assetRef.current = [];
        //             assetRef.current[url] = resSo.data;
        //         }
        //         return resSo.data;
        //     })
        //     .catch(_catchSo => {
        //         let dynamic = dynamicPath == true;
        //     }
        //     );

        let positionFloatArray = [];
        let uvFloatArray = [];
        let indicesFloatArray = data.index.reverse();

        // await Promise.all(dataSO.index.reverse().map((sori, iso) => {
        //     const sor = dataSO.position[sori];
        //     positionFloatArray.push(-sor.x / 10000);
        //     positionFloatArray.push(sor.y / 10000);
        //     positionFloatArray.push(sor.z / 10000);

        //     const sorUv = dataSO.uv[sori];
        //     uvFloatArray.push(sorUv.x / 10000);
        //     uvFloatArray.push(sorUv.y / 10000);
        //     indicesFloatArray.push(sori);
        // }));
        await Promise.all(
            data.position.map((sor) => {
                positionFloatArray.push(-sor.x / 10000);
                positionFloatArray.push(sor.y / 10000);
                positionFloatArray.push(sor.z / 10000);
            }),
            data.uv.map((sorUv) => {
                uvFloatArray.push(sorUv.x / 10000);
                uvFloatArray.push(sorUv.y / 10000);
            })
        );


        positionFloatArray = new Float32Array(positionFloatArray);
        uvFloatArray = new Float32Array(uvFloatArray);

        let bufferGeometry = new THREE.BufferGeometry();
        bufferGeometry.setIndex(indicesFloatArray);
        bufferGeometry.setAttribute('position', new THREE.BufferAttribute(positionFloatArray, 3));
        bufferGeometry.setAttribute('uv', new THREE.BufferAttribute(uvFloatArray, 2));
        bufferGeometry.computeVertexNormals();

        if (assetRef) {
            assetRef.current[url] = bufferGeometry;
            
        }
        dataSO = bufferGeometry;
    }


    return dataSO;
} 