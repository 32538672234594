import { useEffect, useRef, useState } from "react"
import { FunctionVariableContext, GamePlayerContext, SceneContext } from "../../context/game-context";
import '../../../css/minimap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from "react-bootstrap";
import { Vector3 } from "three";

export const MiniMap = ({ handleClose, setShowModal }) => {

    // const [isShow, setShow] = useState(true);

    const setOpenMinimap = FunctionVariableContext((state) => state.setOpenMinimap);
    const [minimap, setMinimap] = useState(SceneContext((state) => state.minimap));
    const [playerModel, setPlayerModel] = useState(GamePlayerContext((state) => state.localPlayerObject));
    const [controlToggle, setControlToggle] = useState(GamePlayerContext((state) => state.controlToggle));
    const scene360 = useRef(GamePlayerContext((state) => state.scene360));
    const changePanoramicID = useRef(FunctionVariableContext((state)=> state.changePanoramicID));
    
    const setScene = SceneContext(state => state.setScene);

    const playerPin = useRef();
    const imageRef = useRef();
    const onTeleportDetection = useRef(FunctionVariableContext((state) => state.onTeleportDetection));

    const [nextTeleportData, setNextTeleportData] = useState();
    const teleportData = useRef();

    const setPlayerLocation = () => {
        let minXmaxZ = new Vector3(-minimap.minXmaxZ.x / 10000, minimap.minXmaxZ.y / 10000, minimap.minXmaxZ.z / 10000);
        let maxXminZ = new Vector3(-minimap.maxXminZ.x / 10000, minimap.maxXminZ.y / 10000, minimap.maxXminZ.z / 10000);

        if(playerModel == undefined) {
            playerPin.current.style.display = 'none';
            return;
        }
        let x = 1 - ((minXmaxZ.x - playerModel.current.position.x) / (minXmaxZ.x - maxXminZ.x));
        let z = ((minXmaxZ.z - playerModel.current.position.z) / (minXmaxZ.z - maxXminZ.z));

        playerPin.current.style.display = 'block';
        playerPin.current.style.top = `${(x * 100)}%`;
        playerPin.current.style.left = `${(z * 100)}%`;

        let width = document.getElementById("minimap-image").width;
        let height = document.getElementById("minimap-image").height;

        // console.log(
        //     "BEFORE",
        //     document.getElementById("minimap-position").style.width,
        //     document.getElementById("minimap-position").style.height
        // );

        document.getElementById("minimap-position").style.width = `${width}px`;
        document.getElementById("minimap-position").style.height = `${height}px`;

        // console.log(
        //     "AFTER",
        //     document.getElementById("minimap-position").style.width,
        //     document.getElementById("minimap-position").style.height
        // );
        // console.log(x, z, playerModel.current.position, minXmaxZ, maxXminZ);
    };

    useEffect(() => {
        window.addEventListener("resize", setPlayerLocation);

        return () => {
            window.removeEventListener("resize", setPlayerLocation);
        }
    }, []);

    useEffect(() => {
        setOpenMinimap(() => {
            if (minimap != null) {
                // setShow(true);
            }
        });

        let minimapSubs = SceneContext.subscribe((state) => state.minimap, (data) => {
            setMinimap(data);
        });

        let localPlayerSubs = GamePlayerContext.subscribe((state) => state.localPlayerObject, (data) => {
            setPlayerModel(data);
        })

        let onTeleportDetectionSubs = FunctionVariableContext.subscribe((state) => state.onTeleportDetection, (data) => {
            onTeleportDetection.current = data;
        });

        let controlToggleSubs = GamePlayerContext.subscribe(state=>state.controlToggle, (data)=>{
            setControlToggle(data);
        });

        let scene360Subs = GamePlayerContext.subscribe(state=>state.scene360, (data)=>{
            scene360.current = data;
        });

        let changePanoramicIDSubs = FunctionVariableContext.subscribe(state=>state.changePanoramicID, (data)=>{
            changePanoramicID.current = data;
        });

        return () => {
            minimapSubs();
            onTeleportDetectionSubs();
            localPlayerSubs();
            controlToggleSubs();
            scene360Subs();
        }
    }, []);

    const setTeleport = (data) => {
        teleportData.current = data;
        setNextTeleportData(data);
    }

    const closeMinimap = () => {
        // setShow(false);

    }

    const makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const startTeleport = () => {


        setShowModal(false);
        if(scene360.current){
            changePanoramicID.current(teleportData.current.targetScene)
        }
        else if(!teleportData.current.isChangeScene)
        {
            let collider = { uuid: makeid(10) }
            if (onTeleportDetection.current) onTeleportDetection.current(nextTeleportData.targetPosition, collider, true);
            teleportData.current = null;
            setNextTeleportData(null);
        }
        else
        {
            window.history.replaceState(null, null, "/"+ teleportData.current.targetScene)
            setScene(teleportData.current.targetScene);
            teleportData.current = null;
            setNextTeleportData(null);
            setShowModal(false);
        }
        // setShow(false);
    }

    return (
        <div className="minimap-background">
            <div className="minimap-panel">
                <button className="minimap-close" onClick={() => { handleClose() }}><FontAwesomeIcon icon={`fas fa-xmark`} /></button>
                <div className="col-sm-12"></div>
                <div className="row col-sm-12">
                    {
                        minimap && minimap.texture && <div key={0} className="col-sm-9 no-padding">
                            <img id="minimap-image" className="minimap-image" src={`${process.env.RESOURCE_URL}/${minimap.sceneName}/minimap/${minimap.texture}`} onLoad={(e) => {
                                setPlayerLocation();
                            }} />
                            <div className="minimap-position" id="minimap-position" ref={imageRef}>
                                <img className="minimap-pin" src={`./img/pin.png`} ref={playerPin} />
                            </div>
                        </div>
                    }
                    <div className="minimap-button-base col-sm-3">

                        <div className="my-4"></div>
                        <Row>
                            <Col className="text-center">
                                <svg
                                    data-src="./icon/btn-explore.svg"
                                    fill="currentColor"
                                    className="w-100"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                MINI MAP
                            </Col>
                        </Row>
                        <div className="my-2"></div>
                        {
                            minimap && minimap.minimapDatas.map((obj, i) => <button key={i} className="minimap-button" onClick={() => { setTeleport(obj) }}>{obj.name}</button>)
                        }
                    </div>
                </div>
            </div>

            {
                nextTeleportData && <div className="minimap-background">
                    <div className="minimap-confirmation row">
                        <div className="col-sm-12">
                            Are you sure want to go to {nextTeleportData.name}?
                        </div>
                        <div className="col-sm-6 pt-4"><button className="minimap-button no" onClick={() => {setNextTeleportData(null); teleportData.current = null}}>No</button></div>
                        <div className="col-sm-6 pt-4"><button className="minimap-button yes" onClick={() => startTeleport()}>Yes</button></div>
                    </div>
                </div>
            }
        </div>);
}