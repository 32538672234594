import { useEffect, useRef, useState } from "react";
import "../../../css/mixer-room.css"
import { FunctionVariableContext, GamePlayerContext } from "../../context/game-context"

export const MixerRoom = () => {

    let [isPlayerHost, setPlayerHost] = useState(GamePlayerContext((state) => state.isPlayerHost));
    let [isMixerRoom, setMixerRoom] = useState(GamePlayerContext((state) => state.isMixerRoom));
    let [isJoinMixer, setJoinMixer] = useState(GamePlayerContext((state) => state.isJoinMixer));
    let [isMixerStarted, setMixerStarted] = useState(GamePlayerContext((state) => state.isMixerStarted));
    let [mixerTime, setMixerTime] = useState(GamePlayerContext((state) => state.mixerTime));
    let [mixerRound, setMixerRound] = useState(GamePlayerContext((state) => state.mixerRound));
    let [isHostControlledRoom, setHostControlledRoom] = useState(GamePlayerContext((state) => state.isHostControlledRoom));
    let [isPresenter, setPresenter] = useState(GamePlayerContext((state) => state.isPresenter));
    
    const createMixerRoom = useRef(FunctionVariableContext((state)=>state.createMixerRoom));
    const removeMixerRoom = useRef(FunctionVariableContext((state)=>state.removeMixerRoom));
    const joinMixerRoom = useRef(FunctionVariableContext((state)=>state.joinMixerRoom));
    const leaveMixerRoom = useRef(FunctionVariableContext((state)=>state.leaveMixerRoom));
    const startMixerRoom = useRef(FunctionVariableContext((state)=>state.startMixerRoom));
    const stopMixerRoom = useRef(FunctionVariableContext((state)=>state.stopMixerRoom));
    const createHostControlRoom = useRef(FunctionVariableContext((state)=>state.createHostControlRoom));
    const stopHostControlRoom = useRef(FunctionVariableContext((state)=>state.stopHostControlRoom));
    
    useEffect(()=>{
        const leaveMixerRoomContext = GamePlayerContext.subscribe((state)=>state.leaveMixerRoom, (data)=>{
            leaveMixerRoom.current = data;
        });
    
        const isPlayerHostContext = GamePlayerContext.subscribe(state => state.isPlayerHost, (data)=>{
            setPlayerHost(data);
        });
    
        const isMixerRoomContext = GamePlayerContext.subscribe(state => state.isMixerRoom, (data)=>{
            setMixerRoom(data);
        });
    
        const isJoinMixerContext = GamePlayerContext.subscribe(state => state.isJoinMixer, (data)=>{
            setJoinMixer(data)
        });
    
        const isMixerStartedContext =  GamePlayerContext.subscribe(state => state.isMixerStarted, (data)=>{
            setMixerStarted(data)
        });
    
        const mixerTimeContext = GamePlayerContext.subscribe((state) => state.mixerTime, (data)=>{
            setMixerTime(data);
        });
        
        const mixerRoundContext = GamePlayerContext.subscribe((state) => state.mixerRound, (data)=>{
            setMixerRound(data);
        });
    
        const isHostControlledRoomContext = GamePlayerContext.subscribe((state) => state.isHostControlledRoom, (data)=>{
            setHostControlledRoom(data);
        });
        
        const isPresenterContext = GamePlayerContext.subscribe((state) => state.isPresenter, (data)=>{
            setPresenter(data);
        });
    
        const createMixerRoomContext = FunctionVariableContext.subscribe((state)=>state.createMixerRoom, (data)=>{
            createMixerRoom.current = data;
        });
        
        const joinMixerRoomContext = FunctionVariableContext.subscribe((state)=>state.joinMixerRoom, (data)=>{
            joinMixerRoom.current = data;
        });
        
        const startMixerRoomContext = FunctionVariableContext.subscribe((state)=>state.startMixerRoom, (data)=>{
            startMixerRoom.current = data;
        });
    
        const createHostControlRoomContext = FunctionVariableContext.subscribe((state)=>state.createHostControlRoom, (data)=>{
            createHostControlRoom.current = data;
        });
    
        const stopHostControlRoomContext = FunctionVariableContext.subscribe((state)=>state.stopHostControlRoom, (data)=>{
            stopHostControlRoom.current = data;
        });

        return ()=>{
            leaveMixerRoomContext();
            isPlayerHostContext();
            isMixerRoomContext();
            isJoinMixerContext();
            isMixerStartedContext();
            mixerTimeContext();
            mixerRoundContext();
            isHostControlledRoomContext();
            isPresenterContext();
            createMixerRoomContext();
            joinMixerRoomContext();
            startMixerRoomContext();
            createHostControlRoomContext();
            stopHostControlRoomContext();
        }
    },[]);
    
    
    const onCreateMixer = ()=>{
        if(createMixerRoom.current) {
            createMixerRoom.current();
        }
    }

    const onJoinMixer = ()=>{
        if(joinMixerRoom.current) {
            joinMixerRoom.current();
        }
    }

    const onLeaveMixer = ()=>{
        if(leaveMixerRoom.current) {
            leaveMixerRoom.current();
        }
    }

    const onRemoveMixer = ()=>{
        if(removeMixerRoom.current) {
            removeMixerRoom.current();
        }
    }

    const onStartMixer = ()=>{
        if(startMixerRoom.current) {
            startMixerRoom.current();
        }
    }

    const onStopMixer = () =>{
        if(stopMixerRoom.current) {
            stopMixerRoom.current();
        }
    }

    const onCreateHostControlRoom = () =>{
        if(createHostControlRoom.current){
            createHostControlRoom.current();
        }
    }

    const onStopHostControlRoom = () =>{
        if(stopHostControlRoom.current){
            stopHostControlRoom.current();
        }
    }

    return (
        <div className="mixer-room">
            {!isHostControlledRoom && isPlayerHost && !isMixerRoom && !isJoinMixer && <button className="mixer-room-create mixer-button" onClick={onCreateMixer}>Create Mixer</button>}
            {!isHostControlledRoom && isMixerRoom && ((!isPlayerHost && !isJoinMixer) || (isPlayerHost && !isJoinMixer)) && !isMixerStarted && <button className="join-mixer-room mixer-button" onClick={onJoinMixer}>Join Mixer</button>}
            {!isHostControlledRoom && isJoinMixer && isMixerRoom && <button className="join-mixer-room mixer-button" onClick={onLeaveMixer}>Leave Mixer</button>}
            {!isHostControlledRoom && isPlayerHost && isMixerRoom && !isMixerStarted && <button className="mixer-room-remove mixer-button" onClick={onRemoveMixer}>Remove Mixer</button>}
            {!isHostControlledRoom && isPlayerHost && isJoinMixer && isMixerRoom && !isMixerStarted && <button className="start-mixer-room mixer-button" onClick={onStartMixer}>Start Mixer</button>}
            {!isHostControlledRoom && isPlayerHost && isMixerRoom && isMixerStarted && <button className="mixer-room-stop mixer-button" onClick={onStopMixer}>Stop Mixer</button>}
            {!isHostControlledRoom && isJoinMixer && <div className="mixer-notification">{ isMixerStarted ? `Mixer round ${mixerRound} [ ${mixerTime} ]` : "Waiting for mixer to start."}</div>}
            
            {isPresenter && <div className="host-control-notification-info">You are presenting.</div>}
            {isHostControlledRoom && <div className="host-control-notification">Room is controlled by the host, only presenter can talk.</div>}
            {isPlayerHost && !isHostControlledRoom && !isMixerRoom && !isJoinMixer && <button className="host-control-create mixer-button" onClick={onCreateHostControlRoom}>Control The Room</button>}
            {isPlayerHost && isHostControlledRoom && !isMixerRoom && !isJoinMixer && <button className="host-control-stop mixer-button" onClick={onStopHostControlRoom}>Stop Control The Room</button>}
        </div>
    )
}