import { useEffect, useRef, useState } from "react"
import LoadObject from "./load-static-object";
import { LoadClonedGeometry } from "./utils";
import { useTrimesh } from "@react-three/cannon";
import { useFrame } from "@react-three/fiber";
import { degToRad } from "three/src/math/MathUtils";
import { Quaternion, Vector3 } from "three";
import { GamePlayerContext } from "../../context/game-context";

export const AnimatedPhysics = ({loadedGeometry, sceneName, object, collider, data, animationData}) =>{

    const target = useRef();
    const setPlayerDie = GamePlayerContext(state=>state.setPlayerDie);
    const setDieTeleportPoint = GamePlayerContext(state=>state.setDieTeleportPoint);

    const playerPhysicsMaterial = {
        friction: 2,
        restitution:1,
        contactEquationStiffness:1,
        contactEquationRelaxation:3,
        frictionEquationStiffness:1,
        frictionEquationRelaxation:3
      }

    const [ref, api] = useTrimesh(() => ({
        mass: 0,
        type: "Dynamic",
        args: [collider.attributes.position.array, collider.index.array],
        material:playerPhysicsMaterial,
        collisionFilterMask:2,
        collisionFilterGroup:2,
        onCollideBegin:(c)=>{
            if(data.isDieOnCollide)
            {
                setDieTeleportPoint({
                    position:new Vector3(data.teleportTarget.position.x/10000, data.teleportTarget.position.y/10000, data.teleportTarget.position.z/10000),
                    rotation:new Quaternion(data.teleportTarget.rotation.x/10000, data.teleportTarget.rotation.y/10000, data.teleportTarget.rotation.z/10000, data.teleportTarget.rotation.w/10000),
                });
            }
        }
    }));

    useEffect(()=>{
        // const LoadData = async () => {
        //     geometry.current = await LoadClonedGeometry(loadedGeometry, object.meshid, object.position, object.rotation, object.scale, sceneName, true, true);
            
        //     SetLoaded(true);
        //     isLoadedRef.current = true;
        // }

        // LoadData();
        target.current = animationData.object.getObjectByName(data.target);

        return()=>{
            collider.dispose();
        }
    },[]);

    useFrame((src, dt)=>{
        if(target.current){
            let worldPosition = new Vector3();
            let worldQuaternion = new Quaternion();
            target.current.getWorldPosition(worldPosition);
            target.current.getWorldQuaternion(worldQuaternion);
            
            api.position.set(worldPosition.x, worldPosition.y, worldPosition.z);
            api.quaternion.set(worldQuaternion.x, worldQuaternion.y, worldQuaternion.z, worldQuaternion.w);
        }
    });
    
    return (<>
        {animationData.animationObject}
    </>);
}