import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../../css/back-to-main-scene.css'
import { SceneContext } from '../../context/game-context';


export const BackToMainScene = () => {

    const [mainScene, setMainScene] = useState(SceneContext((state => state.mainScene)));
    const setScene = SceneContext((state) => state.setScene);

    useEffect(() => {
        let mainSubs = SceneContext.subscribe((state) => state.mainScene, (scene) => {
            setMainScene(scene);
        });

        return () => {
            mainSubs();
        }
    }, []);

    const onClick = () => {

        window.history.replaceState(null, null, "/" + mainScene);
        setScene(mainScene);
    }

    return mainScene.length > 0 &&
        <Button
            className="back-to-main-scene"
            variant="secondary"
            onClick={() => onClick()}
        >
            <FontAwesomeIcon icon="fas fa-arrow-left-long" />
            <span>Back To {mainScene}</span>
        </Button>
}