
import { useFrame } from '@react-three/fiber';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { AnimationMixer, Bone, BoxGeometry, BufferAttribute, BufferGeometry, CanvasTexture, ClampToEdgeWrapping, Euler, Float32BufferAttribute, Group, LinearFilter, LoopOnce, Matrix4, Mesh, MeshBasicMaterial, MeshPhysicalMaterial, Quaternion, RepeatWrapping, RGBAFormat, Skeleton, SkeletonHelper, SkinnedMesh, SphereBufferGeometry, TextureLoader, Uint16BufferAttribute, UnsignedByteType, UVMapping, Vector3 } from 'three';
import { AnimationContext, FunctionVariableContext, GamePlayerContext, PlayerModelContext } from '../context/game-context';
import { getBasisTransform } from '../utils/BasisTransform';
import { Armature, Clip, Gltf2, Retarget, SkinMTX } from 'ossos';
import BoneViewMesh from '../utils/osos/BoneViewMesh';
import SkinMTXMaterial from '../utils/osos/SkinMTXMaterial';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { useAnimations } from '@react-three/drei';
import { PlayerContext } from './player-component/context/player-context';
import { CanvasContext } from './player-component/context/canvas-context';
import SkinMTXMaterialMobile from '../utils/osos/SkinMTXMaterialMobile';


const textureData =
{
    position: {
        skin: [256, 128],
        eyes: [0, 0],
        eyebrow: [256, 0],
        mouth: [0, 128],
        upperbody: [512, 0],
        lowerbody: [1024, 0],
        feet: [1536, 0],
        // props: [0, 512],
        hair: [640, 128],
        head: [1024, 512],

        hat: [0, 512],
        helmet: [256, 512],
        topHead: [0, 768],
        mask: [256, 768],
        tiara: [512, 512],
        earing: [768, 512],
        facialHair: [512, 768]

    },
    size: {
        skin: [128, 128],
        eyes: [256, 128],
        eyebrow: [256, 128],
        mouth: [256, 128],
        upperbody: [512, 512],
        lowerbody: [512, 512],
        feet: [512, 512],
        // props: [512, 512],
        hair: [128, 128],
        head: [512, 512],

        hat: [256, 256],
        helmet: [256, 256],
        topHead: [256, 256],
        mask: [256, 256],
        tiara: [256, 256],
        earing: [256, 256],
        facialHair: [256, 256]
    },
    uvOffset: {
        skin: { offset: [0.1255, 0.755], multiply: [0.061, 0.11] },
        eyes: { offset: [0, 0.875], multiply: [0.125, 0.128] },
        eyebrow: { offset: [0.125, 0.875], multiply: [0.125, 0.125] },
        mouth: { offset: [0, 0.75], multiply: [0.125, 0.125] },
        upperbody: { offset: [0.25, 0.5], multiply: [0.25, 0.5] },
        lowerbody: { offset: [0.5, 0.5], multiply: [0.25, 0.5] },
        feet: { offset: [0.75, 0.5], multiply: [0.25, 0.5] },
        // props: { offset: [0, 0], multiply: [0.25, 0.5] },
        hair: { offset: [0.1875, 0.75], multiply: [0.0625, 0.125] },
        head: { offset: [0.5, 0], multiply: [0.25, 0.5] },


        hat: { offset: [0, 0.25], multiply: [0.125, 0.25] },
        helmet: { offset: [0.125, 0.25], multiply: [0.125, 0.25] },
        topHead: { offset: [0, 0], multiply: [0.125, 0.25] },
        mask: { offset: [0.125, 0], multiply: [0.125, 0.25] },
        tiara: { offset: [0.25, 0.25], multiply: [0.125, 0.25] },
        earing: { offset: [0.375, 0.25], multiply: [0.125, 0.25] },
        facialHair: { offset: [0.25, 0], multiply: [0.125, 0.25] },
    }
}

const waitUntil = (condition) => {
    return new Promise((resolve) => {
        let interval = setInterval(() => {
            if (!condition()) {
                return
            }

            clearInterval(interval)
            resolve()
        }, 100)
    })
}

export const PlayerModelAndAnimation = ({ playerModel, playerId, refPlayer,
    skin, base, skinColor, hairColor, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet,
    hat, helmet, topHead, mask, tiara, earing, facialHair, colliderRadius, children, onClicked, onFBXUpdated, isLocal, isCheckSkin, assetRef, onLocalPlayerFinishLoad }) => {

    const playerContext = useContext(PlayerContext);
    const [avatarList, setAvatarList] = useState(playerContext.avatarList);
    const playerMesh = useRef();
    const setPlayerData = useRef();
    const [playerBase, setPlayerBase] = useState();
    const geometryPoll = useRef({});
    const [isFinishLoad, setIsFinishLoad] = useState(false);
    const onFinishLoad = useRef((cond)=>{
        setIsFinishLoad(cond);
    });


    useEffect(() => {
        const avatarListSubs = GamePlayerContext.subscribe((state) => state.avatarList, (data) => {
            setAvatarList(data);
        });

        return () => {
            avatarListSubs();
        }
    });

    useEffect(() => {
        if (avatarList != null) {
            setPlayerBase(
                <PlayerBase
                    playerMesh={playerMesh}
                    playerModel={playerModel}
                    refPlayer={refPlayer}
                    skin={skin}
                    skinColor={skinColor}
                    hairColor={hairColor}
                    base={base}
                    head={head}
                    eyebrow={eyebrow}
                    eyes={eyes}
                    mouth={mouth}
                    hair={hair}
                    upperBody={upperBody}
                    lowerBody={lowerBody}
                    feet={feet}
                    colliderRadius={colliderRadius}
                    children={children}
                    onClicked={onClicked}
                    onFBXUpdated={onFBXUpdated}
                    avatarList={avatarList}
                    setPlayerData={setPlayerData}
                    isLocal={isLocal}
                    isCheckSkin={isCheckSkin}
                    geometryPoll={geometryPoll}
                    assetRef={assetRef}

                    hat={hat}
                    helmet={helmet}
                    topHead={topHead}
                    mask={mask}
                    tiara={tiara}
                    earing={earing}
                    facialHair={facialHair}
                    onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}


                    onFinishLoad={onFinishLoad}
                />
            );
        }

    }, [skin, skinColor, hairColor, base, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair, avatarList]);

    return (
        <group>
            {playerBase}
            <PlayerAnimation
                skin={skin}
                base={base}
                playerModel={playerModel}
                setPlayerData={setPlayerData}
                isLocal={isLocal}
            />
            {isFinishLoad && children}
        </group>
    );
}

export const PlayerAnimation = ({ playerModel, setPlayerData, skin, base, assetRef, isLocal, onFinishLoad }) => {

    const initAnimation = AnimationContext((state) => state.initAnimation);
    const [animationSource, setAnimationSource] = useState(AnimationContext((state) => state.animationSource));

    let [playerData, setPlayerdata] = useState();
    let [bones, setBones] = useState();
    let [mesh, setMesh] = useState();
    let [texture, setTexture] = useState();

    setPlayerData.current = (bones, mesh, texture) => {
        setPlayerdata({ bones: bones, mesh: mesh, texture: texture });
    }

    useEffect(() => {

        initAnimation();

        let animationSourceSubs = AnimationContext.subscribe((state) => state.animationSource, (source) => {
            setAnimationSource(source);
        });

        return () => {
            animationSourceSubs();
        }

    }, [skin, base]);


    return animationSource && playerData &&
        <PlayerAnimator
            playerModel={playerModel}
            animationSource={animationSource}
            bones={playerData.bones}
            mesh={playerData.mesh}
            texture={playerData.texture}
            skin={skin}
            base={base}
            isLocal={isLocal}
        />
}

export const PlayerAnimator = ({ animationSource, playerModel, bones, mesh, texture, skin, base, assetRef, isLocal }) => {

    const animationRef = useRef();
    const animate = useRef(false);
    const targetArmature = useRef();
    const currentAnimation = useRef("idle");
    const isMobile = /Android|webOS|iPhone|iPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const armature_from_gltf = (gltf, defaultBoneLen = 0.07) => {

        const arm = new Armature();
        const skin = gltf.getSkin();

        for (let j of skin.joints) {
            arm.addBone(j.name, j.parentIndex, j.rotation, j.position, j.scale);
        }

        arm.bind(SkinMTX, defaultBoneLen);
        arm.offset.set(skin.rotation, skin.position, skin.scale);
        return arm;
    }

    const calculate_armature = (bones, defaultBoneLen = 0.07) => {

        const arm = new Armature();

        const boneNames = Object.keys(bones);
        for (let boneName of boneNames) {
            let bone = bones[boneName];
            let parentName = bone.parent.name;

            let parentIndex = boneNames.indexOf(parentName) >= 0 ? boneNames.indexOf(parentName) : null;
            let position = [bone.position.x, bone.position.y, bone.position.z];
            let rotation = [bone.quaternion.x, bone.quaternion.y, bone.quaternion.z, bone.quaternion.w];
            let scale = [bone.scale.x, bone.scale.y, bone.scale.z];

            arm.addBone(bone.name, parentIndex, rotation, position, null);
        }

        arm.bind(SkinMTX, defaultBoneLen);

        return arm;
    }

    const loadAnimation = async (targetArm, animSource) => {
        let boneNames = Object.keys(bones);
        let boneCount = boneNames.length;
        let pose = { name: 'tpose', joints: [] };
        for (let i = 0; i < boneCount; i++) {
            let b = bones[boneNames[i]];
            pose.joints.push({
                index: i,
                pos: [b.position.x, b.position.y, b.position.z],
                rot: [b.quaternion.x, b.quaternion.y, b.quaternion.z, b.quaternion.w],
                scl: [b.scale.x, b.scale.y, b.scale.z]
            });
        }

        if (animationRef.current == null) {
            animationRef.current = new Retarget();
            animationRef.current.anim.inPlace = true;
            animationRef.current.anim.loop = false;
            // console.log(animationRef.current.anim);
        }

        //SOURCE
        if (!animSource) return;
        const sourceArm = armature_from_gltf(animSource);
        const sourceAnim = animSource.getAnimation();
        const sourceClip = Clip.fromGLTF2(sourceAnim);
        animationRef.current.setClipArmature(sourceArm).setClip(sourceClip);
        animationRef.current.setTargetArmature(targetArm).getTargetPose().fromGLTF2(pose);
        animationRef.current.bind();
    }

    playerModel.current.setAnimation = (aniamtionName) => {

        // animationRef.current.setClip(animationSource[aniamtionName]); 
        if (currentAnimation.current != aniamtionName) {
            // console.log(animationSource);
            loadAnimation(targetArmature.current, animationSource[aniamtionName]);
            playerModel.current.currentAnimation = aniamtionName;
            currentAnimation.current = aniamtionName;
        }
    }

    playerModel.current.setAnimationID = (aniamtionID) => {

        // animationRef.current.setClip(animationSource[aniamtionName]); 
        let keys = Object.keys(animationSource);
        let aniamtionName = keys[aniamtionID];
        if (aniamtionName) {
            playerModel.current.setAnimation(aniamtionName);
        }
    }

    useFrame((src, dt) => {
        if (!animate.current) return;

        const targetPose = animationRef.current.getTargetPose();
        animationRef.current.animateNext(dt);
        targetArmature.current.updateSkinFromPose(targetPose);
    });

    useEffect(() => {

        targetArmature.current = calculate_armature(bones);

        const mat = isMobile ? SkinMTXMaterialMobile(texture || 'cyan', targetArmature.current.getSkinOffsets()[0]) : SkinMTXMaterial(texture || 'cyan', targetArmature.current.getSkinOffsets()[0]);
        mesh.material = mat;


        const loadAllAnimation = async () => {
            await loadAnimation(targetArmature.current, animationSource[currentAnimation.current]);
            animate.current = true;
        }

        playerModel.current.topHead = targetArmature.current.bones.find(x => x.name == "topHead");
        // window.currentAnimation = currentAnimation;
        // window.playerModel = playerModel;
        loadAllAnimation();


    }, [bones, mesh, skin, base]);
}

export const PlayerBase = ({showModel, playerModel, refPlayer, skin, skinColor, hairColor, base, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair, colliderRadius, children, onClicked, onFBXUpdated, avatarList, setPlayerData, isLocal, isCheckSkin, geometryPoll, assetRef, onLocalPlayerFinishLoad, onFinishLoad }) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [avatarData, setAvatarData] = useState({ bones: null, baseDirectory: null });
    const [bodyPart, setBodyPart] = useState({ skin: -1, base: -1 });
    const [playerMesh, setPlayerMesh] = useState();
    const loadPlayerModel = PlayerModelContext(state => state.loadPlayerModel);

    const loadMesh = async (src) => {
        await loadPlayerModel(src)
            .then(result => {
            });
    }


    useEffect(() => {

        const loadData = async (src) => {
            let boneData = avatarData;

            if (bodyPart.skin != skin || bodyPart.base != base) {

                geometryPoll.current = {};

                let avatarSkin = avatarList.find(x => x.skin == skin);

                if (!avatarSkin) return;

                let avatarID = avatarSkin.skin;
                let avatar = avatarSkin.bases.find(r => r.basesID == base);
                // if base not found so take first base
                base = avatar == null ? avatarSkin.bases[0].basesID : base;
                avatar = avatar == null ? avatarSkin.bases[0] : avatar;

                let avatarBase = base;

                if (!avatar || !avatarID || !avatar.baseAvatar) return;

                playerModel.current = new Group();

                const unityAxes = '+X+Y+Z';
                const threejsAxis = '-X+Y+Z';
                const worldMatrix = new Matrix4();
                getBasisTransform(unityAxes, threejsAxis, worldMatrix);

                // console.log(avatarID, avatarBase, avatar.baseAvatar);
                // loadPlayerModel(`./avatars/${avatarID}/${avatarBase}/Base/Mesh/${avatar.baseAvatar}`)
                // .then(result => {
                //     console.log("RESULT", result);
                // });

                let urlAvatar = `${process.env.RESOURCE_URL}/avatars/${avatarID}/${avatarBase}/Base/Mesh/${avatar.baseAvatar}`;
                let baseData = null;
                if (assetRef.current[urlAvatar]) {
                    await waitUntil(() => assetRef.current[urlAvatar] != true)
                    baseData = assetRef.current[urlAvatar];
                }
                else {


                    assetRef.current[urlAvatar] = true;
                    await axios.get(urlAvatar, { crossDomain: true })
                        .then(bd => {
                            return bd.data;
                        })
                        .then(async (bd) => {
                            if (assetRef) {
                                assetRef.current[urlAvatar] = bd;
                            }
                            baseData = bd;
                        });
                }

                // let boneArray = [];
                let boneParent = [];
                for (let i = 0; i < baseData.skeletonIndex.length; i++) {
                    let boneName = baseData.skeletonIndex[i];

                    let newBone = new Bone();
                    newBone.name = boneName;
                    boneParent[boneName] = newBone;
                    // boneArray.push(newBone);
                }

                for (let i = 0; i < baseData.skeletonIndex.length; i++) {
                    let boneName = baseData.skeletonIndex[i];
                    let boneData = baseData.skeletonData[boneName];
                    if (boneData.parent.length != 0) boneParent[boneData.parent].add(boneParent[boneName]);
                    else playerModel.current.add(boneParent[boneName]);
                }

                for (let i = 0; i < baseData.skeletonIndex.length; i++) {
                    let boneName = baseData.skeletonIndex[i];
                    let boneData = baseData.skeletonData[boneName];

                    let bonePosition = new Vector3(boneData.position.x, boneData.position.y, boneData.position.z);
                    let boneRotation = new Quaternion(boneData.rotation.x, boneData.rotation.y, boneData.rotation.z, boneData.rotation.w);
                    let boneScale = new Vector3(boneData.scale.x, boneData.scale.y, boneData.scale.z);
                    let matrix = new Matrix4().compose(bonePosition, boneRotation, boneScale);

                    boneParent[boneName].applyMatrix4(matrix);
                }

                boneData = {
                    bones: boneParent,
                    baseDirectory: `${process.env.RESOURCE_URL}/avatars/${avatarID}/${avatarBase}`
                }

                setAvatarData(boneData);

                setBodyPart({
                    skin: skin,
                    base: base,
                    skinColor: skinColor,
                    hairColor: hairColor,
                    head: head,
                    eyebrow: eyebrow,
                    eyes: eyes,
                    mouth: mouth,
                    hair: hair,
                    upperBody: upperBody,
                    lowerBody: lowerBody,
                    feet: feet,
                    hat: hat,
                    helmet: helmet,
                    topHead: topHead,
                    mask: mask,
                    tiara: tiara,
                    earing: earing,
                    facialHair: facialHair
                });

                let mesh = <PlayerMesh
                    playerModel={playerModel}
                    baseDirectory={boneData.baseDirectory}
                    avatarList={avatarList}
                    bones={boneData.bones}
                    head={head}
                    skinColor={skinColor}
                    hairColor={hairColor}
                    eyebrow={eyebrow}
                    eyes={eyes}
                    mouth={mouth}
                    hair={hair}
                    upperBody={upperBody}
                    lowerBody={lowerBody}
                    feet={feet}
                    children={children}
                    onClicked={onClicked}
                    setPlayerData={setPlayerData}
                    skin={skin}
                    base={base}
                    isLocal={isLocal}
                    isCheckSkin={isCheckSkin}
                    assetRef={assetRef}

                    refPlayer={refPlayer}

                    hat={hat}
                    helmet={helmet}
                    topHead={topHead}
                    mask={mask}
                    tiara={tiara}
                    earing={earing}
                    facialHair={facialHair}

                    geometryPoll={geometryPoll}
                    onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}

                    onFinishLoad={onFinishLoad}

                    showModel={showModel}
                />;

                setPlayerMesh(mesh);
            }
            else {

                let bps = ["skin", "base", "skinColor", "hairColor", "head", "eyebrow", "eyes", "mouth", "hair", "upperBody", "lowerBody", "feet", "hat", "helmet", "topHead", "mask", "tiara", "earing", "facialHair"];
                let val = [skin, base, skinColor, hairColor, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair];

                for (let i = 0; i < bps.length; i++) {
                    const bp = bps[i];
                    if (geometryPoll.current[bp]) {
                        if (bodyPart[bp] != val[i]) {
                            geometryPoll.current[bp] = undefined;
                        }
                    }
                }

                setBodyPart({
                    skin: skin,
                    base: base,
                    skinColor: skinColor,
                    hairColor: hairColor,
                    head: head,
                    eyebrow: eyebrow,
                    eyes: eyes,
                    mouth: mouth,
                    hair: hair,
                    upperBody: upperBody,
                    lowerBody: lowerBody,
                    feet: feet,
                    hat: hat,
                    helmet: helmet,
                    topHead: topHead,
                    mask: mask,
                    tiara: tiara,
                    earing: earing,
                    facialHair: facialHair
                });

                let mesh = <PlayerMesh
                    playerModel={playerModel}
                    baseDirectory={boneData.baseDirectory}
                    avatarList={avatarList}
                    bones={boneData.bones}
                    head={head}
                    skinColor={skinColor}
                    hairColor={hairColor}
                    eyebrow={eyebrow}
                    eyes={eyes}
                    mouth={mouth}
                    hair={hair}
                    upperBody={upperBody}
                    lowerBody={lowerBody}
                    feet={feet}
                    children={children}
                    onClicked={onClicked}
                    setPlayerData={setPlayerData}
                    skin={skin}
                    base={base}
                    isLocal={isLocal}
                    isCheckSkin={isCheckSkin}
                    assetRef={assetRef}

                    refPlayer={refPlayer}

                    hat={hat}
                    helmet={helmet}
                    topHead={topHead}
                    mask={mask}
                    tiara={tiara}
                    earing={earing}
                    facialHair={facialHair}

                    geometryPoll={geometryPoll}
                    onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}

                    onFinishLoad={onFinishLoad}
                    
                    showModel={showModel}
                />;

                setPlayerMesh(mesh);
            }
        }

        loadData();

    }, [skin, skinColor, hairColor, base, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair]);

    return playerMesh;

}

export const PlayerMesh = ({ playerModel, refPlayer, avatarList, bones, skinColor, hairColor, skin, base, head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair, setPlayerData, isLocal, isCheckSkin, onClicked, geometryPoll, assetRef, onLocalPlayerFinishLoad, onFinishLoad }) => {

    let [mesh, setMesh] = useState();
    // let skinnedMesh = useRef([]);
    // let animationRef = useRef({});
    // let animate = useRef(false);
    // let [targetBoneView, setTargetBoneView] = useState();
    // let [sourceBoneView, setSourceBoneView] = useState();
    const setIsSkinColorAvailable = GamePlayerContext(state => state.setIsSkinColorAvailable);
    const setIsHairColorAvailable = GamePlayerContext(state => state.setIsHairColorAvailable);
    const loadPlayerModel = PlayerModelContext(state => state.loadPlayerModel);
    const mergedGeometryRef = useRef();
    const canvasTextureRef = useRef();

    const material = new MeshPhysicalMaterial({ transparent: true, depthWrite: true, colorWrite: false });
    const currentData = useRef({
        bones: null, rootID: null, head: null, eyebrow: null, eyes: null, mouth: null, hair: null, upperBody: null, lowerBody: null, feet: null, skinColor: null, hairColor: null,
        hat: null, helmet: null, topHead: null, mask: null, tiara: null, earing: null, facialHair: null
    });

    const loadTexture = async (baseDirectory, bodyPartDir, texturePath, tiling, offset) => {


        let textureURL = `${baseDirectory}/${bodyPartDir}/Texture/${texturePath}`;
        let loadTexture = null;


        if (assetRef.current[textureURL]) {
            await waitUntil(() => assetRef.current[textureURL] != true);
            return assetRef.current[textureURL];
        }
        else {
            try {
                assetRef.current[textureURL] = true;
                loadTexture = await new TextureLoader().loadAsync(textureURL);
                loadTexture.repeat.set(tiling.x, tiling.y);
                loadTexture.offset.set(offset.x, offset.y);
                loadTexture.wrapS = RepeatWrapping;
                loadTexture.wrapT = RepeatWrapping;

                // if (!isLocal) {
                //     console.log(textureURL, loadTexture);
                // }
            }
            catch (e) {
            }

            if (assetRef) {
                assetRef.current[textureURL] = loadTexture;
            }
            return loadTexture;
        }

    }

    const loadMaterials = async (materialSource, baseDirectory, bodyPartDir) => {
        let materials = [];
        for (let i = 0; i < materialSource.length; i++) {
            let materialData = materialSource[i];

            let mat = new MeshPhysicalMaterial({
                color: materialData.color,
                opacity: materialData.opacity,
                transparent: materialData.transparent != 0
            });

            if (materialData.mainTexture) {
                mat.map = await loadTexture(baseDirectory, bodyPartDir, materialData.mainTexture, materialData.tiling, materialData.offset);
            }

            if (materialData.isEmissoion) {
                mat.emissive = materialData.emissionColor
                mat.emissiveMap = await loadTexture(baseDirectory, bodyPartDir, materialData.emissionTexture, materialData.tiling, materialData.offset);
            }

            if (materialData.normalTexture.length > 0) {
                mat.normalMap = await loadTexture(baseDirectory, bodyPartDir, materialData.normalTexture, materialData.tiling, materialData.offset);
            }

            materials.push(mat);
        }
        return materials;
    }

    const loadGeometry = async (baseDirectory, bodyPartDir, bodyPartpath, context, objectToHide, containSkin) => {


        const unityAxes = '+X+Y+Z';
        const threejsAxis = '-X+Y+Z';
        const worldMatrix = new Matrix4();
        getBasisTransform(unityAxes, threejsAxis, worldMatrix);

        // console.log("2", bodyPartDir);


        let result = [];
        let res = null;

        let geomUrl = `${baseDirectory}/${bodyPartDir}/Mesh/${bodyPartpath.json}`;
        if (assetRef.current[geomUrl]) {
            await waitUntil(() => assetRef.current[geomUrl] != true)
            res = assetRef.current[geomUrl];
        }
        else {
            assetRef.current[geomUrl] = true;
            await axios.get(`${baseDirectory}/${bodyPartDir}/Mesh/${bodyPartpath.json}`, { crossDomain: true })
                .then((baseData) => {
                    return baseData.data;
                })
                .then(async (data) => {
                    res = data;
                    if (assetRef) {
                        assetRef.current[geomUrl] = res;
                    }
                });
        }

        let meshes = res.meshes;
        let meshesCount = meshes.length;

        if (res.objectToHide) {
            for (let i = 0; i < res.objectToHide.length; i++) {
                objectToHide.push(res.objectToHide[i]);
            }
        }

        for (let i = 0; i < meshesCount; i++) {
            let meshData = meshes[i];

            if (containSkin.current == false && meshData.isSkin) containSkin.current = true;
            let textureName = meshData.isSkin ? "skin" : bodyPartDir.toLowerCase();
            let texturePosition = textureData.position[textureName];
            let textureSize = textureData.size[textureName];
            let textureOffset = textureData.uvOffset[textureName];

            // console.log(bodyPartDir.toLowerCase(), meshData.isSkin, textureName, texturePosition, textureSize, textureOffset);

            let newBoneIndex = [];
            let boneNameArray = Object.keys(bones);
            let boneIndexCount = meshData.boneIndex.length;

            for (let j = 0; j < boneIndexCount; j++) {
                let idx = meshData.boneIndex[j];
                let newIndex = boneNameArray.indexOf(meshData.bones[i]);
                newBoneIndex.push(newIndex);
            }

            let positionFloatArray = new Float32BufferAttribute(meshData.vertices, 3);
            let uvFloatArray = new Float32BufferAttribute(meshData.uv, 2);
            let uv2FloatArray = new Float32BufferAttribute(meshData.uv2, 2);
            // let boneIndex = new Uint16BufferAttribute(newBoneIndex, 4);
            let boneIndex = new Uint16BufferAttribute(meshData.boneIndex, 4);
            let boneWeight = new Float32BufferAttribute(meshData.boneWeight, 4);
            let normals = new Float32BufferAttribute(meshData.normals, 3);


            let geometry = new BufferGeometry();
            geometry.setIndex(meshData.triangles);
            geometry.setAttribute('position', positionFloatArray);
            geometry.setAttribute('uv', uvFloatArray);
            geometry.setAttribute('skinIndex', boneIndex);
            geometry.setAttribute('skinWeight', boneWeight);
            geometry.setAttribute('normal', normals);
            geometry.name = bodyPartDir;
            geometry.computeVertexNormals();
            if (meshData.uv2.count > 0) geometry.setAttribute('uv2', new BufferAttribute(uv2FloatArray, 2));

            let matrix = new Matrix4().fromArray(meshData.matrix.m);
            geometry.applyMatrix4(matrix);

            let uvCount = geometry.attributes.uv.count;
            for (let j = 0; j < uvCount; j++) {
                let u = (geometry.attributes.uv.getX(j) * textureOffset.multiply[0]) + textureOffset.offset[0];
                let v = (geometry.attributes.uv.getY(j) * textureOffset.multiply[1]) + textureOffset.offset[1];
                if (textureName == "hair") {
                    if (u > 0.25) {
                        u = 0.24;
                    }
                    else if (u < 0.1875) {
                        u = 0.19
                    }
                }

                if (textureName == "skin") {
                    if (u > 0.1875) {
                        u = 0.1874;
                    }
                    else if (u <= 0.125) {
                        u = 0.126
                    }

                }
                geometry.attributes.uv.setXY(j, u, v);
            }

            let thisBone = [];
            let thisBoneInverse = [];
            let meshBoneCount = meshData.bones.length;
            for (let j = 0; j < meshBoneCount; j++) {
                const boneName = meshData.bones[j];
                thisBone.push(bones[boneName]);

                let bindPose = new Matrix4().fromArray(meshData.bindPose[i].m);
                thisBoneInverse.push(bindPose);
            }

            let materials = await loadMaterials(meshData.materials, baseDirectory, bodyPartDir);

            if (meshData.materials[0].mainTexture.length > 0) context.drawImage(materials[0].map.image, texturePosition[0], texturePosition[1], textureSize[0], textureSize[1]);

            let skeleton = new Skeleton(thisBone, thisBoneInverse);

            let newMesh = new SkinnedMesh(geometry, materials[0]);
            newMesh.bind(skeleton);
            newMesh.frustumCulled = false;
            newMesh.receiveShadow = true;
            newMesh.castShadow = true;

            // playerModel.current.add(newMesh);


            let res = {
                mesh: newMesh,
                bodyPart: bodyPartDir,
                textureName: textureName
            }

            if (materials[0].map) {
                res.texture = materials[0].map.image;
            }

            result.push(res);
        }

        return result;
    }

    const loadAllGeometry = async () => {
        // console.log("MEMORY", performance.memory.totalJSHeapSize / 1000000);

        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        context.canvas.width = 2048;
        context.canvas.height = 1024;

        const bodyPartDir = ["Head", "Eyebrow", "Eyes", "Mouth", "Hair", "UpperBody", "LowerBody", "Feet", "Hat", "Helmet", "TopHead", "Mask", "Tiara", "Earing", "FacialHair"];
        const bodyPart = ["head", "eyebrow", "eyes", "mouth", "hair", "upperBody", "lowerBody", "feet", "hat", "helmet", "topHead", "mask", "tiara", "earing", "facialHair"];
        const bodyPartID = [head, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair];
        const bodyPartCount = bodyPart.length;
        let bodyParts = [];

        let objectToHide = [];
        let containSkin = { current: false, hair: false };

        for (let i = 0; i < bodyPartCount; i++) {
            let bodyPartName = bodyPart[i];
            let id = bodyPartID[i];

            let skinID = avatarList.findIndex(x => x.skin == skin);

            if (!avatarList[skinID]) continue;

            let baseID = avatarList[skinID].bases.findIndex(x => x.basesID == base);

            // console.log(skin, baseID, base, avatarList[skinID].bases, avatarList[skinID].bases[baseID]);

            if (!(avatarList[skinID].bases[baseID] &&
                avatarList[skinID].bases[baseID][bodyPartName] &&
                avatarList[skinID].bases[baseID][bodyPartName][id])
            ) continue;

            let jsonData = avatarList[skinID].bases[baseID][bodyPartName][id];

            if (jsonData) {

                let baseDirectory = `${process.env.RESOURCE_URL}/avatars/${skin}/${base}`;
                if (!isLocal) {
                    // console.log(bodyPartName);
                }

                if (geometryPoll.current[bodyPartName] == undefined) {
                    geometryPoll.current[bodyPartName] = await loadGeometry(baseDirectory, bodyPartDir[i], jsonData, context, objectToHide, containSkin);
                }
                else {
                    for (let i = 0; i < geometryPoll.current[bodyPartName].length; i++) {
                        const element = geometryPoll.current[bodyPartName][i];

                        if (element.textureName != 'skin' && element.textureName != 'hair') {
                            let texturePosition = textureData.position[element.textureName];
                            let textureSize = textureData.size[element.textureName];

                            context.drawImage(element.texture, texturePosition[0], texturePosition[1], textureSize[0], textureSize[1]);
                        }
                    }
                }

                if (bodyPartName == "hair") containSkin.hair = true;

                bodyParts.push(geometryPoll.current[bodyPartName]);
            }
            else {
                // console.log("NOTHING", bodyPartName, skin, base);
            }
        }

        if (isCheckSkin) {
            setIsSkinColorAvailable(containSkin.current);
            setIsHairColorAvailable(containSkin.hair);
        }

        // console.log("=====");

        let skinIndexes = [];
        let skinWeights = [];
        let geometries = [];


        let skinBone = [];
        let skinBoneNames = Object.keys(bones);

        for (let i = 0; i < skinBoneNames.length; i++) {
            skinBone.push(bones[skinBoneNames[i]]);
        }

        for (let i = 0; i < bodyParts.length; i++) {
            let bp = bodyParts[i];

            if (bp == undefined) continue;

            for (let j = 0; j < bp.length; j++) {
                let b = bp[j].mesh.skeleton.bones;
                let geom = bp[j].mesh.geometry.clone();

                if (objectToHide.includes(bp[j].bodyPart)) continue;

                let localBoneName = [];
                for (let k = 0; k < b.length; k++) {
                    localBoneName.push(b[k].name);
                }

                let indexCount = geom.attributes.skinIndex.array.length;

                for (let k = 0; k < indexCount; k++) {
                    let localBoneID = geom.attributes.skinIndex.array[k];
                    let currentIndex = skinBoneNames.indexOf(localBoneName[localBoneID]);
                    skinIndexes.push(currentIndex);
                    skinWeights.push(geom.attributes.skinWeight.array[k]);

                }

                if (geom.attributes.uv2) {
                    delete geom.attributes.uv2;
                }

                geometries.push(geom);
            }
        }

        let mergedGeometry = mergeBufferGeometries(geometries, material);
        mergedGeometry.setAttribute('skinIndex', new Uint16BufferAttribute(skinIndexes, 4));
        mergedGeometry.setAttribute('skinWeight', new Float32BufferAttribute(skinWeights, 4));
        mergedGeometry.renderOrder = 2;

        mergedGeometryRef.current = mergedGeometry;

        context.fillStyle = skinColor ? skinColor : "#e4a981";
        context.fillRect(256, 128, 128, 256);

        context.fillStyle = !hairColor || hairColor == 'null' ? "#211904" : hairColor;
        context.fillRect(381, 128, 128, 128);


        //if (isLocal) {

        //var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        //window.location.href = image;
        //}

        var canvasTexture = new CanvasTexture(
            canvas,
            UVMapping,
            ClampToEdgeWrapping,
            ClampToEdgeWrapping,
            LinearFilter,
            LinearFilter,
            RGBAFormat,
            UnsignedByteType,
            1);

        material.map = canvasTexture;

        canvasTextureRef.current = canvasTexture;

        let mesh = new SkinnedMesh(mergedGeometry, material);
        let skeleton = new Skeleton(skinBone);
        mesh.bind(skeleton);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.name = "NewMesh";

        playerModel.current.lastMesh = mesh;

        if (onClicked) {
            // console.log("HERE");
        }

        let tobeRemoveMesh = [];
        playerModel.current.traverse((child) => {
            if (child.isMesh) {
                tobeRemoveMesh.push(child);
            }
        });
        for (let x = 0; x < tobeRemoveMesh.length; x++) {
            tobeRemoveMesh[x].removeFromParent();
            tobeRemoveMesh[x].geometry.dispose();
        }

        playerModel.current.add(mesh);

        setPlayerData.current(bones, mesh, canvasTexture);

        setMesh(mesh);


        // if(isLocal) console.log("ALL LOAD");
    }

    const waitToLoadAllGeometry = async () => {
        
        onFinishLoad.current(false);

        await loadAllGeometry();


        if (isLocal) {
            if (onLocalPlayerFinishLoad.current) {
                onLocalPlayerFinishLoad.current();
            }
        }
        
        
        if (onFinishLoad && onFinishLoad.current) {
            onFinishLoad.current(true);
        }
    }

    useEffect(() => {
        if (avatarList == null) return;

        if (avatarList.length == null) return;

        let avatarSkin = avatarList.find(x => x.skin == skin);

        if (avatarSkin == null) return;

        let isPropsChange = false;

        // console.log(
        //     currentData.current.skin == skin , currentData.current.base == base , 
        //     currentData.current.skinColor == skinColor , currentData.current.hairColor == hairColor ,
        //     currentData.current.head == head , currentData.current.eyebrow == eyebrow , currentData.current.eyes == eyes ,
        //     currentData.current.mouth == mouth , currentData.current.hair == hair , currentData.current.upperBody == upperBody ,
        //     currentData.current.lowerBody == lowerBody , currentData.current.feet == feet , JSON.stringify(currentData.current.props) == JSON.stringify(props)
        // );

        if (currentData.current.skin == skin && currentData.current.base == base &&
            currentData.current.skinColor == skinColor && currentData.current.hairColor == hairColor &&
            currentData.current.head == head && currentData.current.eyebrow == eyebrow && currentData.current.eyes == eyes &&
            currentData.current.mouth == mouth && currentData.current.hair == hair && currentData.current.upperBody == upperBody &&
            currentData.current.lowerBody == lowerBody && currentData.current.feet == feet && currentData.current.hat == hat &&
            currentData.current.helmet == helmet && currentData.current.topHead == topHead && currentData.current.mask == mask &&
            currentData.current.tiara == tiara && currentData.current.earing == earing && currentData.current.facialHair == facialHair) return;

        currentData.current = { rootID: bones.hips.uuid, skinColor: skinColor, hairColor: hairColor, head: head, eyebrow: eyebrow, eyes: eyes, mouth: mouth, hair: hair, upperBody: upperBody, lowerBody: lowerBody, feet: feet, skin: skin, base: base, hat: hat, helmet: helmet, topHead: topHead, mask: mask, tiara: tiara, earing: earing, facialHair: facialHair }

        if (mesh) {
            mesh.removeFromParent();
            mesh.geometry.dispose();
        }

        if (playerModel.current.lastMesh) {
            playerModel.current.lastMesh.removeFromParent();
            playerModel.current.lastMesh.geometry.dispose();
        }

        // if (isLocal) console.log("LOAD GEOM ULANG");
        waitToLoadAllGeometry();

    }, [bones, skin, base, skinColor, hairColor, eyebrow, eyes, mouth, hair, upperBody, lowerBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair]);

    useEffect(() => {
        return () => {
            if (mergedGeometryRef.current) mergedGeometryRef.current.dispose();
            if (canvasTextureRef.current) canvasTextureRef.current.dispose();
            if (material) material.dispose();
        }
    }, []);

    return (
        <group ref={refPlayer} position={[0, -0.73, 0]} >
            <primitive object={playerModel.current} onClicked={onClicked}></primitive>
        </group>
    );
}
