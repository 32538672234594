
import { useBox, useTrimesh } from "@react-three/cannon";
import { Billboard, Box, Plane, Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useState, useEffect, useRef } from "react";
import { DoubleSide, Matrix4, Quaternion, Vector3 } from "three";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";
import { LoadClonedGeometry } from "./utils";

export const PopupObject = ({ loadedGeometry, popupMaterial, popupReadedMaterial, material, materialArtifact, sceneName, object, artifact, popupID, keyObject, visible, indices, vertics, geometry, geometryArtifact, keepPositionOriginal }) => {


    const onPopupClick = useRef(FunctionVariableContext((state) => state.clickPopup));
    const is360 = SceneContext(state => state.is360);
    const orbitOnly = SceneContext(state => state.orbitOnly);

    const [isLoaded, SetLoaded] = useState(false);
    const [isCliked, setClicked] = useState(false);
    const [showClick, setShowClick] = useState(false);
    const [isArtifact, setIsArtifact] = useState(false);
    const showClickRef = useRef(false);
    //const geometry = useRef();
    //const geometryArtifact = useRef();
    const scaleUnity = 10000;
    const mesh = useRef();
    const clickMeRef = useRef();
    const positionObj = keepPositionOriginal == true ? [0,0,0] : [object.position.x / scaleUnity, object.position.y / scaleUnity, object.position.z / scaleUnity];
    const rotationObj = object.rotation.z == null ?
        {
            rotation: keepPositionOriginal == true ? [0,0,0]: [object.rotation.x / scaleUnity, object.rotation.y / scaleUnity, object.rotation.z / scaleUnity, 'XYZ']
        } :
        {
            quaternion: keepPositionOriginal == true ? [0,0,0,0]: [object.rotation.x / scaleUnity, object.rotation.y / scaleUnity, object.rotation.z / scaleUnity, object.rotation.w / scaleUnity]
        };
    const scaleObj = [object.scale.x / scaleUnity, object.scale.y / scaleUnity, object.scale.z / scaleUnity];


    let positionArtifact = [0, 0, 0];
    let rotationArtifact = [0, 0, 0, 0];
    let scaleArtifact = [1, 1, 1];

    if (artifact) {
        positionArtifact = [artifact.position.x / scaleUnity, artifact.position.y / scaleUnity, artifact.position.z / scaleUnity];
        rotationArtifact = [artifact.rotation.x / scaleUnity, artifact.rotation.y / scaleUnity, artifact.rotation.z / scaleUnity, artifact.rotation.w / scaleUnity];
        scaleArtifact = [artifact.scale.x / scaleUnity, artifact.scale.y / scaleUnity, artifact.scale.z / scaleUnity];
    }

    useEffect(() => {

        if(geometryArtifact){
            setIsArtifact(true);
        }

        const LoadData = async () => {
            // console.log(geometry, geometryArtifact, material.material);
            // geometry.current = await LoadClonedGeometry(loadedGeometry, object.meshid, object.position, object.rotation, object.scale, sceneName, true, true);

            // if (artifact) {
            //     if (artifact.meshid.length > 0) {
            //         geometryArtifact.current = await LoadClonedGeometry(loadedGeometry, artifact.meshid, artifact.position, artifact.rotation, artifact.scale, sceneName, true, true);
            //         
            //     }
            // }

            SetLoaded(true);
        }

        const onPopupClickSubs = FunctionVariableContext.subscribe((state) => state.clickPopup, (data) => {
            onPopupClick.current = data;
        });

        LoadData();

        return () => {
            onPopupClickSubs();
            if(geometry) geometry.dispose();
            if (mesh.current) mesh.current.geometry.dispose();
            if (clickMeRef.current) clickMeRef.current.geometry.dispose();
            if (isArtifact) geometryArtifact.dispose();
            if (material.material.map) material.material.map.dispose();
            material.material.dispose();
        }
    }, []);

    useEffect(() => {

        if (clickMeRef.current) clickMeRef.current.position.y = mesh.current.geometry.boundingBox.min.y * mesh.current.scale.y;
    }, [showClick]);

    return (
        isLoaded &&
        <group>
            <group
                position={positionObj}
                {...rotationObj}
            >
                {
                    indices && vertics && <PopupCollider
                        indices={indices}
                        vertics={vertics}
                        setShowClick={setShowClick}
                        showClickRef={showClickRef}
                    />
                }
                {
                    <mesh
                        ref={mesh}
                        key={`popup-${keyObject}`}
                        geometry={geometry}
                        scale={scaleObj}
                        visible={visible}
                        castShadow
                        receiveShadow
                        onClick={
                            (e) => {
                                if (onPopupClick.current) {
                                    // console.log(is360);
                                    if (showClickRef.current || is360 || orbitOnly) {
                                        onPopupClick.current(popupID, sceneName);
                                        setClicked(true);
                                    }
                                }
                            }}
                        material={material.material}
                    />
                }
                {
                    showClick && <Plane
                        ref={clickMeRef}
                        args={[1, 0.4]}
                        rotation={[0, 3.14159, 0]}
                        position={[0, -1, -0.02]}
                        material={isCliked ? popupReadedMaterial : popupMaterial}
                    >
                        <Text
                            position={[0, 0, 0.01]}
                            color={isCliked ? '#c7c7c7' : '#000000'}
                            fontSize={0.15}
                            maxWidth={200}
                            lineHeight={1}
                            letterSpacing={0.02}
                            textAlign={'left'}
                            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                            anchorX="center"
                            anchorY="middle"
                        >
                            CLICK ME
                        </Text>
                    </Plane>
                }

            </group >
            {
                isArtifact && isCliked && <mesh
                    geometry={geometryArtifact}
                    position={positionArtifact}
                    quaternion={rotationArtifact}
                    scale={scaleArtifact}
                    material={materialArtifact.material}
                >
                </mesh>
            }
        </group>

    );

}

const PopupCollider = ({ indices, vertics, setShowClick, showClickRef }) => {

    const [popupRef, popupApi] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        isTrigger: true,
        args: [vertics, indices],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        onCollide: (e) => {
            setShowClick(true);
            showClickRef.current = true;
        },
        onCollideEnd: (e) => {
            setShowClick(false);
            showClickRef.current = false;
        }
    }));


    useFrame(()=>{
        popupApi.position.set(0,0,0);
        popupApi.rotation.set(0,0,0);
    });

    return <group ref={popupRef} ></group>
}
