import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import CloseButton from 'react-bootstrap/CloseButton';
import Fade from 'react-bootstrap/Fade';
import { AudioToggle } from './audio-toggle';
import PlayerListTable from './player-list-table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useRef } from 'react';
import { EmojiAnimationCircular } from './emoji-circular';
import { EndlessRunContext } from '../../context/game-context';

export default function PlayerEndlessrunInteraction({ toggleHoverBoard, controlToggle, sendShoutoutChat, onSelectEmoji, onShoutoutChat }) {

    const [openGameover, setOpenGameover] = useState(false);

    const [openStartGame, setOpenStartGame] = useState(true);
    const [openCountdown, setOpenCountdown] = useState(false);
    const [countDownText, setCountDownText] = useState("Get Ready!");
    const [coin, setCoin] = useState(0);

    const resetCoins = EndlessRunContext((state) => state.resetCoins);
    const setGameStart = EndlessRunContext((state) => state.setGameStart);
    const setGameOver = EndlessRunContext((state) => state.setGameOver);
    const setRetry = EndlessRunContext((state) => state.setRetry);

    useEffect(() => {

        const gameOver = EndlessRunContext.subscribe(state => state.gameOver, (data) => {
            if (data) {
                setOpenGameover(true)
            }
        });


        const listenCoin = EndlessRunContext.subscribe(state => state.userCoins, (data) => {
            setCoin(data);
        });

        return () => {
            gameOver();
        }
    }, [])

    const handlingStartGame = () => {
        setOpenStartGame(false);
        setOpenCountdown(true);

        let i = 1;
        let interval = setInterval(() => {
            let countdown = 4 - i;
            if (countdown == 0) {
                setGameStart(true);
                setCountDownText("Go!")
            } else if (countdown == -2) {
                // finish countdown
                clearInterval(interval);
                setOpenCountdown(false);
            } else if (countdown > 0) {
                setCountDownText(countdown);
            }
            i++;
        }, 1000)
    }

    const retryHandler = () => {
        setCountDownText("Get Ready!")
        setGameOver(false);
        setOpenGameover(false);
        setRetry(true);
        setCoin(0);
        resetCoins();
        handlingStartGame();
    }
    return (
        <>

            <div className={"endlessrun-box coin"}>
                <Card>
                    <Card.Body className={"chat-card-body"}>
                        Coins
                        <h4>{coin}</h4>
                    </Card.Body>
                </Card>
            </div>
            <Fade in={openCountdown}>
                <div className={"endlessrun-box countdown"}>
                    <Card>
                        <Card.Body className={"chat-card-body"}>
                            <h4>{countDownText}</h4>
                        </Card.Body>
                    </Card>
                </div>
            </Fade>

            <Fade in={openGameover}>
                <div className={"endlessrun-box"}>
                    <Card>
                        <Card.Body className={"chat-card-body"}>
                            <h4>Game Over!</h4>
                            <br />
                            <Button variant="default"
                                onClick={() => {
                                    retryHandler();
                                }}
                            >
                                Retry
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            </Fade>


            <Fade in={openStartGame}>
                <div className={"endlessrun-box"}>
                    <Card>
                        <Card.Body className={"chat-card-body"}>
                            <h4>Welcome to Endless Run!</h4>
                            <br />
                            <Button variant="default"
                                onClick={() => {
                                    handlingStartGame();
                                }}
                            >
                                Start Game
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            </Fade>


            <div className={"btn-group-interact right"}>
                <ButtonGroup>
                    <Button variant="secondary"
                        onClick={() => {
                            setOpenEmoji(true);
                            setOpenChat(false);
                            setOpenPlayer(false);
                        }}
                    >

                        <svg
                            data-src="./icon/btn-pose.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                    </Button>




                    <Button variant="secondary"
                        onClick={() => {
                            setOpenEmoji(true);
                            setOpenChat(false);
                            setOpenPlayer(false);
                        }}
                    >

                        <svg
                            data-src="./icon/btn-capture-image.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                    </Button>
                </ButtonGroup>
            </div>
        </>
    )
}