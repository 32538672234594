import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { GameConfigContext } from "../../context/game-context";

export const SceneNotAvailable = () => {

    const setLoading = GameConfigContext((state) => state.setLoading);
    useEffect(() => {

        setLoading(false, null)
    }, [])
    return (
        <div className="required-user-data">
            <Container>
                <h1>Page Is Unavailable</h1>
            </Container>
        </div>
    )
}