import * as THREE from 'three';
import LoadObject from './load-static-object';

export function CreateMatrix(position, rotation, scale) {

    let matrix = new THREE.Matrix4();

    let quaternion = new THREE.Quaternion();
    quaternion.setFromEuler(rotation);

    matrix.compose(position, quaternion, scale);

    return matrix;
}

export function CloneGeometryAndSetMatrix(geometry, position, rotation, scale, setOriginalGeometryPosition){

    const bufferGeometry = geometry.clone();
    
    const scaleUnity = 10000;
    const positionSO = position;
    const rotationSO = rotation;
    const scaleSO = scale;

    const positionObj = new THREE.Vector3(positionSO.x / scaleUnity, positionSO.y / scaleUnity, positionSO.z / scaleUnity);
    const rotationObj = !rotationSO.w ? 
                        new THREE.Euler(rotationSO.x / scaleUnity, rotationSO.y / scaleUnity, rotationSO.z / scaleUnity, 'XYZ') :
                        new THREE.Quaternion(rotationSO.x / scaleUnity, rotationSO.y / scaleUnity, rotationSO.z / scaleUnity, rotationSO.w / scaleUnity);
    const scaleObj = new THREE.Vector3(scaleSO.x / scaleUnity, scaleSO.y / scaleUnity, scaleSO.z / scaleUnity);
    
    
    let matrix = !rotationSO.w ? 
                    CreateMatrix(positionObj, rotationObj, scaleObj) :
                    new THREE.Matrix4().compose(positionObj, rotationObj, scaleObj);
    if(!setOriginalGeometryPosition) bufferGeometry.applyMatrix4(matrix);
    bufferGeometry.computeBoundsTree();

    return bufferGeometry;
}

export async function LoadClonedGeometry(loadedGeometry, geometryName, position, rotation, scale, sceneName, setOriginalGeometryPosition, dynamicPath, assetRef, meshDatas){
    let clickableObjectGeometry = loadedGeometry.current.find(x => x.name === geometryName);
    if (clickableObjectGeometry === undefined) {
        clickableObjectGeometry = await LoadObject(geometryName, sceneName, dynamicPath, assetRef, meshDatas);
        loadedGeometry.current.push({ name: geometryName, geometry: clickableObjectGeometry })
    } else {
        clickableObjectGeometry = clickableObjectGeometry.geometry;
    }
    // console.log(geometryName, clickableObjectGeometry);
    return CloneGeometryAndSetMatrix(clickableObjectGeometry, position, rotation, scale, setOriginalGeometryPosition);
}