
import { useState } from "react";
import "../../../css/nft-popup.css";

export const NFTPopup = ({onOpenNFT}) => {

    const [isNFTPopupShow, setNFTPopupShow] = useState(false);
    const [NFTLink, setNFTLink] = useState("");

    if(onOpenNFT !== undefined)
    {
        onOpenNFT.current = (command, id)=>{
            let link = "/data/dynamic/textures/";
            if(id === "1") link += "1.png";
            else if(id === "2") link += "2.png";
            else if(id === "3") link  += "3.png";
            else if(id === "4") link  += "4.png";
            else if(id === "5") link  += "5.png";
            else if(id === "6") link  += "6.png";
            setNFTLink(link);
            setNFTPopupShow(true);
        }
    }


    const close = () => {
        setNFTPopupShow(false);
    }

    return (
        isNFTPopupShow &&
        <div className="nft-popup-base">
            <img className="nft-popup-image" src={NFTLink.length > 0 ? NFTLink : "https://cdn06.pramborsfm.com/storage/app/media/Prambors/Editorial/NFT%20Bored%20Ape%20Yacht%20Club-20211214165446.jpg?tr=w-800"} alt=""/>
            <button className="nft-popup-close" onClick={close}>X</button>
        </div>
    );
}