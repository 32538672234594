import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useContext, useEffect, useRef, useState } from "react";
import { PlayerContext } from "./component/player-component/context/player-context";
import { degToRad } from 'three/src/math/MathUtils';
import { GamePlayerContext, PlayerSelectionContext } from "../game/context/game-context";
import { PlayerSelectionUI } from "./component/html-component/player-selection-ui";
import { updateUserProfile } from "../store/user.store";
import { PlayerModelAndAnimation } from "./component/player-model-animation";
import userEvent from "@testing-library/user-event";

export const PlayerSelection = ({ playerSettings }) => {

    const refPlayerModel = useRef();
    const refPlayerGroup = useRef();
    const orbitControl = useRef();
    const [gender, setGender] = useState(playerSettings.gender);
    const [upperBody, setUpperBody] = useState(playerSettings.upperBody);
    const [feet, setFeet] = useState(playerSettings.feet);
    const [lowerBody, setLowerBody] = useState(playerSettings.lowerBody);
    const [hair, setHair] = useState(playerSettings.hair);
    const [skin, setSkin] = useState(playerSettings.skin);
    const [base, setBase] = useState(playerSettings.base);
    const [head, setHead] = useState(playerSettings.head);
    const [eyebrow, setEyebrow] = useState(playerSettings.eyebrow);
    const [eyes, setEyes] = useState(playerSettings.eyes);
    const [mouth, setMouth] = useState(playerSettings.mouth);
    const [skinColor, setSkinColor] = useState(playerSettings.skinColor);
    const [hairColor, setHairColor] = useState(playerSettings.hairColor);

    const [hat, setHat] = useState(playerSettings.hat);
    const [helmet, setHelmet] = useState(playerSettings.helmet);
    const [topHead, setTopHead] = useState(playerSettings.topHead);
    const [mask, setMask] = useState(playerSettings.mask);
    const [tiara, setTiara] = useState(playerSettings.tiara);
    const [earing, setEaring] = useState(playerSettings.earing);
    const [facialHair, setFacialHair] = useState(playerSettings.facialHair);

    const assetRef= useRef({});

    let radius = 0.3;

    const {gl, camera} = useThree();

    useEffect(() => {

        const upperBodySubs = PlayerSelectionContext.subscribe(state => state._upperBody, (data) => {
            if (data != null)
                setUpperBody(data);

        })

        const feetSub = PlayerSelectionContext.subscribe(state => state._feet, (data) => {
            if (data != null)
                setFeet(data);
        })

        const lowerBodySubs = PlayerSelectionContext.subscribe(state => state._lowerBody, (data) => {
            if (data != null)
                setLowerBody(data);

        })

        const hairSub = PlayerSelectionContext.subscribe(state => state._hair, (data) => {
            if (data != null)
                setHair(data);
        })

        const propsHead = PlayerSelectionContext.subscribe(state => state._head, (data) => {
            if (data != null)
                setHead(data);
        })

        const eyebrowSubs = PlayerSelectionContext.subscribe(state => state._eyebrow, (data) => {
            if (data != null)
                setEyebrow(data);
        })

        const eyesSubs = PlayerSelectionContext.subscribe(state => state._eyes, (data) => {
            if (data != null)
                setEyes(data);
        })

        const mouthSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setMouth(data);
        })

        const hatSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setHat(data);
        })

        const helmetSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setHelmet(data);
        })

        const topHeadSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setTopHead(data);
        })

        const maskSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setMask(data);
        })

        const tiaraSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setTiara(data);
        })

        const earingSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setEaring(data);
        })

        const facialHairSubs = PlayerSelectionContext.subscribe(state => state._mouth, (data) => {
            if (data != null)
                setFacialHair(data);
        })


        const genderSub = PlayerSelectionContext.subscribe(state => state._gender, (data) => {
            if (data != null) {
                setUpperBody(0);
                setFeet(0);
                setLowerBody(0);
                setHair(0);
                setGender(data);
                setHead(0);
                setEyebrow(0);
                setEyes(0);
                setMouth(0);

                setHat(-1);
                setHelmet(-1);
                setTopHead(-1);
                setMask(-1);
                setTiara(-1);
                setEaring(-1);
                setFacialHair(-1);

            }
        })

        const skinSub = PlayerSelectionContext.subscribe(state => state._skin, (data) => {
            if (data != null) {
                setUpperBody(0);
                setFeet(0);
                setLowerBody(0);
                setHair(0);
                setSkin(data);
                setHead(0);
                setEyebrow(0);
                setEyes(0);
                setMouth(0);

                setHat(-1);
                setHelmet(-1);
                setTopHead(-1);
                setMask(-1);
                setTiara(-1);
                setEaring(-1);
                setFacialHair(-1);
            }
        })

        const baseSub = PlayerSelectionContext.subscribe(state => state._base, (data) => {
            if (data != null) {
                setUpperBody(0);
                setFeet(0);
                setLowerBody(0);
                setHair(0);
                setBase(data);
                setHead(0);
                setEyebrow(0);
                setEyes(0);
                setMouth(0);

                setHat(-1);
                setHelmet(-1);
                setTopHead(-1);
                setMask(-1);
                setTiara(-1);
                setEaring(-1);
                setFacialHair(-1);
            }
        })

        const skinColorSubs = PlayerSelectionContext.subscribe(state => state._skinColor, (data) => {
            if (data != null) {
                setSkinColor(data);
            }
        })

        const hairColorSubs = PlayerSelectionContext.subscribe(state => state._hairColor, (data) => {
            if (data != null) {
                setHairColor(data);
            }
        })

        return () => {
            // selectPlayerSub();
            upperBodySubs();
            hairSub();
            lowerBodySubs();
            genderSub();
            feetSub();
            skinSub();
            baseSub();
            propsHead();
            propsHead();
            eyebrowSubs();
            eyesSubs();
            mouthSubs();
            skinColorSubs();
            hairColorSubs();

            hatSubs();
            helmetSubs();
            topHeadSubs();
            maskSubs();
            tiaraSubs();
            earingSubs();
            facialHairSubs();
        }
    });

    
    useEffect(() => {
        window.addEventListener("resize", canvasResize)

        return()=>{
            window.removeEventListener("resize", canvasResize)
        }
    })

    const canvasResize = ()=>{
        camera.aspect = gl.domElement.clientWidth / gl.domElement.clientHeight;
        camera.updateProjectionMatrix();
        gl.setSize(gl.domElement.clientWidth, gl.domElement.clientHeight);
    }

    const baseGenderHandler = (skin, basesID) => {


        setSelection('_skin', skin);
        setSelection('_base', basesID);

    }

    useEffect(() => {
        if (refPlayerGroup.current) {
            refPlayerGroup.current.position.set(0, 1000, 0);
            refPlayerGroup.current.scale.set(1.2, 1.2, 1.2);
        }
        // orbitControl.current.reset();
    }, [gender, upperBody, feet, lowerBody, hair, skin, base, hair, skinColor, head, upperBody, feet, hat, helmet, topHead, mask, tiara, earing, facialHair]);

    useFrame(() => {
        orbitControl.current.target.set(refPlayerGroup.current.position.x, refPlayerGroup.current.position.y + 0.35, refPlayerGroup.current.position.z);
    });

    return (
        <>

            <OrbitControls
                ref={orbitControl}
                enablePan={false}
                target={[0, 5000, 0]}
                enableZoom={false}
                minDistance={2}
                maxDistance={2.01}
                minPolarAngle={degToRad(79.9)}
                maxPolarAngle={degToRad(80)}
            />
            <group ref={refPlayerGroup}>
                <PlayerModelAndAnimation
                    playerModel={refPlayerModel}
                    skin={skin}
                    base={base}
                    skinColor={skinColor}
                    hairColor={hairColor}
                    head={head}
                    eyebrow={eyebrow}
                    eyes={eyes}
                    mouth={mouth}
                    hair={hair}
                    upperBody={upperBody}
                    lowerBody={lowerBody}
                    feet={feet}
                    assetRef={assetRef}

                    hat={hat}
                    helmet={helmet}
                    topHead={topHead}
                    mask={mask}
                    tiara={tiara}
                    earing={earing}
                    facialHair={facialHair}
                    
                    colliderRadius={radius}
                    isCheckSkin={true}
                />
            </group>
        </>

    )
}