import * as THREE from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import UsePersonControls from '../player-controls';
import { CanvasContext } from './canvas-context';
import { PlayerContext } from './player-context';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Vector3 } from 'three';
import { UpdateParameter } from './player-update-parameter';
import { degToRad } from 'three/src/math/MathUtils';
import { SocketContext } from './socket-context';
import { FunctionVariableContext, GamePlayerContext, SceneContext } from '../../../context/game-context';

export const PlayerControl = (props) => {
    const { forward, backward, left, right, jump, run } = UsePersonControls();

    const setOnSelectEmoji = FunctionVariableContext(state => state.setOnSelectEmoji);
    const isPlayerDie = useRef(false);
    const setControlToggle = GamePlayerContext(state => state.setControlToggle);
    const setPlayerDie = GamePlayerContext(state=>state.setPlayerDie);
    const dynamicGroundColliders = useRef(SceneContext(state=>state.dynamicGroundColliders));
    const capturePhoto = useRef(GamePlayerContext(state => state.capturePhoto));

    const forwardKey = useRef(forward);
    const backwardKey = useRef(backward);
    const leftKey = useRef(left);
    const rightKey = useRef(right);
    const jumpKey = useRef(jump);
    const runKey = useRef(jump);

    const api = props.api;
    const { setDirectionalLightTarget } = useContext(CanvasContext);
    const { networkManager } = useContext(SocketContext);
    const { camera } = useThree();
    const forwardDirection = useRef(new THREE.Vector3(0, 0, 0));

    const updatePlayer = useRef();
    const currentPosition = useRef([0, 0, 0]);
    const currentVelocity = useRef([0, 0, 0]);
    const currentRotation = useRef([0, 0, 0]);
    const currentRaycastHitPoint = useRef([0, 0, 0]);
    const currentInteractiveMesh = useRef();
    const currentSeat = useRef("");
    const currentRaycastHitNormal = useRef(new Vector3(0, 0, 0));
    const currentTargetVelocity = useRef([0, 0, 0]);
    const currentQuaternion = useRef([0, 0, 0, 0]);
    const currentMoveDirection = useRef(new Vector3(0, 0, 0));
    const currentSpeeed = useRef(0);
    const lastGroundPosition = useRef([0, 0, 0]);
    let jumpingTime = useRef(0);
    let isJump = useRef(false);
    let isGrounded = useRef(false);
    let isSpecialAnimation = useRef(false);
    let playerSpeed = 10;
    let playerRunSpeed = 2;
    let playerAirSpeed = 6;
    let playerAcceleration = 10;
    let playerRotationSpeed = 8;
    let cameraMovementSpeed = 10;
    let maxGroundAngle = 50;
    let jumpHeight = 1.2;

    let upDirection = new THREE.Vector3(0, 1, 0);
    let rightDirection = new THREE.Vector3(0, 0, 0);
    let targetQuaternion = new THREE.Quaternion();
    let moveDirection = new THREE.Vector3(0, 0, 0);
    let playerModelWorldPosition = new THREE.Vector3(0, 0, 0);
    let cameraOffsetY = 1.5;
    let maxJumpingTime = 0.05;
    let jumpingSpeed = 6;
    let groundRaycastSafeDistance = 0.2;
    let groundDistanceAdjustment = 0.1;
    let groundRaycastFar = 1;
    let raycastHitPoint = new THREE.Vector3(0, 0, 0);
    let raycastHitNormal = new THREE.Vector3(0, 0, 0);
    let gravity = 0;
    let gravityAcceleration = 1;
    let currentPoint = new THREE.Vector3(0, 0, 0);
    let raycastDir = new THREE.Vector3(0, -1, 0);
    let groundAngle = useRef(0);
    let _isGrounded = false;
    let _isLastGround = useRef(false);
    let colliders = useRef([]);
    let groundHeightWhenJump = useRef(0);
    let airAcceleration = useRef(0);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));
    let isCurrentJump = useRef(false);

    const controlToggle = useRef(GamePlayerContext((state) => state.controlToggle));
    // const orbitControl = props.orbitControl.current;

    //TODO
    //Ini di pass dari camera control, kayaknya ga bener caranya
    const setCameraTarget = props.setCameraTarget.current;

    colliders.current = props.colliders;

    props.onPlayerSit.current = (command, parameter, mesh) => {

        isSpecialAnimation.current = true;
        props.refPlayerModel.current.setAnimation("sit");
        api.position.set(mesh.current.position.x, mesh.current.position.y, mesh.current.position.z);
        props.refPlayerModel.current.rotation.set(mesh.current.rotation.x, mesh.current.rotation.y, mesh.current.rotation.z, "XYZ");
        mesh.current.visible = false;

        if (currentInteractiveMesh.current) currentInteractiveMesh.current.visible = true;
        currentInteractiveMesh.current = mesh.current;
        currentSeat.current = parameter;
    }

    useEffect(() => {
        let gamePlayerSubs = GamePlayerContext.subscribe((state) => state.controlToggle, (data) => {
            controlToggle.current = data;
        });

        let isPlayerDieSubs = GamePlayerContext.subscribe((state) => state.dieTeleportPoint, (data) => {
            
            isPlayerDie.current = true;
            setPlayerDie(true);
            if (data) {
                props.refPlayerModel.current.setAnimation("die");
                setControlToggle(false);
                setTimeout(() => {
                    api.position.set(data.position.x, data.position.y, data.position.z);
                    api.quaternion.set(data.rotation.x, data.rotation.y, data.rotation.z, data.rotation.w);

                    setPlayerDie(false);
                    setControlToggle(true);

                    isPlayerDie.current = false;
                }, 2000);
            }

        });
        
        let dynamicGroundCollidersSubs = SceneContext.subscribe((state)=>state.dynamicGroundColliders, (data)=>{
            dynamicGroundColliders.current = data;
        });
        
        let capturePhotoSubs = GamePlayerContext.subscribe((state)=>state.capturePhoto, (data)=>{
            capturePhoto.current = data;
        });

        return () => {
            gamePlayerSubs();
            isPlayerDieSubs();
            dynamicGroundCollidersSubs();
            capturePhotoSubs();
        }
    }, []);

    useEffect(() => {
        api.angularFactor.set(0, 1, 0);

        props.onSelectEmoji.current = changeAnimation;
        props.refPlayerModel.current.rotation.x = 0;
        props.refPlayerModel.current.rotation.z = 0;
    }, []);

    useEffect(() => {
        const unsubscribe = api.position.subscribe((v) => {
            updatePlayerPosition(v);
            update(1 / 60, v);
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        const unsubscribe = api.velocity.subscribe((v) => {
            currentVelocity.current = v;
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        const unsubscribe = api.rotation.subscribe((v) => currentRotation.current = v);
        return unsubscribe;
    }, []);

    useEffect(() => {
        const unsubscribe = api.quaternion.subscribe((v) => currentQuaternion.current = v);
        return unsubscribe;
    }, []);

    useEffect(() => {
        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {
            isFPS.current = data;

            props.refPlayerModel.current.traverse((child) => {
                if (child.isMesh) {
                    child.visible = !isFPS.current;
                }
            });
        });

        return () => {
            firstPersonSubs();
        };
    }, []);

    const radToDeg = (radians) => {
        let pi = Math.PI;
        return radians * (180 / pi);
    }

    const lerp = (v0, v1, t) => {
        return v0 + t * (v1 - v0);
    }

    const changeAnimation = (id) => {
        props.refPlayerModel.current.onSpecialAnimationFinish = onSpecialAnimationFinish;
        props.refPlayerModel.current.setAnimation(id);
        isSpecialAnimation.current = true;
    }

    useEffect(() => {
        setOnSelectEmoji((animationID) => {
        });
    }, []);

    const onSpecialAnimationFinish = (animationName) => {
        isSpecialAnimation.current = false;
    }

    const updatePlayerPosition = (v) => {
        let minY = lastGroundPosition.current[1] + (props.colliderRadius * 2) + groundDistanceAdjustment;
        let current = lerp(v[1], minY, 0.16);
        let position = [v[0], jumpingTime.current > 0 ? v[1] : isGrounded.current ? current : v[1], v[2]]
        api.position.set(position[0], position[1], position[2]);
        currentPosition.current = position;

        currentPoint.set(currentPosition.current[0], currentPosition.current[1], currentPosition.current[2]);
        raycastDir.set(0, -1, 0);
        let raycasterGround = new THREE.Raycaster(currentPoint, raycastDir, 0.001, groundRaycastFar);

        let groundColliders = [];
        groundColliders = groundColliders.concat(colliders.current);
        groundColliders = groundColliders.concat(dynamicGroundColliders.current);
        let intersectGround = raycasterGround.intersectObjects(groundColliders, true);
        if (intersectGround.length > 0) {
            isGrounded.current = (intersectGround[0].distance < props.colliderRadius * 2 + groundRaycastSafeDistance);

            raycastHitPoint.set(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
            currentRaycastHitPoint.current = [intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z];
            raycastHitNormal.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);
            currentRaycastHitNormal.current.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);

            groundAngle.current = 180 - radToDeg(raycastDir.angleTo(raycastHitNormal));
        }
        else {
            isGrounded.current = false;
        }

        if (currentInteractiveMesh.current) {
            api.position.set(currentInteractiveMesh.current.position.x, currentInteractiveMesh.current.position.y + 0.6, currentInteractiveMesh.current.position.z);
            props.refPlayerModel.current.rotation.set(currentInteractiveMesh.current.rotation.x, currentInteractiveMesh.current.rotation.y, currentInteractiveMesh.current.rotation.z, "XYZ");
        }

        // updatePlayerMovement(v);
    }

    const updatePlayerMovement = (v) => {
        api.velocity.set(currentTargetVelocity.current[0], currentTargetVelocity.current[1], currentTargetVelocity.current[2]);
    }


    let jumpingTargetPosition = 0;
    let isJumping = false;
    const update = (delta, v) => {

        if (colliders.current === undefined) return;

        _isGrounded = isGrounded.current;

        camera.getWorldDirection(forwardDirection.current);
        forwardDirection.current.y = 0;
        forwardDirection.current.normalize();
        rightDirection.crossVectors(forwardDirection.current, upDirection);


        if (forwardKey.current) {
            moveDirection.add(forwardDirection.current);
        }
        else if (backwardKey.current) {
            forwardDirection.current.multiplyScalar(-1);
            moveDirection.add(forwardDirection.current);
        }

        if (rightKey.current) {
            moveDirection.add(rightDirection);
        }
        else if (leftKey.current) {
            rightDirection.multiplyScalar(-1);
            moveDirection.add(rightDirection);
        }

        if (props.analogInput && props.analogInput.current) {

            let analog = props.analogInput.current;
            let angle = Math.atan2(-analog.y, analog.x) - Math.atan2(0, 0);
            angle = 270 - radToDeg(angle);
            moveDirection.add(forwardDirection.current);
            moveDirection.applyAxisAngle(upDirection, degToRad(angle))
        }

        let isJumpKey = jumpKey.current;
        if (props.jumpInput && props.jumpInput.current !== undefined) isJumpKey = props.jumpInput.current || jumpKey.current;


        if (isJumpKey && controlToggle.current === true && !isCurrentJump.current) {
            if (_isGrounded) {
                if (!isJump.current) {
                    jumpingTime.current = maxJumpingTime;
                    isJump.current = true;
                    isCurrentJump.current = true;

                    let worldPosition = new Vector3();
                    props.refPlayerModel.current.getWorldPosition(worldPosition);
                    groundHeightWhenJump.current = worldPosition.y;
                    // console.log(currentRaycastHitPoint.current);

                    isJumping = true;
                    jumpingTargetPosition = v[1] + jumpHeight;

                    if (updatePlayer.current != undefined) updatePlayer.current();
                    // console.log(jumpingTargetPosition);
                }
            }
        } else if (!isJumpKey && isCurrentJump.current) {
            isCurrentJump.current = false;
        }

        if (isJumping && v[1] > jumpingTargetPosition) {
            if (updatePlayer.current != undefined) updatePlayer.current();
            isJumping = false;
            isJump.current = false;
        }

        if (jumpingTime.current <= 0) {
            gravity = !_isGrounded || groundAngle.current > maxGroundAngle ? gravity + (gravityAcceleration) : 0;
        }
        else {
            gravity = 0;
        }

        if (((moveDirection.x !== 0 || moveDirection.z !== 0) && controlToggle.current === true) && groundAngle.current < maxGroundAngle) {
            isSpecialAnimation.current = false;
            if (currentInteractiveMesh.current) {
                currentInteractiveMesh.current.visible = true;
                currentInteractiveMesh.current = undefined;
                currentSeat.current = "";
            }

            moveDirection.normalize();
            var targetMatrix = new THREE.Matrix4().lookAt(moveDirection, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            targetQuaternion.setFromRotationMatrix(targetMatrix);

            props.refPlayerModel.current.quaternion.slerp(targetQuaternion, delta * playerRotationSpeed);

            let acceleration = (_isGrounded ? (runKey.current ? playerRunSpeed : playerSpeed) : playerAirSpeed);
            // currentSpeeed.current += delta * playerAcceleration;
            currentSpeeed.current = acceleration;
            if (currentSpeeed.current > acceleration) currentSpeeed.current = acceleration;
            currentMoveDirection.current.set(moveDirection.x, moveDirection.y, moveDirection.z);
            currentMoveDirection.current.multiplyScalar(currentSpeeed.current);

            lastGroundPosition.current = [currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]];
            // console.log("LAST GROUND 0");
            if (_isGrounded) {
                let up = new THREE.Vector3(0, 1, 0);
                let normalQuaternion = new THREE.Quaternion().setFromUnitVectors(up, currentRaycastHitNormal.current);
                let matrixQuaternion = new THREE.Matrix4().makeRotationFromQuaternion(normalQuaternion);
                currentMoveDirection.current.applyMatrix4(matrixQuaternion);
            }
        }
        else {
            currentSpeeed.current = 0;
            currentMoveDirection.current.set(0, 0, 0);
        }

        if (_isGrounded !== _isLastGround.current) {
            if (_isGrounded) {
                lastGroundPosition.current = [currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]];
                airAcceleration.current = jumpingSpeed;
            }
            _isLastGround.current = _isGrounded;
        }

        if (groundAngle.current > maxGroundAngle) {
            lastGroundPosition.current = [currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]];
        }

        let animationToPlay = "";
        if(isPlayerDie.current){
            animationToPlay = "die";
        }
        else if (!_isGrounded || groundAngle.current > maxGroundAngle) {
            animationToPlay = "jumping";
        }
        else if ((moveDirection.x !== 0 || moveDirection.y !== 0 || moveDirection.z !== 0)) {

            if (runKey.current) {
                animationToPlay = "walk";
            }
            else {
                animationToPlay = "run";
            }
        }
        else {
            animationToPlay = "idle";
        }


        if (isSpecialAnimation.current) {
            currentMoveDirection.current.set(0, 0, 0);
            jumpingTime.current = -1;
            isJumping = false;
            if (updatePlayer.current != undefined) updatePlayer.current();
        }
        else {
            if (props.refPlayerModel && props.refPlayerModel.current && props.refPlayerModel.current.setAnimation && controlToggle.current == true) props.refPlayerModel.current.setAnimation(animationToPlay);
        }



        if (groundAngle.current > maxGroundAngle && _isGrounded) {
            let up = new THREE.Vector3(0, -1, 0);

            let distance = currentRaycastHitNormal.current.dot(up) * -1;
            let normal = currentRaycastHitNormal.current.clone();
            normal.multiplyScalar(distance).add(up).normalize().multiplyScalar(playerAirSpeed);
            currentTargetVelocity.current = [normal.x, normal.y, normal.z];

            var direction = normal.clone();
            direction.y = 0;
            var playerMatrix = new THREE.Matrix4().lookAt(direction, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            targetQuaternion.setFromRotationMatrix(playerMatrix);
            props.refPlayerModel.current.quaternion.slerp(targetQuaternion, delta * playerRotationSpeed);
        }
        else {

            if (isJumping) {
                airAcceleration.current = jumpingSpeed;
            }
            else if (_isGrounded) {
                airAcceleration.current = 0;
            }
            else if (!_isGrounded) {
                airAcceleration.current = lerp(airAcceleration.current, -gravity, 0.1);
            }
            // console.log(jumpVelocity);
            // airAcceleration.current = lerp(airAcceleration.current, -jumpingSpeed, 1);

            currentTargetVelocity.current = [currentMoveDirection.current.x, currentMoveDirection.current.y + airAcceleration.current, currentMoveDirection.current.z];
        }

        // copyPhysicsValueToObject();
        props.refPlayerModel.current.getWorldPosition(playerModelWorldPosition);
        // if (orbitControl) orbitControl.target.lerp(new THREE.Vector3(playerModelWorldPosition.x, playerModelWorldPosition.y + cameraOffsetY, playerModelWorldPosition.z), delta * cameraMovementSpeed);

        // if (currentPosition.current[0] !== 0 && currentPosition.current[1] !== 0 && currentPosition.current[2] !== 0)
        // setCameraTarget(currentPosition.current[0], currentPosition.current[1], currentPosition.current[2], colliders.current, props.refPlayerModel.current);
        // console.log(raycastHitPoint.y);


        // props.refPlayerModel.current.topHead.getWorldPosition(topHeadPosition);
        api.velocity.copy(new Vector3(currentTargetVelocity.current[0], currentTargetVelocity.current[1], currentTargetVelocity.current[2]));

        if (props.refPlayerModel.current && props.refPlayerModel.current.topHead) {
            let topHeadPosition = new Vector3();
            props.refPlayerModel.current.getWorldPosition(topHeadPosition);
            topHeadPosition.y += 1.5;
            setCameraTarget(v[0], v[1], v[2], colliders.current, props.refPlayerModel.current, topHeadPosition, props.isFPS);

        }

        moveDirection.set(0, 0, 0);


        // copyPhysicsValueToObject();
    }

    useFrame((src, delta) => {

        forwardKey.current = forward;
        backwardKey.current = backward;
        leftKey.current = left;
        rightKey.current = right;
        jumpKey.current = jump;
        runKey.current = run;

        if (jumpingTime.current > 0) {
            // jumpingTime.current -= delta;
            gravity = 0;
        }

        let worldPosition = new Vector3();
        props.refPlayerModel.current.getWorldPosition(worldPosition);
        if (jumpingTime.current > 0 && worldPosition.y - groundHeightWhenJump.current > jumpHeight) {
            jumpingTime.current = -1;
            isJumping = false;
            if (updatePlayer.current != undefined) updatePlayer.current();
            // console.log(worldPosition.y - groundHeightWhenJump.current);
        }
    });

    return (
        <group>
            <UpdateParameter
                updatePlayer={updatePlayer}
                currentPosition={currentPosition}
                networkManager={networkManager}
                setDirectionalLightTarget={setDirectionalLightTarget}
                forwardDirection={forwardDirection}
                sit={currentSeat}
                {...props}
            />
        </group>
    )
}