import { useTrimesh } from "@react-three/cannon";
import { useEffect } from "react"
import { SceneContext } from "../../context/game-context";

export const AreaDetection = ({ geometry, area }) => {

    const vertics = geometry.attributes.position.array;
    const indices = geometry.index.array;

    const setArea = SceneContext(state => state.setArea);

    const playerPhysicsMaterial = {
        friction: 0,
        restitution: 0,
        contactEquationStiffness: 0,
        contactEquationRelaxation: 3,
        frictionEquationStiffness: 0,
        frictionEquationRelaxation: 3
    }

    useEffect(() => {
        return () => {
            geometry.dispose();
        }
    }, []);


    const [ref] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [vertics, indices],
        material: playerPhysicsMaterial,
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            setArea(area);
        },
        onCollideEnd: (collider) => {
            setArea(null);
        }
    }));

    return <group ref={ref} dispose={null}></group>
}