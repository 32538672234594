import { useEffect, useState } from "react"
import { SceneContext } from "../../context/game-context";
import "../../../css/npc-popup.css"

export const InstructionPopup = ({showTutorial}) => {

    const [instruction, setInstructionList] = useState(SceneContext(state => state.instructions));

    const setInstruction = SceneContext((state) => state.setInstruction);
    const [instructionID, setInstructionID] = useState(0);

    useEffect(() => {
        let instructionSubs = SceneContext.subscribe((state) => state.instructions, (data) => {

            setInstructionID(0);
            setInstructionList(data);
        });

        return () => {
            instructionSubs();
        }
    }, []);

    return (
        instruction.length > 0 && !showTutorial && <div className="npc-pop">
            <div className="npc-pop-body">
                <div className="npc-pop-panel" dangerouslySetInnerHTML={{ __html: instruction[instructionID] }}></div>
                <div className="npc-button-body">
                    <button className="btn-default button-popup skip" onClick={() => {
                        setInstruction([]);
                    }}>Skip</button>
                    <button className="btn-default button-popup next" onClick={() => {
                        let nextID = instructionID + 1;
                        if (nextID >= instruction.length) {
                            setInstruction([]);
                        }
                        else {
                            setInstructionID(nextID);
                        }
                    }}>Next</button>
                </div>
            </div>
        </div>
    )
}