import { useRef } from "react";
import "../../../css/input-player-name.css";

export const InputPassword = ({onPasswordInput}) =>{
    
    const passwordInput = useRef();

    const onClick = ()=>{
        onPasswordInput(passwordInput.current.value);
    }

    return (
        <div className="player-name-input-base">
            <label className="player-name-label">Password</label>
            <input ref={passwordInput} type="password" className="player-name-input" />
            <button onClick={onClick} className="player-name-button">SUBMIT</button>
        </div>
    );
} 