import { useEffect, useState } from "react"
import { GamePlayerContext } from "../../context/game-context";
import "../../../css/crosshair.css"

export const Crosshair = () =>{
    const [showCrosshair, setShowCrosshair] = useState(GamePlayerContext((state)=>state.isCursorLock));
    const [isFirstPerson, setIsFirstPerson] = useState(GamePlayerContext((state)=>state.isFirstPersonCamera));

    useEffect(()=>{
        const showCrosshairSubs = GamePlayerContext.subscribe((state)=>state.isCursorLock, (data)=>{
            setShowCrosshair(data);
        });

        const isFirstPersonSubs = GamePlayerContext.subscribe((state)=>state.isFirstPersonCamera, (data)=>{
            setIsFirstPerson(data);
        });

        return ()=>{
            showCrosshairSubs();
            isFirstPersonSubs();
        }
    },[]);

    return (showCrosshair && <div className="crosshair"><div className="crosshair-dot"></div></div>);
}