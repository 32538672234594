import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Quaternion } from "three";
import { Vector3 } from "three";
import { SceneContext } from "../../context/game-context";
import { LoadClonedGeometry } from "./utils";

export const ClickableObject = ({ keyObject, data, material, loadedGeometry, onClick, onHoverEnter, onPointerOut, setToggleAction, sceneName, clickableObjects }) => {

    const setClickable = SceneContext((state) => state.setClickable);
    const [isLoaded, SetLoaded] = useState(false);
    const [isButton, SetButton] = useState(false);
    const geometry = useRef();
    const command = useRef("");
    const parameter = useRef("");
    const mesh = useRef();
    const { camera } = useThree();
    const scaleUnity = 10000;
    const positionObj = [data.obj.position.x / scaleUnity, data.obj.position.y / scaleUnity, data.obj.position.z / scaleUnity];
    const rotationObj = !data.obj.rotation.z ?
        {
            rotation: [data.obj.rotation.x / scaleUnity, data.obj.rotation.y / scaleUnity, data.obj.rotation.z / scaleUnity, 'XYZ']
        } : {
            quaternion: [data.obj.rotation.x / scaleUnity, data.obj.rotation.y / scaleUnity, data.obj.rotation.z / scaleUnity, data.obj.rotation.w / scaleUnity]
        }
    const scaleObj = [data.obj.scale.x / scaleUnity, data.obj.scale.y / scaleUnity, data.obj.scale.z / scaleUnity];

    useEffect(() => {

        const LoadData = async () => {

            geometry.current = await LoadClonedGeometry(loadedGeometry, data.obj.name, data.obj.position, data.obj.rotation, data.obj.scale, sceneName, true, true);
            command.current = data.command;
            parameter.current = data.name;
            SetButton(data.button);
            SetLoaded(true);
        }

        setToggleAction.current[parameter.current] = (bool, isRemote) => {
            if (isRemote) mesh.current.visible = bool;
            else if (!isRemote && mesh.current.isRemote && !mesh.current.visible) mesh.current.visible = true;
            if (mesh && mesh.current) mesh.current.isRemote = isRemote;
        }
        clickableObjects.current[data.name] = mesh;

        LoadData();

        return () => {
            geometry.current.dispose();
        }
    }, []);


    return (
        isLoaded && <group
            dispose={null}>
            {
                <mesh
                    ref={mesh}
                    key={`clickable-${keyObject}`}
                    geometry={geometry.current}
                    position={positionObj}
                    {...rotationObj}
                    scale={scaleObj}
                    castShadow
                    receiveShadow
                    onClick={
                        (e) => {
                            setClickable({ command: command.current, parameter: parameter.current });
                            onClick ? onClick(command.current, parameter.current, mesh, e) : onClick = undefined;
                        }}
                    onPointerEnter={
                        (e) => {
                            onHoverEnter ? onHoverEnter(mesh, e) : onHoverEnter = undefined;
                        }
                    }
                    onPointerOut={
                        (e) => {
                            onPointerOut ? onPointerOut(mesh, e) : onPointerOut = undefined;
                        }
                    }
                    material={material.material}
                />
            }
        </group>

    );
}