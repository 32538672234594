import Table from 'react-bootstrap/Table';
import { useEffect, useState } from "react";
import { FunctionVariableContext, GameConfigContext, GamePlayerContext, LocalPlayerContext } from '../../context/game-context';
import "../../../css/player-list-table.css";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularJSON from 'circular-json';

const PlayerListTable = () => {
    const [listPlayer, setListPlayer] = useState(GamePlayerContext(state => state.listPlayer));
    // const [listPlayer, setListPlayer] = useState(["ADAM", "IDIM", "UDUM", "LALA", "DIPSI", "PO", "UJANG"]);
    const [localPlayer, setLocalPlayer] = useState(LocalPlayerContext(state => state));
    const [selectedid, setSelectedID] = useState(null);

    let [isPlayerHost, setPlayerHost] = useState(GamePlayerContext((state) => state.isPlayerHost));
    let [isHostControlledRoom, setHostControlledRoom] = useState(GamePlayerContext((state) => state.isHostControlledRoom));
    let [presenterid, setPresenterID] = useState(GamePlayerContext((state) => state.presenterid));
    let [messageLink, setMessageLink] = useState(GameConfigContext(state => state.messageLink));
    const setOpenDirectMessage = GamePlayerContext(state => state.setOpenChat);

    const setRoomPresenter = FunctionVariableContext(state => state.setRoomPresenter);

    // get context
    useEffect(() => {
        const messageLinkSubs = GameConfigContext.subscribe(state => state.messageLink, (data) => {
            setMessageLink(data);
        });

        const listPlayerSub = GamePlayerContext.subscribe(state => state.listPlayer, (data) => {
            // console.log("PLAYER LIST TABLE LISTEN", data);
            // remotePlayerList.current = state.listPlayer
            setListPlayer(data);
        })
        const localPlayerSub = LocalPlayerContext.subscribe(state => state, (data) => {
            // console.log(data, "PLAYER LIST TABLE LISTEN")
            // const updatedLocalPlayer = CircularJSON.parse(localStorage.getItem("local_player_setting"));
            // remotePlayerList.current = state.listPlayer
            setLocalPlayer(data);
        })

        const isHostControlledRoomContext = GamePlayerContext.subscribe((state) => state.isHostControlledRoom, (data) => {
            setHostControlledRoom(data);
        });

        const isPlayerHostContext = GamePlayerContext.subscribe(state => state.isPlayerHost, (data) => {
            setPlayerHost(data);
        });

        const presenteridContext = GamePlayerContext.subscribe(state => state.presenterid, (data) => {
            setPresenterID(data);
        });

        return () => {
            messageLinkSubs();
            listPlayerSub();
            localPlayerSub();
            isHostControlledRoomContext();
            isPlayerHostContext();
            presenteridContext();
        }
    }, [])

    const onSetAsPresenter = (id) => {
        setRoomPresenter(id);
    }

    const onSetMute = (id) => {
    }
    // console.log(listPlayer, localPlayer, "PLAYER LIST TABLE")
    return (<Table hover size="sm" id="player-list-table">

        <tbody>
            {listPlayer && listPlayer.map((r, i) => {
                if (r.id != localPlayer?.playerId && r.uid != null) {
                    return (
                        <tr key={i}
                            onMouseEnter={() => { setSelectedID(r.uid) }}
                            onMouseLeave={() => { setSelectedID(null) }}
                        >
                            <td className='text-left'>{r.id == localPlayer?.playerId ? "You" : r.displayName} {presenterid == r.id ? "(Presenting)" : ""}</td>
                            <td className='text-right'>
                                <ButtonGroup>
                                    {
                                        selectedid == r.id && isHostControlledRoom && isPlayerHost &&
                                        <Button size="sm" className='set-presenter-button' onClick={() => { onSetAsPresenter(r.id) }}>
                                            <FontAwesomeIcon icon="fas fa-display" />
                                        </Button>
                                    }
                                    <Button size="sm" className='set-presenter-button' onClick={() => {
                                        // onSetMute(r.id)
                                        if (r.uid) {
                                            setOpenDirectMessage(r.uid);
                                        }
                                        console.log(r.uid)
                                    }}>
                                        <FontAwesomeIcon icon={"fas fa-comment"} />
                                    </Button>
                                    {/* {
                                        r.id != localPlayer?.playerId && r.uid ?
                                            <Button size="sm" className='set-presenter-button' onClick={() => {
                                                // onSetMute(r.id)
                                                if (r.uid) {
                                                    setOpenDirectMessage(r.uid);
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={"fas fa-comment"} />
                                            </Button> : "User not login"
                                    } */}
                                </ButtonGroup>
                            </td>
                        </tr>
                    );
                }
            })}
        </tbody>
    </Table>)
}

export default PlayerListTable;