import { useTrimesh } from "@react-three/cannon";
import { useAnimations } from "@react-three/drei"
import { useEffect, useRef, useState } from "react";
import { LoopRepeat } from "three";
import { GamePlayerContext, TreasureHuntContext } from "../../context/game-context";

export const DroppedItem = ({ object, id, total, clip, detectionObjectVertics, detectionObjectIndices, sceneName, geometry }) => {

    const [dropItem, setDropItem] = useState(GamePlayerContext((state) => state.dropItem));
    const addCollectedDropItemList = GamePlayerContext((state) => state.addCollectedDropItemList);
    const currentSequanceID = useRef();
    const addPoint = TreasureHuntContext((state) => state.addPoint);

    let [visible, setVisible] = useState(false);
    let { actions } = useAnimations([clip], object);
    let currentVisibility = useRef();

    useEffect(() => {
        setVisible(false);
        object.visible = false;

        let dropItemSubs = GamePlayerContext.subscribe(state => state.dropItem, (data) => {

            if (data == null) {
                setVisible(false);
            }
            else {
                let dropID = (data.sequence % (total - 1));

                if (id == dropID) {
                    currentSequanceID.current = data.id;
                    setVisible(true);
                }
                else {
                    setVisible(false);
                }
            }

            setDropItem(data)
        });

        return () => {
            dropItemSubs();
            geometry.dispose();
        }
    }, []);

    useEffect(() => {
        currentVisibility.current = visible;
        object.visible = visible;
    }, [visible]);

    const [ref] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [detectionObjectVertics, detectionObjectIndices],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            if (currentVisibility.current) {
                // addCollectedDropItemList(dropItem);
                // setVisible(false);

                // if scene name == dg
                // if(sceneName == 'dg' || sceneName == 'dgaward') {
                addPoint(currentSequanceID.current, "treasure_hunt");
                // }
            }
        },
        onCollideEnd: (collider) => {
        }
    }));

    useEffect(() => {
        let keys = Object.keys(actions);

        keys.forEach(key => {
            // console.log(actions[key]);
            actions[key].loop = LoopRepeat;
            actions[key]._mixer.addEventListener('finished', (e) => {
                currentID.current++;

                let objectKeys = Object.keys(actions);
                if (currentID.current >= objectKeys.length) {
                    currentID.current = 0;
                }

                actions[objectKeys[currentID.current]].play();
            }
            );
            actions[key].play();
        });
    }, []);

    return <primitive object={object} dispose={null}/>
}