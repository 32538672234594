import { useFrame, useThree } from "@react-three/fiber"
import { useEffect } from "react";
import { useRef } from "react";
import { Vector3 } from "three";
import { GamePlayerContext } from "../../context/game-context";

export const PhotoSpot = ({id, obj, position, scale})=>{

    const setPhotoSpotId = GamePlayerContext(state=>state.setPhotoSpotId);
    const removePhotoSpotId = GamePlayerContext(state=>state.removePhotoSpotId);
    const capturedPhoto = useRef(GamePlayerContext(state => state.capturePhoto));
    const photoSpotId = useRef(GamePlayerContext(state=>state.photoSpotId));
    const photoSpotRef = useRef();
    const {camera} = useThree();


    useEffect(()=>{
        let photoSpotIdSubs = GamePlayerContext.subscribe(state=>state.photoSpotId, (data)=>{
            photoSpotId.current = data;
        });

        let capturedPhotoSubs = GamePlayerContext.subscribe(state => state.capturePhoto, (data) => {
            capturedPhoto.current = data;
        });

        return()=>{
            photoSpotIdSubs();
            capturedPhotoSubs();
            removePhotoSpotId(id);
        }
    },[]);

    useFrame(()=>{
        
        let angle = Math.atan2((camera.position.x - position[0]), (camera.position.z - position[2]));
        photoSpotRef.current.rotation.set(0, angle, 0);

        if(!capturedPhoto.current ) return;

        var maxWidth = 600;
        var currentWidth = window.innerWidth * 0.95;
        if(currentWidth > maxWidth) currentWidth = maxWidth;

        var width = window.innerWidth, height = window.innerHeight;
        var widthHalf = width / 2, heightHalf = height / 2;
        
        var pos = photoSpotRef.current.position.clone();
        pos.project(camera);
        pos.x = ( pos.x * widthHalf ) + widthHalf;
        pos.y = - ( pos.y * heightHalf ) + heightHalf;

        let minXSaveArea = (window.innerWidth - currentWidth) / 2;
        let maxXSaveArea = ((window.innerWidth - currentWidth) / 2) + currentWidth;

        
        let minYSaveArea = (window.innerHeight - currentWidth) / 2;
        let maxYSaveArea = ((window.innerHeight - currentWidth) / 2) + currentWidth;
        
        let distance = camera.position.distanceTo(new Vector3(position[0], position[1], position[2]));

        if(pos.x > minXSaveArea && pos.x < maxXSaveArea && pos.y > minYSaveArea && pos.y < maxYSaveArea && distance < 20){
            if(photoSpotRef.current != id)
            {
                setPhotoSpotId(id);
            }
        }
        else
        {
            removePhotoSpotId(id);
        }

    });

    return <primitive ref={photoSpotRef} object={obj} position={position} scale={scale} dispose={null} />
}