import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { config, avatarList } from "../cfg";
import { SceneContext } from "./context/game-context";

export const SceneHandler = ({ sceneID, scene, setListAvatar }) => {
    let { sceneid } = useParams();
    const defaultScene = SceneContext((state) => state.scene);
    const sceneCheck = scene ? scene : (sceneid ? sceneid : defaultScene);

    const setScene = SceneContext((state)=>state.setScene);

    if (sceneID.current != sceneCheck) {
        sceneID.current = sceneCheck;
        // const sceneConfig = config.find((r) => r.scene == sceneid);
        // // if empty load all avatars
        // if (sceneConfig && sceneConfig.allowedAvatars.length > 0) {
        //     setListAvatar(avatarList.filter((r) => sceneConfig.allowedAvatars.indexOf(r.name) !== -1));
        // } else {
        //     setListAvatar(avatarList.map((r) => r.name))
        // }
    }

    useEffect(()=>{
        setScene(sceneID.current);
    },[]);

    return <></>
}