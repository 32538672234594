import { useFrame, useThree } from "@react-three/fiber"
import { forwardRef, useEffect, useRef, useState } from "react";
import { Clock, DataArrayTexture } from "three";
import { Matrix4 } from "three";
import { Quaternion } from "three";
import { Vector3, Raycaster, Euler } from "three";
import { radToDeg } from "three/src/math/MathUtils";
import { GamePlayerContext } from "../../context/game-context";

export const CameraControl = ({ enable, setCameraTarget, onChangeCameraMode }) => {

    const { camera, gl } = useThree();
    const target = useRef(new Vector3(0, 2, 0));
    const currentTarget = useRef(new Vector3(0, 2, 0));
    const isMobile = /Android|webOS|iPhone|iPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const sensitivity = useRef(4);
    const whheelSensitivity = useRef(0.25);
    const position = useRef({ x: 0, y: 0 });
    const colliders = useRef([]);
    const cameraControllID = useRef(GamePlayerContext(state=>state.cameraMode));
    const setCameraControMode = GamePlayerContext(state=>state.setCameraMode);
    const capturePhoto = useRef(GamePlayerContext(state=>state.capturePhoto));
    const controlToggle = useRef(GamePlayerContext(state=>state.controlToggle));
    const isMouseLock = useRef(false);
    const isMouseMove = useRef(false);
    const isMouseRightButtonHold = useRef(false);
    const clock = useRef();
    const lastMousePosition = useRef({ x: 0, y: 0 });
    const lastToggleMousePosition = useRef({ x: 0, y: 0 });
    const targetObject = useRef();
    let distance = useRef(3);
    let cameraSpeed = 0.2;
    let yOffset = 0.5;
    let cameraYOffsetMax = 1.2;
    let cameraYOffsetMin = 0.3;
    let isEnable = useRef(false);

    const xMin = -45;
    const xMax = 45;
    const minDistance = 0;
    const maxDistance = 6;
    let fpsOffset = 0.2;

    let fbxmodel = useRef();
    let fpsMode = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));
    let topHeadPosition = useRef(new Vector3(0, 0, 0));
    let targetPositionLerp = useRef(new Vector3(0, 0, 0));

    const setIsFirstPerson = useRef(GamePlayerContext((state) => state.setIsFirstPersonCamera));
    const setIsCursorLock = useRef(GamePlayerContext((state) => state.setIsCursorLock));
    const setCameraDistance = GamePlayerContext(state=>state.setCameraDistance);
    const firstAngle = useRef( GamePlayerContext(state=>state.firstAngle));

    const touchID = useRef(-1);
    const lastTouch = useRef({ x: 0, y: 0 });
    const lastDistanceTouch = useRef(0);

    const setTarget = (x, y, z, collider, cameraTarget, fbxTopHead, isFPSMode) => {
        let diff = cameraYOffsetMin + ((cameraYOffsetMax - cameraYOffsetMin) * (distance.current / (maxDistance - minDistance)));
        // console.log(diff);
        if(capturePhoto.current) diff = 0.5;
        target.current.set(round(x, 3), round(y, 3) + diff, round(z, 3));
        colliders.current = collider;
        targetObject.current = cameraTarget;
        updateCameraPosition(clock.current.getDelta());
        topHeadPosition.current.set(fbxTopHead.x, fbxTopHead.y + fpsOffset, fbxTopHead.z);
        // isFPSMode.current = fpsMode.current;
    }

    setCameraTarget.current = setTarget;

    useEffect(()=>{
        let cameraControllIDSubs = GamePlayerContext.subscribe((state)=>state.cameraMode, (data)=>{
            cameraControllID.current = data;
        });

        let firstAngleSubs = GamePlayerContext.subscribe((state)=>state.firstAngle, (data)=>{
            position.current.y = radToDeg(data);
        });

        let capturePhotoSubs = GamePlayerContext.subscribe((state)=>state.capturePhoto, (data)=>{
            capturePhoto.current = data;
        });

        let controlToggleSubs = GamePlayerContext.subscribe((state)=>state.controlToggle, (data)=>{
            controlToggle.current = data;
        });

        return()=>{
            controlToggleSubs();
            cameraControllIDSubs();
            firstAngleSubs();
            capturePhotoSubs();
        }
    },[]);

    useEffect(() => {
        // console.log("ENABLE", enable);
        isEnable.current = enable;
        camera.near = 0.1;
        // setCameraDistance(distance.current / (maxDistance - minDistance));
    }, [enable]);

    useEffect(() => {

        gl.domElement.requestPointerLock = gl.domElement.requestPointerLock || gl.domElement.mozRequestPointerLock;

        document.exitPointerLock = document.exitPointerLock || document.mozExitPointerLock;

        gl.domElement.addEventListener("contextmenu", e => e.preventDefault());
        gl.domElement.addEventListener('pointerlockchange', lockChangeAlert, false);
        gl.domElement.addEventListener('mozpointerlockchange', lockChangeAlert, false);
        gl.domElement.addEventListener("wheel", onWheel);
        gl.domElement.addEventListener("mousedown", onMouseDown);
        gl.domElement.addEventListener("mouseup", onMouseUp);
        gl.domElement.addEventListener("mousemove", onMouseMove, false);

        gl.domElement.addEventListener("touchstart", onTouchDown);
        gl.domElement.addEventListener("touchmove", onTouchMove);
        gl.domElement.addEventListener("touchend", onTouchUp, false);
        document.addEventListener("keyup", onKeyUp);

        clock.current = new Clock();

        return()=>{
            gl.domElement.removeEventListener('pointerlockchange', lockChangeAlert, false);
            gl.domElement.removeEventListener('mozpointerlockchange', lockChangeAlert, false);
            gl.domElement.removeEventListener("wheel", onWheel);
            gl.domElement.removeEventListener("mousedown", onMouseDown);
            gl.domElement.removeEventListener("mouseup", onMouseUp);
            gl.domElement.removeEventListener("mousemove", onMouseMove, false);
    
            gl.domElement.removeEventListener("touchstart", onTouchDown);
            gl.domElement.removeEventListener("touchmove", onTouchMove);
            gl.domElement.removeEventListener("touchend", onTouchUp, false);
            document.removeEventListener("keyup", onKeyUp);
        }

    }, []);

    const updateCameraPosition = (delta) => {

        if (!isEnable.current) return;

        let pitchEuler = new Euler(degToRad(position.current.x), 0, 0, 'XYZ');
        let yawEuler = new Euler(0, degToRad(position.current.y), 0, 'XYZ');
        let targetPosition = new Vector3(0, yOffset, fpsMode.current ? 3 : -distance.current);

        let pitchQuaternion = new Quaternion().setFromEuler(pitchEuler);
        let yawQuaternion = new Quaternion().setFromEuler(yawEuler);

        targetPosition.applyQuaternion(pitchQuaternion);
        targetPosition.applyQuaternion(yawQuaternion);

        targetPosition.add(target.current);
        targetPositionLerp.current.lerp(targetPosition, cameraSpeed);

        if (cameraControllID.current === 2) {

            if (targetObject !== undefined && targetObject.current !== undefined) {
                targetPosition.set(0, yOffset, -distance.current);
                let normalQuaternion = new Quaternion().setFromEuler(targetObject.current.rotation);
                targetPosition.applyQuaternion(normalQuaternion);
                targetPosition.add(target.current);

            }
        }


        if (!fpsMode.current) {

            let dir = new Vector3().subVectors(targetPosition, target.current).normalize();
            let raycasterGround = new Raycaster(target.current, dir);
            let intersectGround = raycasterGround.intersectObjects(colliders.current);
            // console.log(colliders.current);

            if (intersectGround.length > 0) {
                if (intersectGround[0].distance < distance.current)
                {
                    let pointDirection = new Vector3(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
                    dir.multiplyScalar(0.5);
                    pointDirection.sub(dir);

                    targetPosition.set(pointDirection.x, pointDirection.y, pointDirection.z);
                }
            }

            currentTarget.current.lerp(target.current, (cameraSpeed));
            camera.position.lerp(targetPosition, (cameraSpeed));
            camera.lookAt(currentTarget.current);
        }
        else {
            camera.position.lerp(topHeadPosition.current, cameraSpeed);
            camera.lookAt(targetPositionLerp.current);
        }

        
        setCameraDistance(targetPosition.distanceTo(currentTarget.current));

    }

    const degToRad = (degrees) => {
        let pi = Math.PI;
        return degrees * (pi / 180);
    }

    const lockChangeAlert = () => {
        if (document.pointerLockElement === gl.domElement || document.mozPointerLockElement === gl.domElement) {
            isMouseLock.current = true;
        } else {
            isMouseLock.current = false;
        }
    }

    const onWheel = (e) => {

        if (!isEnable.current) return;

        let wheel = clamp(e.deltaY, -1, 1);
        distance.current += wheel * whheelSensitivity.current;
        distance.current = clamp(distance.current, minDistance, maxDistance);

        fpsMode.current = distance.current < 1;
        setIsFirstPerson.current(fpsMode.current);

        // setCameraDistance(distance.current / (maxDistance - minDistance));
    }

    const clamp = (value, min, max) => {
        if (value < min)
            return min;
        else if (value > max)
            return max;
        else
            return value;
    }

    const round = (value, decimal) => {
        return parseFloat(value.toFixed(decimal));
    }


    const onMouseDown = (e) => {

        if (!isEnable.current) return;

        if (e.which === 1 || e.button === 0) {
            if (cameraControllID.current === 1 || cameraControllID.current === 2) {
                lastTouch.current = { x: e.clientX, y: e.clientY };
                pointerDown(e.clientX, e.clientY);
            }
        }
    }

    const onMouseMove = (e) => {
        mouseMove(e.clientX, e.clientY, e.movementX, e.movementY);

    }

    const onKeyUp = (e) => {
        if(isMobile) return;
        if(!controlToggle.current) return;
        if (e.key == "c") {
            changeCamera();
        }
    }

    const changeCamera = () => {
        if (cameraControllID.current === 0) {
            if (isMouseLock.current) {
                document.exitPointerLock();
                setIsCursorLock.current(false);
                setCameraControMode(1);
                // cameraControllID.current = 1;
            }
            else {
                isMouseLock.current = true;
                gl.domElement.requestPointerLock();
                setIsCursorLock.current(true);
            }
        }
        else {
            if (cameraControllID.current === 1) {
                setCameraControMode(2);
                // cameraControllID.current = 2;
            }
            else if (cameraControllID.current === 2) {
                
                document.exitPointerLock();
                setIsCursorLock.current(false);
                setCameraControMode(1);
                // cameraControllID.current = 1;

                // gl.domElement.requestPointerLock();
                // setIsCursorLock.current(true);
                // cameraControllID.current = 0;
            }

        }

        if (onChangeCameraMode !== undefined && onChangeCameraMode.current !== undefined) {
            onChangeCameraMode.current(cameraControllID.current);
        }
        isMouseMove.current = false;
        isMouseRightButtonHold.current = false;
    }

    const onMouseUp = (e) => {

        // console.log(e);

        if (!isEnable.current) return;

        //LEFT MOUSE
        if (e.which === 1 || e.button === 0) {
            if (cameraControllID.current === 0) {
                gl.domElement.requestPointerLock();
                setIsCursorLock.current(true);
            }
        }
        //RIGHT MOUSE
        if (e.which === 1 || e.button === 0) {

            let current = { x: e.clientX, y: e.clientY }
            let mouseDistance = vector2Distance(current, lastToggleMousePosition.current);

            /*
            if (cameraControllID.current === 0) {
                if (isMouseLock.current) {
                    document.exitPointerLock();
                    cameraControllID.current = 1;
                }
                else {
                    isMouseLock.current = true;
                    gl.domElement.requestPointerLock();
                }
            }
            else if (mouseDistance < 5) {
                if (cameraControllID.current === 1) {
                    cameraControllID.current = 2;
                }
                else if (cameraControllID.current === 2) {
                    gl.domElement.requestPointerLock();
                    cameraControllID.current = 0;
                }

            }

            if (onChangeCameraMode !== undefined && onChangeCameraMode.current !== undefined) {
                onChangeCameraMode.current(cameraControllID.current);
            }
            */

            isMouseMove.current = false;
            isMouseRightButtonHold.current = false;
        }
    }

    const getDistance = (point1, point2) => {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    const onTouchDown = (e) => {

        if (!isEnable.current) return;

        if (touchID.current != -1) return;

        let tID = -1;
        let touches = [];
        for (let i = 0; i < e.touches.length; i++) {
            const touch = e.touches[i];
            if (touch.target == gl.domElement) {
                if (tID == -1) tID = i;
                touches.push(i);
            }
        }

        if (tID != -1) {
            if (cameraControllID.current === 1 || cameraControllID.current === 2) {
                pointerDown(e.touches[tID].clientX, e.touches[tID].clientY);
            }
        }

        if (touches.length >= 2) {
            let firstTouch = { x: e.touches[touches[0]].clientX, y: e.touches[touches[0]].clientY }
            let secondTouch = { x: e.touches[touches[1]].clientX, y: e.touches[touches[1]].clientY }
            lastDistanceTouch.current = getDistance(firstTouch, secondTouch);

        }
    }


    const onTouchUp = (e) => {
        if (e.touches.length == 0) {
            touchID.current = -1;
        }
        // mouseDown(e.clientX, e.clientY);
    }


    const onTouchMove = (e) => {
        if (!isEnable.current) return;

        let touchCountOnCanvas = 0;
        let tID = -1;
        let tIDs = [];
        for (let i = 0; i < e.touches.length; i++) {
            const touch = e.touches[i];
            if (touch.target == gl.domElement) {
                touchCountOnCanvas++;
                tIDs.push(i);
                if (tID == -1) tID = i;
            }
        }

        if (touchCountOnCanvas == 1 && tID != -1) {

            let movementX = lastTouch.current.x - e.touches[tID].clientX;
            let movementY = lastTouch.current.y - e.touches[tID].clientY;

            mouseMove(e.touches[tID].clientX, e.touches[tID].clientY, movementX, movementY);
            lastTouch.current = { x: e.touches[tID].clientX, y: e.touches[tID].clientY }
        }
        else if (touchCountOnCanvas == 2 && tIDs.length >= 2) {
            let firstTouch = { x: e.touches[tIDs[0]].clientX, y: e.touches[tIDs[0]].clientY }
            let secondTouch = { x: e.touches[tIDs[1]].clientX, y: e.touches[tIDs[1]].clientY }
            let newDistance = getDistance(firstTouch, secondTouch);
            let deltaDistance = lastDistanceTouch.current - newDistance;

            if (!isEnable.current) return;

            distance.current += deltaDistance * whheelSensitivity.current * 0.1;
            distance.current = clamp(distance.current, minDistance, maxDistance);

            fpsMode.current = distance.current < 1;
            setIsFirstPerson.current(fpsMode.current);

            lastDistanceTouch.current = newDistance;
            
            // setCameraDistance(distance.current / (maxDistance - minDistance));
        }

        // mouseDown(e.clientX, e.clientY);
    }

    const pointerDown = (x, y) => {

        if (!isEnable.current) return;

        isMouseRightButtonHold.current = true;
        lastMousePosition.current = { x: x, y: y };
        lastToggleMousePosition.current = { x: x, y: y };
    }

    const mouseMove = (x, y, pointerMovementX, pointerMovementY) => {

        if (!isEnable.current) return;

        if (cameraControllID.current === 0) {
            if (!isMouseLock.current) return;

            let movementX = clamp(pointerMovementX, -1, 1);
            let movementY = clamp(pointerMovementY, -1, 1);

            position.current.y -= movementX * sensitivity.current;
            position.current.x += movementY * sensitivity.current;

            position.current.x = clamp(position.current.x, xMin, xMax);
        }
        else if (cameraControllID.current === 1 || cameraControllID.current === 2) {
            if (!isMouseRightButtonHold.current) return;
            isMouseMove.current = true;

            let movementX = clamp(x - lastMousePosition.current.x, -1, 1);
            let movementY = clamp(y - lastMousePosition.current.y, -1, 1);

            position.current.y -= movementX * sensitivity.current;
            position.current.x += movementY * sensitivity.current;

            position.current.x = clamp(position.current.x, xMin, xMax);
            lastMousePosition.current = { x: x, y: y };
        }
    }


    const vector2Distance = (point1, point2) => {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    return <></>
}