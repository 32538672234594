import { Canvas, invalidate, useFrame, useThree } from "@react-three/fiber"
import { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "./canvas-context";
import { SocketContext } from "./socket-context";
import { Euler, NoToneMapping } from "three";
import { CubeTextureLoader } from "three";
import { DirectionalLightHelper } from "three";
import { AdaptiveDpr, Sky, useHelper, Stars, softShadows, useContextBridge, Cloud } from "@react-three/drei";
import { Vector3 } from "three";
import { Color } from "three";
import { Quaternion, Object3D } from "three";
import { FPSLimiter } from "../../camera-component/FPSLimiter";
import { GameConfigContext, SceneContext } from "../../../context/game-context";
import userEvent from "@testing-library/user-event";
import { Bloom, EffectComposer, Vignette, ToneMapping, BrightnessContrast, SMAA, SSAO, DepthOfField } from "@react-three/postprocessing";
import { BlendFunction } from 'postprocessing'
import { post } from "jquery";
import { PlayerContext } from "./player-context";

export const CanvasProvider = ({ children, dayNightValueChanged, isMobile }) => {

    const canvasRef = useRef();
    const ambientLight = useRef();
    const hemisphereLight = useRef();
    const directionalLight = useRef();
    const directionalLightOffset = useRef(new Vector3(20, 5, 20));
    const moonLight = useRef();
    const moonLightOffset = useRef(new Vector3(20, 5, 20));
    const directionalLightTarget = useRef();
    const [shadow, setShadow] = useState(false);
    const [antiAliasing, setAntiAliasing] = useState(false);
    const [postProcessing, setPostProcessing] = useState(false);
    const setStarCount = useRef();
    const [isDrawSky, setIsDrawSky] = useState(true);
    const [cameraFar, setCameraFar] = useState(300);


    const [isCustomSkybox, setIsCustomSkybox] = useState(SceneContext((state) => state.isCustomSkybox));
    useEffect(() => {
        let isCustomSkyboxSubs = SceneContext.subscribe((state) => state.isCustomSkybox, (data) => {
            setIsCustomSkybox(data);
        });

        return () => {
            isCustomSkyboxSubs();
        }
    }, []);

    useEffect(()=>{
        // let webgl = document.getElementById("webgl");
        // webgl.appendChild(canvasRef.current);
    },[canvasRef.current]);

    window.moonLight = moonLight;
    window.sun = directionalLight;



    let directionalLightSize = 30;
    const setDirectionalLightTarget = (position, direction) => {

        let targetPosition = new Vector3(position.x, position.y, position.z);
        let targetDirection = new Vector3(direction.x, direction.y, direction.z);
        targetDirection.multiplyScalar(directionalLightSize / 2);
        targetPosition.add(targetDirection);
        directionalLightTarget.current.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
    }

    useEffect(() => {
        const settingSubscribe = GameConfigContext.subscribe(state => ({
            antiAliasing: state.antiAliasing,
            shadow: state.shadow,
            postProcessing: state.postProcessing
        }), (data) => {
            // console.log("LISTEN SETTING CHANGED", data)
            setShadow(data.shadow);
            setAntiAliasing(data.antiAliasing);
            setPostProcessing(data.postProcessing);


            // moonLight.current.castShadow = data.shadow;

            invalidate()
        })

        const is360Subs = SceneContext.subscribe((state) => state.is360, (data) => {
            setIsDrawSky(!data);
        });


        return () => {
            settingSubscribe();
            is360Subs();
        }
    }, [])

    useEffect(() => {
        if (directionalLight.current) directionalLight.current.castShadow = data.shadow;
    }, [shadow]);


    const ContextBridge = useContextBridge(SocketContext, PlayerContext)
    return (
        <Canvas
            ref={canvasRef}
            frameloop="always"
            linear={true}
            dpr={[1, 2]}
            shadows={true}
            
            // shadowMap
            performance={{ min: 0.4 }}
            camera={{
                far: cameraFar
            }}
            gl={{
                autoClear: true,
                alpha: true,
                // antialias: antiAliasing,
                toneMapping: NoToneMapping,
                // powerPreference: 'default',
                autoClearColor: 0x000000,
                preserveDrawingBuffer:true,
                debug: true
            }}
        >
            <CameraControl />
            {postProcessing && <EffectComposer>
                <Bloom luminanceThreshold={0.75} luminanceSmoothing={0.5} height={300} />
                <Vignette eskil={false} offset={0.01} darkness={0.3} />
                <SMAA />
                <SSAO
                    blendFunction={BlendFunction.MULTIPLY} // blend mode
                    samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
                    rings={4} // amount of rings in the occlusion sampling pattern
                    distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
                    distanceFalloff={0.5} // distance falloff. min: 0, max: 1
                    rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
                    rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
                    luminanceInfluence={0.5} // how much the luminance of the scene influences the ambient occlusion
                    radius={30} // occlusion sampling radius
                    scale={0.5} // scale of the ambient occlusion
                    bias={0.01} // occlusion bias
                    intensity={3}
                />
            </EffectComposer>}
            {/* <FPSLimiter fps={60} /> */}
            <fogExp2 attach="fog" color="white" density={0.008} />
            {/* <StarsControl
                setStarCount={setStarCount}
            /> */}
            {
                !isCustomSkybox && <DayNightSky
                    directionalLightOffset={directionalLightOffset}
                    dayNightValueChanged={dayNightValueChanged}
                    directionalLight={directionalLight}
                    ambientLight={ambientLight}
                    hemisphereLight={hemisphereLight}
                    moonLight={moonLight}
                    moonLightOffset={moonLightOffset}
                    setStarCount={setStarCount}
                    drawSky={isDrawSky}
                />
            }
            {
                isCustomSkybox && <CustomSkybox />
            }
            {/* <SkyBox /> */}
            <ambientLight ref={ambientLight} intensity={0.5} />
            <hemisphereLight ref={hemisphereLight} color={"#292928"} groundColor={"080820"} intensity={0.5} />
            <directionalLight
                ref={directionalLight}
                intensity={0.5}
                position={[directionalLightOffset.current.x, directionalLightOffset.current.y, directionalLightOffset.current.z]}
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-camera-near={1}
                shadow-camera-far={100}
                shadow-camera-right={directionalLightSize}
                shadow-camera-left={-directionalLightSize}
                shadow-camera-top={directionalLightSize}
                shadow-camera-bottom={-directionalLightSize}
                castShadow={false}
            />
            <directionalLight
                ref={moonLight}
                intensity={0}
                position={[directionalLightOffset.current.x, directionalLightOffset.current.y, directionalLightOffset.current.z]}
                castShadow={false}
            />
            <group ref={directionalLightTarget} />
            <DirectionalLightControl
                directionalLight={directionalLight}
                directionalLightTarget={directionalLightTarget}
                directionalLightOffset={directionalLightOffset}
                directionalLightSize={directionalLightSize}
                setDirectionalLightTarget={setDirectionalLightTarget}
            />
            <DirectionalLightControl
                directionalLight={moonLight}
                directionalLightTarget={directionalLightTarget}
                directionalLightOffset={moonLightOffset}
                directionalLightSize={directionalLightSize}
                setDirectionalLightTarget={setDirectionalLightTarget}
            />
            {/* <Helper directionalLight={directionalLight} /> */}
            {/* <Helper directionalLight={moonLight} /> */}
            <ContextBridge>
                <CanvasContext.Provider value={{ ambientLight, hemisphereLight, directionalLight, moonLight, setStarCount, setDirectionalLightTarget }}>
                    {children}
                </CanvasContext.Provider>
            </ContextBridge>
            <AdaptiveDpr pixelated />
            {/* <CloudSky /> */}
        </Canvas>
    );
}

const CustomSkybox = () => {

    const [customSkybox, setCustomSkybox] = useState(SceneContext((state) => state.customSkybox));
    const { scene } = useThree();

    useEffect(() => {
        let customSkyboxSubs = SceneContext.subscribe((state) => state.customSkybox, (data) => {
            setCustomSkybox(data);
        });

        return () => {
            customSkyboxSubs();
        };
    }, []);


    useEffect(() => {

        if (customSkybox) scene.background = customSkybox;

    }, [customSkybox]);

    return <></>
}

const DayNightSky = ({ directionalLightOffset, dayNightValueChanged, directionalLight, ambientLight, hemisphereLight, moonLight, moonLightOffset, setStarCount, drawSky }) => {

    const [turbidity, setTurbidity] = useState(0.1);
    const [azimuth, setAzimuth] = useState(0.1);
    const [inclination, setInclination] = useState(0.6);
    const [mieCoefficient, setMieCoefficient] = useState(0.5);
    const [sunColor, setSunColor] = useState(new Color('#FFFFFF'));
    const [reyleigh, setReyleigh] = useState(6);
    const [sunOffset, setSunOffset] = useState([0, 0, 0]);
    const [moonOffset, setMoonOffset] = useState([0, 0, 0]);
    const [sunAngleX, setSunAngleX] = useState(0);
    const [sunAngleY, setSunAngleY] = useState(0);
    const currentStar = useRef();
    const starRef = useRef();

    const setSunAngle = (angleX, angleY) => {
        let sunDistance = new Vector3(0, 30, 0);
        let sunQuaternionX = new Quaternion().setFromEuler(new Euler(degToRad(angleX), 0, 0));
        let sunQuaternionY = new Quaternion().setFromEuler(new Euler(0, degToRad(angleY), 0));
        let quaternion = new Quaternion().multiplyQuaternions(sunQuaternionX, sunQuaternionY);
        sunDistance.applyQuaternion(sunQuaternionX);
        sunDistance.applyQuaternion(sunQuaternionY);
        directionalLightOffset.current.set(sunDistance.x, sunDistance.y, sunDistance.z);
        setSunOffset([sunDistance.x, sunDistance.y, sunDistance.z]);
    }

    const setMoonAngle = (angleX, angleY) => {
        let moonDistance = new Vector3(0, 30, 0);
        let moonQuaternionX = new Quaternion().setFromEuler(new Euler(degToRad(angleX), 0, 0));
        let moonQuaternionY = new Quaternion().setFromEuler(new Euler(0, degToRad(angleY), 0));
        let quaternion = new Quaternion().multiplyQuaternions(moonQuaternionX, moonQuaternionY);
        moonDistance.applyQuaternion(moonQuaternionX);
        moonDistance.applyQuaternion(moonQuaternionY);
        moonLightOffset.current.set(moonDistance.x, moonDistance.y, moonDistance.z);
        setMoonOffset([moonDistance.x, moonDistance.y, moonDistance.z]);
    }

    window.setTurbidity = setTurbidity;
    window.setInclination = setInclination;
    window.setMieCoefficient = setMieCoefficient;
    window.setSunColor = setSunColor;
    window.setReyleigh = setReyleigh;
    window.setSunAngle = setSunAngle;

    const degToRad = (degrees) => {
        let pi = Math.PI;
        return degrees * (pi / 180);
    }

    const animationTarget = [
        {
            start: 0,
            end: 0.1,
            turbidity: [0.55, 0.1],
            azimuth: [0.7, 0.8],
            inclination: [0.6, 0.8],
            mieCoefficient: [0.3, 0.1],
            sunIntensity: [0, 0.6],
            sunColor: [0xffffff, 0xffffff],
            ambientIntensity: [0.8, 0.5],
            ambientColor: [0x010136, 0x010136],
            hemiIntensity: [0.6, 0.8],
            hemiColor: [0xffffff, 0xffffff],
            hemiGroundColor: [0xc9c9c9, 0xc9c9c9],
            sunAngleX: [120, 45],
            sunAngleY: [50, -15],
            reyleigh: [10, 10],
            moonIntensity: [0.9, 0],
            moonColor: [0xc2c5cc, 0xc2c5cc],
            moonAngleX: [300, 225],
            moonAngleY: [50, -15],
            startCount: 1500,
        }, {
            start: 0.1,
            end: 0.6,
            turbidity: [0.1, 0.1],
            azimuth: [0.8, 1],
            inclination: [0.8, 1],
            mieCoefficient: [0.5, 0.5],
            sunIntensity: [0.6, 0.6],
            sunColor: [0xffffff, 0xffffff],
            ambientIntensity: [0.5, 0.5],
            ambientColor: [0x2d2a2b, 0x2d2a2b],
            hemiIntensity: [0.8, 0.8],
            hemiColor: [0xffffff, 0xffffff],
            hemiGroundColor: [0xc9c9c9, 0xc9c9c9],
            sunAngleX: [45, 45],
            sunAngleY: [-15, -90],
            reyleigh: [10, 10],
            moonIntensity: [0, 0],
            moonColor: [0xc2c5cc, 0xc2c5cc],
            moonAngleX: [225, 225],
            moonAngleY: [-15, -90],
            startCount: 0,
        },
        {
            start: 0.6,
            end: 0.8,
            turbidity: [0.1, 0.3],
            azimuth: [1, 1.1],
            inclination: [1, 0.8],
            mieCoefficient: [0.1, 0.2],
            sunIntensity: [0.6, 0],
            sunColor: [0xffffff, 0xffffff],
            ambientIntensity: [0.5, 0.8],
            ambientColor: [0x2d2a2b, 0x010136],
            hemiIntensity: [0.8, 0.5],
            hemiColor: [0xffffff, 0xffffff],
            hemiGroundColor: [0xc9c9c9, 0xc9c9c9],
            sunAngleX: [45, 90],
            sunAngleY: [-90, -200],
            reyleigh: [10, 10],
            moonIntensity: [0, 0.7],
            moonColor: [0xffffff, 0xffffff],
            moonAngleX: [225, 270],
            moonAngleY: [-90, -200],
            startCount: 0,
        }, {
            start: 0.8,
            end: 0.9,
            turbidity: [0.55, 0.55],
            azimuth: [0.7, 0.7],
            inclination: [0.6, 0.6],
            mieCoefficient: [0.5, 0.5],
            sunIntensity: [0, 0],
            sunColor: [0xffffff, 0xffffff],
            ambientIntensity: [0.8, 0.8],
            ambientColor: [0x010136, 0x010136],
            hemiIntensity: [0.5, 0.5],
            hemiColor: [0xffffff, 0xffffff],
            hemiGroundColor: [0xc9c9c9, 0xc9c9c9],
            sunAngleX: [90, 90],
            sunAngleY: [-200, -200],
            reyleigh: [10, 10],
            moonIntensity: [0.7, 0.7],
            moonColor: [0xffffff, 0xffffff],
            moonAngleX: [270, 285],
            moonAngleY: [-200, -75],
            startCount: 1500,
        }, {
            start: 0.9,
            end: 1,
            turbidity: [0.55, 0.55],
            azimuth: [0.7, 0.7],
            inclination: [0.6, 0.6],
            mieCoefficient: [0.5, 0.5],
            sunIntensity: [0, 0],
            sunColor: [0xffffff, 0xffffff],
            ambientIntensity: [0.8, 0.8],
            ambientColor: [0x010136, 0x010136],
            hemiIntensity: [0.5, 0.5],
            hemiColor: [0xffffff, 0xffffff],
            hemiGroundColor: [0xc9c9c9, 0xc9c9c9],
            sunAngleX: [90, 90],
            sunAngleY: [-200, -200],
            reyleigh: [10, 10],
            moonIntensity: [0.7, 0.7],
            moonColor: [0xffffff, 0xffffff],
            moonAngleX: [285, 300],
            moonAngleY: [-75, 50],
            startCount: 1500,
        },
    ];

    const setTime = SceneContext((state)=>state.setTime);
    const autoTime = useRef(SceneContext(state=>state.autoTime));

    const dateToTime = [
        0, //0
        0.01, //1
        0.02, //2
        0.03, //3
        0.04, //4
        0.07, //5
        0.09, //6
        0.2, //7
        0.3, //8
        0.35, //9
        0.4, //10
        0.45, //11
        0.5, //12
        0.53, //13
        0.55, //14
        0.58, //15
        0.6, //16
        0.65, //17
        0.7, //18
        0.8, //19
        0.85, //20
        0.9, //21
        0.95, //22
        1, //23       
    ];
    

    dayNightValueChanged.current = (value) => {
        updateSun(value);
    }

    const scalePercent = (value, start, end) => {
        return (value - start) / (end - start);
    }

    const getValue = (value, percent) => {
        return value[0] + ((value[1] - value[0]) * percent);
    }

    const lerp = (x, y, a) => {
        return (1 - a) * x + a * y
    }

    const updateSun = (value) => {

        animationTarget.forEach((target) => {
            if (value >= target.start && value < target.end) {
                let percent = scalePercent(value, target.start, target.end);

                let sunAngelX = getValue(target.sunAngleX, percent);
                let sunAngelY = getValue(target.sunAngleY, percent);
                setSunAngle(sunAngelX, sunAngelY);

                let moonAngelX = getValue(target.moonAngleX, percent);
                let moonAngelY = getValue(target.moonAngleY, percent);
                setMoonAngle(moonAngelX, moonAngelY);

                let currentTurbidity = getValue(target.turbidity, percent);
                setTurbidity(currentTurbidity);

                let currentAzimuth = getValue(target.azimuth, percent);
                setAzimuth(currentAzimuth);

                let currentInclanation = getValue(target.inclination, percent);
                setInclination(currentInclanation);

                let currentReyLeight = getValue(target.reyleigh, percent);
                setReyleigh(currentReyLeight);

                let currentStarCount = target.startCount;
                if (currentStar.current != currentStarCount) {
                    currentStar.current = currentStarCount;
                    if (setStarCount && setStarCount.current) setStarCount.current(currentStarCount);
                }

                let directionalLightIntensity = getValue(target.sunIntensity, percent);
                directionalLight.current.intensity = directionalLightIntensity;

                let color = new Color(target.sunColor[0]).lerp(new Color(target.sunColor[1]), percent);
                directionalLight.current.color.setRGB(color.r, color.g, color.b);

                let moonLightIntensity = getValue(target.moonIntensity, percent);
                moonLight.current.intensity = moonLightIntensity;

                let moonColor = new Color(target.moonColor[0]).lerp(new Color(target.moonColor[1]), percent);
                moonLight.current.color.setRGB(moonColor.r, moonColor.g, moonColor.b);

                let ambientLightIntensity = getValue(target.ambientIntensity, percent);
                ambientLight.current.intensity = ambientLightIntensity;

                let ambientColor = new Color(target.ambientColor[0]).lerp(new Color(target.ambientColor[1]), percent);
                ambientLight.current.color.setRGB(ambientColor.r, ambientColor.g, ambientColor.b);


                let hemiLightIntensity = getValue(target.hemiIntensity, percent);
                hemisphereLight.current.intensity = hemiLightIntensity;

                let hemiColor = new Color(target.hemiColor[0]).lerp(new Color(target.hemiColor[1]), percent);
                hemisphereLight.current.color.setRGB(hemiColor.r, hemiColor.g, hemiColor.b);

                let hemiGroundColor = new Color(target.hemiGroundColor[0]).lerp(new Color(target.hemiGroundColor[1]), percent);
                hemisphereLight.current.groundColor.setRGB(hemiGroundColor.r, hemiGroundColor.g, hemiColor.b);
            }
        });
    }
    const getFirstValueDayNight = SceneContext((state) => state.time)
    useEffect(() => {
        // setSunOffset([directionalLightOffset.current.x, directionalLightOffset.current.y, directionalLightOffset.current.z]);w
        updateSun(getFirstValueDayNight);

        // updateSun(0.28);
        const dayNightSubs = SceneContext.subscribe((state) => state.time, (data) => {
            updateSun(data);
        });

        const autoTimeSubs = SceneContext.subscribe((state)=>state.autoTime, (data)=>{
            autoTime.current = data;
        });

        return () => {
            dayNightSubs();
        }

    }, []);

    useFrame(() => {
        if(!autoTime.current) return;
        
        var date = new Date;
        date.setTime(Date.now());
        let minutes = date.getMinutes();
        let hour = date.getHours();
        let nextHour = hour + 1;
        if(nextHour > 23) nextHour = 0;

        let nowTimeValue = dateToTime[hour];
        let nextTimeValue = dateToTime[nextHour];

        if(nextTimeValue == 0) nextTimeValue = 1;
        let currentTimeValue = nowTimeValue + ((nextTimeValue - nowTimeValue) * (minutes/60));
        if(currentTimeValue > 1) currentTimeValue = 1;
        setTime(currentTimeValue);
    });

    return (<>
        {drawSky && <Sky
            azimuth={azimuth}
            inclination={inclination}
            distance={10000}
            turbidity={turbidity}
            mieCoefficient={mieCoefficient}
            mieDirectionalG={1}
            reyleigh={reyleigh}
        />}
    </>)

}

export const StarsControl = ({ setStarCount }) => {

    const [count, setCount] = useState(1500);
    const [is360, set360] = useState(SceneContext((state) => state.is360));

    useEffect(() => {
        let scene360Subs = SceneContext.subscribe((state) => state.is360, (data) => {
            set360(data);
        });

        return () => {
            scene360Subs();
        }

    }, []);

    setStarCount.current = (data) => {
        setCount(data);
    }

    return !is360 && <Stars count={count} factor={5} speed={5} fade={true} />
}

const SkyBox = () => {
    const { scene } = useThree();
    const loader = new CubeTextureLoader();

    const texture = loader.load([
        './skybox/S2.jpg', './skybox/S4.jpg',
        './skybox/top.jpg', './skybox/ground.jpg',
        './skybox/S1.jpg', './skybox/S3.jpg'
    ]);

    scene.background = texture;
    return null;
}

const DirectionalLightControl = ({ directionalLight, directionalLightTarget, directionalLightOffset, directionalLightSize }) => {

    useFrame(() => {
        directionalLight.current.position.addVectors(directionalLightOffset.current, directionalLightTarget.current.position);
        directionalLight.current.target = directionalLightTarget.current;
    });

    return <></>
}

const Helper = ({ directionalLight }) => {

    useHelper(directionalLight, DirectionalLightHelper, 1);

    return <></>
}

const CameraControl = () => {
    const { camera } = useThree();

    const [graphicQuality, setGraphicQuality] = useState(GameConfigContext(state => state.graphicQuality));

    useEffect(() => {
        camera.near = 0.01;
        // console.log(camera);

        const graphicQualitySubs = GameConfigContext.subscribe((state) => state.graphicQuality, (data) => {
            setGraphicQuality(data);
            if (data == 0) {
                camera.far = 20;
            }
            else if (data == 1) {
                camera.far = 60;
            }
            else if (data == 2) {
                camera.far = 100;
            }
            else if (data == 3) {
                camera.far = 300;
            }

        });

        return (() => {
            graphicQualitySubs();
        });

    }, []);
}

const CloudSky = () => {

    const [clouds, setClouds] = useState([]);

    const randomRange = (min, max) => {
        return Math.random() * (max - min + 1) + min;
    }

    useEffect(() => {

        let cl = [];
        for (let i = 0; i < 1; i++) {

            cl.push(
                <Cloud position={[randomRange(-100, 100), randomRange(50, 60), randomRange(-100, 100)]} args={[1000, 200]} opacity={0.2} />
            );
        }
        setClouds(cl);
    }, []);

    return <group>
        {clouds}

    </group>
}