import { useFrame, useThree } from "@react-three/fiber";
import CircularJSON from "circular-json";
import { useRef, useState } from "react";
import { Quaternion, Vector3 } from "three";
import { GamePlayerContext, LocalPlayerContext } from "../../context/game-context";
import { PlayerModelAndAnimation } from "../player-model-animation";

export const AnimatedCamera = ({ animation, spawnPoin, target, clipName }) => {

    let cameraTarget = useRef();
    const { camera } = useThree();
    const refPlayerModel = useRef();

    const [playerSettings, setPlayerSettings] = useState(LocalPlayerContext((state) => state));

    useState(() => {
        cameraTarget.current = animation.object.getObjectByName(target);

        const localPlayerSettingSub = LocalPlayerContext.subscribe(state => state, (data) => {
            // console.log(data, "ANIMATED CAMERA LISTEN")
            
            // const updatedLocalPlayer = CircularJSON.parse(localStorage.getItem("local_player_setting"));
            setPlayerSettings(data);
        })

        return () => {
            localPlayerSettingSub();
        }
    });

    useFrame((src, dt) => {

        let worldPosition = new Vector3();
        cameraTarget.current.getWorldPosition(worldPosition);

        let worldQuaternion = new Quaternion();
        cameraTarget.current.getWorldQuaternion(worldQuaternion);

        camera.position.set(worldPosition.x, worldPosition.y, worldPosition.z);
        camera.quaternion.set(worldQuaternion.x, worldQuaternion.y, worldQuaternion.z, worldQuaternion.w);
    });


    return (
        <AnimationObject animationObject={animation.animationObject}/>
    );
}

const AnimationObject = ({animationObject}) => {
    return <primitive object={animationObject} dispose={null} />
}