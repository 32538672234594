import axios from "axios";

export async function requestNonce(publicKey) {
    let nonce = null;
    try {
        await axios.post(`${process.env.API_URL}/request_login`, { publicKey }, {
            crossDomain: true
        })
            .then(res => {
                nonce = res.data.nonce;
            })
            .catch(err => {
            })
            .finally(() => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return nonce
}

export async function verifyLogin(publicKey, signature) {
    let response = null;
    try {
        await axios.post(`${process.env.API_URL}/verify_login`, { publicKey, signature }, {
            crossDomain: true
        })
            .then(res => {
                // nonce = res.data.nonce;

                response = res.data;
            })
            .catch(err => {
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}