import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { LoadClonedGeometry } from "./utils";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";


export const StaticObject = ({ keyObject, datas, material, loadedGeometry, sceneName, geometry }) => {

    const [isLoaded, SetLoaded] = useState(false);
    const mergedGeometries = useRef();

    useEffect(() => {
        // const LoadData = async () => {

        //     let geometries = [];
        //     for await (const data of datas) {
        //         const bufferGeometry = await LoadClonedGeometry(loadedGeometry, data.name, data.position, data.rotation, data.scale, sceneName);
        //         geometries.push(bufferGeometry);
        //     }

        //     mergedGeometries.current = mergeBufferGeometries(geometries);
        //     mergedGeometries.current.computeBoundsTree();
        //     SetLoaded(true);
        // }

        // LoadData();

        return()=>{
            geometry.dispose();

            if(material.material.map) material.material.map.dispose();
            material.material.dispose();
        }
    }, []);

    // return (
    //     isLoaded &&
    //     <mesh key={`static-${keyObject}`} geometry={mergedGeometries.current} castShadow receiveShadow>
    //         {material.material}
    //     </mesh>
    // )

    return (
        <mesh key={`static-${keyObject}`} geometry={geometry} castShadow receiveShadow material={material.material} 
        dispose={null}>
        </mesh>
    );
}

export default StaticObject;
