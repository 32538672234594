
import { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';
import { GamePlayerContext, LocalPlayerContext, TreasureHuntContext } from '../../context/game-context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AccountRegister } from './account-register';
import { AccountLogin } from './account-login';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';


export default function AccountComponent({ ...props }) {
    const [userLogged, setUserLogged] = useState(null);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const addPoint = TreasureHuntContext(state => state.addPoint);

    // const { Moralis, authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();

    const {
        loginMetamask,
        walletAddress,
        checkIsAuthenticated,
        loginWalletconnect,
        isFetching,
        isAuthenticated,
        logOut,
        getBalance,
        user,
        usedProvider
    } = useWeb3Service();

    const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);
    const setGamePlayerContext = GamePlayerContext((state) => state.setGamePlayerContext);

    useEffect(() => {
        if (isAuthenticated) {
            updateLocalPlayerSetting({ displayName: user.name, ...user });
            setGamePlayerContext({ linkedProvider: usedProvider, isAccountBinded: user.isAccountBinded, isProfileUpdated: user.isProfileUpdated })
            // add your logic here
            // console.log(isAuthenticated, "EFFECT AUTHENTICATED CHANGED")
        } else {
            setUserLogged(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);


    const mint = async () => {
        // const transactionParams = {
        //     receiver: env.CONTRACT_ADDRESS,
        //     type: "native",
        //     amount: Moralis.Units.ETH("0.1"),
        // };
        // await Moralis.enableWeb3();
        // try {
        //     const response = await Moralis.transfer(transactionParams);
        //     console.log(response, "RESPONSE TRANSACTION SUCCESS")
        // } catch (err) {
        //     console.log(err.message, "RESPONSE TRANSACTION ERROR")
        // }
    }
    const login = async (provider) => {
        if (provider == 'metamask') {
            await loginMetamask();
        } else if (provider == 'walletconnect') {
            await loginWalletconnect();
        }
    }

    const handlingLogOut = async () => {
        await logOut();
        window.location.reload()
        // console.log("logged out");
        // setUserLogged(null);
    }

    const fetchTokenBalances = async () => {
        getBalance()
        // const balances = await Web3Api.account.getTokenBalances();
        // console.log(balances, "TOKEN BALANCES");
    };

    return (
        <>

            <Container>
                <Modal show={showRegister} className="backdrop-register" onHide={() => setShowRegister(false)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{"Register Account"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AccountRegister />
                    </Modal.Body>
                </Modal>
            </Container>

            <Container>
                <Modal backdrop={'static'} show={showLogin} className="backdrop-register" onHide={() => setShowLogin(false)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{"Login Account"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AccountLogin />
                    </Modal.Body>
                </Modal>
            </Container>
            {
                isAuthenticated ?
                    <>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>Wallet Address</Form.Label>
                            <Col sm={5}>{walletAddress}</Col>
                        </Form.Group>
                        <div className='mx-auto text-center'>
                            <Button className={"btn-default"} onClick={handlingLogOut}>Log Out</Button>
                        </div>
                        {/* <button onClick={mint}><h3>Mint</h3></button>
                            <br /><br /><br />
                            <button onClick={fetchTokenBalances}><h3>Get Token Balances</h3></button>
                            <br /><br /><br /> */}
                        {/* <button onClick={devMint}><h3>Dev Mint</h3></button>
                            <br /><br /><br />
                            <button onClick={requestLogin}><h3>Request Login</h3></button>
                            <br /><br /><br /> */}
                        {/* <button onClick={fetchSearchNFTs}><h3>Check NFT</h3></button> */}
                    </>
                    :
                    <div className="d-grid">
                        {/* <Button className={"btn-default"} onClick={() => login('metamask')} disabled={isFetching}>Login Metamask</Button><br />
                        <Button className={"btn-default"} onClick={() => login('walletconnect')} disabled={isFetching}>Login Wallet Connect</Button><br /> */}
                        <Button className={"btn-default"} onClick={() => setShowLogin(true)} disabled={isFetching}>Login With Email</Button><br />
                        {/* <h5 align="center">Or</h5><br />
                        <Button className={"btn-default"} onClick={() => setShowRegister(true)} disabled={isFetching}>Register With Email</Button><br /> */}
                    </div>
            }

            <div className='mx-auto text-center pt-4'>
                <div>
                    <Form.Label column sm={4}>Share</Form.Label>
                </div>

                <FacebookShareButton
                    className="pl-2"
                    url={window.location.href}
                    beforeOnClick={() => {
                        addPoint("share_social_media", "share_social_media")
                    }}
                >
                    <FacebookIcon size={48} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                    className="pl-2"
                    url={window.location.href}
                    beforeOnClick={() => {
                        addPoint("share_social_media", "share_social_media")
                    }}
                >
                    <TwitterIcon size={48} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton
                    className="pl-2"
                    url={window.location.href}
                    beforeOnClick={() => {
                        addPoint("share_social_media", "share_social_media")
                    }}
                >
                    <WhatsappIcon size={48} round={true} />
                </WhatsappShareButton>
                <EmailShareButton
                    className="pl-2"
                    url={window.location.href}
                    beforeOnClick={() => {
                        addPoint("share_social_media", "share_social_media")
                    }}
                >
                    <EmailIcon size={48} round={true} />
                </EmailShareButton>
            </div>

        </>
    );
}
