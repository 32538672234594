import HTMLReactParser from "html-react-parser";
import { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { GameConfigContext, GamePlayerContext, SceneContext, AudioContext } from "../../context/game-context";
import { getListStream, getLocalPlayerStream } from "../../rtc-manager";

export const ListAudioNear = ({ rtcRef }) => {
    const initialVolumeOutput = AudioContext(state => state.volume);

    const listPlayerData = useRef([]);
    const listMicEnable = useRef([]);
    const listPlayerSpeaking = useRef([]);
    const volume = useRef(initialVolumeOutput)
    const [listPlayer, setListPlayer] = useState([]);
    

    const handlingMicCheck = async () => {

        const arrStream = [];
        const listStream = rtcRef.current ? rtcRef.current.streamEvents.selectAll() : [];

        listStream.map((r, i) => {
            arrStream[r.userid] = !r.isAudioMuted;


            // mute player out of range 
            const checkInDistance = listPlayerData.current.find((rd) => rd.i == r.userid);
            if (checkInDistance != null) {
                rtcRef.current.streamEvents[r.streamid].mediaElement.volume = volume.current;
                rtcRef.current.streamEvents[r.streamid].mediaElement.targetVolume = volume.current;
            } else {
                rtcRef.current.streamEvents[r.streamid].mediaElement.volume = 0.0;
                rtcRef.current.streamEvents[r.streamid].mediaElement.targetVolume = 0.0;
            }

        });
        listMicEnable.current = arrStream;
    }

    useEffect(() => {


        const rerenderPlayer = async () => {
            handlingMicCheck();
            setListPlayer(listPlayerData.current.map((r, i) => {
                return (<div className="player-near-audio" key={i}>
                    <div className={`avatar ${listPlayerSpeaking.current.indexOf(r.i) != -1 ? 'speaking' : ''}`}>
                        <svg
                            data-src="./icon/btn-profile.svg"
                            fill="currentColor"
                            className='w-100 full-height'
                        />
                        <div className={`mic-indicator ${listMicEnable.current[r.i] ? 'enable' : 'disable'}`}>
                            {listMicEnable.current[r.i] ?
                                <svg
                                    data-src="./icon/btn-micenable.svg"
                                    fill="currentColor"
                                    className="w-100 full-height"
                                /> :
                                <svg
                                    data-src="./icon/btn-micdisable.svg"
                                    fill="currentColor"
                                    className="w-100 full-height"
                                />}
                        </div>
                    </div>
                    <div className="player-name">
                        {r.a != '' ? r.a : r.i}
                    </div>
                </div>)
            }))
        }

        const speakerPlayerSub = GamePlayerContext.subscribe(state => state.listSpeakerActive, (data) => {
            rerenderPlayer();
        })

        const speakingPlayerSub = AudioContext.subscribe(state => state.playerListSpeaking, (data) => {
            if (data != null) {
                listPlayerSpeaking.current = data;
                rerenderPlayer();
            }
        })


        let playerNearSubs = AudioContext.subscribe(state => state.playerListToListen, (data) => {
            listPlayerData.current = data;

            rerenderPlayer();
        });

        let subscribeVolume = AudioContext.subscribe(state => state.volume, (data) => {
            volume.current = data;
            rerenderPlayer();
        })

        return () => {
            playerNearSubs();
            speakerPlayerSub();
            speakingPlayerSub();
            subscribeVolume();
        }
    }, [])

    return <>
        {/* {
            listPlayer.length > 0 && <div className="list-audio-near-cover">
                <Row className="player-container">
                    {listPlayer}
                </Row>
            </div>
        } */}
    </>
}