import { useState, useRef, useEffect } from 'react';
import { Button, Offcanvas, Form, CloseButton, Image, Container } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountComponent from './account-component';
import Settings from './settings';
import { PlayerSelectionUI } from "../html-component/player-selection-ui";
import { PlayerState } from '../player-component/context/player-state';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { Explore } from './explore';
import { GameConfigContext } from '../../context/game-context';

export default function SettingsMenu({ name, openSetting, onClose, activeTab, rtcRef, listAvatar, ...props }) {
    const [activeKey, setActiveKey] = useState(activeTab.current ? activeTab.current : 'wardrobe');

    const setLoading = GameConfigContext((state) => state.setLoading);
    // console.log(activeTab, activeKey, "SETTINGS MENU");
    useEffect(() => {
        if (activeKey != activeTab.current && activeTab.current) {
            setActiveKey(activeTab.current);
            activeTab.current = null;
        }
    })

    useEffect(() => {
        if(openSetting) {
            setLoading(false);
        }
    },[])

    return (
        <>
            <div className='settings-menu'>
                <Tab.Container
                    activeKey={activeKey}
                    defaultActiveKey='wardrobe'
                    onSelect={(k) => { activeTab.current = null; setActiveKey(k) }}
                    id="list-tab-setting"
                    className="mb-3 settings-menu-tab"
                    fill
                >
                    <Nav className="flex-row nav-tabs-setting" variant={"tabs"} fill>
                        <Nav.Item>
                            <Nav.Link eventKey="wardrobe" href="#">
                                <svg
                                    data-src="./icon/btn-wardrobe.svg"
                                    fill="currentColor" className={"full-width"}
                                />
                                <span>
                                    Wardrobe
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="explore" href="#">
                                <svg
                                    data-src="./icon/btn-explore.svg"
                                    fill="currentColor" className={"full-width"}
                                />
                                <span>
                                    Explore
                                </span>
                            </Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                            <Nav.Link eventKey="account" href="#">
                                <Image src="./icon/btn-outfitredhair.png" roundedCircle={true} className={"full-width"} />
                                <span>
                                    Account
                                </span>
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link eventKey="setting" href="#">
                                <svg
                                    data-src="./icon/btn-settings.svg"
                                    fill="currentColor"
                                    className={"full-width"}
                                />
                                <span>
                                    Setting
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div style={{
                        position:"relative",
                        height:"100%",
                        left:"0px",
                        right:"0px",
                        bottom:"0px",
                        overflowY: "auto",
                    }}>
                        <Tab.Content>
                            {/* <Tab.Pane className="scrollable bg-outfit full-height" eventKey="explore" key={0}>
                            <Explore />
                        </Tab.Pane> */}
                            <Tab.Pane eventKey="wardrobe" key={2}>
                                <PlayerSelectionUI listAvatar={listAvatar} onClose={onClose} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="account" key={3}>
                                <div style={{ width: "300px", marginTop: "30px" }} className={"mx-auto"}>
                                    <AccountComponent />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="scrollable bg-outfit full-height" eventKey="setting" key={4}
                                style={{
                                    overflowY: "auto",
                                    paddingBottom:"50px"
                                }}>
                                <Settings rtcRef={rtcRef} />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>

                <CloseButton
                    className={"close-setting"}
                    onClick={onClose}
                />
            </div >
        </>
    );
}
