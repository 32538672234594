import { useBox, useSphere } from "@react-three/cannon";
import { Billboard, Box, Plane, Text } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useContext, useEffect, useReducer, useRef, useState } from "react"
import { Quaternion, Raycaster, RingGeometry, Vector2, Vector3 } from "three";
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { useWeb3Service } from "../../../blockhain/provider/web3.provider";
import { FunctionVariableContext, GamePlayerContext, SceneContext, VisitorTrackerContext } from "../../context/game-context";
import { PlayerAnimationTest } from "../player-animation-test";
import { PlayerModelAndAnimation } from "../player-model-animation";
import { PlayerContext } from "./context/player-context";
import { PlayerName } from "./player-name";

export const NPC = ({ assetRef }) => {
    const [npcList, setNPCList] = useState(SceneContext((state) => state["sceneAssets"]["npc"]));
    const ref = useRef();

    useEffect(() => {

        const sceneAssetsSubs = SceneContext.subscribe((state) => state["sceneAssets"]["npc"], (data) => {
            setNPCList(data);
        });

        return (() => {
            sceneAssetsSubs();
        });

    }, []);

    return (<group ref={ref}>
        {
            npcList.map((npc, i) => <NPCPlayer
                key={`npc-${i}`}
                npcid={npc.npcId ? npc.npcId : i}
                isShowOnServerTrigger={npc.isShowOnServerTrigger ? npc.isShowOnServerTrigger : false}
                playerSettings={npc.playerSettings}
                animations={npc.animations}
                playerId={npc.id}
                position={npc.position}
                rotation={npc.rotation}
                scale={npc.scale}
                command={npc.command}
                data={npc.data}
                disableDistanceClick={npc.disableDistanceClick}
                targetLookAt={npc.targetLookAt}
                popupMaterial={npc.popupMaterial}
                npcPopupMaterial={npc.popupMaterial}
                assetRef={assetRef}
            />)
        }
    </group>)
}

export const NPCPlayer = ({ npcid, isShowOnServerTrigger, animations, playerId, playerSettings, position, rotation, scale, command, data, disableDistanceClick, targetLookAt, popupMaterial, npcPopupMaterial, assetRef }) => {

    const canBeClicked = useRef(false);
    const [isClickable, setClickable] = useState(false);
    const [isCliked, setClicked] = useState(false);
    const refPlayerModel = useRef();
    const animationClips = useRef();
    const refPlayerBase = useRef();
    const refPlayerScaleBase = useRef();
    const boxDetection = useRef();



    const [npcHoverboard, setNPCHoverboard] = useState(SceneContext(state => state.isNPCHoverboard));
    const droppedNPC = useRef(SceneContext(state => state.droppedNPC));
    const { fbxLoaded, textureLoaded } = useContext(PlayerContext);

    const sprayCan = useRef();
    const sprayCanObject = useRef();
    const sprayCanModel = useRef();

    const { gl, camera } = useThree();
    const raycaster = useRef(new Raycaster());

    const setNPCSelected = SceneContext(state => state.setNPCSelected);

    const playerObjectRef = useRef(GamePlayerContext(state => state.localPlayerObject));
    // console.log("Player Object Ref", playerObjectRef);

    const openLoginPopup = FunctionVariableContext(state => state.openLoginPopup);
    const userId = useRef(GamePlayerContext(state => state.userId));

    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);

    useEffect(() => {
        let userIdSubs = GamePlayerContext.subscribe(state => state.userId, (data) => {
            userId.current = data;
        });

        return () => {
            userIdSubs();
        }
    }, []);

    const [physicsRef, physicsApi] = useBox(() => ({
        type: "Static",
        args: [4.5, 2, 4.5],
        mass: 10,
        isTrigger: true,
        onCollideBegin: (e) => {
            canBeClicked.current = true;
            setClickable(true);
        },
        onCollideEnd: (e) => {
            canBeClicked.current = false;
            setClickable(false);
        }
    }));

    useEffect(() => {
        const playerObjectSubs = GamePlayerContext.subscribe(state => state.localPlayerObject, (data) => {
            // console.log("Player Object Update", playerObjectRef);
            playerObjectRef.current = data;
        });

        const npcHoverboardSubs = GamePlayerContext.subscribe(state => state.isNPCHoverboard, (data) => {
            refPlayerScaleBase.current.visible = !data;
            setNPCHoverboard(data);
        });

        const droppedNPCSubs = SceneContext.subscribe(state => state.droppedNPC, (data) => {
            droppedNPC.current = (data);
        });

        return () => {
            playerObjectSubs();
            npcHoverboardSubs();
            droppedNPCSubs();
        }
    }, []);

    useEffect(() => {
        if (animations[0] == "spray") {
            const sc = fbxLoaded.find(x => x.name == "spray_can.fbx");
            sprayCanObject.current = sc.children[0].clone();

            let materialData = textureLoaded.find(x => x.name == "spray_can.jpg");
            sprayCanObject.current.material = materialData.material;

        }

    }, []);


    const [npcSelected, setNPCSelectedState] = useState(SceneContext(state => state.npcSelected));

    useEffect(() => {
        let npcSelectedSubs = SceneContext.subscribe((state) => state.npcSelected, (data) => {
            setNPCSelectedState(data);
        });

        return () => {
            npcSelectedSubs();
        }
    }, []);

    const click = (event) => {
        if (!canBeClicked.current) {
            if (!disableDistanceClick) return;
        }

        let mouse = new Vector2(
            (event.clientX / gl.domElement.clientWidth) * 2 - 1,
            -(event.clientY / gl.domElement.clientHeight) * 2 + 1
        );

        // console.log(mouse, camera, boxDetection.current);
        raycaster.current.setFromCamera(mouse, camera);
        let intersects = raycaster.current.intersectObject(boxDetection.current, true);


        if (intersects && intersects.length > 0 && data.length > 0) {

            if (isShowOnServerTrigger && (!droppedNPC.current || droppedNPC.current.sequence != parseInt(npcid))) return;

            if (isShowOnServerTrigger && droppedNPC.current && droppedNPC.current.sequence == parseInt(npcid) && !userId.current) {
                openLoginPopup(true);
                return;
            }

            let npcData = { command: command, data: data, object: refPlayerBase.current, usingInputAtTheEnd: isShowOnServerTrigger ? isShowOnServerTrigger : false, droppedID: isShowOnServerTrigger ? droppedNPC.current.id : data.id };
            setVisitorTrackerData("npc", { command: command, data: data, usingInputAtTheEnd: isShowOnServerTrigger ? isShowOnServerTrigger : false, droppedID: isShowOnServerTrigger ? droppedNPC.current.id : data.id })
            setNPCSelected(npcData);
            setClicked(true);
        }
    }

    useEffect(() => {
        if (command.length == 0) return;

        gl.domElement.addEventListener('pointerup', click);

        return () => {
            gl.domElement.removeEventListener('pointerup', click);
        }
    }, []);

    useEffect(() => {

        if (refPlayerBase.current && refPlayerScaleBase.current) {
            refPlayerBase.current.position.set(0, 0.75, 0);
            refPlayerBase.current.rotation.set(rotation[0], rotation[1], rotation[2], rotation[3]);
            refPlayerScaleBase.current.scale.set(scale[0], scale[1], scale[2]);
            refPlayerScaleBase.current.position.set(position[0], position[1], position[2]);

            let forward = new Vector3(0, 0, 0);
            refPlayerBase.current.getWorldDirection(forward);

            physicsApi.position.set(position[0], position[1] + 1, position[2]);
            physicsApi.rotation.set(rotation[0], rotation[1], rotation[2], rotation[3]);

        }

        if (sprayCan.current && sprayCanObject.current && refPlayerModel.current && refPlayerModel.current.rightHand) {

            let rightHandWorldPosition = new Vector3();
            refPlayerModel.current.rightHand.getWorldPosition(rightHandWorldPosition);

            let rightHandWorldRotation = new Quaternion();
            refPlayerModel.current.rightHand.getWorldQuaternion(rightHandWorldRotation);

            sprayCan.current.position.set(rightHandWorldPosition.x, rightHandWorldPosition.y, rightHandWorldPosition.z);
            sprayCan.current.quaternion.copy(rightHandWorldRotation);
            sprayCan.current.scale.set(0.22, 0.22, 0.22);
            sprayCanModel.current.position.set(0, -0.3, 0.3);
            sprayCanModel.current.rotation.set(0, 0, 0, 'XYZ');
        }


        if (refPlayerModel && refPlayerModel.current && refPlayerModel.current.setAnimation != undefined) refPlayerModel.current.setAnimation(animations[0]);
    }, []);

    useFrame(() => {
        physicsApi.position.set(position[0], position[1] + 1, position[2]);
        physicsApi.rotation.set(rotation[0], rotation[1], rotation[2], rotation[3]);
    });

    useFrame((src, dt) => {

        if (targetLookAt) return;

        if (!playerObjectRef.current) return;

        if (!playerObjectRef.current.current) return;

        // console.log(playerObjectRef);

        let worldPosition = new Vector3();
        refPlayerBase.current.getWorldPosition(worldPosition);

        let angle = Math.atan2((playerObjectRef.current.current.position.x - worldPosition.x), (playerObjectRef.current.current.position.z - worldPosition.z));

        refPlayerBase.current.rotation.set(0, angle, 0);
        // let angle = Math.atan2(playerObjectRef.current.current.position.z, playerObjectRef.current.current.position.x) - Math.atan2(worldPosition.z, worldPosition.x);
        // console.log(angle);

        // refPlayerBase.current.rotation.y = angle;


    });


    useFrame((src, dt) => {

        if (!targetLookAt) return;

        if (targetLookAt.name.length == 0) return;

        // console.log(playerObjectRef);

        let worldPosition = new Vector3();
        refPlayerBase.current.getWorldPosition(worldPosition);

        let targetPosition = new Vector3(targetLookAt.position.x / 10000, targetLookAt.position.y / 10000, targetLookAt.position.z / 10000);
        let angle = Math.atan2((targetPosition.x - worldPosition.x), (targetPosition.z - worldPosition.z));

        refPlayerBase.current.rotation.set(0, angle, 0);

        // let angle = Math.atan2(playerObjectRef.current.current.position.z, playerObjectRef.current.current.position.x) - Math.atan2(worldPosition.z, worldPosition.x);
        // console.log(angle);

        // refPlayerBase.current.rotation.y = angle;


    });

    useFrame(() => {

        if (targetLookAt.name.length > 0) return;

        if (!playerObjectRef.current) return;

        if (!playerObjectRef.current.current) return;

        let worldPosition = new Vector3();
        refPlayerBase.current.getWorldPosition(worldPosition);


        let angle = Math.atan2((playerObjectRef.current.current.position.x - worldPosition.x), (playerObjectRef.current.current.position.z - worldPosition.z));

        refPlayerBase.current.rotation.set(0, angle, 0);

    });

    useFrame(() => {
        if (isShowOnServerTrigger) {
            if (droppedNPC.current && droppedNPC.current.sequence == parseInt(npcid)) {
                refPlayerScaleBase.current.visible = (true);
            }
            else {
                refPlayerScaleBase.current.visible = (false);
            }
        }
    });

    useFrame(() => {

        if (refPlayerModel && refPlayerModel.current && refPlayerModel.current.setAnimation != undefined) refPlayerModel.current.setAnimation(animations[0]);
    });

    return (
        <group ref={refPlayerScaleBase}>
            {!isClickable && data.length > 0 &&
                <Billboard position={[0, 2.2, 0]} follow={true} lockX={false} lockY={false} lockZ={false} scale={[0.2, 0.2, 0.2]}>
                    <Text fontSize={2} outlineWidth={'5%'} outlineColor="#000000" outlineOpacity={1}>
                        ...
                    </Text>
                </Billboard>}
            {(isClickable && data.length > 0 && npcSelected == null) &&
                <Billboard position={[0, 2, 0]}>
                    <Plane
                        args={[0.7, 0.2]}
                        onClick={(e) => {
                            if (data.length > 0) {
                                if (isShowOnServerTrigger && (!droppedNPC.current || droppedNPC.current.sequence != parseInt(npcid))) return;


                                let npcData = { command: command, data: data, object: refPlayerBase.current };
                                setNPCSelected(npcData);
                            }
                        }}
                    >
                        <meshBasicMaterial
                            color={0x000000}
                            transparent={false}
                            opacity={1}
                        />
                        <Text
                            position={[0, 0, 0.01]}
                            color={isCliked ? '#c2c2c2' : '#c8ff00'}
                            fontSize={0.1}
                            maxWidth={200}
                            lineHeight={1}
                            letterSpacing={0.02}
                            textAlign={'left'}
                            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                            anchorX="center"
                            anchorY="middle"
                        >
                            CLICK ME
                        </Text>
                    </Plane>
                </Billboard>
            }
            <group ref={physicsRef}></group>
            <group ref={refPlayerBase}>
                {!npcSelected && command.length > 0 &&
                    <Box ref={boxDetection} scale={[0.5, 1.5, 0.5]} position={[0, 0.2, 0]} visible={false}>
                        <meshBasicMaterial
                            color={0xFFFFFF}
                            opacity={0}
                            transparent={true}
                        />
                    </Box>
                }
                <PlayerModelAndAnimation
                    playerModel={refPlayerModel}
                    playerAction={animationClips}
                    playerId={playerId}

                    skin={playerSettings.skin}
                    base={playerSettings.baseId}
                    skinColor={playerSettings.skinColor}
                    hairColor={playerSettings.hairColor}
                    head={playerSettings.head}
                    eyebrow={playerSettings.eyebrow}
                    eyes={playerSettings.eyes}
                    mouth={playerSettings.mouth}
                    hair={playerSettings.hair}
                    upperBody={playerSettings.upperBody}
                    lowerBody={playerSettings.lowerBody}
                    feet={playerSettings.feet}
                    props={[playerSettings.props]}
                    colliderRadius={0}

                    assetRef={assetRef}
                >
                </PlayerModelAndAnimation>
            </group>
            {
                sprayCanObject && sprayCanObject.current &&
                <group ref={sprayCan}>
                    <primitive ref={sprayCanModel} object={sprayCanObject.current} />
                </group>
            }

        </group >
    );
}