import { useEffect, useState } from 'react';
import '../../../css/animation-control.css';
import { GamePlayerContext, SceneContext } from '../../context/game-context';

export const AnimationControl = () => {

    const [controllableAnimation, setControllableAnimation] = useState(GamePlayerContext(state => state.controllableAnimation));
    const setNPCHoverboard = GamePlayerContext((state) => state.setNPCHoverboard);
    window.susu = controllableAnimation;
    useEffect(() => {
        let controllableAnimationSubs = GamePlayerContext.subscribe((state) => state.controllableAnimation, (data) => {

            setControllableAnimation(data);
        });

        return () => {
            controllableAnimationSubs();
        }
    }, [])

    useEffect(()=>{
    },[controllableAnimation]);

    return controllableAnimation.length > 0 && <div className="animation-control">
        {controllableAnimation.map((x, i) => {
            return (
                <div className='row' key={i}>
                    <div className='col-12 panel'>
                        Animation Controller
                    </div>
                    <div className='col-12 title'>
                        {x.name}
                    </div>
                    {
                        x.list.map((y, j) => {
                            return <button className='animation-button' key={j} 
                                onClick={(e)=>{
                                    x.playAnimation(y);
                                    if(j < x.npcHoverboard.length)setNPCHoverboard(x.npcHoverboard[j]);
                                }}
                            >
                                {y}
                            </button>
                        })
                    }
                </div>);
        })}
    </div >
}