import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { GamePlayerContext, LocalPlayerContext } from "../../../context/game-context";
import PlayerLoader from "../player-loader";
import { PlayerContext } from "./player-context";
import { SocketContext } from "./socket-context";


export const PlayerState = ({ children, sceneID }) => {
    const [playerStateData, setPlayerStateData] = useState(null);
    const [avatarList, setAvatarList] = useState(null);
    const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);
    // fetch a user from a fake backend API
    useEffect(() => {
        if (playerStateData == null && sceneID) {
            const fetchData = async () => {
                const { playerJson, fbxLoaded, animationClip } = await PlayerLoader(avatarList);

                setPlayerStateData({ playerJson, fbxLoaded, animationClip })

                try {
                    const getListAvatar = await axios.get(`${process.env.API_URL}/skin/list_skin_by_scene/${sceneID.current}`, {
                        crossDomain: true
                    });
                    setAvatarList(getListAvatar.data.data)
                    // change default skin
                    updateLocalPlayerSetting({ skin: getListAvatar.data.data[0].skin })
                } catch (err) {
                    const avatarDefault = await axios.get(`${process.env.RESOURCE_URL}/player/player_default.json`, {
                        crossDomain: true
                    });
                    setAvatarList(avatarDefault.data.data);
                    updateLocalPlayerSetting({ skin: avatarDefault.data.data[0].skin })
                }
            }
            fetchData();
        }

        return () => {
            setPlayerStateData(null);
            setAvatarList(null);
        }
    }, []);

    return (
        <PlayerContext.Provider
            value={{ ...playerStateData, avatarList }}
        >
            {playerStateData && avatarList && children}
        </PlayerContext.Provider>
    );
};