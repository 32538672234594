import { Debug, Physics, PlaneProps, useBox, usePlane } from "@react-three/cannon"
import { Box, PerspectiveCamera, Sphere, useFBX } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { Vector3 } from "three"
import UsePersonControls from "../player-component/player-controls"
import * as THREE from "three";
import { PlayerModelAndAnimation } from "../player-model-animation"
import { EndlessRunContext, GameConfigContext, GamePlayerContext, LocalPlayerContext, SceneContext } from "../../context/game-context"
import { Button } from "react-bootstrap"

const playerRaycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

const Player = forwardRef((props, ref) => {
    
    const initiateLocalPlayerSetting = LocalPlayerContext((state) => state);
    const [playerSettings, setPlayerSettings] = useState(initiateLocalPlayerSetting);

    return <PlayerModelAndAnimation
        playerModel={ref}
        skin={playerSettings.skin}
        base={playerSettings.base}
        skinColor={playerSettings.skinColor}
        hairColor={playerSettings.hairColor}
        head={playerSettings.head}
        eyebrow={playerSettings.eyebrow}
        eyes={playerSettings.eyes}
        mouth={playerSettings.mouth}
        hair={playerSettings.hair}
        upperBody={playerSettings.upperBody}
        lowerBody={playerSettings.lowerBody}
        feet={playerSettings.feet}
        props={[props]}
        colliderRadius={[]}
    />
})

const Crate = forwardRef((props, ref) => {
    const refPlayerModel = useRef();
    const { left, right } = UsePersonControls();
    // console.log(left, right);
    const [curDir, setCurDir] = useState('mid');
    const boxRef = useRef();
    const cameraRef = useRef();
    const { scene, camera } = useThree();
    // const [boxRef, api] = useBox(() => ({
    //     args: [1, 2, 1], onCollide: () => {
    //         console.log("HIT ")
    //     }, position: [0, 1, 0]
    // }))
    const playerZPosition = useRef(new Vector3(0, 0.1, 0));
    const cameraZPosition = useRef(new Vector3(0, 10, 0));

    useEffect(() => {
        // console.log(props.laneX[0]);
        if (props.startGame) {
            if (left) {
                if (curDir == 'mid') {
                    setCurDir('left');
                    playerZPosition.current.x = props.laneX[0];
                    cameraZPosition.current.x = props.laneX[0];
                    // api.position.set(props.laneX[0], 1, 0);
                } else if (curDir == 'right') {
                    setCurDir('mid');
                    playerZPosition.current.x = props.laneX[1];
                    cameraZPosition.current.x = props.laneX[1];
                    // api.position.set(props.laneX[1], 1, 0);
                    // boxRef.current.x = props.laneX[1];
                }
            } else if (right) {

                if (curDir == 'mid') {
                    setCurDir('right');
                    playerZPosition.current.x = props.laneX[2];
                    cameraZPosition.current.x = props.laneX[2];
                    // api.position.set(props.laneX[2], 1, 0);
                    // boxRef.current.x = props.laneX[2];
                } else if (curDir == 'left') {
                    setCurDir('mid');
                    playerZPosition.current.x = props.laneX[1];
                    cameraZPosition.current.x = props.laneX[1];
                    // api.position.set(props.laneX[1], 1, 0);
                    // boxRef.current.x = props.laneX[1];
                }
            }
        }
    }, [left, right])

    let arrow = null;
    let closestLane = null;
    let lastObjCoin = null;
    useFrame((gl, dt) => {
        // playerZPosition.current.x = boxRef.current.position.x;
        playerZPosition.current.y = boxRef.current.position.y + 1;
        playerZPosition.current.z = boxRef.current.position.z - 2.5;
        // cameraZPosition.current.x = cameraRef.current.position.x;

        pointer.x = boxRef.current.position.x;
        pointer.y = boxRef.current.position.y;
        cameraZPosition.current.y = cameraRef.current.position.y;
        cameraZPosition.current.z = cameraRef.current.position.z;

        // boxRef.current.position.lerp(playerZPosition.current, 10 * dt);

        cameraRef.current.position.lerp(cameraZPosition.current, 10 * dt);

        // playerRaycaster.setFromCamera(pointer, cameraRef.current);
        // console.log(props.refLane.current.children[0]);
        if (closestLane != props.refLane.current.children[2]) {
            closestLane = props.refLane.current.children[2];
            closestLane.visible = true;
            // console.log(closestLane);
        }

        const obstacleGroup = closestLane.children.filter((r) => r.type == 'Group');
        // console.log(obstacleGroup, "CLOSEST LANE CHILDREN")

        const listObstacle = obstacleGroup.map((obj) => {
            const playerPosition = new Vector3();
            refPlayerModel.current.getWorldPosition(playerPosition);

            const objPosition = new Vector3();
            obj.getWorldPosition(objPosition);

            const distance = objPosition.distanceTo(playerPosition);
            if (props.startGame) {
                if (distance < 2 && distance >= 0) {
                    // console.log(distance, obj, "COLLIDE");
                    if (obj.name == 'obstacle') {
                        props.handlerGameOver();
                    } else if (obj.name == 'coin' && lastObjCoin != obj) {
                        obj.visible = false;
                        lastObjCoin = obj;
                        props.getCoins(100)
                    }
                    // obj.material.color.set(0xff0000);
                }
            }
            return { distance, obj };
        });

        // console.log(listObstacle);

        // const intersects = playerRaycaster.intersectObjects(obstacleGroup.children);
        // console.log(intersects, "OBJECTS");
        // console.log(cameraRef.current.getWorldDirection());
        // scene.remove(arrow);
        // arrow = new THREE.ArrowHelper(camera.getWorldDirection(), camera.getWorldPosition(), 100, Math.random() * 0xffffff);
        // scene.add(arrow);
        // for (let i = 0; i < intersects.length; i++) {

        //     intersects[i].object.material.color.set(0xff0000);

        // }


        // const collideObject = listObstacle.filter((r) => r.distance < Math.floor(10));
        // if (collideObject.length > 0) {
        //     console.log(collideObject);
        // } else {
        //     console.log(collideObject);
        // }

        if (refPlayerModel.current && refPlayerModel.current.setAnimation) {
            if (props.startGame)
                refPlayerModel.current.setAnimation("run")
            else
                refPlayerModel.current.setAnimation("idle")
            refPlayerModel.current.position.lerp(playerZPosition.current, 10 * dt);
            refPlayerModel.current.rotation.set(0, -3.2, 0)
        }
    })

    return (
        <group ref={ref}>
            <PerspectiveCamera
                ref={cameraRef}
                fov={80}
                far={props.showLane * 10}
                makeDefault
                position={[0, 3, 0]}
                rotation={[-.4, 0, 0]} >
            </PerspectiveCamera>

            <Box
                args={[1, 2, 1]}
                visible={true}
                ref={boxRef}
            // onUpdate={() => console.log("UPDATE BOX")}
            >
                <meshNormalMaterial />
                <Player ref={refPlayerModel} />
            </Box>
        </group>
    )
})

const Plane = forwardRef((props, ref) => {
    // console.log(props, props.args, api);
    return <mesh
        // attach={props.children}
        ref={ref}
        visible={false}
        rotation={[-Math.PI / 2, 0, 0]}
        position={props.position}
        name={props.key}
        receiveShadow>
        {props.children}
        <planeGeometry args={props.args} />
        <meshStandardMaterial color={props.hex} />
    </mesh>
})

const Obstacle = forwardRef((props, ref) => {
    const gachaFbx = Math.floor(Math.random() * 4);
    const obstacleRef = useRef();

    const listFbx = [
        `${process.env.RESOURCE_URL}/endlessrun/fbx/PalletA.fbx`,
        `${process.env.RESOURCE_URL}/endlessrun/fbx/PalletB.fbx`,
        `${process.env.RESOURCE_URL}/endlessrun/fbx/PalletC.fbx`,
        `${process.env.RESOURCE_URL}/endlessrun/fbx/DrumKomatsu.fbx`
    ]
    const loadFbx = useFBX(listFbx[gachaFbx]);
    const fbx = useMemo(() => {
        let fbx = null;
        if (props.envObstacleRef.current && typeof props.envObstacleRef.current[gachaFbx] != 'undefined') {
            fbx = props.envObstacleRef.current[gachaFbx].clone();
        } else {
            fbx = loadFbx;
            let texture = '';
            if (props.textureObstacleRef.current) {
                texture = props.textureObstacleRef.current.clone();
            } else {
                const textureLoader = new THREE.TextureLoader();
                texture = textureLoader.load(`${process.env.RESOURCE_URL}/endlessrun/textures/TextureA.png`);

                texture.magFilter = THREE.NearestFilter;
                texture.minFilter = THREE.NearestMipMapLinearFilter;
                //texture.generateMipmaps = false;
                texture.needsUpdate = true;

                props.textureObstacleRef.current = texture;
            }

            let material = new THREE.MeshBasicMaterial({ map: texture, skinning: true });
            // material.name = 'aura';
            // material.opacity = 0.6;
            // material.transparent = true;

            fbx.traverse((r) => {
                r.material = material;
            })
            props.envObstacleRef.current[gachaFbx] = fbx;
        }
        return fbx;
    }, [loadFbx]);

    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <primitive object={fbx} ref={obstacleRef} dispose={null} scale={[1, 1, 1]} position={props.position} name={"obstacle"} />
    );
})


const Coin = forwardRef((props, ref) => {

    const coinRef = useRef();
    const loadFbx = useFBX(`${process.env.RESOURCE_URL}/endlessrun/fbx/UT_Coin.fbx`);
    const fbx = useMemo(() => {
        let fbx = null;
        if (props.envCoinRef.current) {
            fbx = props.envCoinRef.current.clone();
        } else {
            fbx = loadFbx;

            let texture = '';
            if (props.textureObstacleRef.current) {
                texture = props.textureObstacleRef.current.clone();
            } else {
                const textureLoader = new THREE.TextureLoader();
                texture = textureLoader.load(`${process.env.RESOURCE_URL}/endlessrun/textures/TextureA.png`);

                texture.magFilter = THREE.NearestFilter;
                texture.minFilter = THREE.NearestMipMapLinearFilter;
                //texture.generateMipmaps = false;
                texture.needsUpdate = true;

                props.textureObstacleRef.current = texture;
            }

            let material = new THREE.MeshBasicMaterial({ map: texture, skinning: true });
            // material.name = 'aura';
            // material.opacity = 0.6;
            // material.transparent = true;

            fbx.traverse((r) => {
                r.material = material;
            })
            props.envCoinRef.current = fbx;
        }
        return fbx;
    }, [loadFbx]);

    useEffect(() => {
        return () => {
        }
    }, [])

    return (
        <primitive object={fbx} ref={coinRef} dispose={null} scale={[1, 1, 1]} position={props.position} name={"coin"} />
    );
})

const Lane = forwardRef((props, ref) => {
    const lane1 = useRef();
    const lane2 = useRef();
    const lane3 = useRef();
    const envObjRefNew = useRef();

    // console.log(props.objMesh)
    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    const hex = "#" + genRanHex(6);
    // console.log(hex);

    return (
        <group ref={ref} visible={true}>
            <Plane
                key={"lane1"}
                ref={lane1}
                hex={hex}
                args={[1, props.laneWidth]} position={[props.position[0] + props.laneX[0], props.position[1], props.position[2]]}>
                {props.objMesh && props.objMesh.filter((r) => r.props.lane == 0)}
            </Plane>
            <Plane
                key={"lane2"}
                ref={lane2}
                hex={hex}
                args={[1, props.laneWidth]} position={[props.position[0] + props.laneX[1], props.position[1], props.position[2]]}>
                {props.objMesh && props.objMesh.filter((r) => r.props.lane == 1)}
            </Plane>
            <Plane
                key={"lane3"}
                ref={lane3}
                hex={hex}
                args={[1, props.laneWidth]} position={[props.position[0] + props.laneX[2], props.position[1], props.position[2]]}>
                {props.objMesh && props.objMesh.filter((r) => r.props.lane == 2)}
            </Plane>
            {props.objMesh && props.objMesh}
            <Environment ref={envObjRefNew} envObjRef={props.envObjRef} position={[props.position[0], props.position[1], props.position[2]]} />
        </group >
    )
});

const CameraHelper = () => {
    const camera = new THREE.PerspectiveCamera(60, 1, 1, 3)
    return <group position={[5, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
        <cameraHelper args={[camera]} />
    </group>
}

const Fog = (props) => {
    const { scene } = useThree();
    const near = 10;
    const far = props.far;
    const color = 'lightblue';
    scene.fog = new THREE.Fog(color, near, far);

    return <></>
}

const Environment = forwardRef((props, ref) => {

    const loadFbx = useFBX(`${process.env.RESOURCE_URL}/endlessrun/fbx/EndlessRunStage.fbx`);
    const fbx = useMemo(() => {
        let fbx = null;
        if (props.envObjRef.current) {
            fbx = props.envObjRef.current.clone();
        } else {
            fbx = loadFbx;

            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(`${process.env.RESOURCE_URL}/endlessrun/textures/TextureA.png`);

            texture.magFilter = THREE.NearestFilter;
            texture.minFilter = THREE.NearestMipMapLinearFilter;
            //texture.generateMipmaps = false;
            texture.needsUpdate = true;

            let material = new THREE.MeshBasicMaterial({ map: texture, skinning: true });
            // material.name = 'aura';
            // material.opacity = 0.6;
            // material.transparent = true;

            fbx.traverse((r) => {
                r.material = material;
            })
            props.envObjRef.current = fbx;
        }
        return fbx;
    }, [loadFbx]);

    useEffect(() => {
        ref.current = fbx;

        return () => {
        }
    }, [])

    return (
        <primitive object={fbx} ref={ref} dispose={null} scale={[1, 1, 1]} position={props.position} />
    );
})



function Ground(props) {
    const [ref] = usePlane(() => ({ material: 'ground', type: 'Static', ...props }))
    return (
        <group ref={ref}>
            <mesh receiveShadow>
                <planeGeometry args={[50, 50]} />
                <meshStandardMaterial color="#303030" />
            </mesh>
        </group>
    )
}


export const EndlessRun = ({ setEndlessRun }) => {
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/EndlessRunStage.fbx`)
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/PalletA.fbx`)
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/PalletB.fbx`)
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/PalletC.fbx`)
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/DrumKomatsu.fbx`)
    useFBX.preload(`${process.env.RESOURCE_URL}/endlessrun/fbx/UT_Coin.fbx`)

    // console.log("MASUK ENDLESS RUN")
    const lane = useRef();
    const crateRef = useRef();
    const envObjRef = useRef();
    const envCoinRef = useRef();
    const envObstacleRef = useRef([]);
    const textureObstacleRef = useRef();
    const laneX = [-2, 0, 2];
    const laneWidth = 10;

    const [startGame, setStartGame] = useState(false);
    const [listLane, setListLane] = useState([
        <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-1"} position={[0, 0.1, 0]} />,
        <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-2"} position={[0, 0.1, 10]} />,
        <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-3"} position={[0, 0.1, 20]} />,
        <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-4"} position={[0, 0.1, 30]} />
    ])


    const speed = 10;
    const showLane = 3;
    const lastPosZ = useRef();
    const indexLane = useRef(showLane + 2);

    const getCoins = EndlessRunContext((state) => state.getCoins);
    const setGameOver = EndlessRunContext((state) => state.setGameOver);
    const setGameStart = EndlessRunContext((state) => state.setGameStart);
    const setRetry = EndlessRunContext((state) => state.setRetry);

    const handlerGameOver = () => {
        if (startGame) {
            setStartGame(false);

            setGameStart(false);
            setGameOver(true);
        }

    }

    useEffect(() => {
        lastPosZ.current = Math.floor(crateRef.current.position.z / laneWidth).toFixed(0);

        const startGame = EndlessRunContext.subscribe(state => state.gameStart, (data) => {
            if (data) {
                setStartGame(true)
            }
        });

        const retryGame = EndlessRunContext.subscribe(state => state.retryGame, (data) => {
            if (data) {
                setRetry(false);
                crateRef.current.position.z = 0;
                setListLane([
                    <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-1"} position={[0, 0.1, 0]} />,
                    <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-2"} position={[0, 0.1, 10]} />,
                    <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-3"} position={[0, 0.1, 20]} />,
                    <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-4"} position={[0, 0.1, 30]} />
                ])
            }
        });


        window.lane = lane;
        setEndlessRun(true);
        return () => {
            if (lane.current)
                lane.current.position.x = 0;

            startGame();
            retryGame();
            setListLane([
                <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-1"} position={[0, 0.1, 0]} />,
                <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-2"} position={[0, 0.1, -10]} />,
                <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-3"} position={[0, 0.1, -20]} />,
                <Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} key={"lane-4"} position={[0, 0.1, -30]} />])
        }
    }, [])

    const generateNewLane = () => {
        const obj = ['nothing', 'coins', 'obstacle'];
        const objMesh = [];
        const laneZ = Math.floor((crateRef.current.position.z).toFixed());
        // console.log(laneX, "LANE X")
        const nextLaneZ = laneZ + ((showLane + 2) * -10);
        const objectZ = nextLaneZ + (laneWidth / 2);
        let lastObj = null;
        // generate coin or obstacle
        for (var i = 1; i <= 9; i++) {
            let margin = i;
            const gachaIdx = lastObj == 'obstacle' ? 2 : 3;
            const createRandomObj = Math.floor(Math.random() * gachaIdx); // 3 is nothing / coin / obstacle
            const createRandomLane = Math.floor(Math.random() * 3);
            lastObj = obj[createRandomObj];

            // console.log(i, obj[createRandomObj], createRandomObj, lastObj, gachaIdx);
            if (obj[createRandomObj] == 'obstacle') {

                objMesh.push(<Obstacle lane={createRandomLane} textureObstacleRef={textureObstacleRef} envObstacleRef={envObstacleRef} position={[laneX[createRandomLane], 0.5, objectZ + margin]} />)
            } else if (obj[createRandomObj] == 'coins') {

                objMesh.push(<Coin lane={createRandomLane} textureObstacleRef={textureObstacleRef} envCoinRef={envCoinRef} position={[laneX[createRandomLane], 1, objectZ + margin]} />)
                i++;
                margin = i;
                if (i <= 9) {
                    objMesh.push(<Coin lane={createRandomLane} textureObstacleRef={textureObstacleRef} envCoinRef={envCoinRef} position={[laneX[createRandomLane], 1, objectZ + margin]} />)
                    i++;
                    margin = i;
                }
                if (i <= 9) {
                    objMesh.push(<Coin lane={createRandomLane} textureObstacleRef={textureObstacleRef} envCoinRef={envCoinRef} position={[laneX[createRandomLane], 1, objectZ + margin]} />)
                }
            }
        }
        // console.log(objMesh, "OBJ MESH");

        if (listLane.length > showLane + 2) {
            listLane.shift();
        }

        listLane.push(<Lane envObjRef={envObjRef} laneX={laneX} laneWidth={laneWidth} objMesh={objMesh} key={`lane-${indexLane.current}`} position={[0.1, 0.1, nextLaneZ]
        } />)
        indexLane.current += 1;
        setListLane([...listLane])

    }

    useFrame((src, dt) => {
        const lastZ = Math.floor(crateRef.current.position.z / laneWidth);
        if (lastPosZ.current != lastZ && lastZ != 0) {
            lastPosZ.current = lastZ;
            // setListLane()
            // if (lastPosX.current != 0) {
            generateNewLane();
            // }
        }

        if (crateRef.current && startGame) {

            crateRef.current.position.z -= speed * dt;
        }

        // if (lane.current) {

        //     lane.current.position.z += speed * dt;

        //     lane.current.updateMatrixWorld(true);
        //     //     lane.current.children.forEach((r) => {
        //     //         console.log(r, "EACH CHILD")
        //     //         // r.position.setZ(lane.current.position.z);
        //     // //         lane.current.updateMatrixWorld();
        //     //     })
        // }

        // console.log(lane.current, "BOX OBJ");
    });

    // console.log(listLane, "RERENDER");
    return <Physics>
        <Debug>
            <group ref={lane} position={[0, 0.1, 0]}>
                {listLane}
            </group>
            <Crate startGame={startGame} getCoins={getCoins} handlerGameOver={handlerGameOver} ref={crateRef} showLane={showLane} laneX={laneX} refLane={lane} position={[0, 1, 0]} />
            {/* <CameraHelper /> */}

            <Fog far={showLane * 10} />

        </Debug>
    </Physics>
}