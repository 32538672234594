import * as THREE from 'three';
import { useFrame } from "@react-three/fiber";
import UsePersonControls from '../player-controls';
import { CanvasContext } from './canvas-context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { Quaternion } from 'three';
import { Euler } from 'three';
import { UpdateParameter } from './player-update-parameter';
import { PlayerContext } from './player-context';
import { GamePlayerContext } from '../../../context/game-context';

export const HoverBoard = (props) => {

    const { fbxLoaded, textureLoaded } = useContext(PlayerContext);

    const { forward, backward, left, right, jump, hoverDown, run } = UsePersonControls();

    const forwardKey = useRef(forward);
    const backwardKey = useRef(backward);
    const leftKey = useRef(left);
    const rightKey = useRef(right);
    const jumpKey = useRef(jump);
    const hoverDownKey = useRef(hoverDown);

    const api = props.api;
    const { networkManager, setDirectionalLightTarget } = useContext(CanvasContext);

    const updatePlayer = useRef();
    const isGrounded = useRef(false);
    const playerModel = useRef(props.refPlayerModel);
    const forwardDirection = useRef(new THREE.Vector3(0, 0, 0));
    const currentPosition = useRef([0, 0, 0]);
    const currentVelocity = useRef([0, 0, 0]);
    const currentTargetVelocity = useRef([0, 0, 0]);
    const lastGroundPosition = useRef(new Vector3(0, 0, 0));
    const currentRaycastHitPoint = useRef([0, 0, 0]);
    const currentYRotation = useRef(0);
    const currentSeat = useRef("");
    const isMove = useRef(-1);
    const hoverBoard = useRef();
    const [hoverBoardGeometry, setHoverBoardGeometry] = useState();
    const [hoverBoardMaterial, setHoverBoardMaterial] = useState();
    const colliders = useRef();

    let cameraOffsetY = 1.5;
    let cameraMovementSpeed = 10;
    let groundRaycastSafeDistance = 2;

    let playerModelWorldPosition = new THREE.Vector3(0, 0, 0);
    let currentPoint = new THREE.Vector3(0, 0, 0);
    let raycastDir = new THREE.Vector3(0, -1, 0);
    let raycastHitPoint = new THREE.Vector3(0, 0, 0);
    let raycastHitNormal = new THREE.Vector3(0, 0, 0);
    let targetDirection = new THREE.Vector3(0, 0, 0);

    let _isGrounded = false;

    const orbitControl = props.orbitControl.current;
    //TODO
    //Ini di pass dari camera control, kayaknya ga bener caranya
    const setCameraTarget = props.setCameraTarget.current;

    let acceleration = 10;
    let gravityAcceleration = 5;
    let maxUpSpeed = 5;
    let maxGravitySpeed = 5;
    let moveSpeed = 15;
    let rotateSpeed = 4;
    let maxRotationX = 30;
    let upSpeed = useRef(0);
    let floatY = 1;
    let currentMoveSpeed = useRef(0);
    let currentRotationX = useRef(0);
    let _isLastGround = useRef(false);
    let _isJump = useRef(false);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));

    const controlToggle = useRef(GamePlayerContext((state)=>state.controlToggle));

    colliders.current = props.colliders;

    useEffect(()=>{
        let gamePlayerSubs = GamePlayerContext.subscribe((state)=>state.controlToggle, (data)=>{
            controlToggle.current = data;
        });

        return()=>{
            gamePlayerSubs();
        }
    },[]);

    useEffect(() => {

        const hoverBoardObject = fbxLoaded.find(x => x.name === "hoverboard_board.fbx").clone();
        let material = null;

        let geometry = null;
        hoverBoardObject.traverse((children => {
            if (children.isMesh) {
                geometry = children.geometry;
                material = children.material;
            }
        }));

        material.transparent = true;
        setHoverBoardMaterial(material);
        setHoverBoardGeometry(geometry);

        // console.log("GEOMETRY HOVERBOARD", geometries);
    }, []);

    useEffect(() => {
        api.angularFactor.set(0, 1, 0);

        currentYRotation.current = radToDeg(props.refPlayerModel.current.rotation.y);
    }, []);

    useEffect(() => {
        const unsubscribe = api.position.subscribe((v) => {
            updatePlayerPosition(v);
                       
            // console.log("POSITION HOVERBOARD");

            update(1/60, v);
        });

        return unsubscribe;
    }, []);

    useEffect(()=>{
        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data)=>{
            isFPS.current = data;

            props.refPlayerModel.current.traverse((child) => {
                if (child.isMesh) {
                    child.visible = !isFPS.current;
                }
            });
        });

        return () => {
            firstPersonSubs();
        };
    },[]);

    useEffect(() => {
        const unsubscribe = api.velocity.subscribe((v) => {
            currentVelocity.current = v;
            updatePlayerMovement(v);
        });

        return unsubscribe;
    }, []);

    const updatePlayerPosition = (v) => {
        currentPoint.set(currentPosition.current[0], currentPosition.current[1], currentPosition.current[2]);
        raycastDir.set(0, -1, 0);
        let raycasterGround = new THREE.Raycaster(currentPoint, raycastDir);
        let intersectGround = raycasterGround.intersectObjects(colliders.current);

        if (intersectGround.length > 0) {
            isGrounded.current = (intersectGround[0].distance < (props.colliderRadius * 2) + groundRaycastSafeDistance);

            raycastHitPoint.set(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
            currentRaycastHitPoint.current = [intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z];
            raycastHitNormal.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);
        }
        else {
            isGrounded.current = false;
        }

        let minY = lastGroundPosition.current.y + (props.colliderRadius * 2) + floatY;
        let current = lerp(v[1], minY, 0.16);
        let position = [v[0], v[1] < minY ? current : v[1], v[2]];

        if (parseFloat(v[1].toFixed(3)) < parseFloat(minY.toFixed(3))) {
            api.position.set(position[0], position[1], position[2]);
            // console.log("HERE");
        }

        currentPosition.current = position;

    }

    const updatePlayerMovement = (v) => {
    }


    const copyPhysicsValueToObject = () => {
        props.playerObject.current.position.set(currentPosition.current[0], currentPosition.current[1], currentPosition.current[2]);
    }

    const radToDeg = (radians) => {
        let pi = Math.PI;
        return radians * (180 / pi);
    }


    const update = (delta, v) => {
        props.refPlayerModel.current.setAnimation("hoverboard");

        _isGrounded = isGrounded.current;

        let axis = {x:0, y:0, j:0}
        if (leftKey.current) {
            axis.x = -1;
        }
        else if (rightKey.current) {
            axis.x = 1;
        }
        
        if (forwardKey.current) {
            axis.y = 1;
        }
        else if (backwardKey.current){
            axis.y = -1;
        }

        if(jumpKey.current){
            axis.j = 1;
        }
        else if(hoverDownKey.current){
            axis.j = -1;
        }

        if(props.jumpInput.current){
            axis.j = 1;
            // console.log(props.jumpInput.current);
        }
        
        if(props.downInput.current){
            axis.j = -1;
            // console.log(props.downInput.current);
        }

        if (props.analogInput && props.analogInput.current) {
            axis.x = Math.abs (props.analogInput.current.x) > 0.5 ? Math.round(props.analogInput.current.x) : 0 ;
            axis.y = Math.abs (props.analogInput.current.y) > 0.5 ? Math.round(props.analogInput.current.y) : 0 ;
           
        }

        if (_isJump.current !== axis.j) {
            _isJump.current = axis.j;
            upSpeed.current = 0;
        }

        let currentRotateSpeed = 0;
        if (axis.x < 0) {
            currentRotateSpeed = rotateSpeed;
            currentRotationX.current = lerp(currentRotationX.current, -maxRotationX, rotateSpeed * delta);
        }
        else if (axis.x > 0) {
            currentRotateSpeed = -rotateSpeed;
            currentRotationX.current = lerp(currentRotationX.current, maxRotationX, rotateSpeed * delta);
        }
        else {
            currentRotationX.current = lerp(currentRotationX.current, 0, rotateSpeed * delta * 2);
        }

        /*
        upSpeed.current += (_isJump.current ? acceleration : -gravityAcceleration) * delta;
        upSpeed.current = clamp(upSpeed.current, -maxGravitySpeed, maxUpSpeed);
        let minY = lastGroundPosition.current.y + (props.colliderRadius * 2) + floatY;
        if ((axis.j === 0) && currentPosition.current[1] < minY) upSpeed.current = 0;
*/
        upSpeed.current = axis.j * maxGravitySpeed;

        props.refPlayerModel.current.getWorldDirection(targetDirection);

        currentYRotation.current += currentRotateSpeed;
        let yEuler = new Euler(0, deg2rad(currentYRotation.current), 0);
        let xEuler = new Euler(0, 0, deg2rad(currentRotationX.current));
        let yQuaternion = new Quaternion().setFromEuler(yEuler);
        let xQuaternion = new Quaternion().setFromEuler(xEuler);
        let playerQuaternion = new Quaternion().multiplyQuaternions(yQuaternion, xQuaternion);

        if(controlToggle.current == true) props.refPlayerModel.current.quaternion.slerp(playerQuaternion, delta * 3);

        if (axis.x !== 0 || axis.y !== 0 || axis.z !== 0) {
            lastGroundPosition.current.set(currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]);
        }
        else {
            if (_isGrounded !== _isLastGround.current) {
                lastGroundPosition.current.set(currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]);
            }
            _isLastGround.current = _isGrounded;
        }

        isGrounded.current = axis.j === 1 ? false : _isGrounded;

        if (axis.y > 0) {
            if (isMove.current !== 1) {
                currentMoveSpeed.current = 0;
                isMove.current = 1;
            }
        }
        else if (axis.y < 0) {
            if (isMove.current !== -1) {
                currentMoveSpeed.current = 0;
                isMove.current = -1;
            }
        }
        else {
            currentMoveSpeed.current = moveTowards(currentMoveSpeed.current, 0, delta * acceleration);
            isMove.current = 0;
        }

        currentMoveSpeed.current += (acceleration * isMove.current) * delta;
        currentMoveSpeed.current = clamp(currentMoveSpeed.current, -moveSpeed, moveSpeed);
        targetDirection.multiplyScalar(currentMoveSpeed.current);
        currentTargetVelocity.current = [targetDirection.x, upSpeed.current, targetDirection.z];

        hoverBoard.current.quaternion.set(props.refPlayerModel.current.quaternion.x, props.refPlayerModel.current.quaternion.y, props.refPlayerModel.current.quaternion.z, props.refPlayerModel.current.quaternion.w);

        props.playerObject.current.getWorldPosition(playerModelWorldPosition);
        
        if(controlToggle.current == true) api.velocity.set(currentTargetVelocity.current[0], currentTargetVelocity.current[1], currentTargetVelocity.current[2]);
        
        if (props.refPlayerModel.current && props.refPlayerModel.current.topHead) {
            let topHeadPosition = new Vector3();
            props.refPlayerModel.current.getWorldPosition(topHeadPosition);
            topHeadPosition.y += 1.5;
            setCameraTarget(v[0], v[1], v[2], colliders.current, props.refPlayerModel.current, topHeadPosition, props.isFPS);
        }
        
        // copyPhysicsValueToObject();
    }

    useFrame(() => {
        forwardKey.current = forward;
        backwardKey.current = backward;
        leftKey.current = left;
        rightKey.current = right;
        jumpKey.current = jump;
        hoverDownKey.current = run;
    });


    const clamp = (value, min, max) => {
        if (value < min)
            return min;
        else if (value > max)
            return max;
        else
            return value;
    }


    const deg2rad = (degrees) => {
        let pi = Math.PI;
        return degrees * (pi / 180);
    }

    const moveTowards = (current, target, maxDelta) => {
        if (Math.abs(target - current) <= maxDelta) {
            return target;
        }

        return current + Math.sign(target - current) * maxDelta;
    }


    const lerp = (v0, v1, t) => {
        return v0 + t * (v1 - v0);
    }

    return (
        <group>
            {
                hoverBoardGeometry && hoverBoardMaterial &&
                <mesh
                    ref={hoverBoard}
                    scale={[0.0035, 0.0035, 0.0035]}
                    position={[0, -props.colliderRadius * 2 + 0.05, 0]}
                    geometry={hoverBoardGeometry}
                    material={hoverBoardMaterial}
                />
            }
            <UpdateParameter
                updatePlayer = {updatePlayer}
                currentPosition={currentPosition}
                networkManager={networkManager}
                setDirectionalLightTarget={setDirectionalLightTarget}
                forwardDirection={forwardDirection}
                sit={currentSeat}
                {...props}
            />
        </group>
    )

}