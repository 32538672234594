import { useFrame } from "@react-three/fiber";
import { Suspense, useContext, useEffect, useRef, useState, forwardRef } from "react";
import { CanvasContext } from "./player-component/context/canvas-context";
import { PlayerName } from "./player-component/player-name";
import { PlayerShoutout } from "./player-component/player-shoutout";
import * as THREE from "three";
import { PlayerContext } from "./player-component/context/player-context";
import { PlayerSpeaker } from "./player-component/player-speaker";
import { AudioContext, GamePlayerContext, RtcLocalContext, SceneContext } from "../context/game-context";
import { Euler, Quaternion, Vector3 } from "three";
import { PlayerAudioPosition } from "./player-component/player-audio-position";
import { PlayerAura } from "./player-component/player-aura";
import { PlayerModelAndAnimation } from "./player-model-animation";
import { SocketContext } from "./player-component/context/socket-context";
import { AuraPlayers } from "./aura-player";



export const RemotePlayers = forwardRef(({ sceneName, setRemoteAction, onRemoteClicked, assetRef, refPlayerPosition }, ref) => {


    const listPlayersState = GamePlayerContext((state) => state.listPlayer.filter((r) => !r.isLocal));
    const listAuraState = GamePlayerContext((state) => state.listAura.filter((r) => !r.isLocal));
    const { networkManager } = useContext(SocketContext);
    const playerListRef = useRef();

    const [remotePlayers, setRemotePlayers] = useState(listPlayersState ? listPlayersState.filter((r) => !r.isLocal) : []);
    const [remotePlayersObj, setRemotePlayersObj] = useState(<></>)
    const [auraPlayersObj, setAuraPlayersObj] = useState(<></>)
    const [auraRemotePlayers, setAuraRemotePlayers] = useState(listAuraState ? listAuraState.filter((r) => !r.isLocal) : []);

    const setPlayerListToListen = AudioContext(state => state.setPlayerListToListen);
    const setPlayerListToMute = AudioContext(state => state.setPlayerListToMute);
    const setPlayerGridId = AudioContext(state => state.setPlayerGridId);
    // const [updatedPlayer, setUpdatedPlayer] = useState(false);
    const setPositionRemotePlayer = GamePlayerContext((state) => state.setPositionRemotePlayer);
    const conversationSpace = useRef(AudioContext(state => state.conversationSpace));

    useEffect(() => {

        const OnUserUpdate = (data) => {
            // setUpdatedPlayer(true);
            let d = {};
            let array = Object.keys(data);
            let listNear = [];
            let listFar = [];

            // let grid = { x: 0, y: 0, z: 0 }
            // let myData = data[networkManager.me.i];
            // grid = myData.g;

            let listAuraPlayer = [];
            for (let i = 0; i < array.length; i++) {
                const key = array[i];
                if (data[key].p && data[key].p[10] && data[key].p[10] == sceneName) {
                    d[key] = data[key];

                    if (refPlayerPosition.current && d[key].u.i != networkManager.me.i) {

                        if (conversationSpace.current.length == 0) {
                            let currentPosition = new Vector3(d[key].p[0], d[key].p[1], d[key].p[2]);
                            let distance = refPlayerPosition.current.distanceTo(currentPosition);
                            if (distance < 5) {
                                if (d[key].p[12].length == 0) {
                                    listNear.push(d[key].u);
                                }
                                else {
                                    listFar.push(d[key].u);

                                    listAuraPlayer.push(d[key]);
                                }
                            }
                            else {
                                listFar.push(d[key].u);

                                listAuraPlayer.push(d[key]);
                            }
                        }
                        else {
                            if (d[key].p[12] == conversationSpace.current) {
                                listNear.push(d[key].u);
                            }
                            else {
                                listFar.push(d[key].u);

                                listAuraPlayer.push(d[key]);
                            }
                        }
                    }
                }
            }

            setAuraRemotePlayers(listAuraPlayer);

            setPlayerListToListen(listNear);
            setPlayerListToMute(listFar);
            // if(grid) setPlayerGridId(`${sceneName}_${grid.x}_${grid.y}_${grid.z}`);

            setPositionRemotePlayer(d);
        }

        networkManager.on("OnUpdateParameterObject", OnUserUpdate);
        const listPlayerSub = GamePlayerContext.subscribe(state => state.listPlayer, (data) => {
            // console.log("PLAYER REMOTE PLAYERS LIST LISTEN", data);
            let remotePlayer = data.filter((r) => r.id != networkManager.me.i);

            setRemotePlayers(remotePlayer);

            // setRemotePlayersObj()
        })

        const conversationSpaceSubs = AudioContext.subscribe(state => state.conversationSpace, (data) => {
            conversationSpace.current = data;
        });

        return () => {
            listPlayerSub();
            conversationSpaceSubs();
            networkManager.off("OnUpdateParameterObject")
        }
    }, [])

    useEffect(() => {
        
        let listAuraId = auraRemotePlayers.map(x=>x.u.i);

        setRemotePlayersObj(
            //remotePlayers.map(player => <RemotePlayer
            remotePlayers.filter(rm => rm.id != networkManager.me.i).map(player => <RemotePlayer
                key={player.id}
                playerId={player.id}
                name={player.displayName}
                position={player.position}
                rotation={player.rotation}
                isLocal={false}
                updateParameter={null}
                onRemoteClicked={onRemoteClicked}

                gender={player.gender}
                hair={player.hair}
                head={player.head}
                upperBody={player.upperBody}
                shoes={player.shoes}
                lowerBody={player.lowerBody}
                props={player.props}
                hat={player.hat}
                helmet={player.helmet}
                topHead={player.topHead}
                mask={player.mask}
                tiara={player.tiara}
                earing={player.earing}
                facialHair={player.facialHair}

                // showModel={!listAuraId.includes(player.id)}

                assetRef={assetRef}

                onUpdateLocalPlayer={() => null} />)
        );

        setAuraPlayersObj(
            remotePlayers.filter(rm => listAuraId.includes(rm.id) && rm.id != networkManager.me.i)
        );

        // console.log(remotePlayers, auraRemotePlayers);
    }, [remotePlayers, auraRemotePlayers]);

    useEffect(()=>{
        
        let npcSelectedSubs = SceneContext.subscribe(state=>state.npcSelected, (data)=>{
            ref.current.visible = data == null;
        });

        return()=>{
            npcSelectedSubs();
        }
    },[]);

    // console.log("CONCURRENT PARENT REMOTE PLAYER");

    return remotePlayers && (
        <Suspense fallback={null}>
            <group ref={ref}>
                {remotePlayersObj}
                {/* <AuraPlayers players={auraPlayersObj} /> */}
            </group>
        </Suspense>)

})


const RemotePlayer = ({ name, skin, base, skinColor, hairColor, head, eyebrow, eyes, mouth, gender, upperBody, shoes: feet, hair, lowerBody,
    hat, helmet, topHead, mask, tiara, earing, facialHair, showModel,
    playerId, setMessage, onRemoteClicked, assetRef }) => {

    const { fbxLoaded, textureLoaded } = useContext(PlayerContext);
    const refPlayerModel = useRef();
    const refPlayerBase = useRef();
    const animationClips = useRef();
    const setPlayerMessage = useRef();
    const currentTargetPositionsRotation = useRef(null);
    const lastTargetPositionsRotation = useRef(null);
    const targetPositionsRotation = useRef([]);
    const [hoverBoardGeometry, setHoverBoardGeometry] = useState();
    const [hoverBoardMaterial, setHoverBoardMaterial] = useState();
    const hoverboard = useRef();


    const [_name, setName] = useState(name);
    const [_skin, setSkin] = useState(skin);
    const [_base, setBase] = useState(base);
    const [_head, setHead] = useState(head);
    const [_eyebrow, setEyebrow] = useState(eyebrow);
    const [_eyes, setEyes] = useState(eyes);
    const [_mouth, setMouth] = useState(mouth);
    const [_skinColor, setSkinColor] = useState(skinColor);
    const [_hairColor, setHairColor] = useState(hairColor);

    const [_gender, setGender] = useState(gender);
    const [_upperBody, setUpperBody] = useState(upperBody);
    const [_feet, setFeet] = useState(feet);
    const [_lowerBody, setLowerBody] = useState(lowerBody);
    const [_hair, setHair] = useState(hair);

    const [_hat, setHat] = useState(hat);
    const [_helmet, setHelmet] = useState(helmet);
    const [_topHead, setTopHead] = useState(topHead);
    const [_mask, setMask] = useState(mask);
    const [_tiara, setTiara] = useState(tiara);
    const [_earing, setEaring] = useState(earing);
    const [_facialHair, setFacialHair] = useState(facialHair);

    let teleportDistance = 20;
    let radius = 0.3;

    let startPosition = new THREE.Vector3();
    let currentPosition = new THREE.Vector3();
    let targetPosition = new THREE.Vector3();
    let diffPosition = new THREE.Vector3();
    let currentDiffPosition = new THREE.Vector3();

    let targetEuler = new Euler();
    let targetQuaternion = new Quaternion();

    let targetRotationEuler = new THREE.Euler();
    let targetRotation = useRef(new THREE.Quaternion(0, 0, 0, 1));

    let maxTargetArrayStack = 10;
    let currentDuration = useRef(0);
    let playerSpeed = 6;
    let playerAirSpeed = 4;
    let playerRotationSpeed = 10;
    let progress = 0;
    let timer = useRef(0);
    let isUpdated = false;
    let refPlayer = useRef();

    const [isShowModel, setShowModel] = useState(showModel);

    setMessage = setPlayerMessage;

    useEffect(() => {

        // const hoverBoardObject = fbxLoaded.find(x => x.name === "hoverboard_board.fbx").clone();
        // let material = null;

        // let geometry = null;
        // hoverBoardObject.traverse((children => {
        //     if (children.isMesh) {
        //         geometry = children.geometry;
        //         material = children.material;
        //     }
        // }));

        // material.transparent = true;

        const hoverBoardObject = fbxLoaded.find(x => x.name === "hoverboard_board.fbx").clone();

        let geometry = null;
        let material = null;
        hoverBoardObject.traverse((children => {
            if (children.isMesh) {
                geometry = children.geometry;
                material = children.material;
            }
        }));

        material.transparent = true;
        setHoverBoardMaterial(material);
        setHoverBoardGeometry(geometry);
    }, []);

    useEffect(()=>{
        setShowModel(showModel);
        if(refPlayer.current) refPlayer.current.visible = showModel;
        
    },[showModel, refPlayer.current]);

    useEffect(() => {
        const positionPlayerSub = GamePlayerContext.subscribe(state => state.positionPlayer[playerId],
            (data) => {
                if (data) {
                    // console.log(data, "POSITION PLAYER SB")

                    // console.log(data.u);
                    if (data.c !== undefined) {
                        if (setMessage !== undefined) {
                            setMessage.current(data.c);
                        }
                    }
                    else {

                        if (targetPositionsRotation.current.length > maxTargetArrayStack) targetPositionsRotation.current = [];

                        if (data.p && data.p.length > 0) {
                            targetPositionsRotation.current.push({
                                position: [data.p[0], data.p[1], data.p[2]],
                                rotation: [data.p[3], data.p[4], data.p[5], data.p[6]],
                                animation: data.p[7],
                                duration: data.p[9],
                                grounded: data.p[8]
                            });
                        }
                    }

                    if (
                        _hair !== data.u.h ||
                        _feet !== data.u.d ||
                        _upperBody !== data.u.b ||
                        _lowerBody !== data.u.l ||
                        _gender !== data.u.g ||

                        data.u.s !== _skin ||
                        data.u.bs !== _base ||
                        data.u.hd !== _head ||
                        data.u.eb !== _eyebrow ||
                        data.u.e !== _eyes ||
                        data.u.m !== _mouth ||
                        data.u.skc !== _skinColor ||
                        data.u.hc !== _hairColor ||

                        data.u.ht != _hat ||
                        data.u.hl != _helmet ||
                        data.u.th != _topHead ||
                        data.u.mk != _mask ||
                        data.u.tr != _tiara ||
                        data.u.er != _earing ||
                        data.u.fh != _facialHair ||
                        data.u.n != _name
                    ) {
                        // console.log(data.u);
                        // console.log(data);w
                        setHair(data.u.h);
                        setFeet(data.u.f);
                        setUpperBody(data.u.b);
                        setLowerBody(data.u.l);
                        setGender(data.u.g);

                        setSkin(data.u.s);
                        setBase(data.u.bs);
                        setHead(data.u.hd);
                        setEyebrow(data.u.eb);
                        setEyes(data.u.e);
                        setMouth(data.u.m);
                        setSkinColor(data.u.skc);
                        setHairColor(data.u.hc);

                        setHat(data.u.ht);
                        setHelmet(data.u.hl);
                        setTopHead(data.u.th);
                        setMask(data.u.mk);
                        setTiara(data.u.tr);
                        setEaring(data.u.er);
                        setFacialHair(data.u.fh);

                        setName(data.u.a ? data.u.a : data.u.n);
                    }
                }
            }
        )

        return () => {
            positionPlayerSub();
        }
    }, []);

    useFrame((state, delta) => {

        if (!refPlayerModel.current) return;

        if (currentTargetPositionsRotation.current == null && targetPositionsRotation.current.length > 0) {

            let targetData = targetPositionsRotation.current.shift();


            if (lastTargetPositionsRotation.current == null) lastTargetPositionsRotation.current = targetData;

            currentTargetPositionsRotation.current = targetData;

            startPosition.set(lastTargetPositionsRotation.current.position[0], lastTargetPositionsRotation.current.position[1], lastTargetPositionsRotation.current.position[2]);
            targetPosition.set(currentTargetPositionsRotation.current.position[0], currentTargetPositionsRotation.current.position[1], currentTargetPositionsRotation.current.position[2]);
            diffPosition.subVectors(targetPosition, startPosition);
            let distance = startPosition.distanceTo(targetPosition);

            targetRotationEuler.set(targetData.rotation[0], targetData.rotation[1], targetData.rotation[2], 'XYZ');
            targetRotation.current.setFromEuler(targetRotationEuler);

            currentDuration.current = distance / (targetData.grounded ? playerSpeed : playerAirSpeed);

            if (distance > teleportDistance || targetData.animation === "sit") {
                refPlayerBase.current.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                refPlayerModel.current.rotation.set(targetRotationEuler.x, targetRotationEuler.y, targetRotationEuler.z, 'XYZ');
                currentTargetPositionsRotation.current = null;

                if (targetData.animation === "sit" && refPlayerModel.current) refPlayerModel.current.setAnimation("sit");
            }

            timer.current = 0;

        }
        else if (currentTargetPositionsRotation.current != null) {

            hoverboard.current.visible = currentTargetPositionsRotation.current.animation === "hoverboard";

            if (currentDuration.current > 0) {
                progress = timer.current / currentDuration.current;

                startPosition.set(lastTargetPositionsRotation.current.position[0], lastTargetPositionsRotation.current.position[1], lastTargetPositionsRotation.current.position[2]);
                targetPosition.set(currentTargetPositionsRotation.current.position[0], currentTargetPositionsRotation.current.position[1], currentTargetPositionsRotation.current.position[2]);

                let angle = Math.atan2((targetPosition.x - startPosition.x), (targetPosition.z - startPosition.z));
                targetEuler.set(0, angle, 0);
                targetQuaternion.setFromEuler(targetEuler);

                if (refPlayerModel.current)
                    refPlayerModel.current.quaternion.slerp(targetQuaternion, playerRotationSpeed * delta);

                currentDiffPosition.subVectors(targetPosition, startPosition);
                currentDiffPosition.multiplyScalar(progress);
                currentPosition.addVectors(startPosition, currentDiffPosition);
                timer.current += delta;

                if (currentTargetPositionsRotation.current.animation != "jump") {
                    if (currentTargetPositionsRotation.current.animation != "idle") {
                        if (refPlayerModel.current && refPlayerModel.current.setAnimation != null) refPlayerModel.current.setAnimation(currentTargetPositionsRotation.current.animation);
                    }
                    // if (refPlayerModel.current && refPlayerModel.current.setAnimation != null) refPlayerModel.current.setAnimation(currentTargetPositionsRotation.current.animation);
                }
            }
            else {
                refPlayerBase.current.position.set(currentTargetPositionsRotation.current.position[0], currentTargetPositionsRotation.current.position[1], currentTargetPositionsRotation.current.position[2]);
            }

            if (timer.current < currentDuration.current) {
                refPlayerBase.current.position.set(currentPosition.x, currentPosition.y, currentPosition.z);

            }
            else {
                if (refPlayerModel.current && refPlayerModel.current.setAnimation) refPlayerModel.current.setAnimation(currentTargetPositionsRotation.current.animation);
                lastTargetPositionsRotation.current = currentTargetPositionsRotation.current;
                currentTargetPositionsRotation.current = null;

                if (refPlayerModel.current)
                    refPlayerModel.current.quaternion.slerp(targetRotation.current, playerRotationSpeed * delta);
            }
        }
        else {
            if (refPlayerModel.current && refPlayerModel.current.currentAnimation == "run" || refPlayerModel.current.currentAnimation == "jumping") {
                refPlayerModel.current.setAnimation("idle");
            }
        }

        hoverboard.current.quaternion.slerp(targetRotation.current, playerRotationSpeed * delta);

    })


    // console.log("CONCURRENT REMOTE PLAYER")
    return (
        <group ref={refPlayerBase}>
            <Suspense fallback={null}>
                <PlayerModelAndAnimation
                    playerModel={refPlayerModel}
                    refPlayer={refPlayer}
                    playerAction={animationClips}
                    playerId={playerId}
                    skin={_skin}
                    base={_base}
                    skinColor={_skinColor}
                    hairColor={_hairColor}
                    head={_gender}
                    eyebrow={_eyebrow}
                    eyes={_eyes}
                    mouth={_mouth}
                    gender={_gender}
                    upperBody={_upperBody}
                    feet={_feet}
                    lowerBody={_lowerBody}
                    hair={_hair}

                    hat={_hat}
                    helmet={_helmet}
                    topHead={_topHead}
                    mask={_mask}
                    tiara={_tiara}
                    earing={_earing}
                    facialHair={_facialHair}

                    colliderRadius={radius}
                    onClicked={onRemoteClicked}

                    assetRef={assetRef}

                    showModel = {isShowModel}
                >

                    <PlayerName
                        playerModel={refPlayerModel}
                        name={_name}
                        opacity={1}
                        position={[0, 1.37, 0]}
                        id={playerId}
                    >
                        <PlayerShoutout
                            playerModel={refPlayerModel}
                            position={[0, 1.8, 0]}
                            duration={5}
                            setMessage={setMessage}
                        />
                    </PlayerName>
                    <PlayerSpeaker
                        name={`speaker-${playerId}`}
                        opacity={1}
                        position={[0, 13, 0]}
                    />
                    <PlayerAudioPosition
                        name={playerId}
                    />
                </PlayerModelAndAnimation>
                {/* <PlayerAura
                    playerModelRef={refPlayerModel}
                    colliderRadius={radius}
                    gender={_gender}
                    animationClip={animationClip} 
                    /> */}
            </Suspense>
            <mesh
                ref={hoverboard}
                scale={[0.0035, 0.0035, 0.0035]}
                position={[0, -radius * 2 + 0.05, 0]}
                geometry={hoverBoardGeometry}
                material={hoverBoardMaterial}
                visible={false}
            />
        </group>
    );
}
