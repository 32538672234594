import { useEffect } from "react";
import { useState } from "react"
import { AudioContext } from "../../context/game-context";
import '../../../css/conversation-space-ui.css';

export const ConversationSpaceUI = () =>{

    const [conversationSpace, setConversationSpace] = useState(AudioContext(state=>state.conversationSpace));

    useEffect(()=>{
        let conversationSpaceSubs = AudioContext.subscribe(state=>state.conversationSpace, (data)=>{
            setConversationSpace(data);
        });

        return()=>{
            conversationSpaceSubs();
        }
    },[]);

    return(conversationSpace && conversationSpace.length > 0 && <div className="conversation-space">{conversationSpace}</div>);
}