import { useEffect, useRef, useState } from "react";
import { GamePlayerContext, SceneContext } from "../../context/game-context";
import "../../../css/drop-npc-popup.css";

export const DropNPCPopup = () => {

    const [dropItem, setDropItem] = useState(SceneContext((state) => state.droppedNPC));
    const [currentDate, setCurrentDate] = useState(Math.floor(Date.now() / 1000));
    const interval = useRef();

    const fancyTimeFormat = (duration)=>
    {
        // Hours, minutes and seconds
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        var ret = "";

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }


    useEffect(() => {
        let dropItemSubs = SceneContext.subscribe((state) => state.droppedNPC, (data) => {
            setDropItem(data);
        });

        setInterval(() => {
            setCurrentDate(Math.floor(Date.now() / 1000))
        }, 1000);

        return () => {
            dropItemSubs();
        };
    });

    return (dropItem && parseInt(dropItem.end) - currentDate > 0 &&
        <div className="drop-npc-item">
            <div className="drop-npc-item-title">{dropItem.message}</div>
        </div>);
}