import axios from "axios";

export async function syncActivity(publicKey, activity) {
    let nonce = true;
    try {
        await axios.post(`${process.env.API_URL}/sync_activity`, activity, {
            crossDomain: true
        })
            .then(res => {
                nonce = true;
            })
            .catch(err => {
                nonce = false;
            })
            .finally(() => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return nonce
}
