import { useEffect, useReducer, useRef, useState } from "react"
import { SceneContext } from "../../context/game-context";
import '../../../css/change-scene-counter.css';

export const ChangeSceneCounter = () => {

    const setScene = SceneContext(state => state.setScene);
    const setChangeSceneCounterFunc = SceneContext(state => state.setChangeSceneCounter);
    const [changeSceneCounter, setChangeSceneCounter] = useState(SceneContext(state => state.changeSceneCounter));
    const [changeSceneName, setChangeSceneName] = useState(SceneContext(state => state.changeSceneName));
    const [counter, setCounter] = useState(5);
    const targetScene = useRef();
    const currentCounter = useRef();

    const countDown = () => {
        setTimeout(() => {
            
            if(targetScene.current == null) return;

            let c = currentCounter.current - 1;

            if (c > 0) {
                currentCounter.current = c;
                setCounter(c);
                countDown();
            }
            else {
                window.history.replaceState(null, null, "/"+ targetScene.current)
                setScene(targetScene.current);
                setChangeSceneCounterFunc(null);
                setChangeSceneCounter(null);
            }

        }, 1000);
    }

    useEffect(() => {

        let changeSceneSubs = SceneContext.subscribe(state => state.changeSceneCounter, (data) => {
            setChangeSceneCounter(data);
            setCounter(5);
            currentCounter.current = 5;
            targetScene.current = data;
            countDown();
            
        });

        let changeSceneNameSubs = SceneContext.subscribe(state=>state.changeSceneName, (data)=>{
            setChangeSceneName(data);
        });

        return () => {
            changeSceneSubs();
            changeSceneNameSubs();
        }
    }, []);

    return (changeSceneCounter &&
        <div className="change-scene-counter">
            <div className="change-scene-counter-border-left"></div>
            You will go to <b>{changeSceneName}</b> in {counter}
            <div className="change-scene-counter-border-right"></div>
        </div>
    );
}