import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useReducer, useRef } from "react";
import { Col } from "react-bootstrap";
import "../../../css/audio.css";

export const Audio = ({ src }) => {

    const [isPlaying, setPlay] = useState(false);
    const [time, setTime] = useState(0);
    const [timeIsModified, setTimeIsModified] = useState(false);
    const audioRef = useRef();
    const sliderRef = useRef();

    const getTime = (t) => {
        var sec_num = parseInt(t, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return minutes + ':' + seconds;
    }

    useEffect(() => {
        setTime(0);
        setTimeIsModified(false);
        sliderRef.current.value = time;
    }, [src]);

    return (
        <div className="row player">
            <audio ref={audioRef} loop src={src} onTimeUpdate={(e) => {
                if (timeIsModified) return;
                let t = e.target.currentTime / e.target.duration * 100;
                setTime(e.target.currentTime);
                sliderRef.current.value = t;
            }} />
            <Col xs={2}>
                <button className="button" onClick={() => {
                    if (!isPlaying) audioRef.current.play();
                    else audioRef.current.pause();
                    setPlay(!isPlaying);
                }}>
                    {!isPlaying && <FontAwesomeIcon icon={"fa-play"} />}
                    {isPlaying && <FontAwesomeIcon icon={"fa-pause"} />}
                </button>

            </Col>


            <Col xs={7} className={'no-padding'} style={{ position: 'relative' }}>
                <input type="range" min="1" max="100" className="slider" step="0.01" ref={sliderRef}
                    onMouseDown={(e) => {
                        setTimeIsModified(true);
                    }}
                    onMouseUp={(e) => {
                        setTimeIsModified(false);
                        let t = audioRef.current.duration * (e.target.value / 100);
                        audioRef.current.currentTime = t;
                    }}>
                </input>
            </Col>
            <Col xs={3}>
                <div className="timeText">
                    {
                        getTime(time)
                    }
                </div>
            </Col>
        </div>
    );
}