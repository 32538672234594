import axios from "axios";
import { NotificationContext, TreasureHuntContext } from "../game/context/game-context";

export async function getEvent(eventCode, idUser) {
    try {
        return await axios.post(`${process.env.TREASURE_API_URL}/event/${eventCode}`, { idUser }, { crossDomain: true })
            .then(res => {
                return res.data;
            })
            .catch(err => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return null
}

export async function claimEvent(eventCode, eventType, key, idUser, additionalData, toast) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/complete_activity`, { event: eventCode, type: eventType, key, idUser, additionalData }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                // toast({ title: "Claim Failed", message: err.response.data.message, type: "danger" });
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}


export async function claimVote(eventCode, eventType, key, voteKey, qty, idUser, toast) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/vote`, { event: eventCode, type: eventType, key, voteKey, qty, idUser }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                toast({ title: "Voting Failed", message: err.response.data.message, type: "danger" });
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}

export async function buyVote(eventCode, eventType, qty, idUser, toast) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/buy_vote`, { event: eventCode, type: eventType, qty, idUser }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                // toast({ title: "Buy Vote Point Failed", message: err.response.data.message, type: "danger" });
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}

export async function getPrize(eventCode) {
    try {
        return await axios.get(`${process.env.TREASURE_API_URL}/prize/${eventCode}`, { crossDomain: true })
            .then(res => {
                return res.data;
            })
            .catch(err => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return null
}


export async function redeem(eventCode, prizeCode, idUser, toast) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/redeem`, { event: eventCode, prizeCode, idUser }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                response = {
                    error: true,
                    message: err.response.data.message
                }
                // toast({ title: "Redeem Failed", message: err.response.data.message, type: "danger" });
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}


export async function boostVotePriceList(eventCode, prizeCode, idUser, toast) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/boost_vote`, { event: eventCode, prizeCode, idUser }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {

                toast({ title: "Redeem Failed", message: err.response.data.message, type: "danger" });
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}


export async function getListBoostVote(eventCode) {
    let response = null;
    try {
        await axios.get(`${process.env.TREASURE_API_URL}/boost_vote/list/${eventCode}`, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                response = err.response.data;
                // toast({ title: "Redeem Failed", message: err.response.data.message, type: "danger" });
                // console.log("ERROR COMPLETE ACTIVITY", err.response);
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}

export async function initiateBoostVotePayment(data) {
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/boost_vote/request_payment`, data, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                response = err.response.data;
                // toast({ title: "Redeem Failed", message: err.response.data.message, type: "danger" });
                // console.log("ERROR COMPLETE ACTIVITY", err.response);
            })
    } catch (err) {
        throw new Error(err);
    }

    return response;
}



export async function boostVotePaymentCheck(orderId) {
    // console.log(`${process.env.TREASURE_API_URL}/boost_vote_payment_check`, prizeCode);
    let response = null;
    try {
        await axios.post(`${process.env.TREASURE_API_URL}/boost_vote/check_payment`, { orderId }, { crossDomain: true })
            .then(res => {
                // nonce = res.data.nonce;
                response = res.data;
            })
            .catch(err => {
                response = err.response.data
                // toast({ title: "Redeem Failed", message: err.response.data.message, type: "danger" });
                // console.log("ERROR COMPLETE ACTIVITY", err.response);
            })
    } catch (err) {
        throw new Error(err);
    }
    // for dev purpose
    // if (status == 'pending') {
    //     response = {
    //         response_code: 1,
    //         payment_type: "telkomsel",
    //         transaction_id: "0aacb540b8d642728602f09a756a2fd0",
    //         order_id: "112233",
    //         transaction_status: "pending",
    //         reward_point: 150
    //     };
    // } else if(status == 'success') {
    //     response = {
    //         response_code: 1,
    //         payment_type: "telkomsel",
    //         transaction_id: "0aacb540b8d642728602f09a756a2fd0",
    //         order_id: "112233",
    //         transaction_status: "success",
    //         reward_point: 150
    //     };
    // } else if(status == 'failed') {
    //     response = {
    //         response_code: 1,
    //         payment_type: "telkomsel",
    //         transaction_id: "0aacb540b8d642728602f09a756a2fd0",
    //         order_id: "112233",
    //         transaction_status: "failed",
    //         reward_point: 150
    //     };
    // }
    return response;
}


export async function getAchievement(eventCode, idUser) {
    try {
        return await axios.post(`${process.env.TREASURE_API_URL}/get_achievement`, { idUser, event: eventCode }, { crossDomain: true })
            .then(res => {
                return res.data;
            })
            .catch(err => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return null
}



export async function getScheduleList(eventCode, idUser) {
    // try {
    //     return await axios.post(`${process.env.TREASURE_API_URL}/get_achievement`, { idUser, event: eventCode }, { headers: { "Access-Control-Allow-Origin": "*" } })
    //         .then(res => {
    //             return res.data;
    //         })
    //         .catch(err => {
    //             console.log("ERROR REQUEST NONCE", err);
    //         })
    // } catch (err) {
    //     console.log(err);
    //     throw new Error(err);
    // }
    // return null
}
