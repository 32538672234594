import { useState, useRef, useEffect } from 'react';
import { Button, Offcanvas, Form } from "react-bootstrap";
import { GamePlayerContext, JoystickContext, RtcLocalContext } from '../../context/game-context';

export default function JoyStick({ name, ...props }) {

    let [isHoverBoard, setIsHoverboard] = useState(GamePlayerContext((state) => state.isHoverboard));
    let twoTouch = false;
    const joystickCoverRef = useRef();
    const pointerRef = useRef();
    const basePointerRef = useRef();
    const threshold = 10;
    const defaultHide = false;
    let x = joystickCoverRef.current ? joystickCoverRef.current.offsetLeft : 0;
    let y = joystickCoverRef.current ? joystickCoverRef.current.offsetTop : 0;
    let joystickWidth = joystickCoverRef.current ? joystickCoverRef.current.offsetWidth / 2 : 0;
    let joystickHeight = joystickCoverRef.current ? joystickCoverRef.current.offsetHeight / 2 : 0;
    const pointerHeight = pointerRef.current ? (pointerRef.current.offsetHeight) / 3 : 0;
    const isJump = false;
    let firstX = x;
    let firstY = y;
    let firstPosition = useRef({
        x: firstX + joystickWidth,
        y: firstY + joystickHeight
    });
    let center = useRef({
        x: firstX + joystickWidth,
        y: firstY + joystickHeight
    });
    let centerPointer = useRef({
        x: joystickWidth,
        y: joystickHeight
    });
    // console.log(x, y, center, firstPosition, joystickCoverRef, "JOYSTICK OFFSET")

    useEffect(() => {
        let isHoverBoardSubs = GamePlayerContext.subscribe((state) => state.isHoverboard, (data) => {
            setIsHoverboard(data);
        });

        return () => {
            isHoverBoardSubs();
        }
    }, []);

    const setScreenOrientation = () => {

        x = joystickCoverRef.current ? joystickCoverRef.current.offsetLeft : 0;
        y = joystickCoverRef.current ? joystickCoverRef.current.offsetTop : 0;
        joystickWidth = joystickCoverRef.current ? joystickCoverRef.current.offsetWidth / 2 : 0;
        joystickHeight = joystickCoverRef.current ? joystickCoverRef.current.offsetHeight / 2 : 0;
        firstX = x;
        firstY = y;

        firstPosition.current = {
            x: firstX + joystickWidth,
            y: firstY + joystickHeight
        };
        centerPointer.current = {
            x: joystickWidth,
            y: joystickHeight
        }
        center.current = firstPosition.current;
    }

    useEffect(() => {
        setScreenOrientation();
        window.addEventListener("resize", setScreenOrientation);

        // console.log(x, y, center, firstPosition, joystickCoverRef, joystickWidth, "JOYSTICK OFFSET INITIALIZED")
    }, [])

    const setJoystick = JoystickContext((state) => state.setPosition);
    const setJump = JoystickContext((state) => state.setJump);
    const setDown = JoystickContext((state) => state.setDown);

    const distance = (point1, point2) => {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    const clampMagnitude = (vector, radius) => {
        let length = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
        let multiplier = Math.min(length, radius) / length;
        vector.x = vector.x * multiplier;
        vector.y = vector.y * multiplier;
        return vector;
    }


    const showBasePointer = (x, y) => {

        if (defaultHide === true) {
            basePointerRef.current.show();
            basePointerRef.current.css("top", `${y}px`);
            basePointerRef.current.css("left", `${x}px`);
        }
    }

    const hideBasePointer = () => {
        if (defaultHide === true) {
            basePointerRef.current.hide();
            pointerRef.current.hide();
        }
        else {
            // basePointerRef.current.style.left = `${firstX}px`;
            // basePointerRef.current.style.top = `${firstY}px`;
            pointerRef.current.style.left = `${joystickWidth}px`;
            pointerRef.current.style.top = `${joystickHeight}px`;
        }
    }

    const processTouch = (pointerPosition) => {
        if (distance(center.current, pointerPosition) > threshold) {
            let position = {
                x: (pointerPosition.x - firstPosition.current.x),
                y: (pointerPosition.y - firstPosition.current.y)
            }

            clampMagnitude(position, 50);

            let currentPointerPosition = {
                x: Math.round(centerPointer.current.x + position.x),
                y: Math.round(centerPointer.current.y + position.y)
            }

            // console.log(center, currentPointerPosition, "JOYSTICK PROCESS TOUCH");
            // console.log(currentPointerPosition);
            // console.log(joystickCoverRef, basePointerRef, pointerRef, pointerRef.current.style);
            // pointerRef.current.show();
            pointerRef.current.style.top = `${currentPointerPosition.y}px`;
            pointerRef.current.style.left = `${currentPointerPosition.x}px`;

            let direction = {
                x: position.x / 50,
                y: -position.y / 50
            }
            setJoystick(direction);
            // execute("move", direction);
        }
    }

    const onTouchMove = (ev) => {
        if (firstPosition.current === undefined) return;
        // console.log(ev.touches);

        let touchID = -1;
        for (let i = 0; i < ev.touches.length; i++) {
            const touch = ev.touches[i];
            if (touch.target == joystickCoverRef.current) {
                touchID = i;
                break;
            }
        }

        if (touchID != -1) {

            let pointerPosition = {
                x: ev.touches[touchID].clientX,
                y: ev.touches[touchID].clientY,
            }

            processTouch(pointerPosition);
        }
        else {
            hideBasePointer();
            twoTouch = true;
            // console.log("pinch");

        }

    }

    const onTouchStart = (event) => {

        let touchID = -1;
        for (let i = 0; i < event.touches.length; i++) {
            const touch = event.touches[i];
            if (touch.target == joystickCoverRef.current) {
                touchID = i;
                break;
            }
        }


        if (touchID != -1) {
            if (defaultHide === true) {
                firstPosition.current = {
                    x: event.touches[touchID].clientX,
                    y: event.touches[touchID].clientY,
                }
            }
            else {
                // console.log(event);
                // firstPosition.current = {
                //     x: center.current.x,
                //     y: center.current.y,
                // }
                
                firstPosition.current = {
                    x: event.touches[touchID].clientX,
                    y: event.touches[touchID].clientY,
                }
            }

            showBasePointer(event.touches[touchID].clientX, event.touches[touchID].clientY);
        }
        else {
            hideBasePointer();

            twoTouch = true;
        }
        
        console.log(event.touches[touchID].clientX);
    }

    const onTouchEnd = (event) => {
        firstPosition.current = undefined;
        x = centerPointer.current.x;
        y = centerPointer.current.y;
        // console.log(center, joystickWidth, "END");

        // execute("end");
        hideBasePointer();
        setJoystick(null);

        if (event.touches.length === 0) twoTouch = false;
    }

    const onTouchStartJump = (event) => {
        setJump(true);
    }


    const onTouchEndJump = (event) => {
        setJump(null);
    }


    const onTouchStartDown = (event) => {
        setDown(true);
    }


    const onTouchEndDown = (event) => {
        setDown(null);
    }
    return (
        <>
            <div id='joystick-new' 
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                <div id='joystick-new-cover' ref={joystickCoverRef}>
                    <div id="joystick-new-base" ref={basePointerRef}>
                        <div id="joystick-new-pointer" ref={pointerRef}></div>
                    </div>
                </div>
            </div>
            {!isHoverBoard && <div id="joystick-new-jump-button-default" onTouchStart={onTouchStartJump} onTouchEnd={onTouchEndJump}>
                <svg
                    data-src="./icon/btn-jump.svg"
                    fill="currentColor"
                    className="w-100 full-height"
                />
            </div>}
            {isHoverBoard && <div id="joystick-new-jump-button" onTouchStart={onTouchStartJump} onTouchEnd={onTouchEndJump}>
                <svg
                    data-src="./icon/btn-jump.svg"
                    fill="currentColor"
                    className="w-100 full-height"
                />
            </div>}
            {isHoverBoard && <div id="joystick-new-down-button" onTouchStart={onTouchStartDown} onTouchEnd={onTouchEndDown}>
                <svg
                    data-src="./icon/btn-jump.svg"
                    fill="currentColor"
                    className="w-100 full-height"
                />
            </div>}
        </>
    );
}
