import { useFrame, useThree } from "@react-three/fiber";
import { useMemo, useRef, forwardRef, useState, useEffect } from "react"
import * as THREE from "three";
import { GamePlayerContext } from "../../context/game-context";
import { Vector3 } from "three";

export const PlayerSpeaker = forwardRef(({ name, position, scale , playerModel, isFPS}, ref) => {

  const playerNameRef = useRef();
  const [visible, setVisible] = useState(false);

  const canvasMaterial = useMemo(() => {
    var fontSize = 45;
    var borderThickness = 4

    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')
    context.canvas.width = 300;
    context.canvas.height = 50;

    context.textBaseline = 'middle'
    context.font = `bold ${fontSize}px -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif`

    const text = `(SOUND ON)`;
    var metrics = context.measureText(text);
    // console.log("CONTEXT", context.canvas.width, context.canvas.height);


    while (metrics.width > context.canvas.width) {
      fontSize -= .5;
      context.font = `bold ${fontSize}px -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif`
      metrics = context.measureText(text);
    }

    // context.fillStyle = "white";
    // context.fillRect(0, 0, canvas.width, canvas.height);

    context.lineWidth = borderThickness;
    context.fillStyle = "black";
    context.fillText(text, (context.canvas.width - metrics.width) / 2, (context.canvas.height / 2))

    var canvasTexture = new THREE.CanvasTexture(
      canvas,
      THREE.UVMapping,
      THREE.ClampToEdgeWrapping,
      THREE.ClampToEdgeWrapping,
      THREE.LinearFilter,
      THREE.LinearFilter,
      THREE.RGBAFormat,
      THREE.UnsignedByteType,
      1);
    const material = new THREE.MeshBasicMaterial({ map: canvasTexture, side: THREE.DoubleSide, transparent: true });

    // console.log(name, "LISTEN SHOW HIDE SPEAKER")

    return material;
  }, [])

  useEffect(() => {

    const speakerPlayerSub = GamePlayerContext.subscribe(state => state.speakerPlayer[name],
      (data) => {
        // console.log(data, "LISTEN SHOW HIDE SPEAKER")
        setVisible(data);
      })

    return () => {
      speakerPlayerSub();
    }

  }, [])

  let scaleMultiplier = 1;

  const { camera } = useThree();

  useFrame(() => {
    playerNameRef.current.lookAt(camera.position.x, camera.position.y, camera.position.z);

    if (playerModel && playerModel.current && playerModel.current.topHead) {
      let worldPosition = new Vector3(0, 0, 0);
      // playerModel.current.topHead.getWorldPosition(worldPosition);
      // playerModel.current.parent.worldToLocal(worldPosition);

      playerNameRef.current.position.set(worldPosition.x, worldPosition.y + 1.5, worldPosition.z);
    }

    if(playerNameRef && playerNameRef.current && isFPS) playerNameRef.current.visible = !isFPS.current;
  });

  return (
    <group ref={ref} name={name} visible={visible}>
      <mesh ref={playerNameRef} scale={[0.4 * scaleMultiplier, 0.07 * scaleMultiplier, 0.2 * scaleMultiplier]} position={position} material={canvasMaterial}>
        <planeGeometry width={300} height={50} >
        </planeGeometry>
      </mesh>
    </group>
    // <sprite scale={[0.4 * scaleMultiplier, 0.07 * scaleMultiplier, 0.2 * scaleMultiplier]} position={position}>
    //   <spriteMaterial sizeAttenuation={false} attach="material" transparent alphaTest={0.5}>
    //     <canvasTexture attach="map" image={canvas} />
    //   </spriteMaterial>
    // </sprite>
  )
})
