import { useTrimesh } from "@react-three/cannon";
import { useEffect } from "react";
import { AudioContext } from "../../context/game-context";

export const ConversationSpace = ({indices, vertics, position, theme})=>{

    const setConversationSpace = AudioContext(state=>state.setConversationSpace);

    useEffect(()=>{
        
    },[]);
    const [popupRef, popupApi] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        isTrigger: true,
        args: [vertics, indices],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        onCollide: (e) => {
            setConversationSpace(theme, false);
        },
        onCollideEnd: (e) => {
            setConversationSpace(theme, true);
        }
    }));


    return <group ref={popupRef} ></group>
}