import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';

export default function WelcomePageUT({ handleSkip, handleContinue }) {
    // console.log(`/img/logo/${minimap.sceneName}/logo.png`);
    return (<>
        <Container>
            <Modal show={true} className="welcome-page-ut" centered>
                <Modal.Body className="no-padding">
                    <div className={"image-background"} style={{ backgroundImage: "url('/img/loading/ut/bg.jpg')" }}>
                        <div className='logo-section'>
                            <Image src="/img/logo/ut/logo.png" className={"logo"} />
                        </div>
                    </div>
                    <div className='text-section'>
                        <h5>Selamat datang di UNITED TRACTORS</h5>

                        <p>
                            Ayo jelajahi virtual museum dengan
                            teknologi. <br /> Kenali dari dekat
                            dan pelajari sejarahnya.
                        </p>
                        <br />
                        <Row>
                            <Col className='text-center'>
                                <Button variant="default-dark" className="w-100" style={{ maxWidth: '180px' }}
                                    onClick={e => handleContinue()}
                                >
                                    Lanjut
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center' style={{ marginTop: '10px' }}>
                                <Button variant="default-dark-outline" className="w-100" style={{ maxWidth: '180px' }}
                                    onClick={e => handleSkip()}
                                >
                                    Skip
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
            </Modal>
        </Container>
    </>
    )
}
