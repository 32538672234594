import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GameConfigContext } from '../../context/game-context';
import WelcomePageDG from './welcome-page/welcome-page-dg';
import WelcomePageUT from './welcome-page/welcome-page-ut';

export default function Tutorial({ isMobile, showTutorial, setShowTutorial, sceneID }) {

    const [currentStep, setCurrentStep] = useState("tutorial");
    const [showWelcomePage, setShowWelcomePage] = useState(true);
    const [welcomePageComponent, setWelcomePageComponent] = useState(null);
    const [minimapLogo, setMinimapLogo] = useState(GameConfigContext(state => state.minimapLogo));
    const specialIcon = [];

    switch (sceneID.current) {
        case 'ut':
            break;
        case 'dgplaza':
        case 'dgaward':
        case 'dgcon':
        case 'dgmainstage':
        case 'dgmall':
        case 'dgexhibition':
            specialIcon.push('redeem');
            specialIcon.push('quest');
            break;
        default:
            break;

    }
    useEffect(() => {
        let stepDefault = "tutorial";
        let setupWelcomePageComponent = null;
        const minimapLogoSubs = GameConfigContext.subscribe(state => state.minimapLogo, (data) => {
            setMinimapLogo(data);
        })
        // if default true , show welcome page , if welcome page has been shown , dont show again 
        if (showTutorial && showWelcomePage === true) {
            switch (sceneID.current) {
                case 'ut':
                    // case 'utpast':
                    // case 'uthd':
                    // case 'utbomag':
                    // case 'utbus':
                    // case 'utpc':
                    // case 'utscania':
                    // case 'uttadano':
                    // case 'utud':
                    // case 'utfuture':
                    // case 'utpresent':
                    stepDefault = "welcome-page";
                    setupWelcomePageComponent = 'welcome-page-ut';
                    break;

                case 'dgplaza':
                    stepDefault = "welcome-page";
                    setupWelcomePageComponent = 'welcome-page-dg';
                    break;
                default:
                    stepDefault = "tutorial";
                    break;

            }
        }

        setWelcomePageComponent(setupWelcomePageComponent);
        setCurrentStep(stepDefault);

        return () => {
            minimapLogoSubs();
        }
    }, [])

    const handleSkip = () => {
        setCurrentStep("tutorial");
        setShowTutorial(false)
        setShowWelcomePage(false);
    }
    const handleContinue = () => {
        setCurrentStep("tutorial");
        setShowWelcomePage(false);
    }

    return (
        <Container>
            {welcomePageComponent == 'welcome-page-ut' && currentStep == "welcome-page" && showWelcomePage &&

                <WelcomePageUT sceneID={sceneID} handleSkip={handleSkip} handleContinue={handleContinue} />
            }
            {welcomePageComponent == 'welcome-page-dg' && currentStep == "welcome-page" && showWelcomePage &&

                <WelcomePageDG sceneID={sceneID} handleSkip={handleSkip} handleContinue={handleContinue} />
            }
            {currentStep == "tutorial" && <>
                <Modal size="lg" show={showTutorial}
                    contentClassName='modal-transparent black-body'
                    className="tutorial" onExited={() => setCurrentStep("tutorial")} centered>
                    <Modal.Body className="text-center modal-body-tutorial">

                        {!isMobile ?
                            <>
                                <Row>
                                    <h4 align='center'>Controls</h4>
                                </Row>
                                <Row>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Lock Camera</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-c-notext.png" className={"full-width"} />
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Player List</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-playerlist.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>



                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Explore World</Col>
                                            <Col md={6}>
                                                {minimapLogo ?
                                                    <Image src={minimapLogo} style={{ width: '35px' }} onError={() => setImageExist(false)} />
                                                    :
                                                    <svg
                                                        data-src="./icon/btn-explore.svg"
                                                        fill="currentColor"
                                                        className='w-100 full-height'
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* <Col md={4}>
                                        <Row>
                                            <Col md={6}>Mic Enable</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-micenable.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>
                                    </Col> */}

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Animoji</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-pose.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Fly Mode</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-fly.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Take Photo</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-capture-image.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Chat</Col>
                                            <Col md={6}>
                                                <FontAwesomeIcon icon="far fa-comment" />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>

                                        <Row>
                                            <Col md={6}>Settings</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./icon/btn-settings.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>

                                    </Col>

                                   <Col md={4}>
                                        <Row>
                                            <Col md={6}>Tutorial</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./tutorial/btn-tutorial.svg"
                                                    fill="currentColor"
                                                    className="w-100 full-height"
                                                />
                                            </Col>
                                        </Row>

                                    </Col>
                                    {/* 
                                    <Col md={4}>

                                        <Row>
                                            <Col md={6}>Change Server</Col>
                                            <Col md={6}>
                                                <svg
                                                    data-src="./tutorial/btn-changeserver.svg"
                                                    fill="currentColor"
                                                    className='w-100 full-height'
                                                />
                                            </Col>
                                        </Row>

                                    </Col> */}

                                    {specialIcon.indexOf('quest') != -1 &&
                                        <Col md={4}>
                                            <Row>
                                                <Col md={6}>Quest</Col>
                                                <Col md={6}>
                                                    <svg
                                                        data-src="./icon/btn-thropy.svg"
                                                        fill="currentColor"
                                                        className='w-100 full-height'
                                                    />
                                                </Col>
                                            </Row>

                                        </Col>
                                    }

                                    {specialIcon.indexOf('redeem') != -1 &&
                                        <Col md={4}>
                                            <Row>
                                                <Col md={6}>Redeem</Col>
                                                <Col md={6}>
                                                    <svg
                                                        data-src="./icon/btn-prize.svg"
                                                        fill="currentColor"
                                                        className='w-100 full-height'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Move</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-wasd-notext.png" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Walk</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-shift-notext.png" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Jump</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-spacebar-notext.png" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Orbit Camera</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-orbit-notext.gif" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>First Person</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-first-person-dekstop-notext.gif" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Col md={6}>Interact</Col>
                                            <Col md={6}>
                                                <Image src="./tutorial/tutorial-interact-notext.gif" className={"full-width"} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <div style={{ marginBottom: '20px' }}>
                                    <Row>
                                        <Image src="./tutorial/tutorial-orbit.gif" style={{ maxWidth: '225px' }} className={"full-width mx-auto"} />
                                        <Image src="./tutorial/tutorial-joystick.gif" style={{ maxWidth: '225px' }} className={"full-width mx-auto"} />
                                        <Image src="./tutorial/tutorial-npc.gif" style={{ maxWidth: '225px' }} className={"full-width mx-auto"} />
                                        <Image src="./tutorial/tutorial-first-person-mobile.gif" className={"full-width  mx-auto"} />
                                        <Image src="./tutorial/tutorial-minimap.gif" className={"full-width  mx-auto"} />
                                    </Row>
                                </div>
                            </>
                        }
                        <Button variant='default-dark-outline' onClick={() => {
                            setShowTutorial(false);
                            const getToken = localStorage.getItem('tutorialDone')
                            // if (!getToken)
                            // localStorage.setItem('tutorialDone', true)
                        }}>Ok, Got It</Button>


                    </Modal.Body>
                </Modal>
            </>
            }
        </Container >
    )
}
