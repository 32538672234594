import * as THREE from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import React, { useContext, useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { SocketContext } from './socket-context';
import { useState } from 'react';
import { AudioContext, GamePlayerContext } from '../../../context/game-context';

export const UpdateParameter = (props) => {

    const { networkManager } = useContext(SocketContext);
    let currentUpdateTime = useRef(0);
    let currentUpdateRate = 0.18;
    let lastGroundedState = false;
    let lastUpdateTime = 0;
    let stopUpdateParameterCount = useRef(0);
    let currentRoom = useRef(GamePlayerContext((state)=>state.currentRoom));

    const lastRoom = useRef(currentRoom);
    const currentUpdatedParameter = useRef();
    const currentPosition = useRef(new THREE.Vector3(0, 0, 0));
    const { camera } = useThree();
    const conversationSpace = useRef(AudioContext(state=>state.conversationSpace));

    let forceUpdate = false;
    if (props.updatePlayer != undefined) {
        props.updatePlayer.current = () => {
            forceUpdate = true;
        }
    }

    useEffect(() => {

        const currentRoomSubs = GamePlayerContext.subscribe((state)=>state.currentRoom, (data)=>{
            currentRoom.current = data;
        });

        const unsubscribe = props.api.position.subscribe((v) => {
            currentPosition.current.set(cutDecimal(v[0], 2), cutDecimal(v[1], 2), cutDecimal(v[2], 2));


        });

        const conversationSpaceSubs = AudioContext.subscribe(state=>state.conversationSpace, (data)=>{
            conversationSpace.current = data;
        });
        return ()=>{
            unsubscribe();
            currentRoomSubs();
            conversationSpaceSubs();
        };
    }, []);

    const cutDecimal = (number, decimal) => {
        return isNaN(number) ? 0 : parseFloat(number.toFixed(decimal));
    }

    useFrame((src, delta) => {

        if (props.refPlayerModel === undefined) return;

        let forwardDirection = new Vector3(0, 0, 0);
        camera.getWorldDirection(forwardDirection);

        if (currentPosition.current.x !== 0 && currentPosition.current.y !== 0 && currentPosition.current.z !== 0)
            props.setDirectionalLightTarget(currentPosition.current, forwardDirection);

        let sendParameter = false;
        if (currentUpdateTime.current > currentUpdateRate) {
            sendParameter = true;
        }
        else {
            currentUpdateTime.current += delta;
        }

        if (forceUpdate) {
            sendParameter = true;
        }

        if (sendParameter ) {
            let updatedParameter = [cutDecimal(props.currentPosition.current[0], 3), cutDecimal(props.currentPosition.current[1], 3), cutDecimal(props.currentPosition.current[2], 3)];
            
            updatedParameter = updatedParameter.concat([cutDecimal(props.refPlayerModel.current.rotation.x, 3), cutDecimal(props.refPlayerModel.current.rotation.y, 3), cutDecimal(props.refPlayerModel.current.rotation.z, 3), 'XYZ']);
            updatedParameter.push(props.refPlayerModel.current.currentAnimation);
            updatedParameter.push(lastGroundedState);

            let keepSending = false;

            if (JSON.stringify(currentUpdatedParameter.current) !== JSON.stringify(updatedParameter)) {
                stopUpdateParameterCount.current = 3;
                keepSending = true;
            }
            else {
                
                if (stopUpdateParameterCount.current > 0) {
                    keepSending = true;
                    stopUpdateParameterCount.current--;
                }
            }

            if(lastRoom.current !== currentRoom.current){
                keepSending = true;
                lastRoom.current = currentRoom.current;
            }

            if (keepSending) {
                currentUpdatedParameter.current = [...updatedParameter];

                let duration = cutDecimal(lastUpdateTime, 3);
                updatedParameter.push(duration);
                updatedParameter.push(props.sceneName);
                updatedParameter.push(props.sit.current);
                updatedParameter.push(conversationSpace.current);

                networkManager.UpdateParameters(updatedParameter, "update_parameter-position");

                lastUpdateTime = 0;
            }


            currentUpdateTime.current = 0;
        }

        lastUpdateTime += delta;
    });

    return <></>
}