import axios from "axios";

export async function getUserProfile() {
    let result = null;
    const bearer = localStorage.getItem(`BEARER`);
    try {
        await axios.get(`${process.env.API_URL}/me`, {
            headers: {
                Authorization: `Bearer ${bearer}`
            },
            crossDomain: true
        })
            .then(res => {
                result = res.data;
            })
            .catch(err => {
                if (err.response.status == 401) {
                    unauthorizedHandler();
                }
                if (err.response.status == 500) {
                    //unauthorizedHandler();
                }
            })
            .finally(() => {
            })
    } catch (err) {
        //unauthorizedHandler();
        throw new Error(err);
    }
    return result
}


export async function updateUserProfile(payload) {
    let result = null;
    const bearer = localStorage.getItem(`BEARER`);
    try {
        await axios.post(`${process.env.API_URL}/user/update_profile`, payload, {
            headers: {
                Authorization: `Bearer ${bearer}`
            },
            crossDomain: true
        })
            .then(res => {
                result = res.data;
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //unauthorizedHandler();
                }
            })
            .finally(() => {
            })
    } catch (err) {
        throw new Error(err);
    }
    return result
}

export async function unauthorizedHandler() {
    localStorage.removeItem(`WALLET_ADDRESS_KEY`);
    localStorage.removeItem(`BEARER`);
    localStorage.removeItem(`skin_locale`);
    window.location.reload();
}