import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';
import { GamePlayerContext, NotificationContext } from '../../context/game-context';

export const AccountRegister = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [disabledButtonRegister, setDisabledButtonRegister] = useState(false);
    const [currentStep, setCurrentStep] = useState('register');
    const registerData = useRef(null);

    const validateEmail = (email) => {
        return String(email).toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    const {
        isAuthenticated,
        setupCustodialWallet,
        user,
    } = useWeb3Service();


    const addToast = NotificationContext((state) => state.addToast);

    const setLocalPlayer = GamePlayerContext((state) => state.setLocalPlayer);
    const setGamePlayerContext = GamePlayerContext((state) => state.setGamePlayerContext);

    useEffect(() => {
        if (isAuthenticated) {
            setLocalPlayer({ displayName: user.name, ...user });
            setGamePlayerContext({ isAccountBinded: user.isAccountBinded, isProfileUpdated: user.isProfileUpdated })
            // add your logic here
            // console.log(isAuthenticated, "EFFECT AUTHENTICATED CHANGED")
        } else {
            // setUserLogged(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    const register = async (e) => {
        e.preventDefault();
        const isEmailValid = validateEmail(email);
        if (!isEmailValid)
            addToast({ title: "Email Invalid", message: "Please enter a valid email", type: "danger" });

        // email success
        setDisabledButtonRegister(true);

        try {
            const register = await axios.post(`${process.env.API_URL}/auth/register_email`, { email, password }, {
                crossDomain: true
            });
            setCurrentStep('validate-otp')
            registerData.current = register.data;
            setDisabledButtonRegister(false);
        } catch (err) {

            setDisabledButtonRegister(false);
            addToast({ title: "Error", message: err.response.data.message, type: "danger" });

        }

        // if (password != confPassword)
        //     addToast({ title: "Password Mismatch", message: "Password should match with Confirmation Password", type: "danger" });

        // if (password == confPassword && isEmailValid) {
        //     setDisabledButtonRegister(true);

        //     try {
        //         const register = await axios.post(`${process.env.API_URL}/auth/register_email`, { email, password });
        //         console.log(register.data);
        //         await setupCustodialWallet(register.data);
        //     } catch (err) {
        //         console.log(err);

        //         setDisabledButtonRegister(false);
        //         addToast({ title: "Error", message: err.response.data.message, type: "danger" });

        //     }
        // }
    }

    const validateOtp = async (e) => {
        e.preventDefault();
        setDisabledButtonRegister(true);
        try {
            const register = await axios.post(`${process.env.API_URL}/auth/validate_otp`, { email, otp }, {
                crossDomain: true
            });
            await setupCustodialWallet(register.data);
        } catch (err) {

            setDisabledButtonRegister(false);
            addToast({ title: "Error", message: err.response.data.message, type: "danger" });

        }
    }
    return (
        <>
            {currentStep == 'register' && <Form onSubmit={register}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                {/* 
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Confirmation Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setConfPassword(e.target.value)} />
                </Form.Group> */}
                <Button variant="primary" type="submit" disabled={disabledButtonRegister} className={"btn-default"}>
                    Submit
                </Button>
            </Form>
            }
            {currentStep == 'validate-otp' && <Form onSubmit={validateOtp}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Input OTP</Form.Label>
                    <Form.Control type="number" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
                    <Form.Text className="text-muted">
                        We have send you OTP to your email , make sure to check your spam folder too.
                    </Form.Text>
                </Form.Group>
                {/* 
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Confirmation Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setConfPassword(e.target.value)} />
                </Form.Group> */}
                <Button variant="primary" type="submit" disabled={disabledButtonRegister} className={"btn-default"}>
                    Submit
                </Button>
            </Form>
            }
        </>
    )
}