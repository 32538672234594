import { MultiplayerUser } from "./mutiplayer-user";

class MultiplayerData
{
    constructor()
    {
        this.cmd = "";
        this.sender = new MultiplayerUser();
        this.data = [];
    }

    toJson()
    {
        let result = {
            cmd:this.cmd,
            sender:this.sender.getData(),
            data:this.data
        }

        return JSON.stringify(result);
    }
}

export {MultiplayerData}