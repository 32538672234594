import { useState } from "react";
import { useEffect } from "react";
import { FunctionVariableContext } from "../../context/game-context";
import "../../../css/trivia-popup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const TriviaPopup = () => {
    const setOnTriviaAnswer = FunctionVariableContext(state => state.setOnTriviaAnswer);

    const [wrong, setWrong] = useState(false);
    const [right, setRight] = useState(false);

    useEffect(() => {
        setOnTriviaAnswer((cond) => {
            if (!cond) setWrong(true);
            else setRight(true);

            setTimeout(() => {
                setWrong(false);
                setRight(false);
            }, 1500);
        });
    }, []);

    return ((wrong || right) &&
        <div className="trivia-popup-background">
            <div className="trivia-popup">
                {
                    wrong && <div className="trivia-popup-answer popup-wrong"><FontAwesomeIcon icon="fa fa-times" />
                        <audio src={`${process.env.RESOURCE_URL}/sounds/wrong.mp3`} autoPlay loop={false} />
                    </div>
                }
                {
                    right && <div className="trivia-popup-answer popup-right"><FontAwesomeIcon icon="fas fa-check" />
                        <audio src={`${process.env.RESOURCE_URL}/sounds/correct.mp3`} autoPlay loop={false} />
                    </div>
                }
            </div>
        </div>
    );
}