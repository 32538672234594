import { Suspense, useRef, useState, useEffect, useContext } from "react";
import Player from "./component/player";
import Scene from "./component/scene";
import { Debug, Physics } from "@react-three/cannon";
import { PlayerState } from "./component/player-component/context/player-state";
import { UtilTools } from "./utils/UtilTool";
import { RemotePlayers } from "./component/remote-player";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { CanvasContext } from "./component/player-component/context/canvas-context";
import { CameraControl } from "./component/camera-component/camera-control";
import { AudioChangeRoom, RtcConnection } from "./rtc-manager";
import { JoyStick, JoyStickOld } from "./component/html-component/joystick-old";
import { GamePlayerContext, SceneContext, GameConfigContext, RtcLocalContext, PlayerSelectionContext, FunctionVariableContext, TreasureHuntContext, LocalPlayerContext, AudioContext } from "./context/game-context";
import $ from 'jquery';
import { PlayerSelection } from "./player-selection";
import { Html, OrbitControls, Stars } from "@react-three/drei";
import { Vector3 } from "three";
import { StarsControl } from "./component/player-component/context/canvas-provider";
import { NPC } from "./component/player-component/npc";
import { SocketContext } from "./component/player-component/context/socket-context";
import { Scene360 } from "./component/scene-component/scene360";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EndlessRun } from "./component/scene-component/endless-run";
import { EndlessRunGame } from "./component/scene-component/endless-run-game";
import { HTMLVideo } from "./component/scene-component/html-video";
import { PlayerModelAndAnimation } from "./component/player-model-animation";
import CircularJSON from "circular-json";
import { BlockAccountLogged } from "./component/html-component/block-account-logged";

export function GameManager(
    { playerName, onSendShoutout, onSelectEmoji, onChangeCameraMode,
        setHoverBoard, setMonitorValue, onClick, onPointerEnter, onRemoteClicked, onRequestDuetAnimation, onGetRequestDuetAnimation, onReplyDuetAnimation,
        onPointerOut, onChangeScene, onPlayerSit, setAvatarSelection, isSelectPlayer, joinHostedRoom, createHostedRoom, sceneID, onShoutoutChat, showTutorial,
        rtcRef }) {

    // const getLastPlayerPosition = SceneContext((state) => state.lastPlayerPosition);
    // const sceneAssets = useRef(SceneContext((state)=>state.clickableObjects));


    // const math = useRef(new UtilTools());
    // const [sceneLoaded, setSceneLoaded] = useState(false);
    // const joystick = useRef();

    const getFirstScene = SceneContext((state) => state.scene);
    const setIs360SceneState = SceneContext((state) => state.set360);
    const setSelectPlayerCompleted = PlayerSelectionContext((state) => state.setSelectPlayerCompleted);
    const initiateLocalPlayerSetting = LocalPlayerContext((state) => state);
    const setControlToggle = GamePlayerContext(state => state.setControlToggle);
    const isTimerStart = useRef(GamePlayerContext(state => state.isTimerStart));
    const setTimer = GamePlayerContext(state => state.setTimer);
    const startTimer = GamePlayerContext(state => state.startTimer);
    const setCountDown = GamePlayerContext(state => state.setCountDown);
    const photoSpotId = useRef(GamePlayerContext(state => state.photoSpotId));
    const setDroppedNPC = SceneContext(state => state.setDroppedNPC);
    const setLocalSpeaking = AudioContext(state=>state.setLocalSpeaking);
    const timer = useRef(0);

    const sceneRef = useRef();
    const localPlayer = useRef();
    const localPlayerPosition = useRef();
    const listRemotePlayerRef = useRef();
    const math = useRef(new UtilTools());
    const { ambientLight, hemisphereLight, directionalLight, setDirectionalLightTarget, moonLight, setStarCount } = useContext(CanvasContext);
    const { networkManager } = useContext(SocketContext);
    const [sceneLoaded, setSceneLoaded] = useState(false);
    const instructions = useRef(SceneContext(state => state.instructions));

    const setLocalPlayerMessage = useRef();
    const orbitControl = useRef();
    const setCameraTarget = useRef();
    const playerFirstPosition = useRef([]);
    const playerFirstRotation = useRef([]);
    const playerFirstForward = useRef();
    const setPlayerPosition = useRef();
    const setRemoteAction = useRef();
    const audioListener = useRef(new THREE.AudioListener());
    const { camera, gl, canvas } = useThree();
    const onRemotePlayerClicked = useRef();
    let worldTime = useRef(0);
    let worldTimeMax = useRef(30);
    let audioRoom = useRef();
    let isCountDownShown = useRef(false);
    const assetRef = useRef({});
    const playerAssetRef = useRef({});
    const meshes = useRef();
    const materials = useRef();
    const refPlayerPosition = useRef();

    const [sceneName, setSceneName] = useState(sceneID && sceneID.current ? sceneID.current : getFirstScene);
    const [player, setPlayer] = useState();
    const [playerSettings, setPlayerSettings] = useState(initiateLocalPlayerSetting);
    let [isSceneOrbitOnly, setSceneOrbitOnly] = useState(false);
    let [isScene360, setScene360] = useState(false);
    let [isEndlessRun, setEndlessRun] = useState(false);
    let [isSceneEndlessRun, setSceneEndlessRun] = useState(false);
    let [scenePanoramicID, setScenePanoramicID] = useState(0);
    const [refCollider, setRefCollider] = useState();
    const [isCustomSkybox, setIsCustomSkybox] = useState(SceneContext((state) => state.isCustomSkybox));
    const [animatedCamera, setAnimatedCameraState] = useState(false);
    const animatedCameraClipName = useRef();
    const animatedCameraFirstPosition = useRef();

    //FOR SETTING & DEBUG PURPOSE
    //==========================================
    // window.ambientLight = ambientLight;
    // window.hemisphereLight = hemisphereLight;
    // window.directionalLight = directionalLight;
    // window.moonLight = moonLight;
    //==========================================


    let currentTime = 0;
    let currentFPS = 0;

    const setWorldTime = useRef(SceneContext((state) => state.setTime));
    const isWorldTimeAuto = useRef(SceneContext((state) => state.autoTime));
    const useStartCountdown = useRef(false);
    const useTimer = useRef(false);

    // const gamePlayerContext = useRef(GamePlayerContext());
    const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);
    const setSpeakerVisible = GamePlayerContext((state) => state.setSpeakerVisible);
    const setScene360Substance = GamePlayerContext((state)=>state.setScene360);
    const setLoading = GameConfigContext((state) => state.setLoading);
    const setShareScreen = RtcLocalContext((state) => state.setShareScreen);
    const setStreamPlayer = RtcLocalContext((state) => state.setStreamPlayer);
    const resetLocalPlayerLastPosition = GamePlayerContext((state) => state.resetLocalPlayerLastPosition);
    const setPlayerHost = GamePlayerContext((state) => state.setPlayerHost);
    const setMixerRoom = GamePlayerContext((state) => state.setMixerRoom);
    const setIsJoinMixerRoom = GamePlayerContext((state) => state.setJoinMixerRoom);
    const setMixerRoomStarted = GamePlayerContext((state) => state.setMixerRoomStarted);
    const setAudioRoom = GamePlayerContext((state) => state.setAudioRoom);
    const setMixer = GamePlayerContext((state) => state.setMixer);
    const setHostControlledRoom = GamePlayerContext((state) => state.setHostControlledRoom);
    const setHostControlledRoomPresenterID = GamePlayerContext((state) => state.setHostControlledRoomPresenterID);
    const setPlayerPing = GamePlayerContext((state) => state.setPing);
    const setConnected = GamePlayerContext((state) => state.setConnected);
    const setDropItem = GamePlayerContext((state) => state.setDropItem);
    const setGraphicQuality = GameConfigContext((state) => state.setGraphicQuality);
    const loading = useRef(GameConfigContext((state) => state.loading));
    const getLocalPlayerSetting = GamePlayerContext((state) => state.getLocalPlayerSetting);

    const setCreateMixerRoom = FunctionVariableContext((state) => state.setCreateMixerRoom);
    const setJoinMixerRoom = FunctionVariableContext((state) => state.setJoinMixerRoom);
    const setLeaveMixerRoom = FunctionVariableContext((state) => state.setLeaveMixerRoom);
    const setRemoveMixerRoom = FunctionVariableContext((state) => state.setRemoveMixerRoom);
    const setStartMixerRoom = FunctionVariableContext((state) => state.setStartMixerRoom);
    const setStopMixerRoom = FunctionVariableContext((state) => state.setStopMixerRoom);
    const setChangeServerRoom = FunctionVariableContext((state) => state.setChangeServerRoom);

    const setCreateHostControlRoom = FunctionVariableContext((state) => state.setCreateHostControlRoom);
    const setStopHostControlRoom = FunctionVariableContext((state) => state.setSetStopHostControlRoom);
    const setRoomPresenter = FunctionVariableContext((state) => state.setSetRoomPresenter);
    const setPlayerIdle = FunctionVariableContext((state) => state.setPlayerIdle);
    const setReconnectSocket = FunctionVariableContext((state) => state.setReconnectSocket);
    const setCapturePhoto = FunctionVariableContext((state) => state.setCapturePhoto);
    const setCapturedPhoto = GamePlayerContext((state) => state.setCapturedPhoto);
    const photoTotem = TreasureHuntContext((state) => state.photoTotem);
    const setCurrentRoom = GamePlayerContext((state) => state.setCurrentRoom);
    const setSendParamterOnChangeRoomCounter = GamePlayerContext((state) => state.setSendParamterOnChangeRoomCounter);
    const userId = useRef(GamePlayerContext((state) => state.userId));
    const photoFrame = useRef(SceneContext((state) => state.photoIframe));
    const setAnimatedCamera = SceneContext((state) => state.setAnimatedCamera);

    const setPlayerSpeaking = AudioContext((state) => state.setPlayerSpeaking);
    const setPlayerSilence = AudioContext((state) => state.setPlayerSilence);

    const animatedCameraPlayerRef = useRef();
    const animatedCameraRefPlayerModel = useRef();

    const [npcSelected, setNpcSelected] = useState(SceneContext(state => state.npcSelected));

    const lastScene = useRef(FunctionVariableContext((state) => state.lastScene));

    const setPresenter = GamePlayerContext((state) => state.setPresenter);

    const isLoadPlayerFinish = useRef();
    const isLoadSceneFinish = useRef();
    window.setLoading = setLoading;

    const isSafari = () => {
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        return iOSSafari;
    }


    const startCountDown = () => {

        setTimeout(() => {
            setCountDown(5);

            setTimeout(() => {
                setCountDown(4);

                setTimeout(() => {
                    setCountDown(3);

                    setTimeout(() => {
                        setCountDown(2);

                        setTimeout(() => {
                            setCountDown(1);

                            setTimeout(() => {
                                setCountDown(0);
                                setControlToggle(true);

                                if (useTimer.current) {
                                    startTimer(true);
                                }

                                setTimeout(() => {
                                    setCountDown(-1);
                                    setControlToggle(true);

                                    if (useTimer.current) {
                                        startTimer(true);
                                    }
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 1000);
        }, 1000);
    }

    const onLocalPlayerFinishLoad = useRef(() => {
        isLoadPlayerFinish.current = true;
        if (isLoadSceneFinish.current) {
            setLoading(false);
        }
    });

    useEffect(() => {

        if (userId != null) {
            networkManager.SetMyId(userId.current);
        }

        let playerNearSubs = AudioContext.subscribe(state => state.playerListToListen, (data) => {
            // console.log("PLAYER NEAR UPDATE", data);
        });


        let playerFarSubs = AudioContext.subscribe(state => state.playerListToMute, (data) => {
            // console.log("PLAYER FAR UPDATE", data);
        });

        // let playerGridSubs = AudioContext.subscribe(state => state.playerGridId, (data) => {
        //     // console.log("PLAYER GRID UPDATE", data);
        // });


        let userIdSubs = GamePlayerContext.subscribe(state => state.userId, (data) => {
            // networkManager.SetMyId(data);
        });

        let photoFrameSubs = SceneContext.subscribe((state) => state.photoIframe, (data) => {
            photoFrame.current = data;
        });

        let isCustomSkyboxSubs = SceneContext.subscribe((state) => state.isCustomSkybox, (data) => {
            setIsCustomSkybox(data);
        });

        let isTimerStartSubs = GamePlayerContext.subscribe((state) => state.isTimerStart, (data) => {
            isTimerStart.current = data;
        });

        let photoSpotIdSubs = GamePlayerContext.subscribe(state => state.photoSpotId, (data) => {
            photoSpotId.current = data;
        });


        let loadingSubs = GameConfigContext.subscribe((state) => state.loading, (data) => {
            if (loading.current == true && data == false) {
                if (useStartCountdown.current) {
                    if (showTutorial == true) {
                        setControlToggle(false);
                    }
                    else {
                        // startCountDown();
                    }
                }
            }

            loading.current = data;
        });

        let isShowInstructionSubs = SceneContext.subscribe((state) => state.instructions, (data) => {
            instructions.current = data;
        });

        let instrctionSubs = SceneContext.subscribe((state) => state.instructions, (data) => {

        });

        setChangeServerRoom((room) => {
            networkManager.ChangeRoom(room);
        });

        setCapturePhoto(() => {

            var image = gl.domElement.toDataURL("image/png").replace("image/png", "image/octet-stream");

            var size = window.innerWidth * 0.95;
            if (size > 600) size = 600;

            var domHeight = gl.domElement.width;
            var domWidth = gl.domElement.height;

            var x = (domWidth - size) / 2;
            var y = (domHeight - size) / 2;


            var img = new Image();
            img.src = image;
            img.onload = (data) => {

                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');
                context.canvas.width = size;
                context.canvas.height = size;

                context.drawImage(img, -y, -x);

                if (photoFrame.current) {
                    let imgSrc = `${process.env.RESOURCE_URL}/${sceneName}/textures/${photoFrame.current}`;

                    var img2 = new Image();
                    img2.crossOrigin = "anonymous";
                    img2.onload = (d) => {

                        context.drawImage(img2, 0, 0, size, size);


                        var imageResult = canvas.toDataURL("image/png");
                        setCapturedPhoto(imageResult);
                    }
                    img2.src = imgSrc;
                }
                else {
                    var imageResult = canvas.toDataURL("image/png");
                    setCapturedPhoto(imageResult);
                }

            }


            if (photoSpotId.current.length > 0) {
                photoTotem(photoSpotId.current[0])
            }


        });

        return () => {
            userIdSubs();
            photoFrameSubs();
            isCustomSkyboxSubs();
            isTimerStartSubs();
            loadingSubs();
            instrctionSubs();
            isShowInstructionSubs();
            photoSpotIdSubs();
            // playerNearSubs();
            playerFarSubs();
            // playerGridSubs();
            assetRef.current = null;
        }
    }, []);

    useEffect(() => {

        const handlingStreamPlayer = (userId) => {
            setStreamPlayer(userId)
        }
        // const rtc = RtcConnection({
        //     room: `${sceneName}-audio`,
        //     playerId: networkManager.socket.id,
        //     playerName: playerName.displayName,
        //     setShareScreen,
        //     setLocalSpeaking:setLocalSpeaking
        // });
        // rtcRef.current = rtc;
        // rtc.onmute = function (e) {
        //     if (e.isAudioMuted) {
        //         setSpeakerVisible(`speaker-${e.userid}`, false);
        //     }
        // };

        // rtc.onunmute = function (e) {
        //     if (!e.isAudioMuted) {
        //         setSpeakerVisible(`speaker-${e.userid}`, true);
        //     }
        // };

        // rtc.socket.on('onspeaking', function (e) {
        //     setPlayerSpeaking(e.userid)
        // });
        // rtc.socket.on('stopped_speaking', function (e) {
        //     setPlayerSilence(e.userid)
        // });


        // rtc.onsilence = function (e) {
        //     console.log(e, "ON SILENCE")
        // };
        // rtc.onvolumechange = function (e) {
        //     console.log(e, "VOLUME CHANGE")
        // }
        // rtc.handlingStreamPlayer = function (e) {
        //     handlingStreamPlayer(e);
        // };
    }, [networkManager.socket.id])

    useEffect(() => {
        if (!showTutorial && !loading.current && instructions.current.length == 0 && !isCountDownShown.current) {

            if (useStartCountdown.current) {
                isCountDownShown.current = true;
                startCountDown();
            }
        }
    }, [loading.current, showTutorial, instructions.current]);


    useEffect(() => {
        const audioChangeRoom = GamePlayerContext.subscribe((state) => state.audioRoom, (data) => {
            if (audioRoom.current != data) {
                audioRoom.current = data;
                AudioChangeRoom(data, rtcRef.current);
            }
        });

        const npcSelectedSubs = SceneContext.subscribe(state => state.npcSelected, (data) => {

            setNpcSelected(data);
        });

        updateLocalPlayerSetting({
            displayName: playerName,
            playerId: networkManager.socket.id
        })

        const lastSceneSubs = SceneContext.subscribe((state) => state.lastScene, (data) => {
            lastScene.current = data;
        });

        const onUserList = (data) => {
        }
        
        const assignSetMe = () => {
            networkManager.SetMe(playerSettings);
        }


        assignSetMe();


        const onConnected = () => {
            // networkManager.CreateHostedRoom(sceneName);

            console.log("JoinRoom");
            networkManager.JoinRoom();
        };

        const onDirectMessage = (data) => {
            if (onGetRequestDuetAnimation && onGetRequestDuetAnimation.current) onGetRequestDuetAnimation.current(data);
        }

        const OnDirectGroupMessage = (data) => {
            if (data.data.action === "duet-apply") {
                onSelectEmoji.current(data.data.animationID);
            }
        }

        const OnJoinHostedRoom = (data) => {

            setConnected(true);
        }

        const OnJoinHostedRoomError = (data) => {
            setConnected(false);
        }

        const OnCreateHostedRoom = (data) => {
            setConnected(true);
            setPlayerHost(true);
        }

        const OnCreateHostedRoomError = (data) => {
            setConnected(false);
        }

        const OnUpdateParameter = (data) => {
            setMixerRoom(data.m != undefined);
            let currentScene = sceneName;
            if (data.m != undefined) {
                var joinMixer = data.m.participants.includes(networkManager.me.i);
                setIsJoinMixerRoom(joinMixer);
                setMixerRoomStarted(data.m.isStarted);

                if (joinMixer) {
                    if (!data.m.isStarted) {
                        currentScene = `mixer-match-${sceneName}`;
                    }
                    else {
                        currentScene = data.r;
                    }

                }
                else {
                    currentScene = sceneName;
                }

                setMixer(data.m.timePerRound - data.m.currentTime, data.m.round);
            }
            else {
                setIsJoinMixerRoom(false);
                setMixerRoomStarted(false);
            }

            setHostControlledRoom(data.p != undefined);
            setPresenter(data.p ? data.p == networkManager.me.i : false);
            setHostControlledRoomPresenterID(data.p);

            setAudioRoom(currentScene);
        }

        const OnPing = (ping) => {
            setPlayerPing(ping);
        }

        const OnDisconnect = () => {
            setConnected(false);
        }

        const shoutoutChatListen = (user, chat) => {
            if (onShoutoutChat.current)
                onShoutoutChat.current(user, chat)
        }

        const OnSpawnItem = (data) => {
            setDropItem(data);
        }

        const OnDeleteItem = (data) => {
            setDropItem(null);
        }

        const OnItemList = (data) => {
            if (data) {
                setDropItem(data);
            }
        }

        const OnJoinRoom = (data) => {

            if (data.data && data.data.length > 0 && data.data[0]) {
                networkManager.UpdateParameters("", "item_list");
                setCurrentRoom(data.data[0]);
            }

        }

        const OnChangeRoom = (data) => {
            if (data.data && data.data.length > 0 && data.data[0]) {
                networkManager.UpdateParameters("", "item_list");
                setSendParamterOnChangeRoomCounter(1);
                setCurrentRoom(data.data[0]);
            }
        }

        const OnSpawnNPC = (data) => {
            setDroppedNPC(data);
        }

        const OnDeleteNPC = (data) => {
            setDroppedNPC(null);
        }

        networkManager.on("OnConnect", onConnected);
        networkManager.on("OnDisconnect", OnDisconnect);
        networkManager.on("OnUserList", onUserList);
        networkManager.on("OnDirectMessage", onDirectMessage);
        networkManager.on("OnDirectGroupMessage", OnDirectGroupMessage);
        networkManager.on("OnJoinHostedRoom", OnJoinHostedRoom);
        networkManager.on("OnJoinHostedRoomError", OnJoinHostedRoomError);
        networkManager.on("OnCreateHostedRoom", OnCreateHostedRoom);
        networkManager.on("OnCreateHostedRoomError", OnCreateHostedRoomError);
        // networkManager.on("OnUpdateParameter", OnUpdateParameter);
        networkManager.on("OnPing", OnPing);
        networkManager.on("OnShoutoutChat", shoutoutChatListen);
        networkManager.on("OnSpawnItem", OnSpawnItem);
        networkManager.on("OnDeleteItem", OnDeleteItem);
        networkManager.on("OnItemList", OnItemList);
        networkManager.on("OnJoinRoom", OnJoinRoom);
        networkManager.on("OnChangeRoom", OnChangeRoom);

        networkManager.on("OnSpawnNPC", OnSpawnNPC);
        networkManager.on("OnDeleteNPC", OnDeleteNPC);

        camera.add(audioListener.current);

        const sceneSub = SceneContext.subscribe(state => state.scene, (data) => {
            resetLocalPlayerLastPosition();
            setSceneName(data)
            setAudioRoom(data);
            sceneID.current = data;
        })


        const localPlayerSettingSub = LocalPlayerContext.subscribe(state => state, (data) => {
            // console.log(data, playerSettings, localStorage.getItem("local_player_setting"), "GAME MANAGER LISTEN");
            // const updatedLocalPlayer = CircularJSON.parse(localStorage.getItem("local_player_setting"));

            setPlayerSettings(data);
            networkManager.SetMe(data);
            networkManager.UpdateParameters(networkManager.me, "update_parameter-user");
        })

        setCreateMixerRoom(function () {
            networkManager.CreateMixerRoom(6, 2, 5, 10);
        });

        setJoinMixerRoom(function () {
            networkManager.JoinMixerRoom();
        });

        setLeaveMixerRoom(function () {
            networkManager.LeaveMixerRoom();
        });

        setRemoveMixerRoom(function () {
            networkManager.RemoveMixerRoom();
        });

        setStartMixerRoom(function () {
            networkManager.StartMixerRoom();
        });

        setStopMixerRoom(function () {
            networkManager.StopMixerRoom();
        });

        setCreateHostControlRoom(function () {
            networkManager.SetRoomControlled();
        });

        setStopHostControlRoom(function () {
            networkManager.StopRoomControlled();
        });

        setRoomPresenter(function (id) {
            networkManager.SetRoomPresenter(id);
        });

        setPlayerIdle(function () {
            networkManager.Disconnect();
        });

        setReconnectSocket(function () {
            networkManager.Reconnect();
        });

        setInterval(() => {
            networkManager.Ping();
        }, 5000);

        const shareScreenListen = RtcLocalContext.subscribe(state => state.screen,
            (data) => {
                if (data != null) {
                    data.isScreen = true;
                    rtc.addStream(data)

                    data.onended = data.onmute = data.oninactive = function () {
                        document.getElementById('video-share-screen-elem').srcObject = null;
                        rtc.removeStream(data.streamid);
                        setShareScreen(null);
                    }
                }
            })

        const isFirstPersonCamera = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {

        });

        const isWorldTimeAutoSubs = SceneContext.subscribe((state) => state.autoTime, (data) => {
            isWorldTimeAuto.current = data;
        });

        return () => {
            networkManager.off("OnConnect", onConnected);
            networkManager.off("OnDisconnect", OnDisconnect);
            networkManager.off("OnUserList", onUserList);
            networkManager.off("OnDirectMessage", onDirectMessage);
            networkManager.off("OnDirectGroupMessage", OnDirectGroupMessage);
            networkManager.off("OnJoinHostedRoom", OnJoinHostedRoom);
            networkManager.off("OnJoinHostedRoomError", OnJoinHostedRoomError);
            networkManager.off("OnCreateHostedRoom", OnCreateHostedRoom);
            networkManager.off("OnCreateHostedRoomError", OnCreateHostedRoomError);
            // networkManager.on("OnUpdateParameter", OnUpdateParameter);
            networkManager.off("OnPing", OnPing);
            networkManager.off("OnShoutoutChat", shoutoutChatListen);
            networkManager.off("OnSpawnItem", OnSpawnItem);
            networkManager.off("OnDeleteItem", OnDeleteItem);
            networkManager.off("OnItemList", OnItemList);
            networkManager.off("OnJoinRoom", OnJoinRoom);
            networkManager.off("OnChangeRoom", OnChangeRoom);

            sceneSub();
            localPlayerSettingSub();
            shareScreenListen();
            audioChangeRoom();
            isFirstPersonCamera();
            isWorldTimeAutoSubs();
            npcSelectedSubs();
            lastSceneSubs();
        }

        // speaker handler 
    }, [])

    const randomRange = (min, max) => {
        return Math.random() * (max - min + 1) + min;
    }

    const onLoadSceneFinish = (spawnPoint, collider, orbitOnly, scene360, sceneEndlessRun, isUseStartCountdown, isUseTimer, isAnimatedCamera, animatedCameraClip, meshData, materialData) => {

        if (spawnPoint.current && spawnPoint.current.length > 0) {
            let point = spawnPoint.current.find(x => x.name == lastScene.current);
            if (point == undefined) point = spawnPoint.current[0];

            let randomX = randomRange(-1, 1);
            let randomZ = randomRange(-1, 1);

            let firstCameraPosition = new Vector3(point.position.x, point.position.y + 1, point.position.z);

            animatedCameraFirstPosition.current = [point.position.x, point.position.y, point.position.z];
            playerFirstPosition.current = [point.position.x + randomX, point.position.y, point.position.z + randomZ];
            playerFirstRotation.current = [point.rotation.x, point.rotation.y, point.rotation.z, point.rotation.w];
            if (setPlayerPosition.current !== undefined) setPlayerPosition.current(point.position.x, point.position.y, point.position.z);

            if (point.forward) {
                playerFirstForward.current = [point.forward.x / 10000, point.forward.y / 10000, point.forward.z / 10000];

                let cameraOffset = new Vector3(point.forward.x / 10000, point.forward.y / 10000, point.forward.z / 10000);
                cameraOffset.multiplyScalar(-1);
                firstCameraPosition.add(cameraOffset);
            }
            else {
                playerFirstForward.current = undefined;
            }

            camera.position.set(firstCameraPosition.x, firstCameraPosition.y, firstCameraPosition.z);
            camera.lookAt(firstCameraPosition);
        }

        meshes.current = meshData;
        materials.current = materialData;

        isCountDownShown.current = false;


        timer.current = 0;
        setTimer(0);

        useStartCountdown.current = isUseStartCountdown;
        useTimer.current = isUseTimer;

        networkManager.sceneName = sceneName;

        networkManager.UpdateParameters("", "item_list");
        setRefCollider(collider);
        // networkManager.CreateHostedRoom(sceneName);
        
        if(networkManager.room == null) networkManager.JoinRoom();

        if (orbitOnly == true) {
            setSelectPlayerCompleted(true);
            setSceneOrbitOnly(true);
        }
        else {
            setSceneOrbitOnly(false);
        }

        if (isAnimatedCamera == true) {
            setSelectPlayerCompleted(true);
            setAnimatedCameraState(true);
            animatedCameraClipName.current = animatedCameraClip;
            setAnimatedCamera(true);
        }
        else {
            setAnimatedCameraState(false);
            setAnimatedCamera(false);
        }

        if (scene360 == true) {
            setSelectPlayerCompleted(true);
            setScene360(true);
            setScene360Substance(true);
            setIs360SceneState(true);
        }
        else {
            setScene360(false);
            setScene360Substance(false);
            setIs360SceneState(false);
        }

        if (sceneEndlessRun) {
            setSceneEndlessRun(true);
        }
        else {
            setSceneEndlessRun(false);
        }

        if (orbitOnly || isAnimatedCamera) {
            setLoading(false);
        }
        else if (isLoadPlayerFinish.current) {
            setLoading(false);
        }

        isLoadSceneFinish.current = true;
    };

    useFrame(() => {
        if (animatedCamera && playerFirstPosition.current && playerFirstForward.current && animatedCameraPlayerRef.current && animatedCameraRefPlayerModel.current) {

            animatedCameraPlayerRef.current.position.set(animatedCameraFirstPosition.current[0], animatedCameraFirstPosition.current[1], animatedCameraFirstPosition.current[2]);

            const currPos = new Vector3(animatedCameraFirstPosition.current[0], animatedCameraFirstPosition.current[1], animatedCameraFirstPosition.current[2]);
            const forwardPos = new Vector3(playerFirstForward.current[0], playerFirstForward.current[1], playerFirstForward.current[2]);

            let angle = Math.atan2((forwardPos.x - currPos.x), (forwardPos.z - currPos.z));
            if (animatedCameraRefPlayerModel.current.setAnimation != undefined) animatedCameraRefPlayerModel.current.setAnimation(animatedCameraClipName.current);
            animatedCameraPlayerRef.current.rotation.set(0, angle, 0, 'XYZ');
        }
    });

    onSendShoutout.current = (message) => {
        if (setLocalPlayerMessage.current)
            setLocalPlayerMessage.current(message);
        networkManager.UpdateParameters(message, "update_parameter-chat");
    }

    useFrame((src, delta) => {
        if (isTimerStart.current) {
            timer.current += delta;
            setTimer(timer.current);
        }
    });

    useFrame((src, delta) => {

        if (setMonitorValue.current === undefined) return;


        currentTime += delta;
        currentFPS++;
        if (currentTime > 1) {
            setMonitorValue.current(currentFPS, gl.info.render.calls, gl.info.render.triangles);
            currentTime = 0;
            currentFPS = 0;
        }

        if (isSceneOrbitOnly && orbitControl.current) {
            let forwardDirection = new Vector3(0, 0, 0);
            camera.getWorldDirection(forwardDirection);
            forwardDirection.y = 0;
            forwardDirection.normalize();

            setDirectionalLightTarget(orbitControl.current.target, forwardDirection);
        }

        if (isWorldTimeAuto.current) {
            worldTime.current += delta;
            if (worldTime.current > worldTimeMax.current) worldTime.current = 0;
            setWorldTime.current(worldTime.current / worldTimeMax.current);
        }

        if (currentFPS < 10) setGraphicQuality(3);
        else if (currentFPS < 25) setGraphicQuality(2);
        else if (currentFPS < 40) setGraphicQuality(1);
        else setGraphicQuality(0);
    });

    useFrame((src, delta) => {
        if (!npcSelected) return;

        let forward = new Vector3(0, 0.8, 1);
        let lookAt = new Vector3(0, 0.8, 0);
        npcSelected.object.localToWorld(forward);
        npcSelected.object.localToWorld(lookAt);

        camera.position.lerp(forward, delta * 5);
        camera.lookAt(lookAt);
        // setCameraTarget.current(
        //     npcSelected.object.position.x,
        //     npcSelected.object.position.y,
        //     npcSelected.object.position.z,
        //     refCollider.current,
        //     npcSelected.object,
        //     npcSelected.object.position,
        //     false
        // );


    });


    joinHostedRoom.current = (roomid) => {
        networkManager.JoinHostedRoom(roomid);
    }

    createHostedRoom.current = (roomid) => {
        networkManager.CreateHostedRoom(roomid);
    }

    onRemotePlayerClicked.current = (mesh, id) => {
        let worldPosition = new THREE.Vector3();
        mesh.eventObject.getWorldPosition(worldPosition);

        if (!localPlayerPosition.current) return;
        let playerPosition = new THREE.Vector3(localPlayerPosition.current[0], localPlayerPosition.current[1], localPlayerPosition.current[2]);
        let distance = worldPosition.distanceTo(playerPosition);

        if (distance < 2) {
            if (onRemoteClicked && onRemoteClicked.current) onRemoteClicked.current(id);
        }
    }

    onRequestDuetAnimation.current = (id, animationID) => {
        networkManager.SendDirect(id, { action: "duet", animationID: animationID });
    }

    onReplyDuetAnimation.current = (isApply, ids, animationID) => {
        if (isApply) {
            networkManager.SendDirectGroup(ids, { action: "duet-apply", animationID: animationID });
        }
        else {
            networkManager.SendDirect(ids, { action: "duet-reject", animationID: animationID });
        }
    }

    const changePanoramicID = (id) => {
        setScenePanoramicID(id);
    }

    return (
        <>
            {!isCustomSkybox && <StarsControl setStarCount={setStarCount} />}

            <Physics
                gravity={[0, 0, 0]}
                allowSleep={false}
                iterations={60}
                tolerance={0.001}
                quatNormalizeFast={true}
                quatNormalizeSkip={1}
                shouldInvalidate={true}
                solver={'GS'}
                stepSize={1 / 60}
                isPaused={false}
            >
                {/* <Debug color="black" > */}
                {/* <Suspense fallback={null}> */}
                <Scene
                    name={sceneName}
                    ref={sceneRef}
                    onSceneLoadFinish={onLoadSceneFinish}
                    onClick={onClick}
                    onPointerEnter={onPointerEnter}
                    onPointerOut={onPointerOut}
                    setRemoteAction={setRemoteAction}
                    assetRef={assetRef}
                    playerAssetRef={playerAssetRef}
                    isLoadSceneFinish={isLoadSceneFinish}
                    networkManager={networkManager}
                >
                    {/* <Suspense fallback={null}> */}
                    <BrowserRouter>
                        <Routes>
                            <Route path="/endlessrun" element={<>
                                <EndlessRun setEndlessRun={setEndlessRun} />
                            </>} />
                        </Routes>
                    </BrowserRouter>
                    {/* {isSceneEndlessRun && <OrbitControls ref={orbitControl}/>} */}
                    {isSceneEndlessRun && <EndlessRunGame />}
                    {!isSceneOrbitOnly && !isSceneEndlessRun && isScene360 && !isEndlessRun && !animatedCamera &&
                        <group>
                            <Scene360 panoramicID={scenePanoramicID} sceneName={sceneName} changePanoramicID={changePanoramicID} assetRef={assetRef} meshes={meshes.current} materials={materials.current} />
                            <OrbitControls rotateSpeed={-0.3} ref={orbitControl} enableDamping={false} distance={0} maxDistance={0.1} minDistance={0} enableZoom={false} position={[0, 0, 0]} position0={[0, 0, 0]} />
                        </group>}
                    {isSceneOrbitOnly && !isSceneEndlessRun && !isScene360 && !isEndlessRun && !animatedCamera && <OrbitControls ref={orbitControl} enablePan={false} distance={8} target={[0, 3, 0]} position={[0, 5, 5]} position0={[0, 5, 5]} minDistance={8} maxDistance={15} minPolarAngle={0} maxPolarAngle={Math.PI/1.8}/>}
                    {!npcSelected && !isSceneOrbitOnly && !isSceneEndlessRun && !isScene360 && !isEndlessRun && !animatedCamera && <CameraControl enable={!isSelectPlayer} setCameraTarget={setCameraTarget} onChangeCameraMode={onChangeCameraMode} />}
                    {/* <Suspense fallback={null}> */}
                    {animatedCamera && <group ref={animatedCameraPlayerRef}>
                        <PlayerModelAndAnimation
                            playerModel={animatedCameraRefPlayerModel}
                            skin={playerSettings.skin}
                            base={playerSettings.base}
                            skinColor={playerSettings.skinColor}
                            hairColor={playerSettings.hairColor}
                            head={playerSettings.head}
                            eyebrow={playerSettings.eyebrow}
                            eyes={playerSettings.eyes}
                            mouth={playerSettings.mouth}
                            gender={playerSettings.gender}
                            hair={playerSettings.hair}
                            upperBody={playerSettings.upperBody}
                            lowerBody={playerSettings.lowerBody}
                            feet={playerSettings.feet}

                            hat={playerSettings.hat}
                            helmet={playerSettings.helmet}
                            topHead={playerSettings.topHead}
                            mask={playerSettings.mask}
                            tiara={playerSettings.tiara}
                            earing={playerSettings.earing}
                            facialHair={playerSettings.facialHair}

                            assetRef={playerAssetRef}

                            onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}
                        />
                    </group>}

                    {
                        !isSceneOrbitOnly && !isSceneEndlessRun && !isSelectPlayer && !isScene360 && !isEndlessRun && !animatedCamera && <Player
                            localPlayer={localPlayer}
                            localPlayerPosition={localPlayerPosition}
                            position={playerFirstPosition.current}
                            rotation={[playerFirstRotation.current[0], playerFirstRotation.current[1], playerFirstRotation.current[2], playerFirstRotation.current[3]]}
                            forward={playerFirstForward}
                            isLocal={true}
                            name={playerSettings.displayName}

                            playerId={playerSettings.id}
                            colliders={refCollider}
                            orbitControl={orbitControl}
                            setCameraTarget={setCameraTarget}
                            onSelectEmoji={onSelectEmoji}
                            setMessage={setLocalPlayerMessage}
                            setHoverBoard={setHoverBoard}
                            sceneName={sceneName}
                            setPlayerPosition={setPlayerPosition}
                            onPlayerSit={onPlayerSit}
                            audioId={playerSettings.id}
                            setAvatarSelection={setAvatarSelection}

                            skin={playerSettings.skin}
                            base={playerSettings.base}
                            skinColor={playerSettings.skinColor}
                            hairColor={playerSettings.hairColor}
                            head={playerSettings.head}
                            eyebrow={playerSettings.eyebrow}
                            eyes={playerSettings.eyes}
                            mouth={playerSettings.mouth}
                            gender={playerSettings.gender}
                            hair={playerSettings.hair}
                            upperBody={playerSettings.upperBody}
                            lowerBody={playerSettings.lowerBody}
                            feet={playerSettings.feet}

                            hat={playerSettings.hat}
                            helmet={playerSettings.helmet}
                            topHead={playerSettings.topHead}
                            mask={playerSettings.mask}
                            tiara={playerSettings.tiara}
                            earing={playerSettings.earing}
                            facialHair={playerSettings.facialHair}
                            refPlayerPosition={refPlayerPosition}

                            assetRef={playerAssetRef}

                            onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}

                        />
                    }
                    {
                        !isSceneOrbitOnly && !isScene360 && !isSceneEndlessRun && !animatedCamera && <RemotePlayers
                            sceneName={sceneName}
                            setRemoteAction={setRemoteAction}
                            onRemoteClicked={onRemotePlayerClicked}
                            ref={listRemotePlayerRef}
                            refPlayerPosition={refPlayerPosition}
                            assetRef={playerAssetRef}
                        />
                    }
                    {<NPC assetRef={playerAssetRef} />}
                    {/* </Suspense> */}
                    {/* </Suspense> */}
                </Scene>
                {/* </Suspense> */}
                {/* </Debug> */}
            </Physics>
        </>
    )
}