import { Container } from "react-bootstrap";
import { FunctionVariableContext } from "../../context/game-context";

export const BlockAccountLogged = () =>{


    return (
        <div className="required-user-data">
            <Container>
                <h1>You Already Open In Another Browser / Tab</h1>
            </Container>
        </div>
    )
}