import { Callback } from "./callback";
import { io } from "socket.io-client";
import { MultiplayerData } from "./multiplayer-data";
import { MultiplayerUser } from "./mutiplayer-user";

class NetworkManager extends Callback {
    constructor(host, port) {
        super();
        this.host = host;
        this.port = port;

        this.me = new MultiplayerUser();
        this.ping = 0;
        this.sceneName = "";
        this.room = null;
    }

    connect = () => {
        let url = this.host;// + ":" + this.port;
        this.socket = io(url, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 15
        });

        this.initializeServerCallback();
        this.socket.connect();
    }

    changeHost = (host, port) => {

        this.host = host !== undefined ? host : this.host;
        this.port = port !== undefined ? port : this.port;

        this.socket.io.uri = encodeURI(this.host);
        // console.log(this.socket.io.uri, this.host, host);
        this.socket.connect();
    }

    disconnect = () => {
        this.socket.disconnect();
    }

    initializeServerCallback = () => {
        this.socket.on("connect", this.OnConnect)
        this.socket.on("disconnect", this.OnDisconnect);
        this.socket.on("receive", this.OnRecieve);
        this.socket.on("update_parameter", this.OnUpdateParameter);
        this.socket.on("user_list", this.OnUserList);
        this.socket.on("spawn_item", this.OnSpawnItem);
        this.socket.on("item_list", this.OnItemList);
        this.socket.on("delete_item", this.OnDeleteItem);
        this.socket.on("collect_item", this.OnCollectItem);
        this.socket.on("join_room", this.OnJoinRoom);
        this.socket.on("join_room_error", this.OnJoinRoomError);
        this.socket.on("change_room", this.OnChangeRoom);
        this.socket.on("banned", this.OnBanned);
        this.socket.on("room_full", this.OnJoinRoomFull);
        this.socket.on("broadcast", this.OnBroadcastMessage);
        this.socket.on("reconnect_attempt", this.OnReconnect);
        this.socket.on("calluser", this.OnCallUser);
        this.socket.on("callaccepted", this.OnCallAccepted);
        this.socket.on("direct_message", this.OnDirectMessage);
        this.socket.on("direct_group_message", this.OnDirectGroupMessage);
        this.socket.on("join_hosted_room", this.OnJoinHostedRoom);
        this.socket.on("join_hosted_room_error", this.OnJoinHostedRoomError);
        this.socket.on("create_hosted_room", this.OnCreateHostedRoom);
        this.socket.on("create_hosted_room_error", this.OnCreateHostedRoomError);
        this.socket.on("spawn_npc", this.OnSpawnNPC);
        this.socket.on("delete_npc", this.OnDeleteNPC);
        this.socket.on("syncedAnimation", this.OnSyncedAnimation);
        this.socket.on("updateSyncedAnimation", this.OnUpdateSyncedAnimation);
        
        this.socket.on("p", this.OnPing);

        this.socket.on("connect_error", () => {
            setTimeout(() => {
                this.socket.connect();
            }, 1000);
        });

    }

    OnSyncedAnimation = (data) =>{
        this.execute("OnSyncedAnimation", JSON.parse(data));
    }
    
    OnUpdateSyncedAnimation = (data) =>{
        this.execute("OnUpdateSyncedAnimation", JSON.parse(data));
    }

    OnCreateHostedRoom = (data) => {
        this.execute("OnCreateHostedRoom", JSON.parse(data));
    }

    OnCreateHostedRoomError = (data) => {
        this.execute("OnCreateHostedRoomError", JSON.parse(data));
    }

    OnJoinHostedRoom = (data) => {
        this.execute("OnJoinHostedRoom", JSON.parse(data));
    }

    OnJoinHostedRoomError = (data) => {
        this.execute("OnJoinHostedRoomError", JSON.parse(data));
    }

    OnCallUser = (data) => {
        this.execute("OnCallUser", data);
    }

    OnCallAccepted = (data) => {
        this.execute("OnCallAccepted", data);
    }

    OnReconnect = () => {
    }

    OnConnect = () => {
        this.me.i = this.socket.id;
        this.execute("OnConnect");
    }

    OnDisconnect = (data) => {
        if (data === "io server disconnect") {
            // the disconnection was initiated by the server, you need to reconnect manually

            setTimeout(() => {
                this.socket.connect();
            }, 1000);
        }
        // console.log("disconnected euy");
        this.execute("OnDisconnect", data);
        
        this.room = null;
        // else the socket will automatically try to reconnect
    }

    OnRecieve = (dataJson) => {
        this.execute("OnRecieve", dataJson);

        let data = JSON.parse(dataJson);

        // console.log("RECEIEVE", data);

        if (data.cmd === "join_room") {
            this.execute("OnJoinRoom", data);
        }
        else if (data.cmd === "initial_update") {
            this.lastSentParameters = "";
            this.execute("OnUserInitialUpdate", data);
        }
        else if (data.cmd === "user_left") {
            this.execute("OnUserLeftRoom", data);
        }
        else if (data.cmd === "update_parameter") {
            this.execute("OnUserUpdateParameter", data);
            // console.log(data);
        }
        else if (data.cmd === "disconnect") {
            this.execute("OnDisconnect", data);
        }
        else if (data.cmd === "spawn_item") {
            this.execute("OnSpawnCollectItem", data);
        }
        else {
            this.execute("OnResponse", data);
        }
    }

    OnDirectMessage = (dataJson) => {

        let data = JSON.parse(dataJson);
        this.execute("OnDirectMessage", data);

    }

    OnDirectGroupMessage = (dataJson) => {

        let data = JSON.parse(dataJson);
        this.execute("OnDirectGroupMessage", data);
    }

    OnUpdateParameter = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnUpdateParameter", data);
        // console.log("OnUpdateParameter", data);

        let dataObject = {};
        // console.log(data);
        for (let i = 0; i < data.d.length; i++) {
            if (data.d[i].u.i !== this.me.i && data.d[i].p) {
                // console.log((data.d[i]));
            }

            if (data.d[i].p && data.d[i].p[10] === this.sceneName && data.d[i].u.i !== this.me.i) {

            }

            if (data.d[i].c !== undefined) {
                this.execute("OnShoutoutChat", data.d[i].u, data.d[i].c);
            }

            dataObject[data.d[i].u.i] = data.d[i];
        }

        // console.log(dataObject);

        this.execute("OnUpdateParameterObject", dataObject);
    }

    OnUserList = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnUserList", data);
    }

    OnSpawnItem = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnSpawnItem", data);
    }

    OnSpawnNPC = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnSpawnNPC", data);
    }

    OnDeleteNPC = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnDeleteNPC", data);
    }

    OnItemList = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnItemList", data);
    }

    OnDeleteItem = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnDeleteItem", data);
    }

    OnCollectItem = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnCollectItem", data);
    }

    OnJoinRoom = (dataJson) =>
    {
        let data = JSON.parse(dataJson);
        this.execute("OnJoinRoom", data);

        this.room = data.data[0];
    }

    OnJoinRoomError = (data) =>
    {
        this.execute("OnJoinRoomError", data);
        
        this.room = null;
    }

    OnChangeRoom = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnChangeRoom", data);

        this.room = data.data[0];
    }

    OnBanned = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnBanned", data);
    }

    OnJoinRoomFull = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnJoinRoomFull", data);
    }

    OnBroadcastMessage = (dataJson) => {
        let data = JSON.parse(dataJson);
        this.execute("OnBroadcastMessage", data);
    }

    OnPing = () => {
        this.execute("OnPing", Date.now() - this.ping);
    }

    send = (cmd, data) => {
        this.socket.emit(cmd, data);
    }

    JoinRoom = (room) => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "join_room";
        multiPlayerData.sender = this.me;
        multiPlayerData.data.push(room);

        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    ChangeRoom = (room) => {
        let data = new MultiplayerData();
        data.cmd = "change_room";
        data.sender = this.me;
        data.data.push(room);

        this.SendParameter(data.cmd, data.toJson());
    }

    CreateHostedRoom = (room) => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "create_hosted_room";
        multiPlayerData.sender = this.me;
        multiPlayerData.data.push(room);

        // console.log("create_hosted_room", multiPlayerData);
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    CreateMixerRoom = (maxUser, userPerRound, roundCount, timePerRound) => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "create_mixer_room";
        multiPlayerData.sender = this.me;
        multiPlayerData.data.push(maxUser);
        multiPlayerData.data.push(timePerRound);
        multiPlayerData.data.push(userPerRound);
        multiPlayerData.data.push(roundCount);

        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    JoinMixerRoom = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "join_mixer_room";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    LeaveMixerRoom = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "leave_mixer_room";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    RemoveMixerRoom = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "remove_mixer_room";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    StartMixerRoom = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "start_mixer_room";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    StopMixerRoom = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "stop_mixer_room";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    SetRoomControlled = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "create_room_control";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    StopRoomControlled = () => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "stop_room_control";
        multiPlayerData.sender = this.me;
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    SetRoomPresenter = (id) => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "set_room_control_presenter";
        multiPlayerData.sender = this.me;
        multiPlayerData.data.push(id);

        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    JoinHostedRoom = (room) => {
        let multiPlayerData = new MultiplayerData();
        multiPlayerData.cmd = "join_hosted_room";
        multiPlayerData.sender = this.me;
        multiPlayerData.data.push(room);

        // console.log("join_hosted_room", multiPlayerData);
        this.SendParameter(multiPlayerData.cmd, multiPlayerData.toJson());
    }

    Ping = () => {
        this.ping = Date.now();
        this.SendParameter("p", "");
    }

    ChangeServer = (host, port) => {
        this.disconnect();

        let networkManager = this;
        setTimeout(function () {
            networkManager.changeHost(host, port);
        }, 1000);
    }

    SetMyId = (data)=>{
        this.me.uid = data;
    }

    SetMe = (data) => {
        // console.log(data);
        this.me.n = data.name;
        this.me.a = data.displayName ? data.displayName : data.name;
        this.me.h = data.hair;
        this.me.f = data.feet;
        this.me.b = data.upperBody;
        this.me.l = data.lowerBody;
        this.me.sh = data.props;
        this.me.g = data.gender;
        this.me.sc = data.sc;

        this.me.bs = data.base;
        this.me.s = data.skin;
        this.me.hd = data.head;
        this.me.eb = data.eyebrow;
        this.me.e = data.eyes;
        this.me.m = data.mouth;
        this.me.skc = data.skinColor;
        this.me.hc = data.hairColor;


        this.me.ht = data.hat;
        this.me.hl = data.helmet;
        this.me.th = data.topHead;
        this.me.mk = data.mask;
        this.me.tr = data.tiara;
        this.me.er = data.earing;
        this.me.fh = data.facialHair;
        // console.log("SET ME", this.me);
    }

    SetMeBody = (data) => {
        // console.log(data);
        this.me.bs = data.base;
        this.me.s = data.skin;
        this.me.hd = data.head;
        this.me.eb = data.eyebrow;
        this.me.e = data.eyes;
        this.me.m = data.mouth;

        this.me.h = data.hair;
        this.me.f = data.feet;
        this.me.b = data.upperBody;
        this.me.l = data.lowerBody;
        this.me.sh = data.props;
        this.me.g = data.gender;
        this.me.sc = data.sc;
        this.me.skc = data.skinColor;
        this.me.hc = data.hairColor;

        this.me.ht = data.hat;
        this.me.hl = data.helmet;
        this.me.th = data.topHead;
        this.me.mk = data.mask;
        this.me.tr = data.tiara;
        this.me.er = data.earing;
        this.me.fh = data.facialHair;
        // console.log("SET ME BODY", this.me);
    }

    SendParameter = (cmd, data) => {
        this.send(cmd, data);
    }

    SendChat(text) {
        this.UpdateParameters(text, "update_parameter-chat");
    }

    SendDirect(to, data) {
        let parameter = {
            to: to,
            data: data
        }

        this.SendParameter("direct_message", JSON.stringify(parameter));
    }

    SendDirectGroup(to, data) {
        let parameter = {
            to: to,
            data: data
        }

        this.SendParameter("direct_group_message", JSON.stringify(parameter));
    }

    UpdateParameters = (parameters, cmd) => {

        let data = new MultiplayerData();
        data.cmd = cmd;
        this.me.scene = this.sceneName;
        data.sender = this.me;
        data.data = parameters;

        this.lastSentParameters = data.toJson(data);
        this.SendParameter(cmd, this.lastSentParameters);
    }

    GetUserList = () => {
        let data = new MultiplayerData();
        data.cmd = "user_list";
        data.sender = this.me;

        this.SendParameter(data.cmd, data.toJson(data));
    }

    GetItemList = () => {
        this.SendParameter('item_list');
    }

    CheckLastParameter = (parameter, lastParameter) => {
        // console.log(parameter, lastParameter);
        if (lastParameter === undefined || lastParameter.length !== parameter.length) {
            lastParameter = [];
            for (let i = 0; i < parameter.length; i++) {
                lastParameter[i] = "";
            }
        }

        let result = true;

        for (let i = 0; i < lastParameter.length; i++) {
            if (parameter[i] !== lastParameter[i]) {
                result = false;
            }

            lastParameter[i] = parameter[i];
        }
        return result;
    }

    getIo() {
        return this.socket;
    }

    Disconnect() {
        this.socket.disconnect(true);
    }

    Reconnect() {
        this.socket.connect();
    }

    SyncAnimation(id, state){
        let data = {
            id:id,
            state:state
        }
        this.socket.emit("syncedAnimation", JSON.stringify(data));
    }
    
    UpdateSyncAnimation(id, state){
        let data = {
            id:id,
            state:state
        }
        this.socket.emit("updateSyncedAnimation", JSON.stringify(data));
    }
}

export { NetworkManager }