import { useState } from "react";
import { useEffect } from "react";
import { FunctionVariableContext, GamePlayerContext, TreasureHuntContext, VisitorTrackerContext } from "../../context/game-context";
import "../../../css/social-media-popup.css";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, Modal } from "react-bootstrap";

export const SocialMediaPopup = () => {
    const clickTenant = TreasureHuntContext(state => state.clickTenantHandler);
    const setClickSocialMediaButton = FunctionVariableContext(state => state.setClickSocialMediaButton);
    const setControlToggle = GamePlayerContext(state => state.setControlToggle);

    const panelRef = useRef();
    const [data, setData] = useState();

    const getButtonIcon = (icon) => {
        let ic = "./icon/globe.svg";

        if (icon == "instagram") {
            ic = "./icon/instagram.svg";
        }
        else if (icon == "facebook") {
            ic = "./icon/facebook.svg";
        }
        else if (icon == "twitter") {
            ic = "./icon/twitter.svg";
        }
        else if (icon == "tiktok") {
            ic = "./icon/tiktok.svg"
        }
        else if (icon == "Whatsapp") {
            ic = "./icon/Whatsapp.svg";
        }
        else if (icon == "telegram") {
            ic = "./icon/telegram.svg";
        }

        return <div className="social-icon-base"><svg className="social-icon-svg"
            data-src={ic}
        /></div>
    }

    const openTab = (url) => {
        setVisitorTrackerData("social_media_go", url)
        window.open(url, '_blank');
    }

    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);
    setClickSocialMediaButton((data) => {
        setVisitorTrackerData("social_media_click", data)
        clickTenant(data.id)
        setData(data);
        setControlToggle(false);
    });


    return data && <div className="social-media-popup">
        {/* <div className="social-media-panel" ref={panelRef}>
            <img className="social-media-photo" src={`${process.env.RESOURCE_URL}/${data.sceneName}/textures/${data.photos[0]}`} />
            {
                data.socialLinks.map((x, i) => <button key={i} className={`social-button social-button-${i + 1}`} onClick={()=>{openTab(x.link);}}>{getButtonIcon(x.icon)}</button>)
            }
            
            
        </div> */}
        <Modal
            show={true}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="bannerModal"
            className="bannerContainer"
            onHide={() => onDismissBanner}
            centered
        >
            {
                data.socialLinks.map((x, i) => <button key={i} className={`social-button social-button-${i + 1}`} onClick={()=>{openTab(x.link);}}>{getButtonIcon(x.icon)}</button>)
            }
            <button className="social-button social-button-go"onClick={()=>{openTab(data.mainLink)}}><div className="social-icon-base">GO</div></button>
            <button className="social-button social-button-close"onClick={(e) => {  setControlToggle(true);setData();}}><div className="social-icon-base"><FontAwesomeIcon icon={`fas fa-xmark`} /></div></button>
             <Image className="bannerImage" src={`${process.env.RESOURCE_URL}/${data.sceneName}/textures/${data.photos[0]}`} fluid />

        </Modal>
    </div>

}