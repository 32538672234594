import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_2e4b2') && 'TradingView' in window) {
                    new window.TradingView.MediumWidget({
                        symbols: [["UNITED TRACTORS", "IDX-UNTR"]],
                        chartOnly: false,
                        width: 1000,
                        height: 500,
                        locale: "en",
                        colorTheme: "light",
                        autosize: false,
                        showVolume: false,
                        hideDateRanges: false,
                        hideMarketStatus: false,
                        hideSymbolLogo: false,
                        scalePosition: "right",
                        scaleMode: "Normal",
                        fontFamily: "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
                        fontSize: "10",
                        noTimeScale: false,
                        valuesTracking: "1",
                        changeMode: "price-and-percent",
                        chartType: "line",
                        container_id: "tradingview_2e4b2"
                    });
                }
            }
        },
        []
    );

    return (
        <div className='tradingview-widget-container'>
            <div id='tradingview_2e4b2' />
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/symbols/IDX-UNTR/" rel="noopener" target="_blank"><span className="blue-text">United Tracktors</span></a> by TradingView
            </div>
        </div>
    );
}
