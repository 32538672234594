import { useEffect, useRef, useState } from "react";
import "../../../css/day-night-slider.css"
import { GameConfigContext } from "../../context/game-context";
import { SceneContext } from "../../context/game-context";

export const DayNigntSlider = () => {

    const inputRef = useRef();
    const taggleRef = useRef();
    const [dayNightValue, setDayNightValue] = useState(SceneContext((state) => state.time));
    const [autoTime, setAutoTime] = useState(SceneContext((state)=>state.autoTime));
    const setDayNight = useRef(SceneContext((state) => state.setTime));
    const setDayNightAuto = useRef(SceneContext((state) => state.setAutoTime));

    window.setDayNight = setDayNight;

    const onChangeDayNighthValue = (e) => {
        setDayNight.current(inputRef.current.value / 100);
    }

    const onAutoChange = (e) => {
        setDayNightAuto.current(taggleRef.current.checked);

    }

    const checkDayNight = () => {
        setTimeout(() => {
            setDayNightValue(inputRef.current.value / 100);
        }, 100);
    }

    useEffect(() => {
        checkDayNight();

        const dayNightSubs = SceneContext.subscribe((state) => state.time, (data) => {
            setDayNightValue(data);
            inputRef.current.value = data * 100;
        });

        const autoTimeSubs = SceneContext.subscribe((state)=>state.autoTime, (data)=>{
            setAutoTime(data);
        });

        taggleRef.current.checked = autoTime;

        return () => {
            dayNightSubs();
            autoTimeSubs();
        }

    }, []);



    return (
        <div>
            <label className="day-night-label">Day/Night</label>
            <div className="day-night-auto-base pull-right">
                <label className="day-night-auto-label">Auto</label>
                <input ref={taggleRef} type="checkbox" className="day-night-auto-check" onChange={onAutoChange} /><br />
            </div>
            <input ref={inputRef} type="range" min="0" max="100" defaultValue="50" className="slider2" onChange={onChangeDayNighthValue} />
        </div>
    );
}