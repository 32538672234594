import { useBox, useCompoundBody, useSphere, useTrimesh } from "@react-three/cannon";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Mesh, MeshBasicMaterial, Object3D, Quaternion, SphereGeometry, Vector3 } from "three";
import { FunctionVariableContext, GameConfigContext, GamePlayerContext, LocalPlayerContext, SceneContext } from "../../context/game-context"
import { EnldesRunObject } from "./endlesrun-object";
import io from 'socket.io-client';
import { PlayerModelAndAnimation } from "../player-model-animation";
import CircularJSON from "circular-json";

export const EndlessRunGame = () => {

    const host = "https://minigame.virtu.ninja";
    const [isConnected, setIsConnected] = useState();
    const [lastPong, setLastPong] = useState(null);

    const setLoading = GameConfigContext((state) => state.setLoading);

    let [endlessRunTrack, setEndlessRunTrack] = useState(SceneContext((state) => state.endlessRunTrack));
    let [endlessRunPlayer, setEndlessRunPlayer] = useState(SceneContext((state) => state.endlessRunPlayer));
    let [currentTrack, setCurrentTrack] = useState([]);
    let [player, setPlayer] = useState([]);
    let [playerPosition, setPlayerPosition] = useState([]);
    let [splines, setSplines] = useState([]);
    let [spheres, setSpheres] = useState([]);
    let [playerObject, setLocalPlayerObject] = useState();
    let playerCollider = useRef();
    let playerRef = useRef();
    const socket = useRef();
    const refPlayerModel = useRef([]);
    const assetRef = useRef({});
    let playerSetting = useRef(LocalPlayerContext(state => state));
    const setCountDown = GamePlayerContext(state => state.setCountDown);
    const totalDistance = useRef();

    let upDirection = new Vector3(0, 1, 0);

    let [isStarted, setStarted] = useState(false);
    let currentTime = useRef(0);
    let currentDistance = useRef(0);
    let trackID = useRef([]);
    let [trackIsLoaded, setTrackIsLoaded] = useState(false);

    let playerSpeed = 5;
    let playerSpeedIncrement = 0.1;
    let targetDistanceRef = useRef();

    let playerRotationSpeed = 1.1;

    let playerAnchor = useRef(new Object3D());
    let controlCam = useRef(true);

    let currentState = useRef('');
    let currentOffset = useRef(0);

    let playerInBoatPosition = [
        new Vector3(0.369, 0.6, 0.681),
        new Vector3(-0.369, 0.6, 0.681),
        new Vector3(0.369, 0.6, -0.681),
        new Vector3(-0.369, 0.6, -0.681)
    ];

    const setButtonLeft = FunctionVariableContext(state => state.setEndlessRunButtonLeft);
    const setButtonRight = FunctionVariableContext(state => state.setEndlessRunButtonRight);
    let setEndlessRunState = SceneContext((state) => state.setEndlessRunState);
    let setEndlessRunScore = SceneContext((state) => state.setEndlessRunScore);
    let setEndlessRunSeat = SceneContext((state) => state.setEndlessRunSeat);

    const { camera } = useThree();

    const moveTowards = (current, target, maxDelta) => {
        if (Math.abs(target - current) <= maxDelta) {
            return target;
        }

        return current + Math.sign(target - current) * maxDelta;
    }

    const onHitObstacle = (id)=>{
        socket.current.emit('obstacle', id);
    }

    const onHitReward = (id)=>{
        socket.current.emit('reward', id);
    }

    useEffect(() => {
        setLoading(false);

        setButtonLeft(() => {
            if (socket.current) socket.current.emit('offset', -0.25);
        });

        setButtonRight(() => {
            if (socket.current) socket.current.emit('offset', 0.25);
        });

        let setLocalPlayerSettings = LocalPlayerContext.subscribe((state) => state, (data) => {
            
            // const updatedLocalPlayer = CircularJSON.parse(localStorage.getItem("local_player_setting"));
            playerSetting.current = data;
        });

        let endlessRunTrackSubs = SceneContext.subscribe((state) => state.endlessRunTrack, (data) => {
            setEndlessRunTrack(data);
        });

        let endlessRunPlayerSubs = SceneContext.subscribe((state) => state.endlessRunPlayer, (data) => {
            setEndlessRunPlayer(data);
        });

        return (() => {
            endlessRunTrackSubs();
            endlessRunPlayerSubs();
            setLocalPlayerSettings();
            countDownSubs();
        });
    }, []);

    useEffect(() => {

        if (trackID.current.length == 0) return;
        if (trackIsLoaded) return;

        let track = [];
        let trackSplines = [];

        for (let i = 0; i < trackID.current.length; i++) {
            let t = endlessRunTrack[trackID.current[i]].track.clone();

            let splines = [];
            for (let j = 0; j < endlessRunTrack[trackID.current[i]].splines.length; j++) {
                const spline = endlessRunTrack[trackID.current[i]].splines[j].clone();
                t.add(spline);

                if (j < endlessRunTrack[trackID.current[i]].splines.length - 1) {
                    trackSplines.push(spline);
                }

                splines.push(spline);
            }

            t.splines = splines;

            if (i > 0) {
                let splineLength = track[i - 1].object.splines.length;
                let endPoint = track[i - 1].object.splines[splineLength - 1];

                let endPosition = new Vector3();
                let endQuaternion = new Quaternion();
                endPoint.getWorldPosition(endPosition);
                endPoint.getWorldQuaternion(endQuaternion);

                t.position.set(endPosition.x, endPosition.y, endPosition.z);
                t.quaternion.set(endQuaternion.x, endQuaternion.y, endQuaternion.z, endQuaternion.w);
            }
            else {

                t.position.set(0, 0, 0);
                t.rotation.set(0, 0, 0, 'XYZ');
            }
            track.push({ object: t, obstacles: endlessRunTrack[trackID.current[i]].obstacles, rewards: endlessRunTrack[trackID.current[i]].rewards });
        }

        let spline = [];
        totalDistance.current = 0;
        for (let i = 0; i < trackSplines.length; i++) {
            if (i < trackSplines.length - 1 && i != 0) {
                let worldPosition = new Vector3();
                trackSplines[i + 1].getWorldPosition(worldPosition);

                let currentPosition = new Vector3();
                trackSplines[i].getWorldPosition(currentPosition);

                totalDistance.current += currentPosition.distanceTo(worldPosition);

                spline.push({
                    object: worldPosition,
                    distance: totalDistance.current,
                });
                trackSplines[i].lookAt(trackSplines[i + 1].position.x, trackSplines[i + 1].position.y, trackSplines[i + 1].position.z);
            }
            else if (i == 0) {
                let worldPosition = new Vector3();
                trackSplines[i + 1].getWorldPosition(worldPosition);
                spline.push({
                    object: worldPosition,
                    distance: totalDistance.current
                });
            }
        }

        socket.current.emit('maxDistance', totalDistance.current);

        let player = endlessRunPlayer.object.clone();
        let positions = [];
        for (let i = 0; i < endlessRunPlayer.positions.length; i++) {
            let position = endlessRunPlayer.positions[i].clone();
            player.add(position);
            positions.push(position);
        }

        player.position.set(spline[0].object.x, spline[0].object.y, spline[0].object.z);
        player.lookAt(spline[1].object);

        setPlayer(player);
        setPlayerPosition(positions);

        let sp = [];
        for (let i = 0; i < spline.length; i++) {
            const geometry = new SphereGeometry(1, 32, 16);
            const material = new MeshBasicMaterial({ color: 0xffff00 });
            const sphere = new Mesh(geometry, material);
            sphere.position.set(spline[i].object.x, spline[i].object.y, spline[i].object.z);
            sp.push(sphere);
        }

        setSpheres(sp);
        setSplines(spline);
        window.tracks = track;
        setCurrentTrack(track);
        setTrackIsLoaded(true);
        socket.current.emit('ready');

    }, [trackID.current]);

    useFrame((scr, dt) => {
        if (splines.length == 0 && !player) return;

        if (!isStarted) return;

        currentTime.current += dt;
        currentDistance.current = moveTowards(currentDistance.current, targetDistanceRef.current, dt * 10);

        let splineID = 0;
        for (let i = 0; i < splines.length; i++) {
            if (currentDistance.current < splines[i].distance) {
                splineID = i;
                break;
            }
        }

        if (splineID >= splines.length || splineID == 0) return;

        let targetDistance = (currentDistance.current - splines[splineID - 1].distance) / (splines[splineID].distance - splines[splineID - 1].distance);

        let direction = new Vector3();
        direction.subVectors(splines[splineID - 1].object, splines[splineID].object);
        direction.normalize();
        let rightDirection = new Vector3();
        rightDirection.crossVectors(direction, upDirection);
        rightDirection.multiplyScalar(currentOffset.current);

        let distancePosition = splines[splineID].object.clone();
        distancePosition.sub(splines[splineID - 1].object);
        distancePosition.multiplyScalar(targetDistance);
        let targetPosition = splines[splineID - 1].object.clone();
        targetPosition.add(distancePosition);
        targetPosition.sub(rightDirection);

        playerAnchor.current.position.lerp(targetPosition, dt * 2);
        player.position.lerp(targetPosition, dt * 2);

        let lookAtTarget = splines[splineID].object.clone();
        lookAtTarget.sub(rightDirection);
        playerAnchor.current.lookAt(lookAtTarget);
        player.quaternion.slerp(playerAnchor.current.quaternion, dt * playerRotationSpeed);

        playerSpeed += playerSpeedIncrement * dt;

    });

    useFrame(()=>{
        if(refPlayerModel.current.length == 0) return;
    
        for (let i = 0; i < refPlayerModel.current.length; i++) {
            if(refPlayerModel.current[i].current.setAnimation) refPlayerModel.current[i].current.setAnimation('sit');
            refPlayerModel.current[i].current.position.set(playerInBoatPosition[i].x, playerInBoatPosition[i].y, playerInBoatPosition[i].z);
        }
    });

    useEffect(() => {

        socket.current = io(host, {
            'reconnection': false,
            'reconnectionDelay': 500,
            'reconnectionAttempts': 10
        });

        socket.current.on('connect', () => {
            setIsConnected(true);
            socket.current.emit("match_making", playerSetting.current);
        });

        socket.current.on('disconnect', () => {
            setIsConnected(false);

            setCountDown('FINISH');
        });

        socket.current.on('update', (data) => {
            // console.log(data);
            if (data.state == 'counter') {
                setCountDown(data.counter);

            }
            else if (data.state == 'play') {
                setCountDown(-1);
                setStarted(true);

                targetDistanceRef.current = data.position;
                currentOffset.current = data.offset;

            }
            else if (data.state == 'result') {
                setCountDown(-1);
            }
            else {
                setCountDown(-1);
            }

            if (trackID.current.length == 0) {
                trackID.current = data.track;
            }

            setEndlessRunScore(data.score);


            if (currentState.current != data.state) {
                currentState.current = data.state;
                setEndlessRunState(data.state);
                if (currentState.current == 'counter') {
                    let p = [];
                    for (let i = 0; i < data.players.length; i++) {
                        const player = data.players[i];

                        if(player.id == playerSetting.current.id){
                            setEndlessRunSeat(i);
                        }
                        refPlayerModel.current[i] = {current:null};
                        p.push(
                            <PlayerModelAndAnimation
                                key={i}
                                playerModel={refPlayerModel.current[i]}
                                playerAction={["sit"]}
                                skin={player.skin}
                                base={player.base}
                                skinColor={player.skinColor}
                                hairColor={player.hairColor}
                                head={player.head}
                                eyebrow={player.eyebrow}
                                eyes={player.eyes}
                                mouth={player.mouth}
                                hair={player.hair}
                                upperBody={player.upperBody}
                                lowerBody={player.lowerBody}
                                feet={player.feet}
                                playerId={player.playerId}
                                colliderRadius={player.radius}

                                hat={player.hat}
                                helmet={player.helmet}
                                topHead={player.topHead}
                                mask={player.mask}
                                tiara={player.tiara}
                                earing={player.earing}
                                facialHair={player.facialHair}

                                assetRef={assetRef}
                            />
                        );
                    }

                    setLocalPlayerObject(p);
                }
            }

        });

        return () => {
            socket.current.off('connect');
            socket.current.off('disconnect');
            socket.current.off('update');
        };
    }, []);

    useFrame((scr, dt) => {
        if (splines.length == 0 || !player) return;

        let cameraTargetPosition = new Vector3(0, 5, -3);
        player.localToWorld(cameraTargetPosition);

        if (controlCam.current) {
            camera.position.lerp(cameraTargetPosition, dt);
            camera.lookAt(player.position);
        }
    });

    return (
        trackIsLoaded && <group>
            {currentTrack.length > 0 && currentTrack.map((x, i) =>
                <primitive key={i} object={x.object}>
                    {
                        x.obstacles.map((y, j) => <EnldesRunObject onHit={onHitObstacle} delay={i + (i * j)} key={j} data={y} isObstacle={true} parent={x.object} objectId={`obstacle_${i}_${j}`}/>)
                    }
                    {
                        x.rewards.map((y, j) => <EnldesRunObject onHit={onHitReward} delay={i + (i * j)} key={j} data={y} isObstacle={false} parent={x.object} objectId={`rewards_${i}_${j}`}/>)
                    }
                </primitive>
            )}
            {
                player &&
                <primitive ref={playerRef} object={player} >
                    {playerObject}
                </primitive>
            }
            {endlessRunPlayer && <PlayerCollider geometry={endlessRunPlayer.collider} api={playerCollider} player={playerRef} isHorizontal={true} radius={0.7} />}
        </group>
    );
}

const PlayerCollider = ({ geometry, api, player, isHorizontal, radius }) => {

    const [playerCollider, colliderAPI] = useCompoundBody(() => ({
        mass: 1,
        type: "Dynamic",
        // args: [geometry.attributes.position.array, geometry.index.array],
        args: [1],
        collisionFilterMask: 3,
        collisionFilterGroup: 2,
        onCollideBegin: (collider) => {
            // console.log("KENA");
        },
        onCollideEnd: (collider) => {
            // console.log("KENA");
        },
        onCollide: () => {
            // console.log("KENA");
        },
        shapes: [
            { args: [radius], position: [0, 0, 0], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, isHorizontal ? 0 : radius, !isHorizontal ? 0 : radius], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, isHorizontal ? 0 : radius * 2, !isHorizontal ? 0 : radius * 2], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, isHorizontal ? 0 : radius * 3, !isHorizontal ? 0 : radius * 3], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
        ]
    }));


    useEffect(() => {

        let positionSubs = colliderAPI.position.subscribe((v) => {
            let difPos = new Vector3(v[0], v[1], v[2]);

            let playerPosition = new Vector3();
            player.current.getWorldPosition(playerPosition);
            // playerPosition.sub(difPos);
            // playerPosition.multiplyScalar(50);

            // console.log(difPos);

            let worldQuaternion = new Quaternion();
            player.current.getWorldQuaternion(worldQuaternion);

            colliderAPI.position.set(playerPosition.x, playerPosition.y, playerPosition.z);
            colliderAPI.quaternion.set(worldQuaternion.x, worldQuaternion.y, worldQuaternion.z, worldQuaternion.w);
        });

        return () => {
            positionSubs();
        }
    }, []);

    useFrame((src, dt) => {
    })

    useEffect(() => {
        api.current = colliderAPI;
    }, []);

    return <group ref={playerCollider}></group>
}