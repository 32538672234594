import React, { useState, useEffect, useRef } from "react";

export function RhythmGameIframe(props) {
  const mainIframe = useRef(null);

  useEffect(() => {
    let call = {
      command: "Startup",
      data: {}
    };
    mainIframe.current.contentWindow.postMessage(call, '*');
    mainIframe.current.contentWindow.focus();
  }, []);

  const containerStyle = {
    margin: "auto",
    padding: 0,
    height: "600px",
    width: "400px",
    position: "relative",
    overflow: "hidden",
    backgroundColor : "#00000000"
  };

  const onMessageReceivedFromIframe = React.useCallback(
    event => {
      onCommandReceived(event.data);
    },
    []
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  async function onCommandReceived(data) {
    // console.log("onCommandReceived");
    // console.log(JSON.stringify(data));

    if (!data.command) return;

    if (data.command === "GameStarted") {
      // TODO: 
    }
    else if (data.command === "GameFinished") {
      // TODO: 
    }
  }

  return (
    <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
      <iframe ref={mainIframe} className="ddr-iframe" style={containerStyle} title="Game iFrame" id="ddr-iframe" src={"https://rhythmgame.animaverse.com/" + "nightdance/index.html"} frameBorder="0" scrolling="no" allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="no"></iframe>
    </div>
  );
};