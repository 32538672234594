import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react"
import { CubeTextureLoader, DoubleSide, Mesh, MeshBasicMaterial } from "three";
import { FunctionVariableContext, GameConfigContext, SceneContext } from "../../context/game-context";
import LoadMaterial from "./load-material";
import LoadObject from "./load-static-object";
import { PopupObject } from "./PopupObject";
import { CloneGeometryAndSetMatrix, LoadClonedGeometry } from "./utils";

export const Scene360 = ({ panoramicID, sceneName, changePanoramicID, assetRef, meshes, materials }) => {

    const { scene } = useThree();

    const [scene360Data, setScene360Data] = useState(SceneContext((state) => state.scenePanoramic));
    const [cubemap360, set360Cubemap] = useState(SceneContext((state) => state.cubemap360));
    const [nextButtons, setNextButtons] = useState([]);
    const [popups, setPopups] = useState([]);
    const setScene = SceneContext((state) => state.setScene);
    const setChangePanoramicID = FunctionVariableContext((state)=> state.setChangePanoramicID);

    const setLoading = GameConfigContext((state)=>state.setLoading);

    let loadedMaterial = useRef([]);
    const loadedGeometry = useRef([]);
    
    const playerReadyCount = useRef(0);
    const playerReady = useRef(false);

    useEffect(() => {

        setChangePanoramicID((data)=>{
            nextButtonClicked(data);
        });

        let scene360DataSubs = SceneContext.subscribe((state) => state.scenePanoramic, (data) => {
            setScene360Data(data);
        });

        let cubemap360Subs = SceneContext.subscribe((state) => state.cubemap360, (data) => {
            set360Cubemap(data);
        });

        return () => {
            scene360DataSubs();
            cubemap360Subs();
        }
    }, []);

    
    useFrame((src, delta) => {
        
        if(!playerReady.current) {
            if(delta < 0.02)
            {
                playerReadyCount.current ++;
            }
            
            if(playerReadyCount.current > 200){
                setLoading(false);
                playerReady.current = true;
            }
        }
    });

    const LoadAllButton = async () => {
        let buttons = [];
        for (let i = 0; i < scene360Data[panoramicID].nextButotns.length; i++) {
            let data = scene360Data[panoramicID].nextButotns[i];

            if (loadedMaterial.current.find(x => x.name === data.button.material) === undefined) {
                const getMaterial = await LoadMaterial(data.button.material, sceneName, true, assetRef, materials);
                loadedMaterial.current.push({ name: data.button.material, material: getMaterial.material });
            }

            let material = loadedMaterial.current.find(x => x.name === data.button.material);
            // console.log("INI", material);
            let geometry = await LoadObject(data.button.meshid, sceneName, true, assetRef, meshes);
            let clonedGeometry = CloneGeometryAndSetMatrix(geometry, data.button.position, data.button.rotation, data.button.scale);

            let newMat = new MeshBasicMaterial({ map: material.material.map, side: DoubleSide });
            buttons.push({ geometry: clonedGeometry, material: newMat, target: data.target });
        }

        let popup = [];
        for (let i = 0; i < scene360Data[panoramicID].popups.length; i++) {

            let data = scene360Data[panoramicID].popups[i];
            if (loadedMaterial.current.find(x => x.name === data.obj.material) === undefined) {
                const getMaterial = await LoadMaterial(data.obj.material, sceneName, true, assetRef, materials);
                loadedMaterial.current.push({ name: data.obj.material, material: getMaterial.material });
            }

            let material = loadedMaterial.current.find(x => x.name === data.obj.material);

            let geometry = await LoadObject(data.obj.meshid, sceneName, true, assetRef, meshes);
            let clonedGeometry = CloneGeometryAndSetMatrix(geometry, data.obj.position, data.obj.rotation, data.obj.scale);

            popup.push(
                <PopupObject
                    key={i}
                    material={material}
                    loadedGeometry={loadedGeometry}
                    geometry={clonedGeometry}
                    sceneName={sceneName}
                    object={data.obj}
                    popupID={data.popupID}
                    keyObject={i}
                    keepPositionOriginal={true}
                />
            );
        }

        setPopups(popup);
        setNextButtons(buttons);
    }

    const nextButtonClicked = (scene) => {
        let id = scene360Data.findIndex(x => x.panoramicID == scene);
        if(id >= 0 ){
            if (changePanoramicID && panoramicID != id) {
                setPopups([]);
                setNextButtons([]);
                setTimeout(() => {
                    changePanoramicID(id);
                }, 300);
            }
        }
        else
        {
            window.history.replaceState(null, null, "/" + scene);
            setScene(scene);
        }
       
    }

    useEffect(() => {
        if (!scene360Data[panoramicID]) return;

        let background = cubemap360[scene360Data[panoramicID].panoramicID];
        scene.background = background;

        LoadAllButton();

    }, [panoramicID]);
    return (
        <group>
            {
                nextButtons.map((x, i) => <mesh key={i} geometry={x.geometry} material={x.material} onClick={() => { nextButtonClicked(x.target) }} />)
            }
            {
                popups
            }
        </group>);
}