import { useEffect, useRef, useState } from "react";
import '../../../css/player-duet-animation.css'

export const PlayerDuetAnimation = ({ onRemoteClicked, onRequestDuetAnimation, onReplyDuetAnimation, onGetRequestDuetAnimation }) => {

    const [showToggle, setShowToggle] = useState({ status: false });
    const [showRequest, setShowRequestToggle] = useState({ status: false });
    const [showReject, setShowReject] = useState({ status: false });
    
    const duetBox = useRef();
    let [top, setTop] = useState(0);
    let [left, setLeft] = useState(0);
    let [targetID, setTargetID] = useState();
    let [targetsID, setTargetsID] = useState();
    let [targetAnimationID, setTargetAnimationID] = useState();
    let [replyWording, setReplyWording] = useState();
    let [timer, setTimer] = useState(0);
    let currentTime = useRef(0);
    const timerFunction = useRef();
    const timerReject = useRef();

    let actionName = {
        15: "Hand Shake",
        16: "High Five"
    }

    onRemoteClicked.current = (id) => {
        // console.log("HERE");
        setShowToggle({ status: true });
        setShowReject({ status: false });
        setTargetID(id);
        clearTimeout(timerReject.current);
    }

    const startTimer = () => {
        currentTime.current--;

        if (currentTime.current > 0) {
            // console.log(currentTime.current);
            setTimer(currentTime.current);

            timerFunction.current = setTimeout(() => {
                startTimer();
            }, 1000);
        } else {
            setShowToggle({ status: false });
            setShowRequestToggle({ status: false });
        }
    }

    const showRequestForm = (data) => {
        // console.log(data, actionName);
        let wording = data.from.a + " want to " + actionName[data.data.animationID] + " with you.";
        // console.log(wording);
        setTargetID(data.from.i);
        setTargetsID([data.from.i, data.to]);
        setReplyWording(wording);
        setTargetAnimationID(data.data.animationID);

        setShowRequestToggle({ status: true });
        setShowReject({ status: false });
        clearTimeout(timerReject.current);
        currentTime.current = 6;

        if (timerFunction && timerFunction.current) clearTimeout(timerFunction.current);
        startTimer();
    }

    const showRejectForm = (data) => {

        let wording = data.from.a + " rejected to " + actionName[data.data.animationID] + " with you.";
        setShowReject({ status: true });
        setReplyWording(wording);

        timerReject.current = setTimeout(()=> {
            setShowReject({ status: false });
        }, 5000);
    }

    onGetRequestDuetAnimation.current = (data) => {

        if (data.data.action === "duet") {
            showRequestForm(data);
        }
        else if (data.data.action === "duet-reject") {
            showRejectForm(data);
        }
    }

    useEffect(() => {

        if (!duetBox) return;
        if (!duetBox.current) return;


    }, [showToggle]);

    const onMouseUp = (e) => {
        setTop(e.clientY);
        setLeft(e.clientX);
    }

    const onClick = (action) => {
        if (onRequestDuetAnimation && onRequestDuetAnimation.current) onRequestDuetAnimation.current(targetID, action);
    }

    const onApply = (action) => {
        setShowRequestToggle({ status: false });
        if (onReplyDuetAnimation && onReplyDuetAnimation.current) onReplyDuetAnimation.current(action, action ? targetsID : targetID, targetAnimationID);

        if (!action) {
            clearTimeout(timerFunction.current);
        }
    }

    useEffect(() => {
        document.addEventListener("mouseup", onMouseUp);
    });

    return (
        showToggle.status ?
            <div
                className="player-duet-animation"
                onClick={() => {
                    setShowToggle({ status: false });
                    setShowRequestToggle({ status: false });
                }}>
                <div ref={duetBox} className="player-duet-request" style={{ position: 'absolute', top: top, left: left }}>
                    <button className="player-duet-request-button" onClick={(e) => onClick(15)}>Hand Shake</button>
                    <button className="player-duet-request-button" onClick={(e) => onClick(16)}>High Five</button>
                </div>
            </div>
            : showRequest.status ?
                <div className="player-duet-animation">
                    <div ref={duetBox} className="player-duet-reply" >
                        <div className="player-duet-header">{replyWording}</div>
                        <div className="player-duet-timer">{timer}</div>
                        <button className="player-duet-reply-button-yes" onClick={(e) => onApply(true)}>Yes</button>
                        <button className="player-duet-reply-button-no" onClick={(e) => onApply(false)}>No</button>
                    </div>
                </div>
            : showReject.status ?
                <div ref={duetBox} className="player-duet-reject" >
                    {replyWording}
                </div>
            : <></>
    );
}