import { useEffect, useReducer, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "../../../css/capture-photo.css";
import { FunctionVariableContext, GamePlayerContext, TreasureHuntContext, VisitorTrackerContext } from "../../context/game-context";

export const CapturePhoto = () => {

    const setCapturePhoto = GamePlayerContext(state => state.setCapturePhoto);
    const capturePhoto = FunctionVariableContext(state => state.capturePhoto);
    const [capturedPhoto, setCapturedPhotoState] = useState(GamePlayerContext(state => state.capturedPhoto));
    const setCapturedPhoto = GamePlayerContext(state => state.setCapturedPhoto);
    const addPoint = TreasureHuntContext(state => state.addPoint);
    const imageResultRef = useRef();

    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);

    useEffect(() => {

        let capturePhotoSubs = GamePlayerContext.subscribe(state => state.capturePhoto, (data) => {
            setCapturePhoto(data);
        });

        let capturedPhotoSubs = GamePlayerContext.subscribe(state => state.capturedPhoto, (data) => {
            if(data != null) {
                setVisitorTrackerData("user_taken_picture", "true")
            }

            setCapturedPhotoState(data);
        });

        return (() => {
            capturePhotoSubs();
            capturedPhotoSubs();
        });
    }, []);

    useEffect(() => {
        if (capturedPhoto && imageResultRef.current) {
            imageResultRef.current.src = capturedPhoto;
        }
    }, [capturedPhoto, imageResultRef.current]);

    const onTryAgain = () => {
        setCapturedPhoto(null);
    }

    const onDownload = () => {
        var a = document.createElement('a');
        a.href = capturedPhoto;
        a.download = `photo_${Date.now()}.jpg`;
        a.click();
        setVisitorTrackerData("user_download_taken_picture", "true")
    }

    const shareImage = async () => {
        const response = await fetch(capturedPhoto);
        const blob = await response.blob();
        const filesArray = [
            new File(
                [blob],
                'meme.jpg',
                {
                    type: "image/jpeg",
                    lastModified: new Date().getTime()
                }
            )
        ];
        const shareData = {
            files: filesArray,
        };
        navigator.share(shareData);
        setVisitorTrackerData("user_share_taken_picture", "true")
    }

    const onShare = () => {
        // setCapturedPhoto(null);
        // share
        shareImage();
        addPoint("share_social_media", "share_social_media")
    }


    return (<div className="capture-photo">
        {
            !capturedPhoto && <div>

                <div className="capture-button-base">
                    <button className="capture-button" onClick={() => {
                        capturePhoto();
                    }}></button>
                </div>
                <svg
                    data-src="./img/photo/photo_frame.svg"
                    fill="currentColor"
                    className="capture-frame"
                />
            </div>
        }
        {
            capturedPhoto && <div className="photo-result">

                <div className="image-result-2">
                    <div className="image-base">
                        <img ref={imageResultRef} className="image-result" />
                        <Row>
                            <Col>
                                <Button variant="default-dark" className="w-100 button-download" onClick={onDownload}>Download</Button>
                            </Col>
                            <Col>
                                <Button variant="default-dark" className="w-100 button-download" onClick={onTryAgain}>Try Again</Button>
                            </Col>
                            <Col>
                                <Button variant="default-dark" className="w-100 button-download" onClick={onShare}>Share</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        }
        <button className="close-button" onClick={() => {
            setCapturedPhoto(null);
            setCapturePhoto(false);
        }}>x</button>

    </div>);
}