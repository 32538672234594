import * as THREE from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import { Debug, useCompoundBody } from "@react-three/cannon";
import { PlayerName } from "./player-component/player-name";
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { PlayerShoutout } from './player-component/player-shoutout';
import { PlayerControl } from './player-component/context/player-control';
import { HoverBoard } from './player-component/context/hoverboard-movement';
import { PlayerSpeaker } from './player-component/player-speaker';
import { FunctionVariableContext, GameConfigContext, GamePlayerContext, JoystickContext, SceneContext } from '../context/game-context';
import { PlayerAnimation, PlayerModelAndAnimation } from './player-model-animation';

const CharacterMovement = ({ setHoverBoard, sceneName, isHoverBoard, ...props }) => {


    const setIsHoverboard = GamePlayerContext((state) => state.setIsHoverboard)
    const [hoverBoard, setHoverBoardToggle] = useState(false);
    const [lastSceneName, setLastSceneName] = useState(sceneName);

    const setHover = (condition) => {
        setHoverBoardToggle(condition);
        setIsHoverboard(condition);
        isHoverBoard.current = condition;
        // console.log(condition);
    }

    if (setHoverBoard !== undefined) {
        setHoverBoard.current = setHover;
    }

    if (sceneName !== lastSceneName) {
        setLastSceneName(sceneName);
    }

    const position = JoystickContext((state) => state.position);
    const playerAnalogInput = useRef(position);
    const jump = JoystickContext((state) => state.jump);
    const down = JoystickContext((state) => state.down);
    const jumpInput = useRef(jump);
    const downInput = useRef(down);


    useEffect(() => {

        const joystickSub = JoystickContext.subscribe(state => state.position, (data) => {
            playerAnalogInput.current = data;
        })

        const jumpSub = JoystickContext.subscribe(state => state.jump, (data) => {
            jumpInput.current = data;
        })

        const downSub = JoystickContext.subscribe(state => state.down, (data) => {
            downInput.current = data;
        })
        return () => {
            joystickSub();
            jumpSub();
            downSub();
        }
    }, [])



    return (
        <group>
            {
                // console.log("RENDER CONTROLL")
            }
            {
                hoverBoard && <HoverBoard sceneName={sceneName} {...props} analogInput={playerAnalogInput} jumpInput={jumpInput} downInput={downInput}></HoverBoard>
            }
            {
                !hoverBoard && <PlayerControl sceneName={sceneName} {...props} analogInput={playerAnalogInput} jumpInput={jumpInput} downInput={downInput}></PlayerControl>
            }
        </group>
        // <ControllerState hoverBoard={hoverBoard} {...props}/>
    );
}

// const ControllerState = ({hoverBoard, ...props}) => {

//     console.log(props);

//     return (
//         hoverBoard ? <HoverBoard {...props}></HoverBoard> : <PlayerControl {...props}></PlayerControl>
//     );
// }

export default function Player({
    localPlayer, localPlayerPosition,
    isLocal, position, rotation, forward,
    name,
    skin, base, skinColor, hairColor, head, eyebrow, eyes, mouth, upperBody, feet, hair, lowerBody, hat, helmet, topHead, mask, tiara, earing, facialHair,
    playerId, colliders, orbitControl, setCameraTarget,
    onSelectEmoji, setMessage, setHoverBoard, sceneName, setPlayerPosition, onPlayerSit,
    audioId, assetRef, onLocalPlayerFinishLoad, refPlayerPosition
}) {
    const refPlayerModel = useRef();
    const animationClips = useRef();
    const groupRef = useRef();
    const onCharacterCollideBegin = useRef();
    const onCharacterCollideStop = useRef();
    const onRayHit = useRef();
    const setPlayerMessage = useRef();
    const playerBase = useRef();
    const playerSyncSpeed = 25;
    const playerHoverBoardSyncSpeed = 25;
    const apiPosition = useRef(new Vector3(0, 0, 0));
    const isHoverBoard = useRef(false);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));
    const onFBXUpdated = useRef();
    const setLocalPlayerObject = GamePlayerContext((state) => state.setLocalPlayerObject);
    const localPlayerLastPosition = GamePlayerContext((state) => state.localPlayerLastPosition);
    const [isTeleporting, setIsTeleporting] = useState();
    const [npcSelected, setNpcSelected] = useState(SceneContext(state => state.npcSelected));
    const setLoading = GameConfigContext((state) => state.setLoading);
    const [showPlayerName, setShowPlayerName] = useState(true);
    const setFirstAngle = GamePlayerContext(state => state.setFirstAngle);

    const playerReadyCount = useRef(0);
    const playerReady = useRef(false);
    // console.log("LOCALLASTPOSITION", localPlayerLastPosition);

    if (!isLocal) setMessage = setPlayerMessage;

    const [isLoading, setIsLoading] = useState(GameConfigContext((state) => state.loading));
    const cameraDistance = useRef(0);

    // console.log("PlayerSettings", name, gender, body, shoes, hair, pant, props);

    useEffect(() => {

        let npcSubs = SceneContext.subscribe(state => state.npcSelected, data => {
            setNpcSelected(data);

            if (!isFPS.current) playerBase.current.visible = (data == null);
        });

        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {
            isFPS.current = data;
        });

        let loadingSubs = GameConfigContext.subscribe((state) => state.loading, (data) => {
            setIsLoading(data);
        });

        let loadSceneFinishSubs = GameConfigContext.subscribe((state) => state.loadSceneFinish, (data) => {
            loadSceneFinishSubs.current = data;
        })

        const listPlayerSub = GamePlayerContext.subscribe(state => state.listPlayer, (data) => {
            // console.log("FINISH LOAD?");
        });

        let sceneSubs = SceneContext.subscribe(state => state.scene, (data) => {
            // console.log(data);
        });

        let cameraDistanceSubs = GamePlayerContext.subscribe(state => state.cameraDistance, (data) => {
            // console.log(data);
            cameraDistance.current = data;
            setShowPlayerName(data > 0.4);
        });

        return () => {
            sceneSubs();
            npcSubs();
            firstPersonSubs();
            loadingSubs();
            listPlayerSub();
            cameraDistanceSubs();
        };
    }, []);

    const playerPhysicsMaterial = {
        friction: 0,
        restitution: 0,
        contactEquationStiffness: 0,
        contactEquationRelaxation: 1,
        frictionEquationStiffness: 0,
        frictionEquationRelaxation: 1
    }

    onFBXUpdated.current = (fbx) => {
        window.playerObject = fbx;
        // console.log("UPDATE NIH", fbx.uuid, refPlayerModel.current.uuid);
    }

    let radius = 0.3;
    // console.log("PLAYER FIRST POSITION", position);

    if (localPlayerLastPosition[0] != 0 && localPlayerLastPosition[1] != 0 && localPlayerLastPosition[2] != 0) {
        position[0] = localPlayerLastPosition[0];
        position[1] = localPlayerLastPosition[1];
        position[2] = localPlayerLastPosition[2];
    }


    let apiProperties = {
        args: [1],
        type: isLocal ? "Dynamic" : "Dynamic",
        mass: 10,
        position: [position[0], position[1] + (radius * 2) + 0.5, position[2]],
        isTrigger: !isLocal,
        allowSleep: false,
        linearDamping: isLocal ? 0 : 1,
        material: playerPhysicsMaterial,
        collisionFilterMask: 1,
        collisionFilterGroup: 2,
        fixedRotation: true,
        shapes: [
            { args: [radius], position: [0, 0, 0], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, radius, 0], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, radius * 2, 0], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
            { args: [radius], position: [0, radius * 3, 0], rotation: [0, 0, 0], type: 'Sphere', collisionFilterMask: 3 },
        ]
    }

    const [ref, api] = useCompoundBody(() => (apiProperties));

    const setOnTeleportDetection = FunctionVariableContext((state) => state.setOnTeleportDetection);

    const forcePosition = (position, i, forward) => {

        if (setPlayerPosition.current) setPlayerPosition.current(position[0], position[1], position[2]);
        i--;

        if (forward) {
            const currPos = new Vector3(position[0], position[1], position[2]);
            const forwardPos = new Vector3(forward[0], forward[1], forward[2]);
            forwardPos.add(currPos);

            let angle = Math.atan2((forwardPos.x - currPos.x), (forwardPos.z - currPos.z));
            setFirstAngle(angle);

            if (refPlayerModel.current) refPlayerModel.current.rotation.set(0, angle, 0, 'XYZ');

        }

        if (i > 0) {
            setTimeout(() => {
                forcePosition(position, i, forward);
            }, 300);
        }
        else {
            setIsTeleporting(false);
        }
    }

    useEffect(() => {
        setOnTeleportDetection(function (data, collider, force, forward) {
            if (collider != null && force != true && collider.uuid != ref.current.uuid) return;

            setIsTeleporting(true);
            const position = [data.position.x / 10000, data.position.y / 10000, data.position.z / 10000];
            const forwardFace = forward ? [forward.forward.x / 10000, forward.forward.y / 10000, forward.forward.z / 10000] : null;

            forcePosition(position, 2, forwardFace);
        });
    }, []);


    setPlayerPosition.current = (x, y, z) => {
        api.position.set(x, y + (radius * 2) + 0.5, z);
        api.velocity.set(0, 0, 0);
    }

    // props.onPlayerSit.current = (command, parameter, mesh) =>{
    //     console.log(mesh);
    // }

    useEffect(() => {

        if (isLocal) {
            localPlayer.current = api;
            window.localPlayer = playerBase;
            // setLocalPlayerObject(playerBase);
            // listen analog
        }

    }, []);

    const { camera } = useThree();

    useEffect(() => {
        window.playerModel = refPlayerModel;

        if (forward.current) {
            const currPos = new Vector3(position[0], position[1], position[2]);
            const forwardPos = new Vector3(forward.current[0], forward.current[1], forward.current[2]);
            currPos.add(forwardPos);

            let angle = Math.atan2((forwardPos.x - currPos.x), (forwardPos.z - currPos.z));
            setFirstAngle(angle);

            if (refPlayerModel.current) refPlayerModel.current.rotation.set(0, angle, 0, 'XYZ');
            // console.log("FORWARD PLAYER", forward.current, refPlayerModel.current);
        }

    }, [refPlayerModel.current, forward.current]);

    const moveTowardsVector3 = (current, target, maxDistanceDelta) => {
        let a = {
            x: target.x - current.x,
            y: target.y - current.y,
            z: target.z - current.z
        }

        let magnitude = Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2));
        if (magnitude <= maxDistanceDelta || magnitude == 0) {
            return target;
        }
        a = {
            x: current.x + a.x / magnitude * maxDistanceDelta,
            y: current.y + a.y / magnitude * maxDistanceDelta,
            z: current.z + a.z / magnitude * maxDistanceDelta,
        }
        return a;
    }

    useEffect(() => {
        let unsubscribe = api.position.subscribe((v) => {

            if (!refPlayerPosition.current) refPlayerPosition.current = new Vector3();
            refPlayerPosition.current.set(v[0], v[1], v[2]);
            apiPosition.current.set(v[0], v[1], v[2]);
            localPlayerPosition.current = v;

            let target = new Vector3(v[0], v[1], v[2]);

            if (playerBase.current) {
                let currentPos = moveTowardsVector3(playerBase.current.position, target, (isHoverBoard.current ? playerHoverBoardSyncSpeed : playerSyncSpeed) * 0.16);
                // console.log(playerHoverBoardSyncSpeed, playerSyncSpeed, playerBase.current, target);
                let targetPos = new Vector3(currentPos.x, currentPos.y, currentPos.z);
                if (playerBase.current.position.distanceTo(targetPos) > 1.5) {
                    playerBase.current.visible = false;
                    playerBase.current.position.set(targetPos.x, targetPos.y, targetPos.z);
                }
                else {
                    playerBase.current.visible = true;
                    playerBase.current.position.lerp(targetPos, 0.5);
                }
            }

            if (cameraDistance.current < 1)
                playerBase.current.visible = false;

            if (!playerReady.current) {

                if (playerBase.current.position.distanceTo(target) < 1) {
                    setLocalPlayerObject(playerBase);
                    playerReady.current = true;
                }
            }

            // playerBase.current.position.lerp(target, (isHoverBoard.current ? playerHoverBoardSyncSpeed : playerSyncSpeed));
        });


        return ()=>{
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        let npcSelectedSubs = SceneContext.subscribe(state => state.npcSelected, (data) => {
            refPlayerModel.current.visible = data == null || cameraDistance.current < 1;
        });

        return () => {
            npcSelectedSubs();
        }
    }, []);

    useFrame((src, delta) => {
        // api.update(delta);
        // playerBase.current.position.lerp(apiPosition.current, delta * (isHoverBoard.current ? playerHoverBoardSyncSpeed : playerSyncSpeed));
    });

    return (
        <group ref={playerBase}>
            <group ref={isLocal ? ref : groupRef}></group>
            {/* <Suspense fallback={null}> */}
            <PlayerModelAndAnimation
                assetRef={assetRef}
                playerModel={refPlayerModel}
                playerAction={animationClips}
                skin={skin}
                base={base}
                skinColor={skinColor}
                hairColor={hairColor}
                head={head}
                eyebrow={eyebrow}
                eyes={eyes}
                mouth={mouth}
                hair={hair}
                upperBody={upperBody}
                lowerBody={lowerBody}
                feet={feet}
                playerId={playerId}
                colliderRadius={radius}
                onFBXUpdated={onFBXUpdated}
                isLocal={true}

                hat={hat}
                helmet={helmet}
                topHead={topHead}
                mask={mask}
                tiara={tiara}
                earing={earing}
                facialHair={facialHair}
                onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}
            >
                <PlayerSpeaker
                    playerModel={refPlayerModel}
                    name={`speaker-${audioId}`}
                    opacity={1}
                    position={[0, 2, 0]}
                    isFPS={isFPS}
                />
                {showPlayerName && <PlayerName
                    playerModel={refPlayerModel}
                    name={name}
                    opacity={1}
                    position={[0, 1.37, 0]}
                    isFPS={isFPS}
                    isLocal={true}
                    id={playerId}
                >
                    <PlayerShoutout
                        playerModel={refPlayerModel}
                        position={[0, 1.8, 0]}
                        duration={5}
                        setMessage={setMessage}
                        isFPS={isFPS}
                    />
                </PlayerName>}
            </PlayerModelAndAnimation>
            {/* </Suspense> */}
            {
                !npcSelected && !isTeleporting && refPlayerModel.current && colliders && <CharacterMovement
                    key={"CharacterMovement"}
                    colliderRadius={radius}
                    colliders={colliders}
                    onRayHit={onRayHit}
                    onCharacterCollideBegin={onCharacterCollideBegin}
                    onCharacterCollideStop={onCharacterCollideStop}
                    isLocal={isLocal}
                    refPlayerModel={refPlayerModel}
                    api={isLocal ? api : groupRef}
                    orbitControl={orbitControl}
                    setCameraTarget={setCameraTarget}
                    playerObject={ref}
                    playerId={playerId}
                    animationClip={animationClips}
                    onSelectEmoji={onSelectEmoji}
                    setMessage={setMessage}
                    setHoverBoard={setHoverBoard}
                    sceneName={sceneName}
                    onPlayerSit={onPlayerSit}
                    isHoverBoard={isHoverBoard}
                    isFPS={isFPS}
                />
            }
        </group>)
}
