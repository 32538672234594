import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { GamePlayerContext } from "../../game/context/game-context";
import { requestNonce, verifyLogin } from "../../store/login.store";
import { getUserProfile } from "../../store/user.store";


const providerOptions = {};
const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
});
let metaMaskProvider = null;

/* WALLET CONNECT PROVIDER */
let walletconnectProvider = new WalletConnectProvider({
    infuraId: process.env.WALLETCONNECT_INFURA_ID
});;

// Subscribe to accounts change
walletconnectProvider.on("accountsChanged", (accounts) => {
});

// Subscribe to chainId change
walletconnectProvider.on("chainChanged", (chainId) => {
});

// Subscribe to session disconnection
walletconnectProvider.on("disconnect", (code, reason) => {
});


/* END WALLET CONNECT PROVIDER */

let web3 = null;

export function Web3Service() {
    const [walletAddress, setWalletAddress] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    const [user, setDataUser] = useState({})
    const [usedProvider, setUsedProvider] = useState(null)
    const providerLabel = {
        "walletconnect": "Wallet Connect",
        "metamask": "Metamask",
        "custodial_wallet": "Custodial Wallet"
    }

    async function initialize() {
        const walletAddress = localStorage.getItem(`WALLET_ADDRESS_KEY`) ? localStorage.getItem(`WALLET_ADDRESS_KEY`) : null
        const provider = localStorage.getItem(`WALLET_PROVIDER`) ? localStorage.getItem(`WALLET_PROVIDER`) : null;

        setWalletAddress(walletAddress)
        setUsedProvider(provider);

        switch (provider) {
            case 'metamask':
                web3 = window.ethereum;
                break;
            case 'walletconnect':
                web3 = walletconnectProvider;
            default:
                break;
        }

        const checkAuth = checkIsAuthenticated();
        if (checkAuth) {
            const getProfile = await getUserProfile();
            const generateUser = generateDataUser(getProfile)
            setDataUser(generateUser)

            return generateUser;
        }

        return null;
    }


    async function loginMetamask() {
        setIsFetching(true);
        if (window.ethereum) {
            metaMaskProvider = await web3Modal.connect();
            web3 = new Web3(metaMaskProvider);
            const accounts = await web3.eth.requestAccounts();

            await personalSign(accounts[0], 'metamask')

            Promise.resolve();

        } else {
            setIsFetching(false);
            return Promise.reject(
                new Error(
                    "Not set ethereum wallet or invalid. You need to install Metamask"
                )
            );
        }
    }

    async function setupCustodialWallet(userVerified) {
        try {
            setIsFetching(true);

            const provider = 'custodial_wallet';
            const userData = userVerified.data;

            // setup local 
            localStorage.setItem(`BEARER`, userVerified.token);
            setWalletAddress(userData.publicKey)
            setIsAuthenticated(true);
            localStorage.setItem(`WALLET_ADDRESS_KEY`, userData.publicKey);
            localStorage.setItem('WALLET_PROVIDER', providerLabel[provider])
            setUsedProvider(providerLabel[provider]);
            // assign to player setting
            const generateUser = generateDataUser(userVerified.data)
            setDataUser(generateUser)

            setIsFetching(false);
        } catch (err) {

            setIsFetching(false);
        } finally {

            setIsFetching(false);
        }
    }


    async function loginWalletconnect() {
        try {
            setIsFetching(true);
            await walletconnectProvider.enable();
            web3 = new Web3(walletconnectProvider);
            const accounts = await web3.eth.getAccounts();


            await personalSign(accounts[0], 'walletconnect')

            setIsFetching(false);
        } catch (err) {

            setIsFetching(false);
        } finally {

            setIsFetching(false);
        }
    }

    function checkIsAuthenticated() {
        const savedToken = localStorage.getItem(`WALLET_ADDRESS_KEY`);

        let checkAuth = savedToken ? true : false;
        setIsAuthenticated(checkAuth);

        return checkAuth;
    }

    async function logOut() {
        localStorage.removeItem(`WALLET_ADDRESS_KEY`);
        localStorage.removeItem(`BEARER`);
        localStorage.removeItem(`skin_locale`);
        localStorage.removeItem(`local_player_setting`);
        setWalletAddress(null);
        setUsedProvider("Guest");
        // localStorage.removeItem(TOKEN_KEY);
        // metamask logout
        if (metaMaskProvider && metaMaskProvider.close) {
            await metaMaskProvider.close;

            metaMaskProvider = null;
            await web3Modal.clearCachedProvider();
        }

        setIsAuthenticated(false);
        return Promise.resolve();
    }

    async function personalSign(account, provider) {

        // get nonce first
        const nonce = await requestNonce(account);
        if (!nonce) {
            setIsFetching(false);
            throw new Error("Cannot Communicate With Server")
        }

        const msg = `Welcome to Animaverse!\n\n` +
            `Click to sign in and accept the Animaverse Terms of Service: https://animaverse.io/tos\n\n` +
            `This request will not trigger a blockchain transaction or cost any gas fees.\n\n` +
            `Wallet address:\n${account}\n\n` +
            `Nonce:\n${nonce}`;
        try {
            web3.eth.personal.sign(msg, account).then(async (sg) => {
                const getVerified = await verifyLogin(account, sg);

                // setup local 
                localStorage.setItem(`BEARER`, getVerified.token);
                setWalletAddress(account)
                setIsAuthenticated(true);
                localStorage.setItem(`WALLET_ADDRESS_KEY`, account);
                localStorage.setItem('WALLET_PROVIDER', providerLabel[provider])
                setUsedProvider(providerLabel[provider]);
                // assign to player setting
                const generateUser = generateDataUser(getVerified.data)
                setDataUser(generateUser)
                setIsFetching(false);
                // await getUserIdentity('metamask');

            }).catch(err => {
                setIsFetching(false);
            })
        } catch (err) {
            setIsFetching(false);
        }

    }


    async function getUserIdentity(provider) {
        const providerUpper = provider.toLocaleUpperCase();
        const address = localStorage.getItem(`WALLET_ADDRESS_KEY`);
        if (!address) {
            return Promise.reject();
        }

        const balance = await getBalance(address);

        return Promise.resolve({
            address,
            balance,
        });
    }


    async function getBalance() {
        metaMaskProvider = await web3Modal.connect();
        const web3 = new Web3(metaMaskProvider);
        return new Promise((resolve, reject) => {
            web3.eth.getBalance(walletAddress, (err, result) => {
                if (err) {
                    // reject(err);
                } else {
                    resolve(web3.utils.fromWei(result, "ether"));
                }
            });
        });
    }

    function generateDataUser(user) {
        return { displayName: user.name, name: user.name, preset: user.preset, isAccountBinded: true, isProfileUpdated: user.isProfileUpdated, id: user.publicKey, userDefaultBase: user.userDefaultBase }
    }

    return {
        initialize,
        walletAddress,
        loginMetamask,
        isAuthenticated,
        checkIsAuthenticated,
        getUserIdentity,
        logOut,
        isFetching,
        getBalance,
        user,
        loginWalletconnect,
        usedProvider,
        setupCustodialWallet,
    };
}

