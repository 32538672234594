import { useTrimesh } from "@react-three/cannon";
import { Text } from "@react-three/drei";
import { useEffect, useRef, useState } from "react"
import { Matrix4, Quaternion, Vector3 } from "three";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";
import LoadObject from "./load-static-object";

export const Trivia = ({ data, sceneName, assetRef, meshes }) => {

    const [questionId, setQuestionId] = useState(-1);
    const questionIdRef = useRef(-1);
    const [languageId, setLanguagesId] = useState(-1);
    const languageIdRef = useRef(-1);
    const questionList = useRef([]);
    const leftAnswerRef = useRef();
    const rightAnswerRef = useRef();
    const questionRef = useRef();
    const score = useRef(0);
    const rightAnswerCount = useRef(0);
    const wrongAnswerCount = useRef(0);
    const [rightAnswerTextCount, setRightAnswerTextCount] = useState(0);
    const [wrongAnswerTextCount, setWrongAnswerTextCount] = useState(0);
    const [progress, setProgress] = useState("");
    const setInstruction = SceneContext(state=>state.setInstruction);
    const questionTeleportPosition = useRef({position:new Vector3(
        data.questionTeleportPosition.position.x, 
        data.questionTeleportPosition.position.y,
        data.questionTeleportPosition.position.z
    )});
    const questionTeleportForward = useRef({forward:new Vector3(
        data.questionTeleportPosition.forward.x, 
        data.questionTeleportPosition.forward.y,
        data.questionTeleportPosition.forward.z
    )});
    const resultTeleportPosition = useRef({position:new Vector3(
        data.resultTeleportPosition.position.x, 
        data.resultTeleportPosition.position.y,
        data.resultTeleportPosition.position.z
    )});
    const resultTeleportForward= useRef({forward:new Vector3(
        data.questionTeleportPosition.forward.x, 
        data.questionTeleportPosition.forward.y,
        data.questionTeleportPosition.forward.z
    )});

    const [leftAnswerText, setLeftAnswerText] = useState("");
    const [rightAnswerText, setRightAnswerText] = useState("");
    const [questionText, setQuesttionText] = useState("");

    const [leftAnswerDetection, setLeftAnswerDetection] = useState();
    const [rightAnswerDetection, setRightAnswerDetection] = useState();
    const [finishDetection, setFinishDetection] = useState();
    const isProcessingAnswer = useRef(false);
    const answeredQuestion = useRef([]);

    const onTeleportDetection = useRef(FunctionVariableContext(state => state.onTeleportDetection));
    const onTriviaAnswer = useRef(FunctionVariableContext(state=>state.onTriviaAnswer));

    const processAnaswer = (collider, currentAnswer)=>{
        if (questionIdRef.current == -1) {
            languageIdRef.current = currentAnswer ? 1 : 0;
            setLanguagesId(languageIdRef.current); 
        }
        else if (questionList.current[questionIdRef.current].questions[languageIdRef.current].correctAnswer == currentAnswer) {
            score.current++;
            rightAnswerCount.current ++;
            onTriviaAnswer.current(true);
            // console.log("BENAR");
        }
        else {
            wrongAnswerCount.current ++;
            onTriviaAnswer.current(false);
            // console.log("SALAH");
        }

        questionIdRef.current++;

        if (questionIdRef.current < data.totalQuestion) {
            onTeleportDetection.current(questionTeleportPosition.current, collider.body, null, questionTeleportForward.current);
            setQuestionId(questionIdRef.current);

            setProgress(`${(questionIdRef.current+1)}/${data.totalQuestion}`);
        }
        else {
            setRightAnswerTextCount(rightAnswerCount.current);
            setWrongAnswerTextCount(wrongAnswerCount.current);
            console.log(resultTeleportForward.current);
            onTeleportDetection.current(resultTeleportPosition.current, collider.body, null, questionTeleportForward.current);
        }
    }

    const shuffle = (array)=> {
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          let temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      }

    const onLeftAnswerCollide = (collider) => {
        if(isProcessingAnswer.current) return;
        isProcessingAnswer.current = true;
        processAnaswer(collider, false);
    }

    const onRightAnswerCollide = (collider) => {
        if(isProcessingAnswer.current) return;
        isProcessingAnswer.current = true;
        processAnaswer(collider, true);
    }

    const wrapLine = (string, maxWidth)=>{
        let strings = string.split(" ");
        let result = "";
        let charCount = 0;
        for (let i = 0; i < strings.length; i++) {
            let string = strings[i];
            let space = " ";
            if(charCount + string.length > maxWidth)
            {
                space = "\n";
                charCount = 0;
            }
            charCount += string.length;
            result = result.concat(space, string);
        }
        return result;
    }

    useEffect(()=>{
        
        setInstruction(data.instructions);

    },[]);

    useEffect(() => {
        // console.log("TRIVIA", data);

        if (questionId == -1) {
            setQuesttionText("Choose language !");
            setLeftAnswerText(data.leftLanguage);
            setRightAnswerText(data.rightLanguage);
        }
        else {
            if(!answeredQuestion.current.includes(questionId))
            {
                setQuesttionText(wrapLine(questionList.current[questionId].questions[languageId].question, 35));
                setLeftAnswerText(wrapLine(questionList.current[questionId].questions[languageId].leftAnswer, 16));
                setRightAnswerText(wrapLine(questionList.current[questionId].questions[languageId].rightAnswer, 16));

                answeredQuestion.current.push(questionId);
            }

        }


        isProcessingAnswer.current = false;

    }, [questionId]);

    const cloneMatrix = async (data) => {

        let src = await LoadObject(data.meshid, sceneName, false, assetRef, meshes);

        let cloned = src.clone();
        let leftMatrix = new Matrix4().compose(
            new Vector3(data.position.x / 10000, data.position.y / 10000, data.position.z / 10000),
            new Quaternion(data.rotation.x / 10000, data.rotation.y / 10000, data.rotation.z / 10000, data.rotation.w / 10000),
            new Vector3(data.scale.x / 10000, data.scale.y / 10000, data.scale.z / 10000)
        );
        cloned.applyMatrix4(leftMatrix);

        return cloned;
    }

    useEffect(() => {

        const loadObject = async () => {
            let left = await cloneMatrix(data.leftAnswerDetection)
            let right = await cloneMatrix(data.rightAnswerDetection);
            let finish = await cloneMatrix(data.finishDetection);

            setLeftAnswerDetection(left);
            setRightAnswerDetection(right);
            setFinishDetection(finish);
        }

        questionList.current = shuffle(data.questionList);
        loadObject();
    }, []);

    useEffect(()=>{
        let onTeleportDetectionSubs = FunctionVariableContext.subscribe(state=>state.onTeleportDetection, (data)=>{
            onTeleportDetection.current = data;
        });

        let onTriviaAnswerSubs = FunctionVariableContext.subscribe(state=>state.onTriviaAnswer, (data)=>{
            onTriviaAnswer.current = data;
        });

        return ()=>{
            onTeleportDetectionSubs();
            onTriviaAnswerSubs();
        }
    },[]);

    return (<group dispose={null}>
        <Text
            ref={leftAnswerRef}
            color={"#" + data.leftAnswer.color}
            fontSize={data.leftAnswer.size / 10}
            maxWidth={200}
            lineHeight={data.leftAnswer.lineHeight}
            letterSpacing={data.leftAnswer.letterSpacing}
            textAlign={data.leftAnswer.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.leftAnswer.anchorX}
            anchorY={data.leftAnswer.anchorY}
            outlineWidth={data.leftAnswer.outlineWidth / 10}
            outlineColor={data.leftAnswer.outlineColor}
            position={[data.leftAnswer.position.x / 10000, data.leftAnswer.position.y / 10000, data.leftAnswer.position.z / 10000]}
            scale={[data.leftAnswer.scale.x / 10000, data.leftAnswer.scale.y / 10000, data.leftAnswer.scale.z / 10000]}
            quaternion={[data.leftAnswer.rotation.x / 10000, data.leftAnswer.rotation.y / 10000, data.leftAnswer.rotation.z / 10000, data.leftAnswer.rotation.w / 10000]}
        >
            {leftAnswerText}
        </Text>
        <Text
            ref={rightAnswerRef}
            color={"#" + data.rightAnswer.color}
            fontSize={data.rightAnswer.size / 10}
            maxWidth={200}
            lineHeight={data.rightAnswer.lineHeight}
            letterSpacing={data.rightAnswer.letterSpacing}
            textAlign={data.rightAnswer.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.rightAnswer.anchorX}
            anchorY={data.rightAnswer.anchorY}
            outlineWidth={data.rightAnswer.outlineWidth / 10}
            outlineColor={data.rightAnswer.outlineColor}
            position={[data.rightAnswer.position.x / 10000, data.rightAnswer.position.y / 10000, data.rightAnswer.position.z / 10000]}
            scale={[data.rightAnswer.scale.x / 10000, data.rightAnswer.scale.y / 10000, data.rightAnswer.scale.z / 10000]}
            quaternion={[data.rightAnswer.rotation.x / 10000, data.rightAnswer.rotation.y / 10000, data.rightAnswer.rotation.z / 10000, data.rightAnswer.rotation.w / 10000]}
        >
           {rightAnswerText}
        </Text>
        <Text
            ref={questionRef}
            color={"#" + data.question.color}
            fontSize={data.question.size / 10}
            maxWidth={200}
            lineHeight={data.question.lineHeight}
            letterSpacing={data.question.letterSpacing}
            textAlign={data.question.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.question.anchorX}
            anchorY={data.question.anchorY}
            outlineWidth={data.question.outlineWidth / 10}
            outlineColor={data.question.outlineColor}
            position={[data.question.position.x / 10000, data.question.position.y / 10000, data.question.position.z / 10000]}
            scale={[data.question.scale.x / 10000, data.question.scale.y / 10000, data.question.scale.z / 10000]}
            quaternion={[data.question.rotation.x / 10000, data.question.rotation.y / 10000, data.question.rotation.z / 10000, data.question.rotation.w / 10000]}
        >
            {questionText}
        </Text>
        
        <Text
            color={"#" + data.resultCorrect.color}
            fontSize={data.resultCorrect.size / 10}
            maxWidth={200}
            lineHeight={data.resultCorrect.lineHeight}
            letterSpacing={data.resultCorrect.letterSpacing}
            textAlign={data.resultCorrect.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.resultCorrect.anchorX}
            anchorY={data.resultCorrect.anchorY}
            outlineWidth={data.resultCorrect.outlineWidth / 10}
            outlineColor={data.resultCorrect.outlineColor}
            position={[data.resultCorrect.position.x / 10000, data.resultCorrect.position.y / 10000, data.resultCorrect.position.z / 10000]}
            scale={[data.resultCorrect.scale.x / 10000, data.resultCorrect.scale.y / 10000, data.resultCorrect.scale.z / 10000]}
            quaternion={[data.resultCorrect.rotation.x / 10000, data.resultCorrect.rotation.y / 10000, data.resultCorrect.rotation.z / 10000, data.resultCorrect.rotation.w / 10000]}
        >
            {rightAnswerTextCount}
        </Text>
        
        <Text
            color={"#" + data.resultWrong.color}
            fontSize={data.resultWrong.size / 10}
            maxWidth={200}
            lineHeight={data.resultWrong.lineHeight}
            letterSpacing={data.resultWrong.letterSpacing}
            textAlign={data.resultWrong.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.resultWrong.anchorX}
            anchorY={data.resultWrong.anchorY}
            outlineWidth={data.resultWrong.outlineWidth / 10}
            outlineColor={data.resultWrong.outlineColor}
            position={[data.resultWrong.position.x / 10000, data.resultWrong.position.y / 10000, data.resultWrong.position.z / 10000]}
            scale={[data.resultWrong.scale.x / 10000, data.resultWrong.scale.y / 10000, data.resultWrong.scale.z / 10000]}
            quaternion={[data.resultWrong.rotation.x / 10000, data.resultWrong.rotation.y / 10000, data.resultWrong.rotation.z / 10000, data.resultWrong.rotation.w / 10000]}
        >
            {wrongAnswerTextCount}
        </Text>
        
        <Text
            color={"#" + data.progress.color}
            fontSize={data.progress.size / 10}
            maxWidth={200}
            lineHeight={data.progress.lineHeight}
            letterSpacing={data.progress.letterSpacing}
            textAlign={data.progress.textAlign}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            anchorX={data.progress.anchorX}
            anchorY={data.progress.anchorY}
            outlineWidth={data.progress.outlineWidth / 10}
            outlineColor={data.progress.outlineColor}
            position={[data.progress.position.x / 10000, data.progress.position.y / 10000, data.progress.position.z / 10000]}
            scale={[data.progress.scale.x / 10000, data.progress.scale.y / 10000, data.progress.scale.z / 10000]}
            quaternion={[data.progress.rotation.x / 10000, data.progress.rotation.y / 10000, data.progress.rotation.z / 10000, data.progress.rotation.w / 10000]}
        >
            {progress}
        </Text>
        {leftAnswerDetection && rightAnswerDetection && finishDetection &&
            <TriviaDetection
                leftAnswerDetection={leftAnswerDetection}
                rightAnswerDetection={rightAnswerDetection}
                finishDetection={finishDetection}
                onLeftAnswerCollide={onLeftAnswerCollide}
                onRightAnswerCollide={onRightAnswerCollide}
            />}
    </group>);
}

const TriviaDetection = ({ leftAnswerDetection, rightAnswerDetection, finishDetection, onLeftAnswerCollide, onRightAnswerCollide }) => {



    const [leftAnswerAPI] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [leftAnswerDetection.attributes.position.array, leftAnswerDetection.index.array],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            onLeftAnswerCollide(collider);
        },
        onCollideEnd: (collider) => {
        }
    }));

    const [rightAnswerAPI] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [rightAnswerDetection.attributes.position.array, rightAnswerDetection.index.array],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            onRightAnswerCollide(collider);
        },
        onCollideEnd: (collider) => {
        }
    }));

    const [finishAPI] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [finishDetection.attributes.position.array, finishDetection.index.array],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
        },
        onCollideEnd: (collider) => {
        }
    }));

    return <></>
}