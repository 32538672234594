import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { createContext } from "react";

const Web3Context = createContext("");

const Web3UtilityContext = create(subscribeWithSelector((set, get) => ({
    metamaskToken: null,
    connectWalletToken: null,
    trustWalletToken: null,
    isAuthenticated: false,
    setToken: (provider, token) => {
        switch (provider) {
            case 'metamask':
                set({ metamaskToken: token })
                break;
            case 'connectWallet':
                set({ connectWalletToken: token })
                break;
            case 'trustWallet':
                set({ trustWalletToken: token })
                break;
            default:
                break;
        }
    },
    setIsAuthenticated: (state) => {
        set({ isAuthenticated: state })
    }
})))


export {
    Web3Context,
    Web3UtilityContext
}