import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function ToastComponent({ data, callbackClose }) {
    const [position, setPosition] = useState('top-start');
    return (
        <ToastContainer position="top-end" className="p-3">
            <Toast onClose={callbackClose} bg={data.type}>
                <Toast.Header>
                    <strong className="me-auto">{data.title}</strong>
                    {/* <small className="text-muted">just now</small> */}
                </Toast.Header>
                <Toast.Body>{data.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
