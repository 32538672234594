var PrivateAuthState = (function() {
  const appName = 'VirtuAnimaverse2022';

  var canEnter = false;

  var getCanEnter = function() {
    canEnter = localStorage.getItem(appName)!==null;
    return canEnter;
  };

  var setCanEnter = function(state) {
    canEnter = state;
    localStorage.setItem(appName, canEnter);
  };

  return {
    getCanEnter: getCanEnter,
    setCanEnter: setCanEnter
  }
})();

export default PrivateAuthState;