import { useEffect, useRef, useState } from "react"
import { GameConfigContext, GamePlayerContext, SceneContext, AudioContext } from "../../context/game-context";

export const AmbienSound = () => {

    
    const initialBGMVolume = AudioContext(state => state.bgmVolume);
    const audioRef = useRef();
    const bgmVolumeRef = useRef(initialBGMVolume);

    const [isLoading, setIsLoading] = useState(GameConfigContext(state => state.loading));
    const [mute, setMute] = useState(SceneContext(state => state.muteAmbientSound));
    const [src, setSrc] = useState(SceneContext(state => state.ambientSound));


    const pause = () =>{
        if(mute && src){
            audioRef.current.pause();
        }
    }

    const play = () =>{
        if(mute && src){
            audioRef.current.play();
        }
    }

    useEffect(() => {
        window.addEventListener('blur', pause);
        window.addEventListener('focus', play);

        return (() => {
            window.removeEventListener('blur', pause);
            window.removeEventListener('focus', play);
        });
    });

    useEffect(() => {
        if (audioRef.current) audioRef.current.volume = bgmVolumeRef.current;
        let ambientSoundSubs = SceneContext.subscribe(state => state.ambientSound, (data) => {
            if (audioRef.current) {
                audioRef.current.src = data;
                setSrc(data);
            }
        });
        let muteAmbientSoundSubs = SceneContext.subscribe(state => state.muteAmbientSound, (data) => {
            setMute(data);
            if (audioRef.current) audioRef.current.volume = data ? bgmVolumeRef.current : 0;
        });

        let loadingSubs = GameConfigContext.subscribe(state => state.loading, (data) => {
            setIsLoading(data);
        });

        let bgmVolumeSubs = AudioContext.subscribe(state => state.bgmVolume, (data) => {
            bgmVolumeRef.current = data;
            if (audioRef.current) audioRef.current.volume = bgmVolumeRef.current;
        })

        return () => {
            ambientSoundSubs();
            muteAmbientSoundSubs();
            bgmVolumeSubs();
        }
    });

    useEffect(() => {
        if (!isLoading) {
            if (mute) {
                if (audioRef.current) audioRef.current.play();
            }
            else {
                audioRef.current.pause();
            }
        }
        else {
            if (audioRef.current) audioRef.current.pause();
        }
    }, [isLoading, mute]);

    return <audio loop={true} ref={audioRef} />
}