
import env from "react-dotenv";

import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";
import { Web3Provider } from "./blockhain/provider/web3.provider";
import { Helmet } from "react-helmet";
import { CookiesProvider } from "react-cookie";
import Loading from "./game/component/html-component/loading";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(
    <>
        <Loading />
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <meta name="HandheldFriendly" content="true" />
        </Helmet>
        <Web3Provider>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </Web3Provider>
    </>
);
