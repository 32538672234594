import { AdaptiveDpr } from "@react-three/drei";
import { Canvas } from "@react-three/fiber"
import { useEffect, useRef } from "react";
import { Suspense } from "react";
import { useState } from "react";
import { GamePlayerContext, PlayerSelectionContext, SceneContext } from "../../context/game-context";
import { PlayerSelection } from "../../player-selection"
import { CanvasProvider } from "../player-component/context/canvas-provider";
import { PlayerState } from "../player-component/context/player-state"
import { SocketState } from "../player-component/context/socket-state";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import { Carousel, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import { PlayerContext } from "../player-component/context/player-context";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateUserProfile } from "../../../store/user.store";
import "../../../css/player-selection.css"
import "external-svg-loader";


export const Explore = ({ onClose }) => {


    return (
        <Container className="py-3 full-height">
            <Row>
                <Col sm={12} className="text-left">
                    <h5 className="text-default">Featured</h5>
                    <div className="bg-outfit-dark py-3">
                        <Carousel variant="dark" className="explore-carousel-featured" >
                            <Carousel.Item>
                                <Row>
                                    <Col sm={6}>
                                        <Row className="full-height">
                                            <Col sm={6} className={"explore-img-section"}>
                                                <img
                                                    className="d-block w-100"
                                                    src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                                    alt="First slide"
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Carousel.Caption>
                                                    <h5>Lorem Ipsum</h5>
                                                    <p>Lorem Ipsum Sit Amet Dolor</p>
                                                </Carousel.Caption>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6}>
                                        <Row className="full-height">
                                            <Col sm={6} className={"explore-img-section"}>
                                                <img
                                                    className="d-block w-100"
                                                    src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                                    alt="First slide"
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Carousel.Caption>
                                                    <h5>Lorem Ipsum</h5>
                                                    <p>Lorem Ipsum Sit Amet Dolor</p>
                                                </Carousel.Caption>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Carousel.Item>

                            <Carousel.Item>
                                <Row>
                                    <Col sm={6}>
                                        <Row className="full-height">
                                            <Col sm={6} className={"explore-img-section"}>
                                                <img
                                                    className="d-block w-100"
                                                    src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                                    alt="First slide"
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Carousel.Caption>
                                                    <h5>Lorem Ipsum</h5>
                                                    <p>Lorem Ipsum Sit Amet Dolor</p>
                                                </Carousel.Caption>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </div>

                    <h5 className="text-default my-2">Explore</h5>
                    <div className="bg-outfit-dark py-3 full-height">
                        <Row>
                            <Col xs={4} sm={3} className={"my-2"}>
                                <a href="#">
                                    <div className=" explore-img-section">
                                        <img
                                            className="d-block w-100"
                                            src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                            alt="First slide"
                                        />
                                    </div>
                                </a>
                            </Col>
                            <Col xs={4} sm={3} className={"my-2"}>
                                <a href="#">
                                    <div className=" explore-img-section">
                                        <img
                                            className="d-block w-100"
                                            src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                            alt="First slide"
                                        />
                                    </div>
                                </a>
                            </Col>
                            <Col xs={4} sm={3} className={"my-2"}>
                                <a href="#">
                                    <div className=" explore-img-section">
                                        <img
                                            className="d-block w-100"
                                            src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18314452b74%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18314452b74%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EDummy%20Map%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                            alt="First slide"
                                        />
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}