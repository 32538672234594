import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useWeb3Service } from "../blockhain/provider/web3.provider";
import { GameConfigContext, GamePlayerContext, LocalPlayerContext } from "./context/game-context";
import "../css/private-message.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularJSON from "circular-json";


export const PrivateMessage = () => {

    const { isAuthenticated, user } = useWeb3Service();
    const setUserId = GamePlayerContext(state => state.setUserId);
    const [chatServer, setChatServer] = useState(GameConfigContext(state => state.chatServer));
    const initiatePlayerSetting = LocalPlayerContext(state => state);
    const [playerSetting, setPlayerSetting] = useState(initiatePlayerSetting);
    const [userId, setUserIdState] = useState(initiatePlayerSetting.id);
    const [appId, setAppId] = useState(GameConfigContext(state => state.appId));
    const setMessageLink = GameConfigContext(state => state.setMessageLink);
    const [openChat, setOpenChatState] = useState(GamePlayerContext(state => state.openChat));
    const setOpenChat = GamePlayerContext(state => state.setOpenChat);
    const [messageLink, setMessageLinkState] = useState();
    const [currentChatLink, setCurrenChatLink] = useState();
    const currentUserId = useRef(initiatePlayerSetting.id);
    const currentAppId = useRef(GameConfigContext(state => state.appId));

    useEffect(() => {
        if (user.id) {
            setUserIdState(user.id);
            currentUserId.current = user.id;
        }
    }, [isAuthenticated]);

    useEffect(() => {

        let openChatSubs = GamePlayerContext.subscribe(state => state.openChat, (data) => {
            setOpenChatState(data);
        });


        let chatServerSubs = GameConfigContext.subscribe(state => state.chatServer, (data) => {
            setChatServer(data);
        });

        let appIdSubs = GameConfigContext.subscribe(state => state.appId, (data) => {
            // console.log(data, "APPID CHAT CHANGES")
            setAppId(data);
            currentAppId.current = data;
        });

        let playerSettingSubs = LocalPlayerContext.subscribe(state => state, (data) => {
            // console.log(data, "PRIVATE MESSAGE LISTEN")
            if (data) {
                // const updatedLocalPlayer = CircularJSON.parse(localStorage.getItem("local_player_setting"));
                setPlayerSetting(data);
            }
        })

        return () => {
            chatServerSubs();
            appIdSubs();
            playerSettingSubs();
            openChatSubs();
        };
    }, []);

    useEffect(() => {
        // console.log(chatServer, playerSetting, userId, currentUserId.current, "USER ID CREATE USER");

        if (userId != null || currentUserId.current != null) {
            setUserId(userId);
            currentUserId.current = userId;

            if (chatServer && playerSetting && currentAppId.current) {
                let paramter = {
                    id: currentUserId.current,
                    appid: currentAppId.current,
                    firstname: playerSetting.displayName,
                    lastname: "",
                    to: openChat,
                    email: playerSetting.id,
                    isGroup: false
                }

                axios.post(`${chatServer}/api/createupdateuser`, paramter, {
                    crossDomain: true
                })
                    .then(function (response) {
                        if (response.data.success == true) {
                            setMessageLinkState(`${response.data.data.link}&cp=false`);
                            setMessageLink(`${response.data.data.link}&cp=false`);
                        }
                    })
            }
        }
    }, [userId, chatServer, playerSetting, appId]);


    useEffect(() => {
        if (openChat == true) {
            setCurrenChatLink(messageLink);
        }
        else if (openChat && openChat.length > 0) {
            // console.log(currentUserId.current, "USER ID");
            let paramter = {
                id: currentUserId.current,
                appid: currentAppId.current,
                to: openChat,
                isGroup: false
            }
            // console.log(paramter, "PARAMETER");

            axios.post(`${chatServer}/api/getlink`, paramter, {
                crossDomain: true
            })
                .then(function (response) {
                    if (response.data.success == true) {
                        setCurrenChatLink(response.data.data.link);
                    }
                });
        }
        else {
            setCurrenChatLink();
        }
    }, [openChat]);

    return currentChatLink && <div className="private-chat-base">
        <iframe className="private-chat" src={currentChatLink}></iframe>
        <button className="private-chat-close" onClick={() => {
            setOpenChat();
        }}><FontAwesomeIcon icon={`fas fa-xmark`} /></button>
    </div>
}