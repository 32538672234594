import { useTrimesh } from "@react-three/cannon";
import { useAnimations } from "@react-three/drei"
import { useEffect, useState } from "react";
import { LoopRepeat } from "three";
import { GamePlayerContext, TreasureHuntContext } from "../../context/game-context";

export const StaticCollectableItems = ({ object, clip, id, total, detectionObjectVertics, detectionObjectIndices, sceneName, geometry }) => {

    const [collectedItem, setCollectedItem] = useState(GamePlayerContext((state) => state.collectedStaticItem));
    const addCollectedStaticItemList = GamePlayerContext((state) => state.addCollectedStaticItemList);
    const addPoint = TreasureHuntContext((state) => state.addPoint);
    const getCollectedStaticItemList = GamePlayerContext((state) => state.getCollectedStaticItemList);

    let [visible, setVisible] = useState(true);
    let { actions } = useAnimations([clip], object);

    useEffect(() => {
        let collectedItemSubs = GamePlayerContext.subscribe(state => state.collectedStaticItem, (data) => {
            setCollectedItem(data);
        });

        return () => {
            collectedItemSubs();
            geometry.dispose();
        }
    });

    useEffect(() => {
        // console.log("COLLECTED", collectedItem);

        if (collectedItem.includes(id)) {
            setVisible(false);

            object.visible = false;
        }
    }, [collectedItem]);

    const [ref] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        args: [detectionObjectVertics, detectionObjectIndices],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            if (visible) {

                const listCollected = getCollectedStaticItemList();
                if (listCollected.indexOf(id) == -1) {
                    // addCollectedStaticItemList(id);

                    // setVisible(false);
                    // object.visible = false;

                    // if scene name == dg
                    // if scene name == dg
                    // if (sceneName == 'dgplaza' || sceneName == 'dgaward') {
                    addPoint(id, "find_dg_icon");
                    // }
                }
            }
        },
        onCollideEnd: (collider) => {
        }
    }));

    useEffect(() => {
        let keys = Object.keys(actions);

        keys.forEach(key => {
            // console.log(actions[key]);
            actions[key].loop = LoopRepeat;
            actions[key]._mixer.addEventListener('finished', (e) => {
                currentID.current++;

                let objectKeys = Object.keys(actions);
                if (currentID.current >= objectKeys.length) {
                    currentID.current = 0;
                }

                actions[objectKeys[currentID.current]].play();
            }
            );
            actions[key].play();
        });
    }, []);

    return <primitive object={object} dispose={null} />
}