import { useEffect, useRef, useState } from 'react'
import '../../../css/area.css'
import { SceneContext } from '../../context/game-context';

export const Area = ()=>{

    const [area, setArea] = useState(SceneContext(state=>state.area));
    const areaRef = useRef();

    useEffect(()=>{
        let areaSubs = SceneContext.subscribe(state=>state.area, (data)=>{
            if(areaRef.current != data) {
                setArea(data);
                areaRef.current = data;
            }
        });

        return()=>{
            areaSubs();
        }
    },[]);

    return area && <div className="area">{area}</div>
}