export const moveTowardsVector3 = (current, target, maxDistanceDelta) => {
    let a = {
        x: target.x - current.x,
        y: target.y - current.y,
        z: target.z - current.z
    }

    let magnitude = Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2));
    if (magnitude <= maxDistanceDelta || magnitude == 0) {
        return target;
    }
    a = {
        x: current.x + a.x / magnitude * maxDistanceDelta,
        y: current.y + a.y / magnitude * maxDistanceDelta,
        z: current.z + a.z / magnitude * maxDistanceDelta,
    }
    return a;
}


class UtilTools {
    constructor() {

    }

    lerp(v0, v1, t) {
        return v0 + t * (v1 - v0);
    }

    clamp(value, min, max) {
        if (value < min)
            return min;
        else if (value > max)
            return max;
        else
            return value;
    }

    moveTowards(current, target, maxDelta) {
        if (Math.abs(target - current) <= maxDelta) {
            return target;
        }

        return current + Math.sign(target - current) * maxDelta;
    }

    randomInt(max) {
        return Math.floor(Math.random() * max);
    }

    distance(point1, point2) {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    moveTowardsVector2(current, target, maxDistanceDelta) {
        let a = {
            x: target.x - current.x,
            y: target.y - current.y
        }

        let magnitude = Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2));
        if (magnitude <= maxDistanceDelta || magnitude == 0) {
            return target;
        }
        a = {
            x: current.x + a.x / magnitude * maxDistanceDelta,
            y: current.y + a.y / magnitude * maxDistanceDelta,
        }
        return a;
    }

    moveTowardsVector3(current, target, maxDistanceDelta) {
        let a = {
            x: target.x - current.x,
            y: target.y - current.y,
            z: target.z - current.z
        }

        let magnitude = Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2));
        if (magnitude <= maxDistanceDelta || magnitude == 0) {
            return target;
        }
        a = {
            x: current.x + a.x / magnitude * maxDistanceDelta,
            y: current.y + a.y / magnitude * maxDistanceDelta,
            z: current.z + a.z / magnitude * maxDistanceDelta,
        }
        return a;
    }

    makeid(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    deg2rad(degrees) {
        let pi = Math.PI;
        return degrees * (pi / 180);
    }

    rad2deg(radians) {
        let pi = Math.PI;
        return radians * (180 / pi);
    }

    randomRange(min, max) {
        return Math.random() * (max - min + 1) + min;
    }
}

export { UtilTools }