import { useState } from "react";
import "../../../css/performance-monitor.css";

export const PerformanceMonitor = ({ setMonitorValue }) => {

    let [fps, setFPS] = useState(0);
    let [drawCall, setDrawCall] = useState(0);
    let [triangles, setTriangles] = useState(0);

    setMonitorValue.current = (_fps, _drawcall, _triangles) => {
        setFPS(_fps);
        setDrawCall(_drawcall);
        setTriangles(_triangles);
    }

    return (
        <div className="monitor-base">
            <div className="monitor-base-fps">FPS : {fps}</div>
            <div className="monitor-base-drawcall">DC : {drawCall}</div>
            <div className="monitor-base-triangles">TRIS : {triangles}</div>
        </div>
    )
}