import { useState } from 'react';
import '../../../css/change-camera-mode.css'

export const CameraModeToggle = ({onChangeCameraMode, changeCamera}) => {

    const [cameraMode, setCameraMode] = useState(1);

    const onChangeCamera = (id) => {
        setCameraMode(id);
    }

    onChangeCameraMode.current = onChangeCamera;

    return <div className='camera-mode-base'>
        {cameraMode === 0 && <div><div className='camera-mode-title'>Camera Mode<br></br><b>(C To Change)</b>:</div><div className='camera-mode-word'>Cursor Lock <div className='camera-mode-word-sub'>Move mouse to rotate</div></div></div>}
        {cameraMode === 1 && <div><div className='camera-mode-title'>Camera Mode<br></br><b>(C To Change)</b>:</div><div className='camera-mode-word'>Classic <div className='camera-mode-word-sub'>Hold & drag right mouse to rotate</div></div></div>}
        {cameraMode === 2 && <div><div className='camera-mode-title'>Camera Mode<br></br><b>(C To Change)</b>:</div><div className='camera-mode-word'>Camera Follow <div className='camera-mode-word-sub'>Use keyboard axis to rotate camera</div></div></div>}
    </div>
}