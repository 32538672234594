import { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap";
import "../../../css/emoji-animation.css"
import { FunctionVariableContext, GameConfigContext, VisitorTrackerContext } from "../../context/game-context";

export const EmojiAnimationCircular = ({ onSelectEmoji, setOpenWindowEmoji }) => {


    const onSelectEmojiFunction = useRef(FunctionVariableContext(state => state.onSelectEmoji));
    const getDefaultHideAnim = GameConfigContext((state) => state.hideAnimoji)
    const [listHideAnim, setListHideAnim] = useState(getDefaultHideAnim);
    
    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);

    useEffect(() => {
        let onSelectEmojiSubs = FunctionVariableContext.subscribe((state) => state.onSelectEmoji, (data) => {
            onSelectEmojiFunction.current = data;
        });

        let getListEmoji = GameConfigContext.subscribe((state) => state.hideAnimoji, (data) => {
            setListHideAnim(data);
        });

        return () => {
            onSelectEmojiSubs();
            getListEmoji();
        }
    }, []);

    const selectEmoji = (animationID) => {
        
        setVisitorTrackerData("emoji", animationID)
        if (onSelectEmoji) onSelectEmoji(animationID);

        if (onSelectEmojiFunction.current) onSelectEmojiFunction.current(animationID);
        setOpenWindowEmoji(false);
    }

    return (
        <div>
            <Row>
                {listHideAnim.indexOf('wave') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('wave')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-wave.svg"
                                fill="currentColor"
                            />
                            <span>Wave</span>
                        </button>
                    </Col>
                }
                {listHideAnim.indexOf('rumbadance') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('rumbadance')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-rumbadance.svg"
                                fill="currentColor"
                            />
                            <span>Rumba Dance</span>
                        </button>
                    </Col>
                }
                {listHideAnim.indexOf('hiphopdance') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('hiphopdance')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-hiphopdance.svg"
                                fill="currentColor"
                            />
                            <span>Hip Hop Dance</span>
                        </button>
                    </Col>
                }
                {listHideAnim.indexOf('breakdance') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('breakdance')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-breakdance.svg"
                                fill="currentColor"
                            />
                            <span>Break Dance</span>
                        </button>
                    </Col>
                }
                {listHideAnim.indexOf('clap') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('clap')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-clap.svg"
                                fill="currentColor"
                            />
                            <span>Clap</span>
                        </button>
                    </Col>
                }
                {/* {listHideAnim.indexOf('bike') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('bike')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-clap.svg"
                                fill="currentColor"
                            />
                            <span>Bike</span>
                        </button>
                    </Col>
                } */}
                {/* {listHideAnim.indexOf('throw') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('throw')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-throwmoney.svg"
                                fill="currentColor"
                            />
                            <span>Throw Money</span>
                        </button>
                    </Col>
                }
                {listHideAnim.indexOf('pushup') == -1 &&
                    <Col xs={3}>
                        <button onClick={e => selectEmoji('pushup')} className="emoji-animation-button">
                            <svg
                                data-src="./icon/img-emoji-throwmoney.svg"
                                fill="currentColor"
                            />
                            <span>Push Up</span>
                        </button>
                    </Col>
                } */}
            </Row>
        </div>
    )
}