import { Html } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react"
import * as THREE from "three";
import { Vector3 } from "three";

export const PlayerShoutout = ({ position, duration, setMessage, playerModel, isFPS }) => {

    let [isActive, setIsActive] = useState(false);
    let [isFPSMode, setFPSMode] = useState(false);
    const playerNameRef = useRef();
    const currentTime = useRef(0);
    const htmlRef = useRef();

    let [messageText, setMessageText] = useState("HALOOO APA KABAR KALIAN SEMUA");

    setMessage.current = (message) => {
        currentTime.current = duration;
        setIsActive(true);
        setMessageText(message);
    }

    useFrame((e, delta) => {

        if (currentTime.current > 0) {
            currentTime.current -= delta;
        }
        else {
            setIsActive(false);
            setMessageText("");
        }

        if (playerNameRef && playerNameRef.current && playerModel && playerModel.current && playerModel.current.topHead) {
            let worldPosition = new Vector3(0, 0, 0);
            playerNameRef.current.position.set(worldPosition.x, worldPosition.y + 1.5, worldPosition.z);
        }

        if (playerNameRef && playerNameRef.current && isFPS) playerNameRef.current.visible = !isFPS.current;
    });


    return (isActive && <Html
                sprite
                distanceFactor={1}
                transform
                fullscreen
                prepend
                scale={2.5}
                ref={htmlRef}
                position={[0,0.18,0]}
            >
                <div className="chat-box-arrow"></div>
                <div className="chat-box-3d">
                    {messageText}
                </div>

            </Html>
        // isActive && <mesh ref={playerNameRef} scale={[0.4 * scaleMultiplier, 0.2 * scaleMultiplier, 0.2 * scaleMultiplier]} position={position} material={canvasMaterial}>
        //     <planeGeometry width={300} height={50} >
        //     </planeGeometry>
        // </mesh>


        // isActive && <group ref={groupRef}>
        //     <Html
        //         sprite
        //         distanceFactor={1}
        //         transform
        //         fullscreen
        //         prepend
        //         zIndexRange={[0, 0]}
        //         scale={2.5}
        //         ref={htmlRef}
        //     >
        //         messageText
        //     </Html>
        // </group>
    )
}
