
import { useThree } from '@react-three/fiber';
import React, { useEffect, useState } from "react";

export default function UsePersonControls() {
  const keys = {
    KeyW: 'forward',
    KeyS: 'backward',
    KeyA: 'left',
    KeyD: 'right',
    Space: 'jump',
    ArrowUp: 'cameraUp',
    ArrowDown: 'cameraDown',
    ArrowLeft: 'cameraLeft',
    ArrowRight: 'cameraRight',
    ShiftLeft: 'run',
    ControlLeft: 'hoverDown',
  }

  const moveFieldByKey = (key) => keys[key]

  const [movement, setMovement] = useState({
    forward: false,
    backward: false,
    left: false,
    right: false,
    jump: false,
    cameraUp: false,
    cameraDown: false,
    cameraLeft: false,
    cameraRight: false,
    run: false,
    hoverDown: false
  })

  let movementKey = {
    forward: false,
    backward: false,
    left: false,
    right: false,
    jump: false,
    cameraUp: false,
    cameraDown: false,
    cameraLeft: false,
    cameraRight: false,
    run: false,
    hoverDown: false
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      // console.log(e.code);
      if (moveFieldByKey(e.code) && movementKey[moveFieldByKey(e.code)] == false) {
        // console.log(e.code, moveFieldByKey(e.code), movementKey[moveFieldByKey(e.code)]);
        movementKey[moveFieldByKey(e.code)] = true;
        setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: true }))
      }
    }
    const handleKeyUp = (e) => {
      if (moveFieldByKey(e.code) && movementKey[moveFieldByKey(e.code)] == true) {
        movementKey[moveFieldByKey(e.code)] = false;
        // console.log(moveFieldByKey(e.code), movement[moveFieldByKey(e.code)], movement['forward'], "WOK WOK");
        setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: false }))
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])
  return movement
}