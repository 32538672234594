import { useEffect, useRef, useState } from "react"
import { AudioToggle } from "./audio-toggle"
import { CameraModeToggle } from "./camera-mode"
import { ChangeScene } from "./change-scene"
import { DayNigntSlider } from "./day-night-slider"
import { HoverBoardToggle } from "./hover-board-toggle"
import { PerformanceMonitor } from "./performance-monitor"
import PlayerListTable  from "../html-component/player-list-table";
import SettingsMenu from "./settings-menu"
import ShareScreen from "./share-screen"
import { MixerRoom } from "./mixer-room"
import { HostedRoom } from "./hosted-room"
import { Ping } from "./ping"

export const DevelopmentTools = ({
    onChangeCameraMode, toggleHoverBoard, dayNightValueChanged, 
    setMonitorValue, onChangeScene, audioToggleRef, sceneID,
    joinHostedRoom, createHostedRoom, openAvatarSelection
}) => {

    const [showDevTools, setShowDevTools] = useState(true);
    const base = useRef();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.code == "Minus") {
            }
        }
        const handleKeyUp = (e) => {
            if (e.code == "Minus") {
                setShowDevTools(!showDevTools);
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handleKeyUp)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handleKeyUp)
        }
    }, [showDevTools]);

    return (
        <div ref={base} style={{ display: showDevTools ? "block" : "none" }}>
            <MixerRoom />
            <ShareScreen />
            <HostedRoom joinHostedRoom={joinHostedRoom} createHostedRoom={createHostedRoom} />
            <CameraModeToggle onChangeCameraMode={onChangeCameraMode} />
            {/* <HoverBoardToggle toggleHoverBoard={toggleHoverBoard} /> */}
            {/* <a dayNightValueChanged={dayNightValueChanged} /> */}
            <PerformanceMonitor setMonitorValue={setMonitorValue} />
            {/* <ChangeScene onChangeScene={onChangeScene} sceneID={sceneID} /> */}
            {/* <button onClick={openAvatarSelection} id="avatar-selection-button">CHANGE AVATAR</button> */}
            {/* <PlayerListTable /> */}
        </div>
    );

}