import { useEffect, useState } from "react";
import { GamePlayerContext } from "../../context/game-context";
import "../../../css/count-down.css"

export const CountDown = ({showTutorial}) => {

    const [countDown, setCountDown] = useState(GamePlayerContext(state => state.countDown));

    useEffect(() => {
        let countDownSubs = GamePlayerContext.subscribe(state => state.countDown, (data) => {
            setCountDown(data);
        });

        return()=>{
            countDownSubs();
        };
    });

    return (
        countDown != -1 && <div className="count-down-base">
            <div className="count-down" >
                {
                    countDown == 0 ? "GO!" : countDown
                }
            </div >
        </div>
    );
}