import { useEffect } from "react";
import { useState } from "react";
import "../../../css/change-server-room.css";
import { FunctionVariableContext, GameConfigContext, GamePlayerContext } from "../../context/game-context";

export const ChangeServerRoom = () => {

    let [showChangeRoom, setShowChangeRoomState] = useState(GamePlayerContext(state => state.showChangeRoom));
    let [currentRoom, setCurrentRoomState] = useState(GamePlayerContext(state => state.currentRoom));
    let changeServerRoom = FunctionVariableContext(state => state.changeServerRoom);
    let [displayCurrentRoom, setDisplayCurrentRoomState] = useState(currentRoom);
    let setShowChangeRoom = GamePlayerContext(state => state.setShowChangeRoom);

    useEffect(() => {
        let currentRoomSubs = GamePlayerContext.subscribe(state => state.currentRoom, (data) => {
            setCurrentRoomState(data);
            setDisplayCurrentRoomState(data);
        });

        let showChangeRoomSubs = GamePlayerContext.subscribe(state=> state.showChangeRoom, (data)=>{
            setShowChangeRoomState(data);
        });

        return () => {
            currentRoomSubs();
            showChangeRoomSubs();
        }
    });

    useEffect(()=>{
        setDisplayCurrentRoomState(currentRoom);
    },[showChangeRoom]);

    return showChangeRoom && <div className="change-server-room-base">
        <div className="change-server-room-panel">
            <div className="change-server-room-title">Change Room</div>
            <div className="change-server-room-background"><div className="change-room">{displayCurrentRoom}</div></div>
            <button className="change-server-room-btn change-server-room-btn-left" onClick={() => {
                let d = parseInt(displayCurrentRoom) - 1;
                if (d < 0) d = 0;
                setDisplayCurrentRoomState(d);
            }}>{"<"}</button>
            <button className="change-server-room-btn change-server-room-btn-right" onClick={() => {
                let d = parseInt(displayCurrentRoom) + 1;
                if (d > 20) d = 20;
                setDisplayCurrentRoomState(d);
            }}>{">"}</button>
            <button className="change-server-room-act change-server-room-btn-save" onClick={()=>{
                setShowChangeRoom(false);
            }}>Cancel</button>
            <button className="change-server-room-act change-server-room-btn-cancel" onClick={()=>{
                if(changeServerRoom){
                    changeServerRoom(displayCurrentRoom);
                    setShowChangeRoom(false);
                } 
            }}>Save</button>
        </div>
    </div>
}