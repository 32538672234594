import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Image, Modal } from 'react-bootstrap';
import { GameConfigContext, SceneContext } from '../../context/game-context';
import { MiniMap } from './mini-map';

export default function MinimapModal({ }) {
    const [showModal, setShowModal] = useState(false);
    const checkMinimap = SceneContext(state => state.minimap);
    const [minimap, setMinimap] = useState(SceneContext((state) => state.minimap));
    const [imageExist, setImageExist] = useState(true);

    const defaultLogoScene = GameConfigContext(state => state.minimapLogo);
    const [logo, setLogo] = useState(defaultLogoScene);

    useEffect(() => {

        let minimapSubs = SceneContext.subscribe((state) => state.minimap, (data) => {
            setMinimap(data);
        });
        
        const logoSubs = GameConfigContext.subscribe(state => state.minimapLogo, (data) => {
            setLogo(data);
        })

        return () => {
            minimapSubs();
            logoSubs();
        }
    }, []);

    const handleClose = () => {
        setShowModal(false);
    }
    // console.log(`/img/logo/${minimap.sceneName}/logo.png`);
    return (checkMinimap && <>
        <a href="#" className="minimap-modal-btn" onClick={() => setShowModal(true)}>
            {logo ?
                <Image src={logo} className="w-100" onError={() => setImageExist(false)} />
                :
                <svg
                    data-src="./icon/btn-explore.svg"
                    fill="currentColor"
                    className='w-100 full-height'
                />
            }
        </a>
        <Container>
            <Modal show={showModal} className="tutorial" centered size="lg">
                <Modal.Body className="no-padding">
                    <MiniMap handleClose={handleClose} setShowModal={setShowModal} />
                </Modal.Body>
            </Modal>
        </Container>
        {/* <div className='points-div'>
            <h5>Points</h5>
            <span className='points'>40.000</span>
        </div> */}
    </>
    )
}
