import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { GameConfigContext } from "../../context/game-context";

export const RequiredUserDataModal = ({ showRequiredUserData, requiredUserData, setRequiredUserData, setShowRequiredUserData }) => {

    const setLoading = GameConfigContext((state) => state.setLoading);
    useEffect(() => {

        setLoading(false, null)
    }, [])
    return (
        <div className="required-user-data">
            <Container>
                <h4>We use necessary your user information to make our site work.
                    We'd also like to set analytics cookies that help us make improvements by measuring how you use the site.
                    These will be set only if you accept.</h4>
                <div>
                    {/* <Button variant="primary" type="button" onClick={() => setShowRequiredUserData(false)}>
                    Decline
                </Button> */}
                    <Button variant="primary" type="button" className={"btn-default"} onClick={() => setRequiredUserData(false)}>
                        Accept
                    </Button>
                </div>
            </Container>
        </div>
    )
}