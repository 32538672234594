import { useTrimesh } from "@react-three/cannon";
import { Plane, Text } from "@react-three/drei";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";
import { LoadClonedGeometry } from "./utils";

export const SocialMediaButton = ({ loadedGeometry, text, popupColor, textColor, textReadedColor, material, materialArtifact, sceneName, object, artifact, popupID, keyObject, visible, indices, vertics, clickMePosition, data, geometryArtifact, geometry }) => {
    const onPopupClick = useRef(FunctionVariableContext((state) => state.clickSocialMediaButton));
    const is360 = SceneContext(state => state.is360);
    const orbitOnly = SceneContext(state => state.orbitOnly);

    const [isLoaded, SetLoaded] = useState(false);
    const [isCliked, setClicked] = useState(false);
    const [showClick, setShowClick] = useState(false);
    const [isArtifact, setIsArtifact] = useState(false);
    const showClickRef = useRef(false);
    // const geometry = useRef();
    // const geometryArtifact = useRef();
    const scaleUnity = 10000;
    const mesh = useRef();
    const clickMeRef = useRef();
    const positionObj = [object.position.x / scaleUnity, object.position.y / scaleUnity, object.position.z / scaleUnity];
    const rotationObj = object.rotation.z == null ?
        {
            rotation: [object.rotation.x / scaleUnity, object.rotation.y / scaleUnity, object.rotation.z / scaleUnity, 'XYZ']
        } :
        {
            quaternion: [object.rotation.x / scaleUnity, object.rotation.y / scaleUnity, object.rotation.z / scaleUnity, object.rotation.w / scaleUnity]
        };
    const scaleObj = [object.scale.x / scaleUnity, object.scale.y / scaleUnity, object.scale.z / scaleUnity];


    let positionArtifact = [0, 0, 0];
    let rotationArtifact = [0, 0, 0, 0];
    let scaleArtifact = [1, 1, 1];

    if (artifact) {
        positionArtifact = [artifact.position.x / scaleUnity, artifact.position.y / scaleUnity, artifact.position.z / scaleUnity];
        rotationArtifact = [artifact.rotation.x / scaleUnity, artifact.rotation.y / scaleUnity, artifact.rotation.z / scaleUnity, artifact.rotation.w / scaleUnity];
        scaleArtifact = [artifact.scale.x / scaleUnity, artifact.scale.y / scaleUnity, artifact.scale.z / scaleUnity];
    }

    useEffect(() => {
        const LoadData = async () => {
            // geometry.current = await LoadClonedGeometry(loadedGeometry, object.meshid, object.position, object.rotation, object.scale, sceneName, true);

            // if (artifact) {
            //     if (artifact.meshid.length > 0) {
            //         geometryArtifact.current = await LoadClonedGeometry(loadedGeometry, artifact.meshid, artifact.position, artifact.rotation, artifact.scale, sceneName, true);
            //         setIsArtifact(true);
            //     }
            // }

            SetLoaded(true);
        }

        const onPopupClickSubs = FunctionVariableContext.subscribe((state) => state.clickSocialMediaButton, (data) => {
            onPopupClick.current = data;
        });

        LoadData();

        return () => {
            onPopupClickSubs();
            if(geometry) geometry.dispose();
            if (mesh.current) mesh.current.geometry.dispose();
            if (clickMeRef.current) clickMeRef.current.geometry.dispose();
            if (isArtifact && geometryArtifact) geometryArtifact.dispose();
        }
    }, []);

    useEffect(() => {

        if (clickMeRef.current) clickMeRef.current.position.y = clickMePosition == "bottom" ? mesh.current.geometry.boundingBox.min.y * mesh.current.scale.y : mesh.current.geometry.boundingBox.max.y * mesh.current.scale.y;
    }, [showClick]);

    return (
        isLoaded &&
        <group
            position={positionObj}
            {...rotationObj}
            dispose={null}
        >
            {
                indices && vertics && <SocialMediaButtonCollider
                    indices={indices}
                    vertics={vertics}
                    setShowClick={setShowClick}
                    showClickRef={showClickRef}
                />
            }
            {
                <mesh
                    ref={mesh}
                    key={`popup-${keyObject}`}
                    geometry={geometry}
                    scale={scaleObj}
                    visible={visible}
                    castShadow
                    receiveShadow
                    onClick={
                        (e) => {
                            if (onPopupClick.current) {
                                // console.log(is360);
                                if (showClickRef.current || is360 || orbitOnly) {
                                    if (data.photos.length > 0) {
                                        onPopupClick.current(data);
                                        setClicked(true);
                                    }
                                }
                            }
                        }}
                    material={material.material}
                />
            }
            {
                showClick && text.length > 0 && <Plane
                    ref={clickMeRef}
                    args={[1, 0.4]}
                    rotation={[0, 3.14159, 0]}
                    position={[0, -1, -0.02]}
                >
                    <meshBasicMaterial
                        color={`#${popupColor}`}
                    />
                    <Text
                        position={[0, 0, 0.01]}
                        color={isCliked ? `#${textReadedColor}` : `#${textColor}`}
                        fontSize={0.15}
                        maxWidth={200}
                        lineHeight={1}
                        letterSpacing={0.02}
                        textAlign={'left'}
                        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                        anchorX="center"
                        anchorY="middle"
                    >
                        {text}
                    </Text>
                </Plane>
            }

            {
                isArtifact && isCliked && <mesh
                    geometry={geometryArtifact}
                    position={positionArtifact}
                    quaternion={rotationArtifact}
                    scale={scaleArtifact}
                >
                    {materialArtifact.material}
                </mesh>
            }
        </group>

    );
}

const SocialMediaButtonCollider = ({ indices, vertics, setShowClick, showClickRef }) => {

    const [popupRef, popupApi] = useTrimesh(() => ({
        mass: 0,
        type: "Static",
        isTrigger: true,
        args: [vertics, indices],
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        onCollide: (e) => {
            setShowClick(true);
            showClickRef.current = true;
        },
        onCollideEnd: (e) => {
            setShowClick(false);
            showClickRef.current = false;
        }
    }));


    return <group ref={popupRef} ></group>
}
