import { useEffect, useState } from "react"
import { SceneContext } from "../../context/game-context";
import "../../../css/npc-popup.css"
import { useRef } from "react";
import { useWeb3Service } from "../../../blockhain/provider/web3.provider";
import axios from "axios";
import { Button, Col, Modal, Row } from "react-bootstrap";

export const NPCPopup = () => {

    const [npcSelected, setNPCSelectedState] = useState(SceneContext(state => state.npcSelected));

    const setNPCSelected = SceneContext((state) => state.setNPCSelected);
    const [chatId, setChatId] = useState(0);
    const [showInput, setShowInput] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState(false);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const showInputRef = useRef(false);
    const inputRef = useRef();
    const userId = useRef();

    const { isAuthenticated, user } = useWeb3Service();

    useEffect(() => {
        userId.current = user.id;
    }, [isAuthenticated]);

    useEffect(() => {
        let npcSelectedSubs = SceneContext.subscribe((state) => state.npcSelected, (data) => {

            showInputRef.current = false;
            setShowInput(false);
            setChatId(0);
            setNPCSelectedState(data);
        });

        return () => {
            npcSelectedSubs();
        }
    }, []);

    return (
        <>

            <Modal
                show={showModalMsg}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>{messageTitle}</h5>
                    <br />

                    <Row>
                        <Col>
                            {messageBody}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => setShowModalMsg(false)}>Okay</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {npcSelected &&
                <div className="npc-pop">
                    <div className="npc-pop-body">
                        {!showInput && <div className="npc-pop-panel" dangerouslySetInnerHTML={{ __html: npcSelected.data[chatId] }}></div>}
                        {showInput && <div className="npc-pop-panel"><input className="npc-input" ref={inputRef} /></div>}
                        <div className="npc-button-body">
                            <button className="btn-default button-popup skip" onClick={() => {
                                setNPCSelected(null);
                            }}>Skip</button>
                            <button className="btn-default button-popup next" onClick={() => {
                                let nextID = chatId + 1;
                                if (nextID >= npcSelected.data.length) {
                                    if (!npcSelected.usingInputAtTheEnd) {
                                        setNPCSelected(null);
                                    }
                                    else {
                                        if (!showInputRef.current) {
                                            showInputRef.current = true;
                                            setShowInput(true);
                                        }
                                        else {
                                            if (inputRef.current.value.length > 0) {

                                                let data = {
                                                    idUser: user.id,
                                                    id: npcSelected.droppedID,
                                                    event: "dgcon2022",
                                                    no_tlp: inputRef.current.value
                                                };

                                                let url = `${process.env.TREASURE_API_URL}/npc_pulsa/redeem`;
                                                // console.log(url, data);

                                                axios.post(url, data, {
                                                    crossDomain: true
                                                })
                                                    .then((res) => res.data)
                                                    .then((res) => {
                                                        setMessageTitle("Claim pulsa kamu berhasil!")
                                                        setShowModalMsg(true);
                                                    }).catch((err) => {
                                                        setMessageTitle("Yah claim pulsa kamu gagal")
                                                        setMessageBody(err.response.data.message)
                                                        setShowModalMsg(true);
                                                    });


                                                showInputRef.current = false;
                                                setShowInput(false);
                                                setNPCSelected(null);
                                            }
                                        }
                                    }
                                }
                                else {
                                    setChatId(nextID);
                                }
                            }}>Next</button>
                        </div>
                    </div>
                </div>}
        </>
    )
}