import axios from "axios";
import { Gltf2 } from "ossos";
import * as THREE from 'three';
import { Material, MeshPhysicalMaterial, TextureLoader } from "three";
import { FBXLoader } from '../../utils/loader/FBXLoader';

export const cloneAnimated = () => {

}


const LoadPlayerData = async (listAvatar) => {
    let playerJson = null;
    let animationData = {};
    let animationClip = {};

    let processPlayerJson = await axios.get(`${process.env.RESOURCE_URL}/avatars/avatars.json`, { crossDomain: true })
        .then(res => res.data)
        .then(async (res) => {
            playerJson = res;
        });

    let processAnimation = await axios.get(`${process.env.RESOURCE_URL}/animations/animations.json`, { crossDomain: true })
        .then(res => res.data)
        .then(async (res) => {
            animationData = res;
            for (let i = 0; i < res.animation.length; i++) {
                const [gltf] = await Promise.all([
                    Gltf2.fetch(`${process.env.RESOURCE_URL}/animations/${res.animation[i]}`)
                ]);

                let animationName = res.animation[i].replace('.gltf', '');
                animationClip[animationName] = gltf;
            }
        });

    const [aura] = await Promise.all([
        Gltf2.fetch(`${process.env.RESOURCE_URL}/avatars/aura/aura.js`)
    ]);


    return { playerJson, animationClip, animationData, aura }
}

const LoadFBX = async (playerJson) => {
    let fbxLoaded = [];

    let fbxLoader = new FBXLoader();
    let textureLoader = new TextureLoader();
    for (let i = 0; i < playerJson.animationProps.length; i++) {
        const data = playerJson.animationProps[i];

        let fbx = await fbxLoader.loadAsync(`${process.env.RESOURCE_URL}/animations/${data.fbx}`);

        let texture = null;
        if (data.texture.length > 0) texture = await textureLoader.loadAsync(`${process.env.RESOURCE_URL}/animations/${data.texture}`, (e) => {});

        let emission = null;
        if (data.emission.length > 0) emission = await textureLoader.loadAsync(`${process.env.RESOURCE_URL}/animations/${data.emission}`);

        let material = new MeshPhysicalMaterial();
        if (texture) material.map = texture;
        if (emission) material.emissiveMap = emission;

        fbx.name = data.fbx;
        fbx.traverse((child) => {
            if (child.isMesh) {
                child.material = material;
            }
        });

        fbxLoaded.push(fbx);
    }

    return fbxLoaded;
}

export default async function PlayerLoader(listAvatar) {
    // console.log("PLAYER LOADER : LOAD")
    let object = [];
    const { playerJson, animationClip, animationData, aura } = await LoadPlayerData();
    const fbxLoaded = await LoadFBX(animationData);

    return { playerJson, fbxLoaded, animationClip, aura }
}