import React from "react";
import { useTrimesh } from "@react-three/cannon"
import { useEffect } from "react";


const MeshCollider = (props) => {
    const vertics = props.geometries.attributes.position.array;
    const indices = props.geometries.index.array;


    useEffect(()=>{
        return()=>{
            props.geometries.dispose();
        }
    },[]);

    const playerPhysicsMaterial = {
        friction: 0,
        restitution:0,
        contactEquationStiffness:0,
        contactEquationRelaxation:3,
        frictionEquationStiffness:0,
        frictionEquationRelaxation:3
      }


    const [ref] = useTrimesh(() => ({
        mass: 0,
        position: props.geometries.position,
        type: "Static",
        args: [vertics, indices],
        material:playerPhysicsMaterial,
        collisionFilterMask:2,
        collisionFilterGroup:2
    }));

    return (
        <group ref={ref}></group>
    )
}

export default MeshCollider;
