import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { FunctionVariableContext, GameConfigContext, GamePlayerContext } from '../../context/game-context';
import { ChangeSceneCircular } from './change-scene-circular';

export default function MenuCircular({ toggleHoverBoard, onChangeScene, sceneID, setOpenDevelopmentSetting, setShowTutorial, THAchievementModalRef, THRedeemModalRef }) {

    const [isHoverBoard, setHoverBoard] = useState(false);
    const [isOpenScene, setOpenScene] = useState(false);
    const [devSetting, setDevSetting] = useState(false);
    const [dgscene, setDgScene] = useState(["dgplaza", "dgaward", "dgcon", "dgmainstage", "dgmall", "dgexhibition"])

    const openMinimap = useRef(FunctionVariableContext(state => state.openMinimap));
    let setShowChangeRoom = GamePlayerContext(state => state.setShowChangeRoom);


    const setShowSettingMenu = GameConfigContext((state) => state.setShowSettingMenu);
    useEffect(() => {
        let openMinimapSubs = FunctionVariableContext.subscribe(state => state.openMinimap, (data) => {
            openMinimap.current = data;
        });

        return (() => {
            openMinimapSubs()
        });
    }, []);

    const hoverboardHandler = () => {

        if (toggleHoverBoard && toggleHoverBoard.current) toggleHoverBoard.current(!isHoverBoard);
        setHoverBoard(!isHoverBoard);
    }
    const sceneHandler = () => {
        if (openMinimap.current) openMinimap.current();
        // setOpenScene(!isOpenScene);
    }

    const developmentToolsHandler = () => {
        setOpenDevelopmentSetting(!devSetting)
        setDevSetting(!devSetting)
    }

    const onOpenAudioAndCameraSetting = useRef(FunctionVariableContext(state => state.onOpenAudioAndCameraSetting));
    useEffect(() => {
        let onOpenAudioAndCameraSettingSubs = FunctionVariableContext.subscribe(state => state.onOpenAudioAndCameraSetting, (data) => {
            onOpenAudioAndCameraSetting.current = data;
        });

        return () => {
            onOpenAudioAndCameraSettingSubs();
        }
    });

    return (<>
        {isOpenScene && <ChangeSceneCircular onChangeScene={onChangeScene} sceneID={sceneID} />}
        {/* 
        <ul id="menu">
                <a className="menu-button icon-plus" href="#menu" title="Show navigation">
                    <svg
                        data-src="./icon/btn-settings.svg"
                        fill="currentColor"
                        className="w-100 full-height"
                    />
                </a>
                <a className="menu-button icon-minus" href="#0" title="Hide navigation">
                    {/* <FontAwesomeIcon icon={`fas fa-xmark`} /> 
                </a>
                <li className="menu-item">
                    <a href="#" className="button" onClick={() => setShowSettingMenu(true, 'explore')}>
                        <svg
                            data-src="./icon/btn-explore.svg"
                            fill="currentColor"
                        />
                    </a>
                    {/* <a href="#" className="button" onClick={() => hoverboardHandler()}>
                        <Image src={`./icon/${isHoverBoard ? 'btn-walk.png' : 'btn-hoverboard.png'}`} roundedCircle={true} className={"full-width"} />
                    </a> 
                </li>
                <li className="menu-item">
                    <a href="#" onClick={() => setShowTutorial(true)} >
                        <Image src="./icon/btn-help.png" roundedCircle={true} className={"full-width"} />
                    </a>
                </li>
                <li className="menu-item">

                    <a href="#" className="button" onClick={() => setShowSettingMenu(true, 'setting')}>
                        <svg
                            data-src="./icon/btn-settings.svg"
                            fill="currentColor"
                        />
                    </a>
                    {/* <a href="#" onClick={() => sceneHandler()}>
                        <Image src="./icon/btn-scene.png" roundedCircle={true} className={"full-width"} />
                    </a> 
                </li>
                <li className="menu-item">
                    <a href="#" onClick={() => developmentToolsHandler()}>
                        <FontAwesomeIcon icon={`fas fa-screwdriver-wrench`} />
                    </a>
                </li>
            </ul>
        */}
        <div className="container">
            {/* <ul id="menu">
                <a className="menu-button icon-plus" href="#menu" title="Show navigation">
                    <svg
                        data-src="./icon/btn-settings.svg"
                        fill="currentColor"
                        className="w-100 full-height"
                    />
                </a>
                <a className="menu-button icon-minus text-center" href="#0" title="Hide navigation">
                    <FontAwesomeIcon className='menu-button-close' icon={`fas fa-xmark`} />
                </a>
                {dgscene.indexOf(sceneID.current) != -1 ? <>
                    <li className="menu-item">
                        <a href="#" className="button" onClick={() => { if (THAchievementModalRef.current) { THAchievementModalRef.current(true) } }}>
                            <svg
                                data-src="./icon/btn-thropy.svg"
                                fill="currentColor"
                            />
                        </a>
                        {/* <a href="#" className="button" onClick={() => hoverboardHandler()}>
                        <Image src={`./icon/${isHoverBoard ? 'btn-walk.png' : 'btn-hoverboard.png'}`} roundedCircle={true} className={"full-width"} />
                    </a>
                    </li>
                    <li className="menu-item" >
                        <a href="#" className="button" onClick={() => setShowChangeRoom(true)}>
                            <FontAwesomeIcon icon={"fa-server"} />
                        </a>
                    </li>
                    <li className="menu-item">

                        <a href="#" className="button" onClick={() => { if (THRedeemModalRef.current) { THRedeemModalRef.current(true) } }}>
                            <svg
                                data-src="./icon/btn-prize.svg"
                                fill="currentColor"
                            />
                        </a>
                        {/* <a href="#" onClick={() => sceneHandler()}>
                        <Image src="./icon/btn-scene.png" roundedCircle={true} className={"full-width"} />
                    </a>
                    </li>
                </> : <>
                    <li className="menu-item" style={{ display: 'none' }}>
                        <a href="#" onClick={() => {
                            if (onOpenAudioAndCameraSetting.current)
                                onOpenAudioAndCameraSetting.current();
                        }}>
                            <Image src="./icon/cam-mic.svg" roundedCircle={true} className={"full-width white-icon"} />
                        </a>
                    </li>
                    <li className="menu-item" style={{ display: 'none' }}>
                    </li>
                    <li className="menu-item">
                        <a href="#" onClick={() => setShowTutorial(true)} >
                            <Image src="./icon/btn-help.png" roundedCircle={true} className={"full-width"} />
                        </a>
                    </li>
                </>}
                <li className="menu-item">
                    <a href="#" className="button" onClick={() => setShowSettingMenu(true, 'wardrobe')}>

                        <svg
                            data-src="./icon/btn-profile.svg"
                            fill="currentColor"
                            className='full-height'
                        />
                    </a>
                </li>
            </ul> */}

            <ul id="menu-right">
                <a className="menu-button right icon-plus" href="#menu-right" title="Show navigation">
                    <svg
                        data-src="./icon/btn-settings.svg"
                        fill="currentColor"
                        className="w-100 full-height"
                    />
                </a>
                <a className="menu-button right icon-minus" href="#0" title="Hide navigation">
                    {/* <FontAwesomeIcon icon={`fas fa-xmark`} /> */}
                </a>
                {dgscene.indexOf(sceneID.current) != -1 ? <>
                    <li className="menu-item">
                        <a href="#" className="button" onClick={() => { if (THAchievementModalRef.current) { THAchievementModalRef.current(true) } }}>
                            <svg
                                data-src="./icon/btn-thropy.svg"
                                fill="currentColor"
                            />
                        </a>
                        {/* <a href="#" className="button" onClick={() => hoverboardHandler()}>
                        <Image src={`./icon/${isHoverBoard ? 'btn-walk.png' : 'btn-hoverboard.png'}`} roundedCircle={true} className={"full-width"} />
                </a> */}
                    </li>
                    <li className="menu-item" >
                        <a href="#" className="button" onClick={() => setShowChangeRoom(true)}>
                            <FontAwesomeIcon icon={"fa-server"} />
                        </a>
                    </li>
                    <li className="menu-item">

                        <a href="#" className="button" onClick={() => { if (THRedeemModalRef.current) { THRedeemModalRef.current(true) } }}>
                            <svg
                                data-src="./icon/btn-prize.svg"
                                fill="currentColor"
                            />
                        </a>
                        {/* <a href="#" onClick={() => sceneHandler()}>
                        <Image src="./icon/btn-scene.png" roundedCircle={true} className={"full-width"} />
                        </a>  */}
                    </li>
                </> : <>
                    <li className="menu-item">
                        <a href="#" className="button" onClick={() => setShowChangeRoom(true)}>
                            <FontAwesomeIcon icon={"fa-server"} />
                        </a>
                    </li>
                    <li className="menu-item" style={{ display: 'none' }}>
                        <a href="#" onClick={() => {
                            if (onOpenAudioAndCameraSetting.current)
                                onOpenAudioAndCameraSetting.current();
                        }}>
                            <Image src="./icon/cam-mic.svg" roundedCircle={true} className={"full-width white-icon"} />
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="#" onClick={() => setShowTutorial(true)} >
                            <Image src="./icon/btn-help.png" roundedCircle={true} className={"full-width"} />
                        </a>
                    </li>
                </>}
                <li className="menu-item">
                    <a href="#" className="button" onClick={() => setShowSettingMenu(true, 'wardrobe')}>

                        <svg
                            data-src="./icon/btn-profile.svg"
                            fill="currentColor"
                            className='full-height'
                        />
                    </a>
                </li>
            </ul>
        </div>
    </>)
}
