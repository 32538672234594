import { useTrimesh } from "@react-three/cannon";
import { useEffect, useRef } from "react";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";

export const ChangeSceneDetection = (props) => {
    const vertics = props.data.geometry.geometry.attributes.position.array;
    const indices = props.data.geometry.geometry.index.array;
    const setScene = SceneContext((state) => state.setScene);
    const setChangeSceneCounter = SceneContext((state) => state.setChangeSceneCounter);
    const isTrigger = useRef(false);

    const playerPhysicsMaterial = {
        friction: 0,
        restitution: 0,
        contactEquationStiffness: 0,
        contactEquationRelaxation: 3,
        frictionEquationStiffness: 0,
        frictionEquationRelaxation: 3
    }

    useEffect(()=>{
        return()=>{
            props.data.geometry.geometry.dispose();
        }
    },[]);

    const [ref] = useTrimesh(() => ({
        mass: 0,
        position: props.data.geometry.geometry.position,
        type: "Static",
        args: [vertics, indices],
        material: playerPhysicsMaterial,
        collisionFilterMask: 2,
        collisionFilterGroup: 2,
        isTrigger: true,
        onCollideBegin: (collider) => {
            if (setScene) {
                setChangeSceneCounter(props.data.data.scene, props.data.data.displayName? props.data.data.displayName : props.data.data.scene);
                // window.history.replaceState(null, null, "/"+ props.data.data.scene)
                // setScene(props.data.data.scene);
            }
        },
        onCollideEnd:(collider)=>{
            if (setScene) {
                setChangeSceneCounter(null);
                // window.history.replaceState(null, null, "/"+ props.data.data.scene)
                // setScene(props.data.data.scene);
            }
        }
    }));

    return (
        <group ref={ref} dispose={null}></group>
    )
}