import React from "react";
import { useEffect, useContext, useState } from "react";
import { GamePlayerContext, LocalPlayerContext } from "../../game/context/game-context";
import { Web3Context } from "../context/web3.context";
import { Web3Service } from "../service/web3.service";

export function Web3Provider({ children }) {
  const web3Service = Web3Service();
  const [isInitialized, setIsInitialized] = useState(false);

  const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);

  const getLocalPlayerSetting = GamePlayerContext((state) => state.getLocalPlayerSetting);

  const setGamePlayerContext = GamePlayerContext(
    (state) => state.setGamePlayerContext
  );

  useEffect(() => {
    const initialize = async () => {
      const checkAuth = await web3Service.initialize();
      if (checkAuth) {
        updateLocalPlayerSetting(checkAuth);
        setGamePlayerContext({
          isAccountBinded: checkAuth.isAccountBinded,
          isProfileUpdated: true,
        });
      } else {
        // check if there any local storage skin
        const getLocalePlayerSetting = localStorage.getItem("local_player_setting");
        if (getLocalePlayerSetting) {
          updateLocalPlayerSetting(JSON.parse(getLocalePlayerSetting))
          setGamePlayerContext({
            isProfileUpdated: true,
          });
        }
      }
      setIsInitialized(true);
    };

    initialize();
  }, []);

  return (
    <Web3Context.Provider value={{ ...web3Service }}>
      {isInitialized && children}
    </Web3Context.Provider>
  );
}

export const useWeb3Service = () => React.useContext(Web3Context);
