class MultiplayerUser {
    constructor() {

        this.i = "";
        this.n = "";//name
        this.a = "";//alias or display name
        this.r = "";
        this.sc = "";
        this.g = "";
        this.h = "";
        this.d = "";
        this.b = "";
        this.l = "";
        this.sh = "";
        this.b = "";
        this.s = "";
        this.hd = "";
        this.eb = "";
        this.e = "";
        this.m = "";
        this.skc = "";
        this.hc = "";


        this.ht = "";
        this.hl = "";
        this.th = "";
        this.mk = "";
        this.tr = "";
        this.er = "";
        this.fh = "";

        this.uid = "";
    }

    toJson() {
        return JSON.stringify(this.getData());
    }

    getData() {
        return {
            i: this.i,
            n: this.n,
            a: this.a,
            r: this.r,
            sc: this.sc,
            g: this.g,
            h: this.h,
            d: this.d,
            b: this.b,
            l: this.l,
            sh: this.sh,
            bs: this.bs,
            s: this.s,
            hd: this.hd,
            eb: this.eb,
            e: this.e,
            m: this.m,
            f: this.f,
            skc: this.skc,
            hc: this.hc,

            ht: this.ht,
            hl: this.hl,
            th: this.th,
            mk: this.mk,
            tr: this.tr,
            er: this.er,
            fh: this.fh,

            uid: this.uid
        }
    }
}

export { MultiplayerUser }