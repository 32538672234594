import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Billboard, Html, Text } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import * as THREE from "three";
import { Vector3 } from "three";
import { AudioContext } from "../../context/game-context";

export const PlayerName = ({ name, id, position, scale, playerModel, isFPS, isLocal, children }) => {

  const groupRef = useRef();
  const htmlRef = useRef();
  let [isFPSMode, setFPSMode] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const lastParent = useRef();

  useEffect(() => {
    const speakingPlayerSub = AudioContext.subscribe(state => state.playerListSpeaking, (data) => {
      if (data != null) {
        setIsSpeaking(data.includes(id));
      }
    })

    let localPlayerSpeaking = null;
    if(isLocal) {
      localPlayerSpeaking = AudioContext.subscribe(state => state.isLocalSpeaking, (data)=>{
        setIsSpeaking(data);
      });
    }

    return () => {
      speakingPlayerSub();
      localPlayerSpeaking?.();
    }
  }, []);

  useFrame(() => {
    if (playerModel && playerModel.current && playerModel.current.topHead && groupRef.current) {
      const getTopheadVector = playerModel.current.topHead.world.pos;
      groupRef.current.position.set(getTopheadVector[0], getTopheadVector[1] - 0.52, getTopheadVector[2]);
    }
  });

  let fps = isLocal ? isFPS.current : false;
  useEffect(() => {
    if (isLocal) setFPSMode(isFPS.current);
  }, [fps]);

  return (
    <Suspense fallback={null}>
      {!isFPSMode &&
        <group ref={groupRef}>
          <Html
            ref={htmlRef}
            distanceFactor={1}
            transform
            fullscreen
            prepend
            sprite
            scale={2.5}
          >
            <div className="player-name-3d">
              {isSpeaking && <div className="player-speak-indicator">
                <div className="player-speak-icon">
                  <FontAwesomeIcon icon={"fas fa-volume-up"} />
                </div>
              </div>}
              {name}
            </div>
          </Html>
          {children}
        </group>
      }
    </Suspense>
  )
}
