import { useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useContext, useEffect, useRef, useState } from "react"
import { LoopOnce, LoopRepeat, Vector3 } from "three";
import { FunctionVariableContext, GamePlayerContext, SceneContext } from "../../context/game-context";
import { SocketState } from "../player-component/context/socket-state";
import { PlayerModelAndAnimation } from "../player-model-animation";

export const AnimationObject = ({networkManager, clips, syncedToServer, object, clipId, isControllable, npcHoverboard, loops, teleportationPosition, forcedAvatarAnimation, npcs, playerAssetRef }) => {

    const onSelectEmojiFunction = useRef(FunctionVariableContext(state => state.onSelectEmoji));
    const onTeleportDetection = useRef(FunctionVariableContext(state => state.onTeleportDetection));
    const { actions } = useAnimations(clips, object);
    const addControllableAnimation = GamePlayerContext(state => state.addControllableAnimation);
    const currentID = useRef(0);
    const onChangeCurrentAnimationId = useRef();
    const npcList = useRef([]);

    window.animationObject = object;

    const playAnimation = (actionName) => {
        let keys = Object.keys(actions);
        keys.forEach((key, i) => {
            if (actionName == key) {
                if (teleportationPosition && teleportationPosition[i] && teleportationPosition[i].name.length > 0) {
                    onTeleportDetection.current(teleportationPosition[i], null, null, teleportationPosition[i]);
                }
                if (forcedAvatarAnimation && forcedAvatarAnimation[i] && forcedAvatarAnimation[i].length > 0) {

                    onSelectEmojiFunction.current(forcedAvatarAnimation[i]);
                }

                actions[key].play();

                if (onChangeCurrentAnimationId.current) onChangeCurrentAnimationId.current(i);
            }
            else {
                actions[key].stop();
            }
        })
    }

    const OnSyncedAnimation = (data) => {
        if(data.id == clipId) playAnimation(data.state);
    }

    const OnUpdateSyncedAnimation = (data) => {
        if(data.id == clipId) playAnimation(data.state);
    }


    const playAnimationAndUpdate = (actionName) => {
        if(syncedToServer) networkManager.UpdateSyncAnimation(clipId, actionName);
        else playAnimation(actionName);
    }

    useEffect(() => {
        let onTeleportDetectionSubs = FunctionVariableContext.subscribe(state => state.onTeleportDetection, (data) => {
            onTeleportDetection.current = data;
        });

        let onSelectEmojiSubs = FunctionVariableContext.subscribe((state) => state.onSelectEmoji, (data) => {
            onSelectEmojiFunction.current = data;
        });

        networkManager.on("OnSyncedAnimation", OnSyncedAnimation);
        networkManager.on("OnUpdateSyncedAnimation", OnUpdateSyncedAnimation);

        // console.log("CLIPS", clips);
        if (syncedToServer) networkManager.SyncAnimation(clipId, clips[0].name);

        return () => {
            onTeleportDetectionSubs();
            onSelectEmojiSubs();

            networkManager.off("OnSyncedAnimation", OnSyncedAnimation);
            networkManager.off("OnSyncedAnimation", OnSyncedAnimation);
        }
    });


    useEffect(() => {
        let keys = Object.keys(actions);

        npcList.current = [];
        if (npcs) {
            for (let i = 0; i < npcs.length; i++) {
                let n = {
                    npcId: npcs[i].id,
                    id: npcs[i].name,
                    isShowOnServerTrigger: npcs[i].isShowOnServerTrigger ? npcs[i].isShowOnServerTrigger : false,
                    animations: npcs[i].animation,
                    playerSettings: npcs[i].playerSetting,
                    command: npcs[i].command,
                    data: npcs[i].data,
                    disableDistanceClick: npcs[i].disableDistanceClick,
                    position: [npcs[i].obj.position.x / 10000, npcs[i].obj.position.y / 10000, npcs[i].obj.position.z / 10000],
                    rotation: [npcs[i].obj.rotation.x / 10000, npcs[i].obj.rotation.y / 10000, npcs[i].obj.rotation.z / 10000],
                    scale: [npcs[i].obj.scale.x / 10000, npcs[i].obj.scale.y / 10000, npcs[i].obj.scale.z / 10000],
                    forward: [npcs[i].obj.forward.x / 10000, npcs[i].obj.forward.y / 10000, npcs[i].obj.forward.z / 10000],
                }

                if (npcs[i].id.length > 0) {
                    npcList.current.push(n);
                }
                else {
                    npcList.current.push(null);
                }
            }
        }

        keys.forEach((key, i) => {
            if (loops && loops[i] != undefined && loops[i] == true) {
                actions[key].loop = LoopRepeat;
            }
            else if (loops && loops[i] != undefined && loops[i] == false) {
                actions[key].loop = LoopOnce;
                actions[key].clampWhenFinished = true;
            }
            else {
                actions[key].loop = LoopRepeat;
            }

            actions[key]._mixer.addEventListener('finished', (e) => {
                currentID.current++;

                let objectKeys = Object.keys(actions);
                if (currentID.current >= objectKeys.length) {
                    currentID.current = 0;
                }

                if (loops && loops[i] != undefined && loops[i] == true) {
                    actions[objectKeys[currentID.current]].play();
                }
                else if (loops && loops[i] != undefined && loops[i] == false) {

                }
                else {
                    actions[objectKeys[currentID.current]].play();
                }
            }
            );
        });

        if (isControllable) {
            addControllableAnimation({
                name: clipId,
                list: keys,
                npcHoverboard: npcHoverboard,
                playAnimation: playAnimationAndUpdate
            });
        }

        actions[keys[currentID.current]].play();

    });

    return (<group>
        <NPCAnimation npcList={npcList} onChangeCurrentAnimationId={onChangeCurrentAnimationId} playerAssetRef={playerAssetRef} />
        <primitive object={object} />
    </group>);
}

const NPCAnimation = ({ npcList, onChangeCurrentAnimationId, playerAssetRef }) => {

    let [animationID, setAnimationId] = useState(0);
    let [currentNPC, setCurrentNPC] = useState(null);
    let playerModel = useRef();

    useEffect(() => {
        onChangeCurrentAnimationId.current = (id) => {
            setAnimationId(id);
        }
    }, []);

    useEffect(() => {
        if (npcList.current[animationID]) {
            setCurrentNPC(
                <group position={npcList.current[animationID].position} scale={npcList.current[animationID].scale}>
                    <PlayerModelAndAnimation
                        playerModel={playerModel}
                        skin={npcList.current[animationID].playerSettings.skin}
                        base={npcList.current[animationID].playerSettings.base}
                        skinColor={npcList.current[animationID].playerSettings.skinColor}
                        hairColor={npcList.current[animationID].playerSettings.hairColor}
                        head={npcList.current[animationID].playerSettings.head}
                        eyebrow={npcList.current[animationID].playerSettings.eyebrow}
                        eyes={npcList.current[animationID].playerSettings.eyes}
                        mouth={npcList.current[animationID].playerSettings.mouth}
                        gender={npcList.current[animationID].playerSettings.gender}
                        hair={npcList.current[animationID].playerSettings.hair}
                        upperBody={npcList.current[animationID].playerSettings.upperBody}
                        lowerBody={npcList.current[animationID].playerSettings.lowerBody}
                        feet={npcList.current[animationID].playerSettings.feet}

                        hat={npcList.current[animationID].playerSettings.hat}
                        helmet={npcList.current[animationID].playerSettings.helmet}
                        topHead={npcList.current[animationID].playerSettings.topHead}
                        mask={npcList.current[animationID].playerSettings.mask}
                        tiara={npcList.current[animationID].playerSettings.tiara}
                        earing={npcList.current[animationID].playerSettings.earing}
                        facialHair={npcList.current[animationID].playerSettings.facialHair}

                        assetRef={playerAssetRef}

                        isLocal={false}
                    // onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}
                    />
                </group>
            );
        }
        else {
            setCurrentNPC(null);
        }

    }, [animationID]);

    useFrame(() => {
        if (npcList.current[animationID]) {
            if (playerModel.current && playerModel.current.setAnimation != undefined) {
                playerModel.current.setAnimation(npcList.current[animationID].animations[0]);

                playerModel.current.position.y = 0.7;

                let forward = new Vector3(npcList.current[animationID].forward[0], npcList.current[animationID].forward[1], npcList.current[animationID].forward[2]);
                let targetLookAt = new Vector3(npcList.current[animationID].position[0], npcList.current[animationID].position[1], npcList.current[animationID].position[2]);
                targetLookAt.add(forward);

                playerModel.current.lookAt(targetLookAt);
            }
        }
    });

    return <group>{currentNPC}</group>
}

