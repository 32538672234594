import { useEffect, useRef, useState } from "react";
import { GamePlayerContext } from "../../context/game-context";
import "../../../css/drop-item-popup.css";

export const DropItemPopup = () => {

    const [dropItem, setDropItem] = useState(GamePlayerContext((state) => state.dropItem));
    const [currentDate, setCurrentDate] = useState(Math.floor(Date.now() / 1000));
    const interval = useRef();

    const fancyTimeFormat = (duration)=>
    {
        // Hours, minutes and seconds
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        var ret = "";

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }


    useEffect(() => {
        let dropItemSubs = GamePlayerContext.subscribe((state) => state.dropItem, (data) => {
            setDropItem(data);
        });

        setInterval(() => {
            setCurrentDate(Math.floor(Date.now() / 1000))
        }, 1000);

        return () => {
            dropItemSubs();
        };
    });

    return (dropItem && parseInt(dropItem.end) - currentDate > 0 &&
        <div className="drop-item">
            <div className="drop-item-title">FIND DG ICON</div>
            <div className="drop-item-time">{parseInt(dropItem.end) - currentDate > 0 ? fancyTimeFormat(parseInt(dropItem.end) - currentDate) : 0}</div>
        </div>);
}